import React from 'react';
import type { CountryCode } from '@novacredit/frontend-common/utils/countries';
import { CARDSHOP_LIVE_COUNTRY_CODES } from '@novacredit/frontend-common/utils/countries';
import { DesignSystemButton } from '@novacredit/pandorasbox';

import CountrySelect from 'components/common/CountrySelect';
import CtaCard from 'components/common/CtaCard';

import './MovingFromPrompt.scss';

const MovingFromPrompt = ({
	onChange,
	onSubmit,
	trackingPage,
	countryCodes = CARDSHOP_LIVE_COUNTRY_CODES,
	label = 'I moved to the U.S. from...',
	buttonText = 'Get started',
}: {
	onChange: (country: CountryCode) => void;
	onSubmit: () => void;
	countryCodes: CountryCode[];
	trackingPage: 'Newcomer Home Page' | 'MVM Overview Page';
	label?: string;
	buttonText?: string;
}) => (
	<CtaCard
		className="moving-from-prompt"
		button={
			<DesignSystemButton onClick={() => onSubmit()} tag="button">
				{buttonText}
			</DesignSystemButton>
		}
	>
		<label className="country-select-label" htmlFor="countrySelect">
			{label}
		</label>
		<CountrySelect
			countryCodes={countryCodes}
			trackingPage={trackingPage}
			placeholder="Select your country"
			onChange={onChange}
		/>
	</CtaCard>
);

export default MovingFromPrompt;
