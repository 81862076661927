import React, { useEffect, useState } from 'react';

import { DesignSystemButton } from '@novacredit/pandorasbox';

import Icon from 'components/common/Icon';
import CardListItem from './CardListItem';

import './CardList.scss';

const NUM_TO_SHOW = 3;

type Props = {
	cards: any[];
	onReset: (...args: any[]) => any;
	paginate: boolean;
};

const CardList = ({ cards, onReset, paginate }: Props) => {
	const [numShowing, setNumShowing] = useState(NUM_TO_SHOW);
	const visibleCards = paginate ? cards.slice(0, numShowing) : cards;
	useEffect(() => {
		setNumShowing(NUM_TO_SHOW);
	}, [cards]);

	const showMore = () => {
		if (numShowing < cards.length) {
			setNumShowing(numShowing + NUM_TO_SHOW);
		}
	};

	if (!cards.length) {
		return (
			<div className="card-list-no-cards-found" data-testid="no-cards-found">
				<Icon size="3x" name="credit-card" />
				<p>We couldn&apos;t find any cards that match your filters</p>
				<DesignSystemButton onClick={onReset} tag="button" variant="ghost">
					Clear all Filters
				</DesignSystemButton>
			</div>
		);
	}

	return (
		<>
			{visibleCards.map(card => (
				<CardListItem key={card.slug} card={card} />
			))}

			{visibleCards.length < cards.length && (
				<DesignSystemButton
					className="w-100"
					variant="ghost"
					onClick={showMore}
					tag="button"
					data-testid="showmore-button"
				>
					Show more cards
				</DesignSystemButton>
			)}
		</>
	);
};

export default CardList;
