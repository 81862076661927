/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import classNames from 'classnames';

import Icon from './Icon';
import SubmenuLink from './SubmenuLink';

import './MenuDropdown.scss';

const MenuDropdown = ({ className, title, columns, icon, align = 'left' }) => {
	return (
		<li className={classNames('uq-menu-dropdown', className)}>
			{icon && <Icon icon={icon} width="20" className="uq-menu-dropdown-left-icon" />}
			<button type="button" className="uq-menu-dropdown-title">
				{title}
			</button>
			<Icon icon="chevron-down" width="20" className="uq-menu-dropdown-chevron" />
			<ul
				className={classNames('uq-menu-dropdown-list', {
					'align-left': align === 'left',
					'align-right': align === 'right',
				})}
			>
				{columns?.map((column, index) => {
					const { links, ctas, columnHeadline } = column;
					return (
						<div key={index} className="uq-menu-dropdown-column">
							{columnHeadline && (
								<li className="uq-menu-dropdown-column-header">{columnHeadline}</li>
							)}
							{links.map((link, index) => (
								<SubmenuLink
									key={index}
									href={link.href}
									label={link.label}
									icon={link.icon}
									newTab={link.newTab}
									onClick={link.onClick}
								/>
							))}
							{ctas && <hr className="uq-menu-dropdown-divider" />}
							{ctas &&
								ctas.map((cta, index) => (
									<SubmenuLink
										key={index}
										href={cta.href}
										label={cta.label}
										icon={cta.icon}
										newTab={cta.newTab}
									/>
								))}
						</div>
					);
				})}
			</ul>
		</li>
	);
};

export default MenuDropdown;
