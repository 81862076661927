import React from 'react';
import { Field } from 'formik';

import Icon from 'components/common/Icon';

import './Checkbox.scss';

type OwnProps = {
	label: string;
	name: string;
	checked?: boolean;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
// eslint-disable-next-line no-use-before-define
type Props = OwnProps & typeof Checkbox.defaultProps;

const Checkbox = ({ label, name, checked }: Props) => {
	return (
		// eslint-disable-next-line jsx-a11y/label-has-for
		<label className="checkbox">
			<Field type="checkbox" name={name} checked={checked} />
			<span className="checkmark">
				<Icon name="check" />
			</span>
			{label}
		</label>
	);
};

Checkbox.defaultProps = {
	checked: false,
};

export default Checkbox;
