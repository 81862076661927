import { common, utils } from '@novacredit/validations';

const { validateRequired } = utils;
const { isEmail, isName, minLength, maxLength, isAsciiorAsciiConvertibleCharacters } = common;

const interpolation = /{(.[^}]+)}/g;

const validationMessages = {
	VALIDATION_ERROR_MESSAGE_EMAIL: '^Please enter your email in the format example@email.com',
	VALIDATION_ERROR_MESSAGE_REQUIRED: "^Can't be blank",
	VALIDATION_ERROR_MESSAGE_NAME: '^Symbols & numbers are invalid',
	VALIDATION_ERROR_MESSAGE_IS_NOT_ASCII_OR_ASCII_CONVERTIBLE_CHARACTERS:
		'^Only Latin characters (e.g. a, â) are valid',
	VALIDATION_ERROR_MESSAGE_MIN_LENGTH: '^Must be at least {length} characters',
	VALIDATION_ERROR_MESSAGE_MAX_LENGTH: '^Must be at most {length} characters',
};

const validateEmail = (value: string) => {
	return validateRequired(value, isEmail, minLength(1), maxLength(100));
};

const validateName = (value: string) => {
	return validateRequired(
		value,
		isName,
		isAsciiorAsciiConvertibleCharacters,
		minLength(2),
		maxLength(50),
	);
};

const validateForm = validators => values => {
	return Object.keys(validators).reduce((errors, fieldKey) => {
		const fieldValue = values[fieldKey];
		const validateField = validators[fieldKey];

		if (!validateField) {
			return errors;
		}

		const fieldErrors = validateField(fieldValue);

		if (fieldErrors) {
			const interpolatedMessage = validationMessages[fieldErrors.label].replace(
				interpolation,
				(_, key) => fieldErrors.data[key],
			);
			errors[fieldKey] = interpolatedMessage;
		}
		return errors;
	}, {});
};

export { validateEmail, validateName, validateRequired, validateForm, validationMessages };
