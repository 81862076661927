import requests from '../utils/requests';
import type { Env } from '../types';

const EVENT_API = {
	// development: 'https://api.aphrodite.novacredit.com/events-dev/create',
	development: 'http://localhost:8051/events/create',
	staging: 'https://api.aphrodite.novacredit.com/events/create',
	production: 'https://api.novacredit.com/events/create',
};

class ServerError extends Error {
	constructor(status: number) {
		super(`${status} error`);
		this.name = 'A server error occurred while posting event';
	}
}

export const postEvent = ({ event, nodeEnv }: { event: {}; nodeEnv: Env }): Promise<Response> => {
	const url = EVENT_API[nodeEnv];
	const body = {
		events: [event],
		sentAt: new Date().toISOString(),
	};

	const sendRequest = async () => {
		const response = await requests.post(url, body);
		if (response.status >= 500) {
			throw new ServerError(response.status);
		}

		return response;
	};

	const sendRequestWithRetry = requests.withRetry(sendRequest, {
		retries: 4,
		factor: 1.5,
		initialDelay: 1000,
		maxDelay: 5000,
		delayFn: requests.delay,
		isRetriableError: err => err instanceof ServerError || requests.isFailedToFetchError(err),
	});

	return sendRequestWithRetry();
};
