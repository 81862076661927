const Spacer2 = "2px";
const Spacer4 = "4px";
const Spacer8 = "8px";
const Spacer12 = "12px";
const Spacer16 = "16px";
const Spacer24 = "24px";
const Spacer32 = "32px";
const Spacer48 = "48px";
const Spacer64 = "64px";
var spacers = {
	Spacer2: Spacer2,
	Spacer4: Spacer4,
	Spacer8: Spacer8,
	Spacer12: Spacer12,
	Spacer16: Spacer16,
	Spacer24: Spacer24,
	Spacer32: Spacer32,
	Spacer48: Spacer48,
	Spacer64: Spacer64
};

export { spacers as s };
