import AsyncTaskQueue from '../AsyncTaskQueue';
import { retryWithTimeout } from '../helpers';
import type { InitializeOptions, TrackingDestination, TrackingOptions } from './destination';
import type { Env } from '../../types';
import type { TrackingEvent } from '../events';
import type { EventSchema } from '../eventSchema';

declare global {
	interface Window {
		dataLayer: object[];
		gtag: Function;
	}
}

/**
 * Forwards event data to Google Analytics.
 *
 * Read the docs:
 *
 * https://developers.google.com/analytics/devguides/collection/gtagjs
 *
 * Note: the `GoogleAnalyticsTag` must be rendered within the application using
 * this destination to persist events to Google Analytics.
 */
class GoogleAnalytics implements TrackingDestination {
	name = 'GoogleAnalytics';
	queue: AsyncTaskQueue;
	appName: string;
	category: string;
	nodeEnv: Env | null = null;
	options: InitializeOptions['GoogleAnalytics'] | null = null;

	constructor({ appName, category }: { appName: string; category: string }) {
		this.appName = appName;
		this.category = category;
		this.queue = new AsyncTaskQueue();
	}

	initialize({ nodeEnv, options }: { nodeEnv: Env; options?: InitializeOptions }): Promise<void> {
		this.nodeEnv = nodeEnv;
		this.options = options?.GoogleAnalytics;
		return retryWithTimeout(this._checkGaLoaded.bind(this), 10000, 250);
	}

	_checkGaLoaded(): Promise<void> {
		return new Promise((resolve, reject) => {
			if (!window.gtag) {
				reject(
					new Error(
						'window.gtag not found. Is the GoogleAnalyticsTag component rendered?',
					),
				);
			} else {
				this.queue.activate();
				resolve();
			}
		});
	}

	track({
		event,
		properties,
		getDefaultProperties,
		options,
	}: {
		event: TrackingEvent;
		properties: object;
		getDefaultProperties: () => object;
		options?: TrackingOptions;
	}): Promise<void> {
		return this._track(() => {
			return new Promise<void>(resolve => {
				window.gtag('event', event.displayName, {
					appName: this.appName,
					category: this.category,
					event_category: this.category,
					event_callback: resolve,
					...getDefaultProperties(),
					...properties,
				});
			});
		}, options);
	}

	trackPage({
		properties,
		getDefaultProperties,
		options,
	}: {
		properties: object;
		getDefaultProperties: () => object;
		options?: TrackingOptions;
	}): Promise<void> {
		return this._track(() => {
			return new Promise<void>(resolve => {
				// See also: https://developers.google.com/analytics/devguides/collection/gtagjs/pages
				window.gtag('event', 'page_view', {
					appName: this.appName,
					category: this.category,
					event_category: this.category,
					event_callback: resolve,
					...getDefaultProperties(),
					...properties,
				});
			});
		}, options);
	}

	// eslint-disable-next-line class-methods-use-this
	alias(): Promise<void> {
		return Promise.resolve();
	}

	identify({
		properties,
		options,
	}: {
		properties: EventSchema['nova.meta.IDENTIFY'];
		options?: TrackingOptions;
	}): Promise<void> {
		return this._track(() => {
			// https://developers.google.com/analytics/devguides/collection/ga4/user-properties
			window.gtag('set', 'user_properties', properties);
			return Promise.resolve();
		}, options);
	}

	setUserProps({
		properties,
		options,
	}: {
		properties: EventSchema['nova.meta.IDENTIFY'];
		options?: TrackingOptions;
	}): Promise<void> {
		return this._track(() => {
			// https://developers.google.com/analytics/devguides/collection/ga4/user-properties
			window.gtag('set', 'user_properties', properties);
			return Promise.resolve();
		}, options);
	}

	_track(task: () => Promise<void>, options?: TrackingOptions): Promise<void> {
		if (options?.GoogleAnalytics?.skip) {
			return Promise.resolve();
		}

		return this.queue.enqueue(() => {
			if (this.options?.skip) {
				return Promise.resolve();
			}

			if (!window.gtag) {
				throw new Error(
					'Google Analytics tracking was called before Google Analytics loaded',
				);
			}

			return task();
		});
	}
}

export default GoogleAnalytics;
