import React, { useContext } from 'react';
import classnames from 'classnames';

import CollapseContext from './CollapseContext';

type OwnProps = {
	children: ((...args: any[]) => any) | React.ReactNode;
	className?: string;
	tag?: string;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
// eslint-disable-next-line no-use-before-define
type Props = OwnProps & typeof CollapseToggle.defaultProps;

const CollapseToggle = ({ children, className, tag: Tag, ...attributes }: Props) => {
	// @ts-expect-error ts-migrate(2339) FIXME: Property 'isOpen' does not exist on type '{}'.
	const { isOpen, toggle, open, close } = useContext(CollapseContext);
	return (
		<Tag className={classnames('collapse-toggle', className)} onClick={toggle} {...attributes}>
			{typeof children === 'function' ? children({ isOpen, open, close, toggle }) : children}
		</Tag>
	);
};

CollapseToggle.defaultProps = {
	className: null,
	tag: 'div',
};

export default CollapseToggle;
