import SHA256 from 'crypto-js/sha256';
import type { ConsentMap } from '../consent/common';

import { getEvent, getUnvalidatedEvent } from './events';
import { logEventToConsole } from './debug';
import { resolveAllWithTimeout } from './helpers';
import type { TrackingEventName, TrackingEvent } from './events';
import type { Env, UserEnv } from '../types';
import type { EventSchema } from './eventSchema';
import type {
	InitializeOptions,
	TrackingDestination,
	TrackingOptions,
} from './destinations/destination';
import type { Properties } from './properties';

type TrackingClientConfig = {
	env?: Env;
	appName: string;
	sensitiveProperties?: string[];
	destinations: TrackingDestination[];
};

const hashProperties = (properties: Properties, keysToHash: string[]) => {
	return Object.keys(properties).reduce<Properties>((result, key) => {
		if (keysToHash.includes(key)) {
			// coerce to string before we attempt to hash
			const propertyValue = (properties[key] || '').toString();
			result[key] = SHA256(propertyValue).toString();
		} else {
			result[key] = properties[key];
		}

		return result;
	}, {});
};

// If any tracking call takes longer than this (in ms), the TrackingClient will
// automatically resolve the promise. We use this mainly to ensure that,
// when a user takes an action that would redirect them away from the site,
// event tracking succeeds before we redirect. But the timeout keeps us from
// holding up the redirect for *too* long.
const TIMEOUT_IN = 1000;

/**
 * TrackingClient dispatches product analytics tracking events to all registered
 * destinations.
 *
 * It exposes a `track` function that accepts an event name and properties,
 * as well as some other common tracking behaviors like `identify` and
 * `trackPage`.
 *
 * Consumers of this class must call `initialize` before tracking events
 * will fire to Mixpanel or other destinations.
 */
export default class TrackingClient {
	config: TrackingClientConfig;
	destinations: TrackingDestination[];
	defaultProperties: {};
	nodeEnv: Env | null;

	constructor(config: TrackingClientConfig) {
		this.config = config;
		this.destinations = config.destinations;
		this.defaultProperties = {};
		this.nodeEnv = null;
	}

	setDefaultProperties(properties = {}): Properties {
		this.defaultProperties = {
			...this.defaultProperties,
			...this.hashSensitiveProperties(properties),
		};
		logEventToConsole('Set Default Properties', this.defaultProperties);
		return this.defaultProperties;
	}

	hashSensitiveProperties = (properties: Properties): Properties => {
		return hashProperties(properties, this.config.sensitiveProperties || []);
	};

	initialize({
		nodeEnv,
		userEnv,
		options = {},
	}: {
		nodeEnv: Env;
		userEnv: UserEnv;
		options?: InitializeOptions;
	}): Promise<void> {
		this.nodeEnv = nodeEnv;
		const initializePromises = this.destinations.map(destination =>
			destination.initialize({
				nodeEnv,
				userEnv,
				options,
			}),
		);

		return resolveAllWithTimeout(initializePromises, TIMEOUT_IN);
	}

	cookieConsentUpdated(consents: ConsentMap): void {
		this.destinations.forEach(destination => {
			if (destination.cookieConsentUpdated) {
				destination.cookieConsentUpdated(consents);
			}
		});
	}

	track<T extends TrackingEventName>(
		eventName: T,
		// If Typescript complains here, EventSchema is likely missing a corresponding
		// entry for a newly added event type.
		properties: EventSchema[T],
		options?: TrackingOptions,
	): Promise<void> {
		const timestamp = new Date().toISOString();

		const event = options?.skipEventValidation
			? (getUnvalidatedEvent(eventName) as TrackingEvent)
			: getEvent(eventName);

		logEventToConsole(eventName, properties);

		if (!event && this.nodeEnv && this.nodeEnv !== 'production') {
			// eslint-disable-next-line no-console
			console.warn(
				`TrackingClient received unknown event: ${eventName}. Tracking events should be registered in /frontend-common/tracking/events.ts`,
			);
		}

		const props = properties instanceof Object ? properties : {};

		const trackPromises = this.destinations.map(destination =>
			destination.track({
				event,
				properties: this.hashSensitiveProperties(props),
				getDefaultProperties: () => this.defaultProperties,
				options,
				timestamp,
			}),
		);

		return resolveAllWithTimeout(trackPromises, TIMEOUT_IN);
	}

	alias(userId: string, options?: TrackingOptions): Promise<void> {
		const timestamp = new Date().toISOString();

		logEventToConsole('Alias', { userId });

		const aliasPromises = this.destinations.map(destination =>
			destination.alias({
				userId,
				options,
				timestamp,
			}),
		);

		return resolveAllWithTimeout(aliasPromises, TIMEOUT_IN);
	}

	identify(
		userId: string,
		properties: EventSchema['nova.meta.IDENTIFY'] = {},
		options?: TrackingOptions,
	): Promise<void> {
		const timestamp = new Date().toISOString();

		logEventToConsole('Identify', { ...properties, userId });

		const identifyPromises = this.destinations.map(destination =>
			destination.identify({
				userId,
				properties: this.hashSensitiveProperties(properties),
				options,
				timestamp,
			}),
		);

		return resolveAllWithTimeout(identifyPromises, TIMEOUT_IN);
	}

	trackPage(url: string, properties = {}, options?: TrackingOptions): Promise<void> {
		const timestamp = new Date().toISOString();

		logEventToConsole('Track Page', { ...properties, url });

		const trackPagePromises = this.destinations.map(destination =>
			destination.trackPage({
				url,
				properties: this.hashSensitiveProperties(properties),
				getDefaultProperties: () => this.defaultProperties,
				options,
				timestamp,
			}),
		);

		return resolveAllWithTimeout(trackPagePromises, TIMEOUT_IN);
	}

	setUserProps(
		properties: EventSchema['nova.meta.IDENTIFY'] = {},
		options?: TrackingOptions,
	): Promise<void> {
		const timestamp = new Date().toISOString();

		logEventToConsole('Set User Properties', properties);

		const setUserPropsPromises = this.destinations.map(destination =>
			destination.setUserProps({
				properties: this.hashSensitiveProperties(properties),
				options,
				timestamp,
			}),
		);

		return resolveAllWithTimeout(setUserPropsPromises, TIMEOUT_IN);
	}
}
