import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import Breadcrumbs from 'components/common/Breadcrumbs';
import Page from 'components/common/Page';
import OnboardingQuestion from 'components/common/Onboarding/OnboardingSurvey';
import PageTitle from 'components/common/PageTitle';

import 'components/pages/GetStartedPage.scss';

const GetStartedPage = () => {
	const location = useLocation();

	const consumerDashboardHomeCrumb = {
		title: 'Newcomer Home',
		url: '/',
		tag: Link,
	};
	const breadcrumbs = [
		{
			title: 'Eligibility Questionnaire',
			isCurrentPage: true,
		},
	];
	// @ts-expect-error
	const onboardingQuestion = <OnboardingQuestion location={location} />;

	return (
		<>
			<Page fullscreen className="eligibility-questionnaire">
				<PageTitle title="Eligibility Questionnaire" />
				<Breadcrumbs crumbs={breadcrumbs} homeCrumb={consumerDashboardHomeCrumb} />
				{onboardingQuestion}
			</Page>
		</>
	);
};

export default GetStartedPage;
