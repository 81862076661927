import React, { useCallback, useEffect } from 'react';
import { BackgroundCard, ThumbnailCard } from '@novacredit/pandorasbox';
import { Link, useLocation } from 'react-router-dom';
import { useElementOnScreen, useMount } from '@novacredit/frontend-common/hooks';

import CountrySelectCard from 'components/common/CountrySelectCard';
import FAQ from 'components/common/FAQ/FAQ';
import Page from 'components/common/Page';
import PageHero from 'components/common/PageHero';
import PageTitle from 'components/common/PageTitle';
import { getCmsURL } from 'utils';
import { APP_PATHS } from 'modules/paths';
import './MarketplacePage.scss';

import shoppingBagsImg from 'assets/images/shopping-bags.jpg';

import cardOverBlackBackdropImg from 'assets/images/card-over-black-backdrop.jpg';
import carsInRowImg from 'assets/images/cars-in-row.jpg';
import phoneOnDeskImg from 'assets/images/phone-on-desk.jpg';
import studentsTossingCapsImg from 'assets/images/students-tossing-caps.jpg';

const MarketplacePage = () => {
	const [productsRef, isVisible] = useElementOnScreen();
	const { hash } = useLocation();
	const scrollToProducts = useCallback(() => {
		if (!hash || hash !== '#products') return;
		setTimeout(() => {
			productsRef.current?.scrollIntoView({ block: 'start' });
			// scroll a bit further down if the top menu is visible, so that
			// it doesn't clip the top of the product cards
			const topMenu = document.getElementsByClassName('uq-menu')[0];
			if (topMenu?.clientHeight) {
				setTimeout(() => {
					window.scrollBy({ top: -topMenu.clientHeight });
				}, 0);
			}
		}, 0);
	}, [hash, productsRef]);

	useMount(() => {
		scrollToProducts();
		window.addEventListener('focus', scrollToProducts);
		return () => {
			window.removeEventListener('focus', scrollToProducts);
		};
	});

	useEffect(() => {
		if (isVisible) {
			window.removeEventListener('focus', scrollToProducts);
		}
	}, [isVisible, scrollToProducts]);

	return (
		<Page fullscreen className="marketplace-page">
			<PageTitle title="Marketplace" />
			<PageHero
				title="Apply for U.S. credit cards and more from day one"
				subtitle="No U.S. credit? No problem. We work with partners worldwide so you can use your foreign credit history to get essential products once you arrive in the U.S."
				backgroundImgSrc={shoppingBagsImg}
			/>
			<div className="marketplace-page-content-container">
				<div className="marketplace-page-content">
					<div className="marketplace-primary-card">
						<BackgroundCard
							title="Credit Cards"
							buttonText="Browse Credit Cards"
							body="Explore premium credit card offerings from issuers like American Express"
							ctaTag={Link}
							imageSrc={cardOverBlackBackdropImg}
							to={APP_PATHS.CARDSHOP}
						/>
					</div>

					<span ref={productsRef}>
						<div className="marketplace-products-row mt-lg-5 mt-0 mb-5">
							<ThumbnailCard
								className="marketplace-product-card mt-3 mt-lg-0 mr-lg-4 mr-0"
								title="Phone Providers"
								bodyText="Find great deals on phones and plans with our partners"
								linkText="View phone providers"
								href={getCmsURL('/partner/verizon')}
								imageSrc={phoneOnDeskImg}
							/>
							<ThumbnailCard
								className="marketplace-product-card mt-3 mt-lg-0 mr-lg-4 mr-0"
								title="Auto Financing"
								bodyText="Find the right car for your new life in the U.S."
								linkText="View financing options"
								href={getCmsURL('/partner/westlake-financial')}
								imageSrc={carsInRowImg}
							/>
							<ThumbnailCard
								className="marketplace-product-card mt-3 mt-lg-0"
								title="Student Loans"
								bodyText="Apply for a new student loan or refinance an existing one"
								linkText="View loan options"
								href={getCmsURL('/partner/mpower')}
								imageSrc={studentsTossingCapsImg}
							/>
						</div>
					</span>
					<CountrySelectCard />
					<FAQ />
				</div>
			</div>
		</Page>
	);
};

export default MarketplacePage;
