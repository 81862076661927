import imgOptions from 'assets/images/immigrant-guide/task-options.jpg';
import imgStudentloans from 'assets/images/immigrant-guide/task-studentLoans.jpg';
import imgVisa from 'assets/images/immigrant-guide/task-visa.jpg';
import imgStatus from 'assets/images/immigrant-guide/task-status.jpg';
import imgMedical from 'assets/images/immigrant-guide/task-medical.jpg';
import imgSSN from 'assets/images/immigrant-guide/task-ssn.jpg';
import imgBank from 'assets/images/immigrant-guide/task-bank.jpg';
import imgMobile from 'assets/images/immigrant-guide/task-mobile.jpg';
import imgCredit from 'assets/images/immigrant-guide/task-credit.jpg';
import imgSendMoney from 'assets/images/immigrant-guide/task-sendMoney.jpg';
import imgCar from 'assets/images/immigrant-guide/task-car.jpg';
import imgLongHousing from 'assets/images/immigrant-guide/task-longHousing.jpg';
import imgCreditScore from 'assets/images/immigrant-guide/task-creditScore.jpg';
import imgWorkCulture from 'assets/images/immigrant-guide/task-workCulture.jpg';

export const TAB_CONFIG = [
	{
		id: 'BEFORE_MOVE',
		urlHash: 'before',
		title: 'Before your move',
		mobileTitle: 'Before',
		tasks: [
			{
				taskId: 'OPTIONS',
				taskTitle: 'Understand your immigration options',
				taskDetail:
					"Now that you've decided you'd like to move to the U.S., understand your immigration options via work, study, and more.",
				taskImage: imgOptions,
				taskLink: {
					text: 'How to immigrate to the United States',
					src: 'https://www.novacredit.com/resources/how-to-immigrate-to-the-united-states/',
				},
			},
			{
				taskId: 'STUDENTLOANS',
				taskTitle: 'Find student loan options for international students',
				taskDetail:
					'If you are moving to the U.S. to study, find out how to apply for a student loan without a cosigner.',
				taskImage: imgStudentloans,
				taskLink: {
					text: 'How to get international student loans with no cosigner',
					src: 'https://www.novacredit.com/resources/international-student-loans-without-cosigner/',
				},
			},
			{
				taskId: 'VISA',
				taskTitle: 'Apply for your visa',
				taskDetail:
					'Once you decide which visa you are going to pursue, browse our guides on how to apply.',
				taskImage: imgVisa,
				taskLink: {
					text: 'Navigating visas',
					src: 'https://www.novacredit.com/categories/navigating-visas/',
				},
			},
			{
				taskId: 'STATUS',
				taskTitle: 'Check your case status',
				taskDetail:
					"Now that you've applied for your visa, learn how to check your case status with the US Citizen and Immigration Services (USCIS).",
				taskImage: imgStatus,
				taskLink: {
					text: 'How to check your USCIS case status',
					src: 'https://www.novacredit.com/resources/check-your-uscis-case-status/',
				},
			},
			{
				taskId: 'MEDICAL',
				taskTitle: 'Get temporary medical insurance',
				taskDetail:
					"Learn about temporary medical insurance, why it's important as you move, and how to get it.",
				taskImage: imgMedical,
				taskLink: {
					text: 'How to get medical insurance when traveling',
					src: 'https://www.novacredit.com/resources/how-to-get-medical-insurance-when-traveling/',
				},
			},
		],
	},
	{
		id: 'WHEN_ARRIVE',
		urlHash: 'arrive',
		title: 'When you arrive',
		mobileTitle: 'Arrival',
		tasks: [
			{
				taskId: 'SSN',
				taskTitle: 'Apply for your Social Security Number',
				taskDetail:
					'Instructions for how to apply for a social security number (SSN)—a key document here in the U.S.',
				taskImage: imgSSN,
				taskLink: {
					text: 'Documents required to obtain a SSN',
					src: 'https://www.novacredit.com/resources/documents-required-to-obtain-a-social-security-number/',
				},
			},
			{
				taskId: 'BANK',
				taskTitle: 'Set up a bank account',
				taskDetail: 'Instructions for setting up your first U.S. bank account.',
				taskImage: imgBank,
				taskLink: {
					text: 'How to open a U.S. bank account without a SSN',
					src: 'https://www.novacredit.com/resources/bank-account-without-ssn/',
				},
			},
			{
				taskId: 'MOBILE',
				taskTitle: 'Set up a mobile phone',
				taskDetail:
					'Learn how to pick the right phone plan in the U.S., including how to do so without a U.S. credit history.',
				taskImage: imgMobile,
				taskLink: {
					text: 'How to pick the right phone plan in the U.S.',
					src: 'https://www.novacredit.com/resources/how-to-pick-the-right-phone-plan-in-the-us/',
				},
			},
			{
				taskId: 'CREDIT',
				taskTitle: 'Get a credit card',
				taskDetail:
					'Understand your credit card options as a newcomer, and learn how to apply for your first premium U.S. credit card.',
				taskImage: imgCredit,
				taskLink: {
					text: 'How to apply for a credit card without a SSN',
					src: 'https://www.novacredit.com/resources/credit-card-without-ssn/',
				},
			},
		],
	},
	{
		id: 'AFTER_MOVE',
		urlHash: 'after',
		title: 'After your move',
		mobileTitle: 'After',
		tasks: [
			{
				taskId: 'CAR',
				taskTitle: 'Get a car',
				taskDetail:
					'Learn how to finance your first U.S. car purchase using your foreign credit history.',
				taskImage: imgCar,
				taskLink: {
					text: 'How to pre-qualify for an auto loan as a newcomer',
					src: 'https://www.novacredit.com/resources/how-to-finance-a-car-purchase-as-a-newcomer-to-the-us/',
				},
			},
			{
				taskId: 'LONGHOUSING',
				taskTitle: 'Find longer term housing',
				taskDetail:
					'Understand how to get a longer term apartment in the U.S.—and how your foreign credit history can make the process easier in some cases.',
				taskImage: imgLongHousing,
				taskLink: {
					text: 'How to rent an apartment in the U.S.',
					src: 'https://www.novacredit.com/resources/how-to-rent-an-apartment-in-the-u-s/',
				},
			},
			{
				taskId: 'CREDITSCORE',
				taskTitle: 'Begin to establish a U.S. credit score',
				taskDetail:
					"Learn how to build a credit score in the U.S. credit reporting system and understand why it's important.",
				taskImage: imgCreditScore,
				taskLink: {
					text: 'How to get a credit score after moving to the U.S.',
					src: 'https://www.novacredit.com/resources/how-to-get-a-credit-score-after-moving-to-the-us/',
				},
			},
			{
				taskId: 'SENDMONEY',
				taskTitle: 'Send money internationally',
				taskDetail:
					'Learn how to send money to and from the U.S. with an overview of major remittances providers.',
				taskImage: imgSendMoney,
				taskLink: {
					text: 'How to send money abroad without spending a fortune',
					src: 'https://www.novacredit.com/resources/how-to-send-money-internationally-without-spending-a-fortune/',
				},
			},
			{
				taskId: 'WORKCULTURE',
				taskTitle: 'Understand the nuances of U.S. work culture',
				taskDetail:
					'Read our intro to work life in the U.S.—from office jargon to lunch breaks, and everything in between.',
				taskImage: imgWorkCulture,
				taskLink: {
					text: 'A brief overview of work culture in the U.S.',
					src: 'https://www.novacredit.com/resources/work-hours-lunch-breaks-meetings-and-etiquette-a-brief-overview-of-work-culture-in-the-us/',
				},
			},
		],
	},
];

export const COUNTRY_FEEDBACK = {
	BEFORE_MOVE: 'Planning your move to ',
	WHEN_ARRIVE: 'Getting set up in ',
	AFTER_MOVE: 'Settling into your new life in ',
};

export default TAB_CONFIG;
