import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { DesignSystemButton } from '@novacredit/pandorasbox';

import { getTrackingPropsForCreditCard, tracker } from 'modules/tracking';
import type { Card } from 'types';
import { APP_PATHS } from 'modules/paths';

type Props = {
	card: Card;
	className?: string;
	copy?: string;
	currentPage?: 'Card Details' | 'Card Shop';
	variant?: React.ComponentProps<typeof DesignSystemButton>['variant'];
};

/**
 * This component adds an Apply button to the page.
 * For Amex cards, this will lead to a redirect page with more instructions.
 * For other cards, this will lead directly to the partner's site.
 */
const ApplyButton = ({ card, className = '', copy, currentPage, variant }: Props) => {
	const isCardAmex = card.manufacturer === 'American Express';
	const buttonText = copy || 'Continue';

	const history = useHistory();

	const trackApplyClick = () => {
		tracker.track('nova.client.CREDIT_CARD_APPLY_CLICKED', {
			page: currentPage,
			...getTrackingPropsForCreditCard(card),
		});
	};

	const partnerRedirectHandler = () => {
		const promisesToAwait = [
			tracker.track('nova.client.CREDIT_CARD_APPLY_CLICKED', {
				page: currentPage,
				...getTrackingPropsForCreditCard(card),
			}),
			tracker.track('nova.client.REDIRECTED_TO_CUSTOMER', {
				customerName: card.manufacturer,
				productName: card.name,
				page: currentPage,
				...getTrackingPropsForCreditCard(card),
			}),
		];
		Promise.all(promisesToAwait).finally(() => {
			window.open(card.apply_url, '_blank');
			history.push(`${APP_PATHS.MARKETPLACE}#products`);
		});
	};

	return (
		<>
			{isCardAmex ? (
				<DesignSystemButton
					tag={Link}
					to={`/cards/redirect/amex/${card.slug}`}
					className={className}
					onClick={trackApplyClick}
					variant={variant}
				>
					{buttonText}
				</DesignSystemButton>
			) : (
				<DesignSystemButton
					tag="button"
					className={className}
					onClick={partnerRedirectHandler}
					variant={variant}
				>
					{buttonText}
				</DesignSystemButton>
			)}
		</>
	);
};

export default ApplyButton;
