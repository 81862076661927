import React, { useState } from 'react';
import classnames from 'classnames';

import './Collapse.scss';

import CollapseContext from './CollapseContext';
import CollapseContent from './CollapseContent';
import CollapseToggle from './CollapseToggle';

type OwnProps = {
	children: React.ReactNode;
	className?: string;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
// eslint-disable-next-line no-use-before-define
type Props = OwnProps & typeof Collapse.defaultProps;

const Collapse = ({ children, className, ...attributes }: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const open = () => setIsOpen(true);
	const close = () => setIsOpen(false);
	const toggle = () => setIsOpen(!isOpen);

	return (
		<CollapseContext.Provider value={{ isOpen, open, close, toggle }}>
			<div
				className={classnames('collapse', { 'collapse-open': isOpen }, className)}
				{...attributes}
			>
				{typeof children === 'function'
					? children({ isOpen, open, close, toggle })
					: children}
			</div>
		</CollapseContext.Provider>
	);
};

Collapse.Toggle = CollapseToggle;
Collapse.Content = CollapseContent;

Collapse.defaultProps = {
	className: null,
};

export default Collapse;
