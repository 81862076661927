const FontSize14 = "14px";
const FontSize16 = "16px";
const FontSize18 = "18px";
const FontSize20 = "20px";
const FontSize24 = "24px";
const FontSize36 = "36px";
const FontSize48 = "48px";
const FontSize64 = "64px";
const FontWeightLight = "300";
const FontWeightRegular = "400";
const FontWeightSemibold = "600";
const FontFamily = "'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif";
var designSystemFontTokens = {
	FontSize14: FontSize14,
	FontSize16: FontSize16,
	FontSize18: FontSize18,
	FontSize20: FontSize20,
	FontSize24: FontSize24,
	FontSize36: FontSize36,
	FontSize48: FontSize48,
	FontSize64: FontSize64,
	FontWeightLight: FontWeightLight,
	FontWeightRegular: FontWeightRegular,
	FontWeightSemibold: FontWeightSemibold,
	FontFamily: FontFamily
};

export { designSystemFontTokens as d };
