import { COOKIES, getNovaCookie, setNovaCookie } from '../utils/novaCookies';

declare global {
	interface Window {
		analytics: {
			debug: () => void;
		};
	}
}

let debug: Boolean;

/**
 * Expose an analytics.debug() function on the console to mimic what Segment
 * used to do, allowing easier visibility and validation of tracking events.
 *
 * The state of debugging (true/false) is maintained in cookies so that the
 * user can see tracking events that happen on page load when the page is
 * refreshed.
 */
if (typeof window !== 'undefined') {
	window.analytics = {
		debug: (setDebug = true) => {
			debug = setDebug;
			setNovaCookie(COOKIES.TRACKING_DEBUG, setDebug.toString());
		},
	};
}

export const logEventToConsole = (actionName: string, properties: object | null): void => {
	if (typeof debug === 'undefined') {
		debug = getNovaCookie(COOKIES.TRACKING_DEBUG) === 'true';
	}

	if (!debug) {
		return;
	}

	// eslint-disable-next-line no-console
	console.log(
		`%ctracking action: %c${actionName}\n%cproperties: %c${JSON.stringify(
			properties,
			null,
			2,
		)}`,
		'color: gray;',
		'color: red;',
		'color: gray;',
		'color: blue;',
	);
};
