import { useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import { getQueryParams } from '@novacredit/frontend-common/utils/location';
import {
	getCreditRatingByDisplayName,
	getCreditRatingByName,
} from '@novacredit/frontend-common/utils/creditRating';

import { stringifyArray } from 'utils';
import CardShopContext, { getEnabledFilters, ACTIONS } from 'contexts/CardShopContext';
import StoredCountryContext from 'contexts/StoredCountryContext';

/**
 * Custom hook that keeps URL query parameters in sync with credit
 * card filter options.
 */
const useFiltersWithQueryParams = () => {
	const { storedCountry, setStoredCountry } = useContext(StoredCountryContext);
	const [didLoadFiltersFromURL, setDidLoadFiltersFromURL] = useState(false);
	const filters = useContext(CardShopContext);

	const location = useLocation();
	const history = useHistory();
	// @ts-expect-error
	const queryParams = getQueryParams(location);

	const creditCardRatingFromState = getCreditRatingByName(filters.state.creditRating);

	const queryString = qs.stringify(
		{
			...queryParams,
			...{
				features: stringifyArray(getEnabledFilters(filters.state.features)) || null,
				rewards: stringifyArray(getEnabledFilters(filters.state.rewards)) || null,
				country: storedCountry,
				credit_rating: creditCardRatingFromState?.displayName,
			},
		},
		{ skipNulls: true },
	);

	// On initial load, check if any filters are set in the URL query params and
	// set the CardShopContext state to match.
	useEffect(() => {
		if (queryParams.features) {
			filters.dispatch({
				type: ACTIONS.UPDATE_FEATURES,
				payload: { filters: queryParams.features },
			});
		}

		if (queryParams.rewards) {
			filters.dispatch({
				type: ACTIONS.UPDATE_REWARDS,
				payload: { filters: queryParams.rewards },
			});
		}

		if (queryParams.credit_rating) {
			const creditCardRatingFromParams = getCreditRatingByDisplayName(
				queryParams.credit_rating,
			);

			if (creditCardRatingFromParams) {
				filters.dispatch({
					type: ACTIONS.UPDATE_CREDIT_RATING,
					payload: { filters: creditCardRatingFromParams.name },
				});
			}
		}

		if (queryParams.country) {
			setStoredCountry(queryParams.country, {
				page: 'Cardshop',
				location: 'URL Query Parameters',
			});
		}

		setDidLoadFiltersFromURL(true);

		// Ignoring dependency rule so that this only fires once on component mount.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// If the state of CardShopContext filters changes, update the URL query params
	// to match.
	useEffect(() => {
		// Give the hook that sets filters based on URL query params a chance
		// to run before we start changing the query params to match CardShopContext state.
		if (!didLoadFiltersFromURL) {
			return;
		}

		const adjustedUrl = `${location.pathname}?${queryString}`;
		history.replace(adjustedUrl);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryString, didLoadFiltersFromURL]);
};

export default useFiltersWithQueryParams;
