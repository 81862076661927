import { getCmsURL } from 'utils';

const HomePage = () => {
	const newcomerHomepageOnMarketingSite = getCmsURL();
	window.location.replace(newcomerHomepageOnMarketingSite);

	return null;
};

export default HomePage;
