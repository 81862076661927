import classnames from 'classnames';
import React from 'react';

import './CtaCard.scss';

const CtaCard = ({
	children,
	button,
	className,
}: {
	children: React.ReactNode;
	button: React.ReactNode;
	className: string;
}) => {
	return (
		<div className={classnames('cta-card-component', className)}>
			<div className="card-component blurred">
				<div className="cta-card-content">{children}</div>
				<div className="button-overlay-container">{button}</div>
			</div>
		</div>
	);
};

export default CtaCard;
