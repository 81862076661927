import { useHistory } from 'react-router-dom';
import type { CountryCode } from '@novacredit/frontend-common/utils/countries';

import { APP_PATHS } from 'modules/paths';

export const useNavigateToCountry = (country: CountryCode) => {
	const history = useHistory();

	const navigateToCountry = () => {
		if (country) {
			history.push(`${APP_PATHS.GET_STARTED}?country=${country}`);
		} else {
			history.push(`${APP_PATHS.GET_STARTED}`);
		}
	};

	return navigateToCountry;
};

export default useNavigateToCountry;
