import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
	/**
	 * Will be added as a title to the window. Recommended to be < 46 characters for SEO.
	 */
	title?: string;
	soloTitle?: string;
};

const PageTitle = ({ title, soloTitle }: Props) => {
	if (soloTitle) {
		return (
			<Helmet>
				<title>{soloTitle}</title>
			</Helmet>
		);
	}
	if (!title) {
		return <></>;
	}
	return (
		<Helmet>
			<title>{title} | Nova Credit</title>
		</Helmet>
	);
};

export default PageTitle;
