/* eslint-disable react/no-array-index-key */
import React from 'react';
import classNames from 'classnames';

import { getCmsURL } from 'utils';
import Icon from './Icon';
import footerConfig from './footerConfig';
import EmailForm from './EmailForm';

import './Footer.scss';

// eslint-disable-next-line react/prop-types
const Footer = () => {
	const data = footerConfig;

	const TABS = data.tabs;
	const activeTab = 0;

	const columns = [
		{ title: data.footerColumn1Title, columns: [data.footerColumn1Links] },
		{
			title: data.footerColumn2Title,
			columns: [data.footerColumn2LinksLeft, data.footerColumn2LinksRight],
		},
		{ title: data.footerColumn3Title, columns: [data.footerColumn3Links] },
	];

	return (
		<footer className="uq-footer">
			<div className="uq-footer-grid">
				<div className="uq-footer-logo-column">
					<a href={TABS[activeTab].href}>
						<Icon icon="logo-stacked" className="uq-footer-logo-icon" />
					</a>

					<div>
						<div className="uq-footer-email-capture">
							<div
								className="uq-footer-email-capture-description"
								id="email-capture-description"
							>
								{footerConfig.footerEmailCapture}
							</div>
						</div>
						<EmailForm aria-labelledby="email-capture-description" />
					</div>
				</div>
				<div className="uq-footer-links-column">
					{columns.map(({ title, columns: subColumns }, index) => (
						<div
							key={title}
							className={classNames(
								subColumns && subColumns.length === 1
									? 'single-column'
									: 'multi-column',
							)}
						>
							<h4 className="uq-footer-column-header">{title}</h4>
							<hr className="uq-footer-column-divider" />
							<div className="uq-footer-column-list">
								{subColumns?.map((col, colIndex) => (
									<div
										className="uq-footer-column-list-item"
										key={`footer-link-list-${index}-${colIndex}`}
									>
										{col.map(({ href, label, newTab }) => (
											// eslint-disable-next-line react/jsx-no-target-blank
											<a
												href={href}
												key={label}
												target={newTab ? '_blank' : null}
												rel={newTab ? 'noreferrer noopener' : null}
												className="uq-footer-link"
											>
												{label}
											</a>
										))}
									</div>
								))}
							</div>
						</div>
					))}
				</div>
				<div className="uq-footer-bottom-row">
					<div className="uq-footer-social">
						<a
							href="https://www.instagram.com/novacredit/"
							target="_blank"
							rel="noreferrer noopener"
							className="uq-footer-social-link"
						>
							<Icon icon="instagram" width="32" />
						</a>
						<a
							href="https://twitter.com/nova_credit"
							target="_blank"
							rel="noreferrer noopener"
							className="uq-footer-social-link"
						>
							<Icon icon="twitter" width="32" />
						</a>
						<a
							href="https://www.linkedin.com/company/nova-credit-inc./"
							target="_blank"
							rel="noreferrer noopener"
							className="uq-footer-social-link"
						>
							<Icon icon="linkedin" width="32" />
						</a>
						<a
							href="https://www.facebook.com/novacredit"
							target="_blank"
							rel="noreferrer noopener"
							className="uq-footer-social-link"
						>
							<Icon icon="facebook" width="32" />
						</a>
					</div>
					<div className="uq-footer-copyright">{data.footerCopyright}</div>
					<nav>
						<ul className="uq-footer-policies">
							<li>
								<a href={getCmsURL('/terms')}>Terms of service</a>
							</li>
							<li>
								<a href={getCmsURL('/privacy/')}>Privacy policy</a>
							</li>
							<li>
								<a href={getCmsURL('/cookie-policy')}>Cookie policy</a>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
