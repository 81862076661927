import React, { useState } from 'react';
import { useMount } from '@novacredit/frontend-common/hooks';
import { captureException } from '@novacredit/frontend-common/services/sentry';
import { MASKED_PROPERTIES } from '@novacredit/frontend-common/utils/consumer';
import { isCardshopCountry } from '@novacredit/frontend-common/utils/countries';
import type { CountryCode } from '@novacredit/frontend-common/utils/countries';
import { getQueryParams } from '@novacredit/frontend-common/utils/location';

import { Intercom } from '@novacredit/frontend-common/vendors';
import Typography from '@novacredit/pandorasbox/src/components/DesignSystem/Typography/Typography';

import OnboardingQuestionButtons, {
	onboardingQuestions,
	getQuestionAfterCountrySelect,
} from 'components/common/Onboarding/OnboardingQuestions';
import EndStateCTA, { endStates } from 'components/common/Onboarding/OnboardingEndStates';
import type {
	OnboardingQuestionName,
	SurveyResponses,
} from 'components/common/Onboarding/OnboardingQuestions';
import { tracker } from 'modules/tracking';

type Props = {
	location: Location;
};

const INITIAL_QUESTION = 'start';

const OnboardingSurvey = ({ location }: Props) => {
	const [surveyResponses, setSurveyResponses] = useState<SurveyResponses>({ country: null });
	const [currQuestionKey, setCurrQuestionKey] =
		useState<OnboardingQuestionName>(INITIAL_QUESTION);
	const [endState, setEndState] = useState(null);

	const currQuestion = onboardingQuestions[currQuestionKey];

	const updateResponses = <T extends keyof SurveyResponses>(
		key: T,
		value: SurveyResponses[T],
	) => {
		const newResponses = { ...surveyResponses, [key]: value };
		setSurveyResponses(newResponses);
	};
	const queryParams = getQueryParams(location);
	const countryFromURL = (queryParams.country || '').toString().toUpperCase();

	useMount(() => {
		// if countryFromURL is supported by a customer, prefill the country answer with countryFromUrl
		// otherwise set to empty string.
		const countryResponse = isCardshopCountry(countryFromURL)
			? (countryFromURL as CountryCode)
			: '';

		updateResponses('country', countryResponse);
		if (countryResponse) {
			tracker.track('nova.consumer_dashboard.ONBOARDING_SURVEY_STARTED', null);
			const nextQuestion = getQuestionAfterCountrySelect(countryResponse);
			setCurrQuestionKey(nextQuestion);
		}
	});

	// Skip the "Get started" question if `started=true` is set in the query parameters.
	// We could likely generalize this logic into `shouldSkipQuestion` handlers in the future
	// if this pattern is useful for other skipping.
	useMount(() => {
		const started = queryParams.started === 'true';
		if (started) {
			const nextState = (onboardingQuestions.start.options[0].nextQuestion as Function)({
				surveyResponses,
			});

			if (onboardingQuestions[nextState]) {
				setCurrQuestionKey(nextState);
			}
		}
	});

	const trackQuestion = (questionKey, value) => {
		const maskedQuestionKey = MASKED_PROPERTIES[questionKey];

		if (!maskedQuestionKey) {
			captureException(
				new Error('Attempted to track an onboarding survey question without a known mask'),
			);
			return;
		}

		tracker.track('nova.consumer_dashboard.ONBOARDING_SURVEY_QUESTION_ANSWERED', {
			question: maskedQuestionKey,
			answer: value,
		});

		// Coerce the `unsupportedCountry` question to just `country` as the user property
		// for Intercom tracking purposes so that data about where the user is arriving from
		// lives in one place.
		const intercomUserProperty =
			maskedQuestionKey === 'unsupportedCountry' ? 'country' : maskedQuestionKey;

		// Send each survey question and answer to Intercom as user props as they are answered.
		// Intercom will store with an anonymous id until the user signs up with an email at the end
		// of the survey at which point the question and answer data will be associated with the user.
		Intercom.update({
			[intercomUserProperty]: value,
		});
	};

	const onNextButtonClick = (value, nextQuestion) => {
		// if the user doesn't choose a Date for the datePicker, update the results accordingly
		let updatedSurveyResponses;
		if (currQuestion.questionType === 'monthYearPicker' && !(value instanceof Date)) {
			updatedSurveyResponses = { ...surveyResponses, arrivalDateOther: value };
			setSurveyResponses(updatedSurveyResponses);
		} else if (currQuestion.onboardingAnswerKey) {
			// add the response to the object storing the user's responses
			let parsedValue = value;
			if (value === 'true') {
				parsedValue = true;
			} else if (value === 'false') {
				parsedValue = false;
			}
			updatedSurveyResponses = {
				...surveyResponses,
				[currQuestion.onboardingAnswerKey]: parsedValue,
			};
			setSurveyResponses(updatedSurveyResponses);
			trackQuestion(currQuestion.onboardingAnswerKey, value);
		} else {
			updatedSurveyResponses = surveyResponses;
		}

		// get the next question or end state based on the user's response
		const nextState =
			typeof nextQuestion === 'function'
				? nextQuestion({ value, surveyResponses: updatedSurveyResponses })
				: nextQuestion;
		if (onboardingQuestions[nextState]) {
			setCurrQuestionKey(nextState);
		} else {
			// if the user is at an end point, track that and display the end state
			tracker.track('nova.consumer_dashboard.ONBOARDING_SURVEY_FINISHED', {
				endState: nextState,
			});
			setEndState(endStates[nextState]);
		}
	};

	const onBackButtonClick = (value, nextQuestion) => {
		// if the user doesn't choose a Date for the datePicker, update the results accordingly
		let updatedSurveyResponses;
		if (currQuestion.questionType === 'monthYearPicker' && !(value instanceof Date)) {
			updatedSurveyResponses = { ...surveyResponses, arrivalDateOther: value };
			setSurveyResponses(updatedSurveyResponses);
		} else if (currQuestion.onboardingAnswerKey) {
			// add the response to the object storing the user's responses
			updatedSurveyResponses = {
				...surveyResponses,
				[currQuestion.onboardingAnswerKey]: value,
			};
			setSurveyResponses(updatedSurveyResponses);
			trackQuestion(currQuestion.onboardingAnswerKey, value);
		} else {
			updatedSurveyResponses = surveyResponses;
		}

		// get the next question or end state based on the user's response
		const nextState =
			typeof nextQuestion === 'function'
				? nextQuestion({ value, surveyResponses: updatedSurveyResponses })
				: nextQuestion;
		if (onboardingQuestions[nextState]) {
			setCurrQuestionKey(nextState);
		} else {
			// if the user is at an end point, track that and display the end state
			tracker.track('nova.consumer_dashboard.ONBOARDING_SURVEY_FINISHED', {
				endState: nextState,
			});
			setEndState(endStates[nextState]);
		}
	};

	const currQuestionTitle = !endState ? currQuestion.title : null;
	const currQuestionSubtitle = !endState ? currQuestion.subtitle : endState?.subtitle;
	const endStateTitle = endState?.title;

	return (
		<div className="onboarding-survey">
			<div className="heading-container">
				{currQuestionTitle && (
					<Typography tag="h1" variant="Heading-4">
						{currQuestionTitle}
					</Typography>
				)}
				{endStateTitle && (
					<Typography tag="h1" variant="Heading-3">
						{endStateTitle}
					</Typography>
				)}
				<div className="subtitle-container">
					{currQuestionSubtitle && (
						<Typography tag="h2" variant="Body-Regular">
							{currQuestionSubtitle}
						</Typography>
					)}
				</div>
			</div>
			{endState ? (
				<EndStateCTA surveyResponses={surveyResponses} endState={endState} />
			) : (
				<OnboardingQuestionButtons
					currQuestion={currQuestion}
					surveyResponses={surveyResponses}
					updateResponses={updateResponses}
					onNextButtonClick={onNextButtonClick}
					onBackButtonClick={onBackButtonClick}
				/>
			)}
		</div>
	);
};

export default OnboardingSurvey;
