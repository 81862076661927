import React, { useState } from 'react';
import classnames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CARDSHOP_LIVE_COUNTRIES } from '@novacredit/frontend-common/utils/countries';
import { getQueryParams } from '@novacredit/frontend-common/utils/location';
import { DesignSystemButton, Tooltip, Typography } from '@novacredit/pandorasbox';
import { REFERRER_REWARD } from '@novacredit/frontend-common/const/referralProgram';

import { tracker } from 'modules/tracking';
import Page from 'components/common/Page';
import { Row, Col, Container } from 'components/common/Layout';
import ShareMenu from 'components/common/ShareMenu';
import PageTitle from 'components/common/PageTitle';

import confetti from 'assets/images/confetti.jpg';
import './YourReferralLink.scss';
import { CHANNEL } from '@novacredit/frontend-common/utils/social';
import { getCmsURL } from 'utils';

const TWITTER_SHARE_TEXT = `Check this out! U.S. newcomers can easily leverage their foreign credit history from select countries to apply for premium U.S. credit cards:`;

const YourReferralLink = () => {
	const queryParams = getQueryParams(window.location);
	const referralCode = queryParams.code;
	// TODO: should this be a cms link?
	const referralLink = `${getCmsURL()}/r/${referralCode}`;
	const [copied, setCopied] = useState(false);

	// Handler that will cause the button styling to temporarily change, signaling
	// to the user that the link has been copied to their clipboard. Also tracks
	// the event to product analytics destinations.
	const handleOnCopy = () => {
		tracker.track('nova.consumer_dashboard.REFERRAL_PROGRAM_PLP_COPIED', null);

		setCopied(true);

		setTimeout(() => {
			setCopied(false);
		}, 100);
	};

	const supportedCountries = CARDSHOP_LIVE_COUNTRIES.map(country => country.name);

	// Needed for Cypress e2e since copying to clipboard is a bit of a challenge.
	const hiddenTestLink = (
		// eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label
		<a style={{ display: 'none' }} href={referralLink} data-testid="hidden-referral-link" />
	);

	return (
		<Page>
			{hiddenTestLink}
			<PageTitle title="Referral Link" />
			<div className="your-referral-link">
				<img src={confetti} alt="" className="confetti" />
				<Container>
					<Row>
						<Col size={{ lg: 6 }} offset={{ lg: 3 }} className="content">
							<Typography tag="h1" variant="Heading-3">
								Awesome! Welcome to the team
							</Typography>
							<Typography tag="p" variant="Body-Regular">
								Here&apos;s your unique referral link:
							</Typography>
							<div className="referral-link fs-mask">
								<p className="referral-link-url">{referralLink}</p>
								<CopyToClipboard text={referralLink} onCopy={handleOnCopy}>
									<DesignSystemButton
										tag="button"
										type="submit"
										className={classnames('copy-button', {
											copied,
										})}
									>
										Copy
									</DesignSystemButton>
								</CopyToClipboard>
							</div>
							<ShareMenu
								inline
								display="row"
								content="Referral Program"
								link={referralLink}
								channels={[
									{
										channel: CHANNEL.WHATSAPP,
										shareText:
											'Using Nova Credit, U.S. newcomers can easily leverage their foreign credit history from select countries to apply for premium U.S. credit cards! Check it out: ',
									},
									{ channel: CHANNEL.FACEBOOK },
									{
										channel: CHANNEL.TWITTER,
										shareText: TWITTER_SHARE_TEXT,
									},
								]}
							/>
							<div>
								<Typography tag="p" variant="Body-Regular" className="mt-2">
									You&apos;ll get {REFERRER_REWARD} for each person who uses your
									link to apply for a credit card using their foreign credit
									history from one of our{' '}
									<Tooltip
										position="bottom"
										theme="light"
										arrow
										tooltipTrigger={
											<span className="supported-countries">
												supported countries
											</span>
										}
										content={
											<p className="muted your-referral-link__tooltip">
												{' '}
												Supported countries currently include{' '}
												{supportedCountries
													.slice(0, -1)
													.join(', ')} and{' '}
												{supportedCountries[supportedCountries.length - 1]}.
											</p>
										}
									/>
									. Rewards will be processed monthly via Amazon.com eGift Card.
								</Typography>
								<Typography tag="p" variant="Caption-Regular" className="mt-4">
									Note: Credit card applicants are not guaranteed to have the
									option to use their foreign credit history as part of their
									application; this is at the sole discretion of the card issuer.
								</Typography>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</Page>
	);
};

export default YourReferralLink;
