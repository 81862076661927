import { COOKIE_TYPES } from '../consent/common';
import type { ConsentMap } from '../consent/common';

/**
 * Contains code related to Google Consent Mode for both Google Analytics (GA) and
 * Google Tag Manager (GTM)
 *
 * These methods can be used in conjunction with a third-party cookie consent solution
 * (e.g Cookiebot), or with a custom in-house cookie consent solution, provided a
 * `consent` object is provided to `updateGoogleConsents` with the proper key-value
 * pairs.
 *
 * Google Consent API: https://developers.google.com/tag-platform/devguides/consent
 */
const DEFAULT_GTM_CONSENTS = {
	ad_storage: 'denied',
	analytics_storage: 'denied',
	functionality_storage: 'granted',
	personalization_storage: 'denied',
	security_storage: 'granted',
};

const GTM_COOKIE_TYPE_MAPPING = {
	[COOKIE_TYPES.marketing]: 'ad_storage',
	[COOKIE_TYPES.statistics]: 'analytics_storage',
	[COOKIE_TYPES.necessary]: 'functionality_storage',
	[COOKIE_TYPES.preferences]: 'personalization_storage',
} as const;

/**
 * We need to make sure when GA or GTM is initialized, that we disable consents by
 * default. This snippet is based on:
 *
 * https://support.cookiebot.com/hc/en-us/articles/360016047000-Cookiebot-and-Google-Consent-Mode
 * https://developers.google.com/tag-platform/devguides/consent#implementation_example
 */
export const setDefaultConsents = (dataLayer: object[]) => {
	// Google recommends using `arguments`, trying to use an array does not work
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	function gtag(command: string, setting: string, vars: object | boolean) {
		// eslint-disable-next-line prefer-rest-params
		dataLayer.push(arguments);
	}
	gtag('consent', 'default', {
		...DEFAULT_GTM_CONSENTS,
		wait_for_update: 500,
	});
	gtag('set', 'ads_data_redaction', true);
	gtag('set', 'url_passthrough', true);
};

/**
 * This updates the GTM consents based on what is received
 */
export const updateGoogleConsents = (dataLayer: object[], consent: ConsentMap) => {
	// Google recommends using `arguments`, trying to use an array does not work
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	function gtag(command: string, setting: string, vars: object | boolean) {
		// eslint-disable-next-line prefer-rest-params
		dataLayer.push(arguments);
	}

	const updatedGoogleConsents = { ...DEFAULT_GTM_CONSENTS };

	Object.entries(consent).forEach(([consentType, isGranted]) => {
		const googleType = GTM_COOKIE_TYPE_MAPPING[consentType as keyof ConsentMap];
		updatedGoogleConsents[googleType] = isGranted ? 'granted' : 'denied';
	});

	gtag('consent', 'update', updatedGoogleConsents);

	if (updatedGoogleConsents.ad_storage === 'granted') {
		gtag('set', 'ads_data_redaction', false);
	}
};
