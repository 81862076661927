import React, { useContext, useState } from 'react';
import { Formik } from 'formik';

import { Intercom } from '@novacredit/frontend-common/vendors';
import {
	DesignSystemButton,
	DesignSystemCheckbox,
	DesignSystemFormField,
	Typography,
} from '@novacredit/pandorasbox';
import { getDynamicProperties } from '@novacredit/frontend-common/tracking/properties';
import { REFERRER_REWARD } from '@novacredit/frontend-common/const/referralProgram';

import { tracker, getRedirectAfterClickHandler } from 'modules/tracking';
import { validateEmail, validateForm, validateName } from 'modules/validations';
import Page from 'components/common/Page';
import { Row, Col, Container } from 'components/common/Layout';
import { ReferralProgramTOSAgreement } from 'components/common/Legal';
import PIISafeForm from 'components/common/PIISafeForm';
import CountryFlagList from 'components/common/CountryFlagList';
import PageTitle from 'components/common/PageTitle';
import { Redirect } from 'react-router-dom';
import { APP_PATHS } from 'modules/paths';
import SessionContext from 'contexts/SessionContext';
import { getCmsURL } from 'utils';
import useApi from 'hooks/useApi';

import referralImg from 'assets/graphics/people-with-phone.svg';

import './ReferAFriend.scss';

const LEARN_MORE_LINK = 'https://help.novacredit.com/s/topic/0TO4z0000000qMiGAI/referral-program';

type FormSubmission = {
	firstName: string;
	lastName: string;
	email: string;
	marketingConsent: boolean;
};

const ReferAFriend = () => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [referralCode, setReferralCode] = useState(null);
	const { experimentsClient } = useContext(SessionContext);

	const isReferralProgramActive =
		experimentsClient &&
		experimentsClient.getFeatureFlag('REFERRAL_PROGRAM_REACTIVATION') === 'ENABLED';

	const { makeRequest: createReferrer } = useApi({
		defer: true,
		method: 'POST',
		path: '/referrers',
	});

	const PROGRAM_DESCRIPTION = `
		Get a ${REFERRER_REWARD} Amazon gift card for every person you refer who uses their foreign
		credit history to apply for eligible products in the U.S., valid for up to 50 qualified
		referrals per calendar year. Applicants must opt in to use their foreign credit
		history. Partner must retrieve your foreign credit history to qualify. Use of your
		foreign credit history is at the partner's discretion.
	`;

	const validate = validateForm({
		email: validateEmail,
		firstName: validateName,
		lastName: validateName,
	});

	const initialValues: FormSubmission = {
		email: '',
		firstName: '',
		lastName: '',
		marketingConsent: true,
	};

	const onSubmit = (values: typeof initialValues) => {
		setIsSubmitting(true);

		const formSubmission = { ...values };
		const {
			utmSource: source,
			utmMedium: medium,
			utmCampaign: campaign,
		} = getDynamicProperties();

		createReferrer({
			body: {
				firstName: values.firstName,
				lastName: values.lastName,
				email: values.email,
				host: getCmsURL(),
				source,
				medium,
				campaign,
			},
		}).then(result => {
			const referralCodeFromResponse = result?.referralCode;

			Intercom.subscribeToEmailMarketing({
				email: formSubmission.email,
				consentToMarketing: formSubmission.marketingConsent,
				name: `${formSubmission.firstName} ${formSubmission.lastName}`,
				tags: ['consumer', 'd2cConsumer', 'referrerUser'],
				subscriptionSource: {
					app: 'cms',
					location: 'Referral Program Sign-Up',
				},
				userProperties: {
					firstName: formSubmission.firstName,
					lastName: formSubmission.lastName,
					referralCode: referralCodeFromResponse,
				},
				trackingFn: () => {
					tracker.track('nova.client.EMAIL_SUBSCRIBED', {
						location: 'Referral Program Sign-Up',
					});
				},
			});

			tracker.track('nova.consumer_dashboard.REFERRAL_PROGRAM_ACCOUNT_CREATED', null);
			setReferralCode(referralCodeFromResponse);
		});
	};

	if (!isReferralProgramActive) {
		window.location.href = getCmsURL();
		return null;
	}

	if (referralCode) {
		return <Redirect to={`${APP_PATHS.YOU_REFERRAL_LINK}?code=${referralCode}`} />;
	}

	return (
		<Page>
			<PageTitle title="Refer a Friend" />
			<Container className="refer-a-friend">
				<Row>
					<Col size={{ md: 7 }}>
						<>
							<Typography tag="h1" variant="Heading-3" variantMobile="Subtitle-2">
								Share Nova Credit with your friends and family to help them unlock
								credit opportunities in the U.S.
							</Typography>
							<Typography
								tag="p"
								variant="Body-Regular"
								variantMobile="Caption-Regular"
							>
								{PROGRAM_DESCRIPTION}{' '}
								<a
									href={LEARN_MORE_LINK}
									target="_blank"
									rel="noopener noreferrer"
									onClick={getRedirectAfterClickHandler({
										callbacks: [
											() => {
												return tracker.track(
													'nova.consumer_dashboard.REFERRAL_PROGRAM_LEARN_MORE_CLICKED',
													null
												);
											},
										],
									})}
								>
									Learn more
								</a>
							</Typography>
							<Typography tag="p" variant="Subtitle-2" variantMobile="Body-Semibold">
								Sign up to refer your friends
							</Typography>
							<Typography
								tag="p"
								variant="Body-Regular"
								variantMobile="Caption-Regular"
							>
								Enter your information to generate your unique referral code.
							</Typography>
							<Formik
								initialValues={initialValues}
								validate={validate}
								onSubmit={onSubmit}
							>
								{({
									errors,
									touched,
									values,
									isValid,
									dirty,
									setFieldValue,
									setFieldTouched,
								}) => (
									<PIISafeForm>
										<div>
											<Row>
												<Col size={{ md: 6 }}>
													<DesignSystemFormField<'input'>
														component="input"
														inputName="firstName"
														label="First name"
														helpTextId="first-name-help-text"
														errorMessage={
															touched.firstName && errors.firstName
														}
														onBlur={() =>
															setFieldTouched('firstName', true)
														}
														onChange={(
															e: React.ChangeEvent<HTMLInputElement>
														) =>
															setFieldValue(
																'firstName',
																e.target.value
															)
														}
													/>
												</Col>
												<Col>
													<DesignSystemFormField<'input'>
														component="input"
														inputName="lastName"
														label="Last name"
														helpTextId="last-name-help-text"
														errorMessage={
															touched.lastName && errors.lastName
														}
														onBlur={() =>
															setFieldTouched('lastName', true)
														}
														onChange={(
															e: React.ChangeEvent<HTMLInputElement>
														) =>
															setFieldValue(
																'lastName',
																e.target.value
															)
														}
													/>
												</Col>
											</Row>
											<Row>
												<Col>
													<DesignSystemFormField<'input'>
														component="input"
														type="email"
														label="Email address"
														inputName="email"
														helpTextId="email-help-text"
														errorMessage={touched.email && errors.email}
														onBlur={() =>
															setFieldTouched('email', true)
														}
														onChange={(
															e: React.ChangeEvent<HTMLInputElement>
														) => setFieldValue('email', e.target.value)}
													/>
												</Col>
											</Row>
											<Row>
												<Col>
													<DesignSystemCheckbox
														className="mb-3"
														inputName="marketingConsent"
														checked={values.marketingConsent}
														onChange={() => {
															setFieldValue(
																'marketingConsent',
																!values.marketingConsent
															);
														}}
														label="I'd also like to receive emails from
																Nova Credit with educational content,
																product updates and offers."
													/>
												</Col>
											</Row>
											<DesignSystemButton
												className="mb-3"
												tag="button"
												type="submit"
												disabled={
													!(isValid && dirty) ||
													isSubmitting ||
													!!referralCode
												}
											>
												Sign up
											</DesignSystemButton>
										</div>
									</PIISafeForm>
								)}
							</Formik>
							<Typography tag="p" variant="Caption-Regular">
								<ReferralProgramTOSAgreement />
							</Typography>
						</>
					</Col>
					<Col
						offset={{ lg: 1 }}
						size={{ md: 4 }}
						className="refer-a-friend__content-right"
					>
						<img src={referralImg} alt="" className="mt-5 mb-5" />
						<Typography
							tag="p"
							variant="Body-Semibold"
							variantMobile="Body-Semibold"
							className="refer-a-friend__supported-countries-header"
						>
							Currently supporting newcomers from
						</Typography>
						<div className="refer-a-friend__supported-countries">
							<CountryFlagList />
						</div>
					</Col>
				</Row>
			</Container>
		</Page>
	);
};

export default ReferAFriend;
