/**
 * This file has utils for interacting with Nova-related cookies.
 * All Nova cookies should begin with "nova_" and be restricted to Nova domains.
 */
import { COOKIE_TYPES } from '../consent/common';
import type { ConsentMap, OnUpdateConsentCallback } from '../consent/common';
import { ENV, getEnvFromLocationOrUrl } from './env';

export const COOKIES = {
	ANONYMOUS_ID: 'anonymous_id',
	CARDSHOP_COUNTRY: 'cardshop_country',
	FULLSTORY_DEBUG: 'fullstory_debug',
	TRACKING_DEBUG: 'tracking_debug',
	UTM_INFO: 'utm_info',
	LANGUAGE: 'language',
	REFERRAL: 'referral',
} as const;

const NOVA_COOKIE_TYPES = {
	[COOKIES.ANONYMOUS_ID]: COOKIE_TYPES.statistics,
	[COOKIES.CARDSHOP_COUNTRY]: COOKIE_TYPES.preferences,
	[COOKIES.FULLSTORY_DEBUG]: COOKIE_TYPES.necessary,
	[COOKIES.TRACKING_DEBUG]: COOKIE_TYPES.necessary,
	[COOKIES.UTM_INFO]: COOKIE_TYPES.marketing,
	[COOKIES.LANGUAGE]: COOKIE_TYPES.preferences,
	[COOKIES.REFERRAL]: COOKIE_TYPES.statistics,
} as const;

type NovaCookieTypes = typeof NOVA_COOKIE_TYPES;

// This is used to keep track of what cookies we have tried to set
const cookieCache = {} as Record<keyof NovaCookieTypes, string>;

let consentCache: ConsentMap = {
	necessary: true,
	statistics: false,
	preferences: false,
	marketing: false,
};

const getCookie = (name: string): string | undefined => {
	// eslint-disable-next-line security/detect-non-literal-regexp
	const regex = new RegExp(`(^| )${name}=([^;]+)`);
	const match = document.cookie.match(regex);
	return (match && match[2]) || '';
};

const setCookie = (cookie: string): void => {
	document.cookie = cookie;
};

/**
 * Used to retrieve nova_ cookies.
 */
export const getNovaCookie = (cookieName: string): string | undefined => {
	return getCookie(`nova_${cookieName}`);
};

/**
 * Used to set cookies, adding a nova_ to the name.
 * Respects consumers cookie consent.
 */
export const setNovaCookie = (cookieName: keyof NovaCookieTypes, cookieValue: string): void => {
	// Store the cookie locally
	cookieCache[cookieName] = cookieValue;

	// Check if user has consented to setting this cookie
	if (!consentCache[NOVA_COOKIE_TYPES[cookieName]]) {
		return;
	}

	const nodeEnv = getEnvFromLocationOrUrl();

	let fullCookie = `nova_${cookieName}=${cookieValue};`;
	if (nodeEnv !== ENV.development) {
		fullCookie += ` domain=novacredit.com; SameSite=None; Secure`;
	}

	setCookie(fullCookie);
};

/**
 * Attempts to set any cookies that were previously cached.
 * Respects consumers cookie consent.
 */
export const onConsentUpdate: OnUpdateConsentCallback = (consents): void => {
	consentCache = consents;

	Object.entries(cookieCache).forEach(([cookieName, cookieValue]) => {
		setNovaCookie(cookieName as keyof NovaCookieTypes, cookieValue);
	});
};

/**
 * Checks whether consent has been granted for a given cookie
 */
export const isCookieConsentGranted = (cookieName: keyof NovaCookieTypes): boolean => {
	return consentCache[NOVA_COOKIE_TYPES[cookieName]];
};
