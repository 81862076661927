import qs from 'qs';

import type { CreditDuration } from '@novacredit/frontend-common/utils/consumer';
import { CREDIT_RATINGS, AMEX_MIN_SCORE } from '@novacredit/frontend-common/utils/creditRating';
import {
	CONSUMER_US_CREDIT_DURATION_OPTIONS,
	US_CREDIT_DURATION_TO_QUERY_PARAM,
} from '@novacredit/frontend-common/utils/consumer';
import type { CountryCode } from '@novacredit/frontend-common/utils/countries';
import { isAmexCountry, isCitiCountry } from '@novacredit/frontend-common/utils/countries';
import { SUPPLIER_CODE_TO_NAME } from '@novacredit/frontend-common/const';
import {
	BASE_CONSUMER_DASHBOARD_DOMAIN,
	BASE_CMS_DOMAIN,
} from '@novacredit/frontend-common/utils/url';
import type { Env } from '@novacredit/frontend-common/types';
import {
	COOKIES,
	getNovaCookie,
	setNovaCookie,
} from '@novacredit/frontend-common/utils/novaCookies';

import { tracker } from 'modules/tracking';
import {
	ENV,
	BASE_API_URLS,
	CARDSHOP_SECURED_CARDS_PATH,
	COUNTRY_NAMES,
	COLLOQUIAL_COUNTRY_NAMES,
	COUNTRY_BUREAU_DEFAULT_MAPPING,
} from 'consts';

export const isPrPreview = (): Boolean => /bobacredit/.test(window.location.href);

export const getNodeEnv = ((): (() => Env) => {
	const href = window.location.href;
	if (/localhost.*|bobacredit/.test(href) || process.env.NODE_ENV === ENV.development) {
		return () => ENV.development;
	}
	if (/aphrodite.*/.test(href)) {
		return () => ENV.staging;
	}
	return () => ENV.production;
})();

export const getBaseURL = () => {
	return BASE_API_URLS[getNodeEnv()];
};

export const getConsumerDashURL = () => {
	return BASE_CONSUMER_DASHBOARD_DOMAIN[getNodeEnv()];
};

// Equivalent (by defualt) to the following when using `date-fns`: `format(parse(date), 'MMMM dd, yyyy');`.
export const formatDate = (
	date,
	dateFormatOptions: Intl.DateTimeFormatOptions = {
		month: 'long',
		day: 'numeric',
		year: 'numeric',
	}
) => {
	const dateObj = new Date(date);

	return new Intl.DateTimeFormat('en-US', dateFormatOptions).format(new Date(dateObj));
};

export const getFixedPercentage = ({ percentage, fixedPlace = 0 }) => {
	return (percentage * 100).toFixed(fixedPlace);
};

export const stringifyArray = arr => (Array.isArray(arr) ? arr.join(',').toLowerCase() : '');

export const getCountryNames = countryCode => {
	return {
		countryName: COUNTRY_NAMES[countryCode],
		colloquialName: COLLOQUIAL_COUNTRY_NAMES[countryCode] || COUNTRY_NAMES[countryCode],
	};
};

export const getBureauNameForCountry = countryCode => {
	return SUPPLIER_CODE_TO_NAME[COUNTRY_BUREAU_DEFAULT_MAPPING[countryCode]];
};

// ----------------------------------------------------------------------------
// CMS (www.novacredit.com)
// ----------------------------------------------------------------------------

export const getCmsURL = (path?: string) => {
	const OLD_TO_NEW_MAP = {
		'/about': '/company',
		'/blog': '/corporate-blog',
		'/categories/building-credit/': '/resources?category=building-credit',
		'/leadership': '/company',
		'/partner/mpower': '/student-loan',
		'/partner/verizon': '/mobile-phone',
		'/partner/westlake-financial': '/partner-westlake-financial',
		'/resources/best-credit-cards-for-non-u-s-citizens':
			'/resources/credit-cards-for-non-u-s-citizens',
		'/resources/best-secured-credit-card/': '/resources/great-secured-credit-cards',
		'/resources/how-to-transfer-your-credit-score': '/resources/international-credit',
	};
	const baseURL = BASE_CMS_DOMAIN[getNodeEnv()];

	if (!path) {
		return baseURL;
	}

	const rewrittenPath = OLD_TO_NEW_MAP[path] || path;
	return `${baseURL}${rewrittenPath}`;
};

export const getSecuredCardsArticleURL = () => {
	return getCmsURL(CARDSHOP_SECURED_CARDS_PATH);
};

export const getFriendlyUSACreditDuration = (USACreditDuration: CreditDuration) => {
	return US_CREDIT_DURATION_TO_QUERY_PARAM[USACreditDuration] || '';
};

/**
 * The value for the USACreditDuration onboarding survey answer which we
 * send to Mixpanel, Intercom, etc., needs to be mapped back to the key in
 * the CONSUMER_US_CREDIT_DURATION_OPTIONS enum to ensure it is formatted correctly
 * to send via a graphql query
 * @param {String} [usaCreditDuration] the duration the user had usa credit for
 * @returns {String | null} the key associated with the usaCreditDuration value
 */
export const transformUSACreditDuration = usaCreditDuration => {
	return Object.keys(CONSUMER_US_CREDIT_DURATION_OPTIONS).find(
		key => CONSUMER_US_CREDIT_DURATION_OPTIONS[key] === usaCreditDuration
	);
};

// @ts-expect-error ts-migrate(2551) FIXME: Property 'documentMode' does not exist on type 'Do... Remove this comment to see the full error message
export const isIE = !!window.document.documentMode;

// ----------------------------------------------------------------------------
// CARD RECOMMENDATIONS UTILS
// ----------------------------------------------------------------------------

export const hasGoodOrExcellentCredit = score => {
	return score >= CREDIT_RATINGS.GOOD.min;
};

export const isCreditDurationSupported = ({
	customer,
	creditDuration,
}: {
	customer: 'American Express' | 'Citi';
	creditDuration: CreditDuration;
}) => {
	// Note - Should stay in sync with consumercore creditCards Model
	const customerToSupportedUSACreditDuration = {
		'American Express': [
			CONSUMER_US_CREDIT_DURATION_OPTIONS.DONT_KNOW,
			CONSUMER_US_CREDIT_DURATION_OPTIONS.LESS_THAN_6_MOS,
		],
		Citi: [
			CONSUMER_US_CREDIT_DURATION_OPTIONS.DONT_KNOW,
			CONSUMER_US_CREDIT_DURATION_OPTIONS.LESS_THAN_6_MOS,
			CONSUMER_US_CREDIT_DURATION_OPTIONS.BTW_6_12_MOS,
		],
	};

	const usaCreditDuration = transformUSACreditDuration(creditDuration);

	return usaCreditDuration
		? customerToSupportedUSACreditDuration[customer].includes(
				CONSUMER_US_CREDIT_DURATION_OPTIONS[usaCreditDuration]
		  )
		: true;
};

export const isAmexEligible = ({
	score,
	countryCode,
	creditDuration,
	ignoreScore = false,
}: {
	score?: number;
	countryCode: CountryCode;
	creditDuration: CreditDuration;
	ignoreScore?: boolean;
}) => {
	const isScoreAboveThreshold = score >= AMEX_MIN_SCORE;

	const isCountrySupported = isAmexCountry(countryCode);

	return (
		(ignoreScore || isScoreAboveThreshold) &&
		isCountrySupported &&
		isCreditDurationSupported({ customer: 'American Express', creditDuration })
	);
};

export const isCitiEligible = ({
	score,
	countryCode,
	creditDuration,
	hasSSN,
	ignoreScore = false,
}: {
	score?: number;
	countryCode: CountryCode;
	creditDuration: CreditDuration;
	hasSSN: boolean;
	ignoreScore?: boolean;
}) => {
	const isScoreAboveThreshold = hasGoodOrExcellentCredit(score);
	const isCountrySupported = isCitiCountry(countryCode);

	return (
		(ignoreScore || isScoreAboveThreshold) &&
		isCountrySupported &&
		isCreditDurationSupported({ customer: 'Citi', creditDuration }) &&
		!!hasSSN
	);
};

export const isEligibleForNovaEnabledCards = ({
	score,
	countryCode,
	creditDuration,
	hasSSN,
	// Ordinarily, users need a certain credit score to be eligible. This
	// bypasses the credit score based portion of the eligibility check.
	ignoreScore = false,
}: {
	score?: number;
	countryCode: CountryCode;
	creditDuration: CreditDuration;
	hasSSN: boolean;
	ignoreScore?: boolean;
}) => {
	const amexEligible = isAmexEligible({ score, countryCode, creditDuration, ignoreScore });
	const citiEligible = isCitiEligible({
		score,
		countryCode,
		creditDuration,
		hasSSN,
		ignoreScore,
	});

	return amexEligible || citiEligible;
};

export const getValidUSACreditDuration = friendlyUSACreditDuration => {
	return Object.keys(CONSUMER_US_CREDIT_DURATION_OPTIONS).find(
		key =>
			US_CREDIT_DURATION_TO_QUERY_PARAM[CONSUMER_US_CREDIT_DURATION_OPTIONS[key]] ===
			friendlyUSACreditDuration
	);
};

/**
 * Shallow merges `obj` into `base`. Appends values to array when a same key is present in both objects.
 */
export const shallowMerge = (base: { [key: string]: unknown }, obj: { [key: string]: unknown }) => {
	const merged: { [key: string]: unknown } = { ...base };

	Object.entries(obj).forEach(([key, value]) => {
		if (!base.hasOwnProperty(key)) {
			merged[key] = value;
		} else if (!Array.isArray(base[key]) && value !== base[key]) {
			merged[key] = [base[key], value];
		} else if (Array.isArray(base[key]) && value !== (base[key] as Array<unknown>).at(-1)) {
			merged[key] = [...(base[key] as Array<unknown>), value];
		}
	});

	return merged;
};

export const trackUtmParameters = (locationSearch: Location['search']) => {
	// pull UTM parameters from the URL
	const urlSearch = qs.parse(locationSearch, { ignoreQueryPrefix: true });
	const utmOnly = {
		utmCampaign: urlSearch.utm_campaign,
		utmContent: urlSearch.utm_content,
		utmMedium: urlSearch.utm_medium,
		utmSource: urlSearch.utm_source,
		utmTerm: urlSearch.utm_term,
	} as { [key: string]: string };
	// set user properties on MixPanel
	if (Object.keys(utmOnly).length) {
		tracker.setUserProps(
			{ ...utmOnly },
			{
				FacebookPixel: { skip: true },
				GoogleAnalytics: { skip: true },
				GoogleTagManager: { skip: true },
			}
		);
	}
	// merge with previous values and store in cookie for later use
	const existing = qs.parse(getNovaCookie(COOKIES.UTM_INFO));
	const mergedParams = shallowMerge(existing, utmOnly);
	const asString = qs.stringify(mergedParams);
	if (asString) {
		setNovaCookie(COOKIES.UTM_INFO, asString);
	}
};

export const trackReferrerUrl = () => {
	const referrerUrl = document.referrer;
	if (
		// user accessed it through a bookmark or entering the address directly
		!referrerUrl ||
		// redirect from inside consumer dashboard
		referrerUrl.indexOf(getConsumerDashURL()) === 0 ||
		// redirect from CMS
		referrerUrl.indexOf(getCmsURL()) === 0
	) {
		return;
	}

	tracker.setUserProps({ websiteReferrerUrl: referrerUrl });
};

export const trackReferral = (referrerOrAffiliate: {
	affiliate: { referralCode: string };
	referrer: { referralCode: string };
}) => {
	if (!referrerOrAffiliate) {
		return;
	}
	const referralCode = referrerOrAffiliate.affiliate?.referralCode
		? referrerOrAffiliate.affiliate.referralCode
		: referrerOrAffiliate.referrer?.referralCode;
	setNovaCookie(COOKIES.REFERRAL, referralCode);
};
