import React from 'react';
import classnames from 'classnames';

type OwnProps = {
	children: React.ReactNode;
	className?: string;
	tag?: string;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
// eslint-disable-next-line no-use-before-define
type Props = OwnProps & typeof Container.defaultProps;

const Container = ({ children, className, tag: Tag, ...attributes }: Props) => {
	return (
		<Tag className={classnames('container', className)} {...attributes}>
			{children}
		</Tag>
	);
};

Container.defaultProps = {
	className: null,
	tag: 'div',
};

export default Container;
