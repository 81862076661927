/**
 * NOTE: Keep in sync with enum in common package
 */
export const CONSUMER_US_CREDIT_DURATION_OPTIONS = {
	LESS_THAN_6_MOS: '0-6_MOS',
	BTW_6_12_MOS: '6-12_MOS',
	MORE_THAN_12_MOS: '12_OR_MORE_MOS',
	DONT_KNOW: 'DONT_KNOW',
} as const;

export type CreditDuration =
	typeof CONSUMER_US_CREDIT_DURATION_OPTIONS[keyof typeof CONSUMER_US_CREDIT_DURATION_OPTIONS];

/**
 *  Mapping from the selected USACreditDuration in the Ellis onboarding survey to a PII-friendly string passed to the CMS card shop
 */
export const US_CREDIT_DURATION_TO_QUERY_PARAM = {
	[CONSUMER_US_CREDIT_DURATION_OPTIONS.LESS_THAN_6_MOS]: 'short',
	[CONSUMER_US_CREDIT_DURATION_OPTIONS.BTW_6_12_MOS]: 'medium',
	[CONSUMER_US_CREDIT_DURATION_OPTIONS.MORE_THAN_12_MOS]: 'long',
	[CONSUMER_US_CREDIT_DURATION_OPTIONS.DONT_KNOW]: 'none',
};

/**
 * NOTE: Keep in sync with enum in common package
 */
export const MASKED_PROPERTIES = {
	hasSSN: 'drinksCoffee',
	hasUSACreditHistory: 'hasAPet',
	hasHomeCreditHistory: 'hasACat',
	USACreditDuration: 'petsAge',
	currentlyInUSA: 'likesPizza',
	plannedArrivalDate: 'plannedDate',
	arrivalDate: 'date',
	country: 'country',
	unsupportedCountry: 'unsupportedCountry',
} as const;

export const VISA_LIST = [
	'A1',
	'A2',
	'A3',
	'B1',
	'B2',
	'B1/B2',
	'C1',
	'C1/D',
	'C2',
	'C3',
	'D',
	'E1',
	'E2',
	'E3',
	'E3D',
	'E3R',
	'F1',
	'F1 OPT',
	'F2',
	'F3',
	'G1',
	'G2',
	'G3',
	'G4',
	'G5',
	'H1B',
	'H1B1',
	'H1C',
	'H2A',
	'H2B',
	'H3',
	'H4',
	'I',
	'J1',
	'J2',
	'K1',
	'K2',
	'K3',
	'K4',
	'L1',
	'L2',
	'M1',
	'M2',
	'M3',
	'N8',
	'N9',
	'NATO 1',
	'NATO 2',
	'NATO 3',
	'NATO 4',
	'NATO 5',
	'NATO 6',
	'NATO 7',
	'O1',
	'O2',
	'O3',
	'P1',
	'P2',
	'P3',
	'P4',
	'Q1',
	'R1',
	'R2',
	'S5',
	'S6',
	'S7',
	'T1',
	'T2',
	'T3',
	'T4',
	'T5',
	'T6',
	'TN',
	'TD',
	'U1',
	'U2',
	'U3',
	'U4',
	'U5',
	'V1',
	'V2',
	'V3',
	'IR1',
	'IR2',
	'IR3',
	'IH3',
	'IR4',
	'IH4',
	'IR5',
	'CR1',
	'CR2',
	'IW1',
	'IW2',
	'IB1',
	'IB2',
	'IB3',
	'AM1',
	'AM2',
	'AM3',
	'SB1',
	'SC1',
	'SC2',
	'SI1',
	'SI2',
	'SI3',
	'SM1',
	'SM2',
	'SM3',
	'SQ1',
	'SQ2',
	'SQ3',
	'SU2',
	'SU3',
	'SU5',
	'F11',
	'F12',
	'B11',
	'B12',
	'F21',
	'F22',
	'F23',
	'F24',
	'F25',
	'C21',
	'C22',
	'C23',
	'C24',
	'C25',
	'B21',
	'B22',
	'B23',
	'B24',
	'B25',
	'FX1',
	'FX2',
	'FX3',
	'CX1',
	'CX2',
	'CX3',
	'BX1',
	'BX2',
	'BX3',
	'F31',
	'F32',
	'F33',
	'C31',
	'C32',
	'C33',
	'B31',
	'B32',
	'B33',
	'F41',
	'F42',
	'F43',
	'E11',
	'E12',
	'E13',
	'E14',
	'E15',
	'E21',
	'E22',
	'E23',
	'E31',
	'E32',
	'E34',
	'E35',
	'EW3',
	'EW4',
	'EW5',
	'BC1',
	'BC2',
	'BC3',
	'SD1',
	'SD2',
	'SD3',
	'SE1',
	'SE2',
	'SE3',
	'SF1',
	'SF2',
	'SG1',
	'SG2',
	'SH1',
	'SH2',
	'SJ1',
	'SJ2',
	'SK1',
	'SK2',
	'SK3',
	'SK4',
	'SL1',
	'SN1',
	'SN2',
	'SN3',
	'SN4',
	'SP',
	'SR1',
	'SR2',
	'SR3',
	'C51',
	'C52',
	'C53',
	'T51',
	'T52',
	'T53',
	'R51',
	'R52',
	'R53',
	'I51',
	'I52',
	'I53',
	'DV1',
	'DV2',
	'DV3',
	'Other',
	'None',
] as const;

export default {
	CONSUMER_US_CREDIT_DURATION_OPTIONS,
	US_CREDIT_DURATION_TO_QUERY_PARAM,
	MASKED_PROPERTIES,
};
