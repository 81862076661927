export type Channel =
	| 'Facebook'
	| 'Twitter'
	| 'LinkedIn'
	| 'email'
	| 'WhatsApp'
	| 'Copy link'
	| 'Email';

export const CHANNEL = {
	FACEBOOK: 'Facebook',
	TWITTER: 'Twitter',
	LINKEDIN: 'LinkedIn',
	EMAIL: 'Email',
	WHATSAPP: 'WhatsApp',
	COPYLINK: 'Copy link',
} as const;
