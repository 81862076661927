import React from 'react';
import MarkdownJSX from 'markdown-to-jsx';

import MarkdownLink from './MarkdownLink';

const options = {
	overrides: {
		a: { component: MarkdownLink },
	},
};

type Props = {
	content: string;
};

const Markdown = ({ content }: Props) => {
	return <MarkdownJSX options={options}>{content}</MarkdownJSX>;
};

export default Markdown;
