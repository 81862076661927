import qs from 'qs';

import type { ExperimentOverrides } from '../experiments/ExperimentsClient';

/**
 * Inspects the URL query parameters and builds an experiments override object
 * from any parameters that are prefixed with 'experiment_'. For example:
 *
 * www.novacredit.com/cards?experiment_redesign=new_version
 *
 * would result in the overrides object:
 *
 * { REDESIGN: 'new_version' }
 */
export const getOverridesFromQueryParams = (): ExperimentOverrides => {
	// If this gets called during a server-side render (e.g. in the CMS),
	// then we won't have a window to grab URL query parameters from.
	//
	// TODO: see if we can avoid calling this server-side in the first place.
	if (typeof window === 'undefined') {
		return {};
	}

	const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

	const experimentOrFeatureFlagRegex = /^experiment_|feature_flag_/i;

	return Object.fromEntries(
		Object.entries(queryParams)
			.filter(([queryParamKey]) => experimentOrFeatureFlagRegex.test(queryParamKey))
			.map(([queryParamKey, queryParamValue]) => {
				const experimentName = queryParamKey.replace(experimentOrFeatureFlagRegex, '');

				// The qs library also handles array based URL query parameters which we don't
				// care about here - coerce the query param value to a string.
				const variant = (queryParamValue || '').toString();

				return [experimentName.toUpperCase(), variant.toUpperCase()];
			}),
	);
};

/**
 * Takes in an object of experiment overrides and translates it to query params.
 *
 * For example, if provided:
 *
 *     overrides: { SOME_AB_TEST: 'VARIANT' }
 *
 * it returns:
 *
 *     { experiment_some_ab_test: 'VARIANT' }
 *
 * which can then be injected into query parameters.
 *
 * @param {ExperimentOverrides} overrides experiment flags and their current values
 * @returns {Object} a mapping of query parameters
 */
export const getQueryParamsFromExperimentOverrides = (overrides: ExperimentOverrides): Object => {
	return Object.keys(overrides).reduce<Record<string, string>>((previous, curr) => {
		const queryParamKey = `experiment_${curr.toLocaleLowerCase()}`;
		previous[queryParamKey] = overrides[curr];
		return previous;
	}, {});
};

export default {
	getOverridesFromQueryParams,
	getQueryParamsFromExperimentOverrides,
};
