import React from 'react';
import classNames from 'classnames';
import { Tooltip, Typography } from '@novacredit/pandorasbox';

import './AdDisclosure.scss';

const AdDisclosure = ({ align = 'left' }: { align?: 'left' | 'right' }) => (
	<div className={classNames('ad-disclosure', `align-${align}`)}>
		<Tooltip
			position={'bottom' as any}
			theme="light"
			distance={-10}
			tooltipTrigger={
				<Typography
					tag="p"
					variant="Body-Regular"
					color="ColorBlue600"
					className="ad-disclosure-trigger"
				>
					Advertiser Disclosure
				</Typography>
			}
			content={
				<Typography
					tag="p"
					variant="Caption-Regular"
					color="ColorCoolGray900"
					className="tooltip-text mb-0"
				>
					<strong>Advertiser Disclosure:</strong> The card offers that appear on this site
					are from companies from which Nova Credit receives compensation. This
					compensation may impact how and where products appear on this site (including,
					for example, the order in which they appear). Nova Credit does not include all
					card companies or all card offers available in the marketplace.
				</Typography>
			}
			arrow
		/>
	</div>
);

export default AdDisclosure;
