/* eslint-disable */
// @ts-nocheck

// See: https://developers.facebook.com/docs/meta-pixel/implementation/gdpr
export const updateFacebookConsent = (consentGranted: boolean) => {
	if (window.fbq) {
		fbq('consent', consentGranted ? 'grant' : 'revoke');
	}
};

// See: https://developers.facebook.com/docs/facebook-pixel/implementation
export const loadFacebookPixel = (facebookPixelId, advancedMatchingData, consentGranted = true) => {
	// Load the pixel
	!(function (f, b, e, v, n, t, s) {
		if (f.fbq) return;
		n = f.fbq = function () {
			n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
		};
		if (!f._fbq) f._fbq = n;
		n.push = n;
		n.loaded = !0;
		n.version = '2.0';
		n.queue = [];
		t = b.createElement(e);
		t.async = !0;
		t.src = v;
		s = b.getElementsByTagName(e)[0];
		s.parentNode.insertBefore(t, s);
	})(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

	// We may need to revoke consent for now, until we've confirmed the user's cookie choices
	updateFacebookConsent(consentGranted);

	fbq('init', facebookPixelId, advancedMatchingData);
};
