/* eslint-disable security/detect-unsafe-regex */
import { getQueryParams } from '../utils/location';

export type Properties = { [key: string]: string | number | boolean | undefined };

type Browsers =
	| 'Opera Mini'
	| 'Opera'
	| 'BlackBerry'
	| 'Internet Explorer Mobile'
	| 'Samsung Internet'
	| 'Microsoft Edge'
	| 'Facebook Mobile'
	| 'Chrome'
	| 'Chrome iOS'
	| 'UC Browser'
	| 'Firefox iOS'
	| 'Mobile Safari'
	| 'Safari'
	| 'Android Mobile'
	| 'Konqueror'
	| 'Firefox'
	| 'Internet Explorer'
	| 'Mozilla'
	| 'Unknown';

export const getScreenHeight = (): number => {
	return window.screen.height;
};

export const getScreenWidth = (): number => {
	return window.screen.width;
};

const getDevice = (userAgent: string): string => {
	if (/Windows Phone/i.test(userAgent) || /WPDesktop/.test(userAgent)) {
		return 'Windows Phone';
	} else if (/iPad/.test(userAgent)) {
		return 'iPad';
	} else if (/iPod/.test(userAgent)) {
		return 'iPod Touch';
	} else if (/iPhone/.test(userAgent)) {
		return 'iPhone';
	} else if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
		return 'BlackBerry';
	} else if (/Android/.test(userAgent)) {
		return 'Android';
	} else {
		return 'Desktop';
	}
};

const getOs = (userAgent: string) => {
	if (/Windows/i.test(userAgent)) {
		if (/Phone/.test(userAgent) || /WPDesktop/.test(userAgent)) {
			return 'Windows Phone';
		}
		return 'Windows';
	} else if (/(iPhone|iPad|iPod)/.test(userAgent)) {
		return 'iOS';
	} else if (/Android/.test(userAgent)) {
		return 'Android';
	} else if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
		return 'BlackBerry';
	} else if (/Mac/i.test(userAgent)) {
		return 'Mac OS X';
	} else if (/Linux/.test(userAgent)) {
		return 'Linux';
	} else if (/CrOS/.test(userAgent)) {
		return 'Chrome OS';
	} else {
		return '';
	}
};

const getBrowser = (userAgent: string, vendor = '', opera: object): Browsers => {
	if (opera || userAgent.includes(' OPR/')) {
		if (userAgent.includes('Mini')) {
			return 'Opera Mini';
		}
		return 'Opera';
	} else if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
		return 'BlackBerry';
	} else if (userAgent.includes('IEMobile') || userAgent.includes('WPDesktop')) {
		return 'Internet Explorer Mobile';
	} else if (userAgent.includes('SamsungBrowser/')) {
		// https://developer.samsung.com/internet/user-agent-string-format
		return 'Samsung Internet';
	} else if (userAgent.includes('Edge') || userAgent.includes('Edg/')) {
		return 'Microsoft Edge';
	} else if (userAgent.includes('FBIOS')) {
		return 'Facebook Mobile';
	} else if (userAgent.includes('Chrome')) {
		return 'Chrome';
	} else if (userAgent.includes('CriOS')) {
		return 'Chrome iOS';
	} else if (userAgent.includes('UCWEB') || userAgent.includes('UCBrowser')) {
		return 'UC Browser';
	} else if (userAgent.includes('FxiOS')) {
		return 'Firefox iOS';
	} else if (vendor.includes('Apple')) {
		if (userAgent.includes('Mobile')) {
			return 'Mobile Safari';
		}
		return 'Safari';
	} else if (userAgent.includes('Android')) {
		return 'Android Mobile';
	} else if (userAgent.includes('Konqueror')) {
		return 'Konqueror';
	} else if (userAgent.includes('Firefox')) {
		return 'Firefox';
	} else if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
		return 'Internet Explorer';
	} else if (userAgent.includes('Gecko')) {
		return 'Mozilla';
	} else {
		return 'Unknown';
	}
};

const getBrowserVersion = (userAgent: string, vendor: string, opera: object): string => {
	const browser = getBrowser(userAgent, vendor, opera);
	const versionRegexs: { [key: string]: RegExp } = {
		'Internet Explorer Mobile': /rv:(\d+(\.\d+)?)/,
		'Microsoft Edge': /Edge?\/(\d+(\.\d+)?)/,
		Chrome: /Chrome\/(\d+(\.\d+)?)/,
		'Chrome iOS': /CriOS\/(\d+(\.\d+)?)/,
		'UC Browser': /(UCBrowser|UCWEB)\/(\d+(\.\d+)?)/,
		Safari: /Version\/(\d+(\.\d+)?)/,
		'Mobile Safari': /Version\/(\d+(\.\d+)?)/,
		Opera: /(Opera|OPR)\/(\d+(\.\d+)?)/,
		Firefox: /Firefox\/(\d+(\.\d+)?)/,
		'Firefox iOS': /FxiOS\/(\d+(\.\d+)?)/,
		Konqueror: /Konqueror:(\d+(\.\d+)?)/,
		BlackBerry: /BlackBerry (\d+(\.\d+)?)/,
		'Android Mobile': /android\s(\d+(\.\d+)?)/,
		'Samsung Internet': /SamsungBrowser\/(\d+(\.\d+)?)/,
		'Internet Explorer': /(rv:|MSIE )(\d+(\.\d+)?)/,
		Mozilla: /rv:(\d+(\.\d+)?)/,
	};

	const regex = versionRegexs[browser];
	if (!regex) {
		return '';
	}

	const matches = userAgent.match(regex);
	if (!matches) {
		return '';
	}

	return matches[matches.length - 2];
};

const getCampaignProperty = (keyword: string, location: Location): string => {
	const value = getQueryParams(location)[`utm_${keyword}`];
	return value || '';
};

const getReferringDomain = (referrer: string) => {
	const split = referrer.split('/');
	if (split.length >= 3) {
		return split[2];
	}
	return '';
};

/**
 * Retrieves tracking properties directly from `window` that we expect not to
 * change over the course of the user's session.
 */
export const getSessionProperties = (): Properties => {
	// opera object will exist if the browser is opera.
	const opera = ((window as unknown) as { opera: object }).opera;
	const userAgent = window.navigator.userAgent;

	return {
		os: getOs(userAgent),
		browser: getBrowser(userAgent, window.navigator.vendor, opera),
		device: getDevice(userAgent),
		browserVersion: getBrowserVersion(userAgent, window.navigator.vendor, opera),
		// These dimensions could in theory change over the session, but it's rare
		// enough to justify pre-computing them rather than re-checking on every event.
		screenHeight: getScreenHeight(),
		screenWidth: getScreenWidth(),
	};
};

/**
 * Retrieves tracking properties directly from `window` that may change between
 * tracking events over the course of the user's session.
 */
export const getDynamicProperties = (): Properties => ({
	url: window.location.href,
	currentUrl: window.location.href,
	path: window.location.pathname,
	search: window.location.search,
	title: window.document.title,
	referrer: window.document.referrer,
	referringDomain: getReferringDomain(window.document.referrer),
	utmSource: getCampaignProperty('source', window.location),
	utmMedium: getCampaignProperty('medium', window.location),
	utmCampaign: getCampaignProperty('campaign', window.location),
	utmContent: getCampaignProperty('content', window.location),
	utmTerm: getCampaignProperty('term', window.location),
});
