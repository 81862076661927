import React, { useContext, useEffect, useRef } from 'react';
import classnames from 'classnames';

import CollapseContext from './CollapseContext';

type OwnProps = {
	children: ((...args: any[]) => any) | React.ReactNode;
	className?: string;
	tag?: string;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
// eslint-disable-next-line no-use-before-define
type Props = OwnProps & typeof CollapseContent.defaultProps;

const CollapseContent = ({ children, className, tag: Tag, ...attributes }: Props) => {
	const container = useRef();
	// @ts-expect-error ts-migrate(2339) FIXME: Property 'isOpen' does not exist on type '{}'.
	const { isOpen, toggle, open, close } = useContext(CollapseContext);

	useEffect(() => {
		let maxHeight = 0;
		if (isOpen) {
			// @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
			maxHeight = container.current.scrollHeight;
		}
		// @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
		container.current.style.maxHeight = `${maxHeight}px`;
	}, [isOpen]);

	return (
		<Tag ref={container} className={classnames('collapse-content', className)} {...attributes}>
			{typeof children === 'function' ? children({ isOpen, open, close, toggle }) : children}
		</Tag>
	);
};

CollapseContent.defaultProps = {
	className: null,
	tag: 'div',
};

export default CollapseContent;
