import React, { useState } from 'react';
import { CARDSHOP_LIVE_COUNTRY_CODES } from '@novacredit/frontend-common/utils/countries';
import type { CountryCode } from '@novacredit/frontend-common/utils/countries';

import MovingFromPrompt from 'components/common/MovingFromPrompt';
import { useStoredCountryContext } from 'contexts/StoredCountryContext';
import { useNavigateToCountry } from 'hooks/useNavigateToCountry';
import { Typography } from '@novacredit/pandorasbox';

import './CountrySelectCard.scss';

const CountrySelectCard = () => {
	const { storedCountry } = useStoredCountryContext();
	const [country, setCountry] = useState<CountryCode>(storedCountry);
	const navigateToCountry = useNavigateToCountry(country);

	return (
		<div className="country-select-card mt-lg-5 mt-3">
			<div className="country-select-card-background-gradient" />
			<div className="country-select-card-contents">
				<Typography
					tag="h2"
					variant="Heading-2"
					className="country-select-card-title text-center"
					variantMobile="Heading-4"
				>
					Check Your Eligibility and Kickstart Your Financial Journey
				</Typography>
				<MovingFromPrompt
					countryCodes={CARDSHOP_LIVE_COUNTRY_CODES}
					onSubmit={navigateToCountry}
					onChange={newCountry => setCountry(newCountry)}
					trackingPage="MVM Overview Page"
					buttonText="Continue"
					label="Where are you from?"
				/>
			</div>
		</div>
	);
};

export default CountrySelectCard;
