/* eslint-disable global-require */
require('core-js');

require('regenerator-runtime/runtime');

require('whatwg-fetch');

require('raf/polyfill');

require('core-js/actual/array/includes');

require('core-js/actual/object/assign');

require('core-js/actual/object/from-entries');

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable();

  window.Promise = require('promise/lib/es6-extensions.js');
} // In tests, polyfill requestAnimationFrame since jsdom doesn't provide it yet.
// We don't polyfill it in the browser--this is user's responsibility.


if (process.env.NODE_ENV === 'test') {
  require('raf').polyfill(global);
}