/* eslint-disable */
// @ts-nocheck

const loadUserleap = (envId: string) => {
	(function (l, e, a, p) {
		window.UserLeap = function () {
			U._queue.push(arguments);
		};
		var U = window.UserLeap;
		U.appId = a;
		U._queue = [];
		a = l.createElement('script');
		a.defer = 1;
		a.src = `${e}?id=${U.appId}`;
		p = l.getElementsByTagName('script')[0];
		p.parentNode.insertBefore(a, p);
	})(document, 'https://cdn.userleap.com/shim.js', envId);
};

export { loadUserleap };
