import { getCmsURL } from 'utils';

export default {
	tabs: [
		{ href: getCmsURL('/personal'), name: 'individual' },
		{ href: getCmsURL(), name: 'business' },
	],
	footerColumn1Title: 'Enterprise',
	footerColumn2Title: 'Resources',
	footerColumn3Title: 'About Us',
	footerColumn1Links: [
		{ label: 'Credit Passport®', href: getCmsURL('/credit-passport'), newTab: false },
		{ label: 'Cash Atlas™', href: getCmsURL('/cash-atlas'), newTab: false },
		{ label: 'Income Navigator', href: getCmsURL('/income-navigator'), newTab: false },
		{ label: 'Platform', href: getCmsURL('/platform'), newTab: false },
		{ label: 'Use Cases', href: getCmsURL('/solutions-by-use-case'), newTab: false },
		{ label: 'Industries', href: getCmsURL('/solutions-by-industry'), newTab: false },
	],
	footerColumn2LinksLeft: [
		{
			label: 'Introducing ‘Income Navigator,’ a New Leader in Income Verification',
			href: getCmsURL(
				'/corporate-blog/introducing-income-navigator-a-new-leader-in-income-verification-coverage',
			),
			newTab: false,
		},
		{
			label: 'Webinar On-Demand: Cash Flow is Here: Top use cases helping underwriters today',
			href: getCmsURL(
				'/corporate-blog/main-takeaways-cash-flow-is-here-top-use-cases-helping-underwriters-today',
			),
			newTab: false,
		},
		{
			label: 'Nova Credit Appoints Financial Industry Veteran as Chief Customer Officer',
			href: getCmsURL(
				'/corporate-blog/nova-credit-appoints-financial-industry-veteran-as-chief-customer-officer',
			),
			newTab: false,
		},
	],
	footerColumn2LinksRight: [
		{
			label: 'Nova Credit Raises $45M Series C Financing',
			href: getCmsURL(
				'/corporate-blog/nova-credit-raises-usd45m-series-c-financing-to-scale-cash-flow-underwriting',
			),
			newTab: false,
		},
		{
			label: 'Nova Credit Expands to Canada, Partners with Scotiabank',
			href: getCmsURL(
				'/corporate-blog/nova-credit-expands-into-canada-partnering-with-scotiabank-as-first-canada',
			),
			newTab: false,
		},
		{ label: 'View all resources', href: getCmsURL('/corporate-blog'), newTab: false },
	],
	footerColumn3Links: [
		{ label: 'Company', href: getCmsURL('/company'), newTab: false },
		{ label: 'Careers', href: getCmsURL('/careers'), newTab: false },
		{ label: 'Press', href: getCmsURL('/press'), newTab: false },
		{ label: 'Corporate Blog', href: getCmsURL('/corporate-blog'), newTab: false },
		{
			label: 'Get In Touch',
			href: 'https://help.novacredit.com/s/contactsupport',
			newTab: false,
		},
		{
			label: 'Disputes',
			href: 'https://help.novacredit.com/s/dispute',
			newTab: false,
		},
		{ label: 'Help Center', href: 'https://help.novacredit.com/s/', newTab: false },
	],
	footerCopyright: '© Copyright 2024 Nova Credit Inc.',
	footerEmailCapture:
		'Subscribe to our newsletter to stay up to date on the latest updates, events, and resources:',
};
