import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

import type { AppPath } from 'modules/paths';
import Loader from 'components/common/Loader';
import SessionContext from 'contexts/SessionContext';

/**
 * A wrapper on top of react-router-dom's Route
 */
const AuthRoute = ({
	exact = false,
	path,
	component,
}: {
	exact?: boolean;
	path: AppPath;
	component: React.ElementType;
}) => {
	const { loading } = useContext(SessionContext);

	if (loading) {
		return <Route path={path} exact={exact} component={Loader} />;
	}

	// @ts-expect-error
	return <Route path={path} exact={exact} component={component} />;
};

export default AuthRoute;
