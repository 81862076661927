import React from 'react';
import classNames from 'classnames';

import { tracker, getTrackingPropsForCreditCard } from 'modules/tracking';

import './RatesAndFeesLink.scss';

/**
 * Note: this component is duplicative with a CMS component.
 */
const RatesAndFeesLink = ({
	href,
	className,
	currentPage,
	card,
}: {
	href: string;
	className?: string;
	currentPage?: string;
	card?: any;
}) => {
	const onViewRatesAndFees = () => {
		if (currentPage && card) {
			tracker.track('nova.consumer_dashboard.RATES_AND_FEES_CLICKED', {
				...getTrackingPropsForCreditCard(card),
				page: currentPage,
			});
		}
	};

	return (
		<div className={classNames('rates-and-fees-link', className)}>
			<a href={href} target="_blank" rel="noopener noreferrer" onClick={onViewRatesAndFees}>
				Rates &amp; Fees
			</a>
			<p className="rates-and-fees-disclaimer">Terms apply</p>
		</div>
	);
};

export default RatesAndFeesLink;
