/* eslint-disable camelcase */
import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, Typography, DesignSystemButton } from '@novacredit/pandorasbox';

import { tracker, getTrackingPropsForCreditCard } from 'modules/tracking';
import ApplyButton from 'components/common/ApplyButton';
import Collapse from 'components/common/Collapse/Collapse';
import Icon from 'components/common/Icon';
import { Row, Col } from 'components/common/Layout';
import RatesAndFeesLink from 'components/common/RatesAndFeesLink';
import IndependentDisclosure from 'components/common/IndependentDisclosure';
import CardImage from 'components/common/CardImage';
import CardPill from './CardPill';
import 'components/common/CardList/CardListItem.scss';

const AMEX_BLUE_CASH_SLUG = 'blue-cash-everyday-card-from-american-express';
const AMEX_GOLD_CASH_SLUG = 'american-express-gold-card';

const CardListItem = ({ card }: { card: any }) => {
	const {
		slug: id,
		name,
		source,
		terms_url,
		bullets,
		annual_fee_description,
		intro_purchase_apr_description,
		intro_purchase_apr_period_description,
		regular_purchase_apr_description,
	} = card;
	const intro_purchase_apr =
		intro_purchase_apr_period_description && intro_purchase_apr_period_description !== 'N/A'
			? `${intro_purchase_apr_description} for ${intro_purchase_apr_period_description}`
			: intro_purchase_apr_description;

	// Historically, the Amex Blue Cash has consistently been the most popular.
	// This could be enhanced in the future to be more dynamic based on recent user activity.
	// These are only displayed on mobile.
	const showMostPopularBadge = card.slug === AMEX_BLUE_CASH_SLUG;
	const showMostPopularForTravelBadge = card.slug === AMEX_GOLD_CASH_SLUG;

	const onTrackDescriptionToggle = event => {
		const isExpanded = event.currentTarget.dataset.isopen;
		if (isExpanded) {
			tracker.track('nova.consumer_dashboard.CREDIT_CARD_DESCRIPTION_EXPANDED', {
				experiment: event.currentTarget.dataset.isexperiment,
				expanded: event.currentTarget.dataset.isopen,
				...getTrackingPropsForCreditCard(card),
			});
		}
	};
	const onViewCardPageClick = () => {
		tracker.track('nova.consumer_dashboard.CREDIT_CARD_DETAILS_CLICKED', {
			currentPage: 'Card Shop',
			...getTrackingPropsForCreditCard(card),
		});
	};
	return (
		<div className="card-list-item" data-testid="card-list-item">
			<Row>
				<Col>
					<Typography tag="h2" className="card-name" variant="Heading-4">
						<Link to={`/cards/${id}`} onClick={onViewCardPageClick}>
							{name}
						</Link>
						{source === 'independent' && <IndependentDisclosure />}
						{showMostPopularBadge && <CardPill text="Most popular" />}
						{showMostPopularForTravelBadge && (
							<CardPill text="Popular for travel" color="emerald" />
						)}
					</Typography>
				</Col>
			</Row>
			<Row className="card-panels">
				<Col className="card-left-panel">
					<div className="card-image">
						<CardImage card={card} />
						<RatesAndFeesLink href={terms_url} card={card} currentPage="Card Shop" />
					</div>
					<div className="card-data">
						<div className="card-rates">
							<div className="card-rate">
								<Typography
									tag="p"
									variant="Body-Semibold"
									className="card-rate_title"
								>
									Annual Fee
								</Typography>
								<Typography
									tag="p"
									variant="Body-Regular"
									className="card-rate_info"
								>
									{annual_fee_description}
								</Typography>
							</div>
							<div className="card-rate">
								<Typography
									tag="p"
									variant="Body-Semibold"
									className="card-rate_title"
								>
									Intro purchase APR
								</Typography>
								<Typography
									tag="p"
									variant="Body-Regular"
									className="card-rate_info"
								>
									{intro_purchase_apr}
								</Typography>
							</div>
							<div className="card-rate">
								<Typography
									tag="p"
									variant="Body-Semibold"
									className="card-rate_title"
								>
									Regular APR
								</Typography>
								<Typography
									tag="p"
									variant="Body-Regular"
									className="card-rate_info"
								>
									{regular_purchase_apr_description}
								</Typography>
							</div>
						</div>
						{bullets && (
							<Collapse>
								<ul className="card-bullets">
									<Typography
										tag="h3"
										variant="Body-Semibold"
										className="card-detail_title"
									>
										Card Details
									</Typography>
									<li>{card.bullets[0]}</li>
									<Collapse.Content>
										{card.bullets.slice(1).map(bullet => {
											return <li key={bullet}>{bullet}</li>;
										})}
									</Collapse.Content>

									<Collapse.Toggle>
										{({ isOpen }) => (
											<DesignSystemButton
												onClick={onTrackDescriptionToggle}
												variant="ghost"
												className="link-button"
												data-isopen={isOpen}
												data-isexperiment
												tag="button"
											>
												{isOpen ? 'Show less' : 'Read more'}
											</DesignSystemButton>
										)}
									</Collapse.Toggle>
								</ul>
							</Collapse>
						)}
					</div>
				</Col>
				<Col className="card-right-panel">
					<ApplyButton card={card} className="card-item-btn" currentPage="Card Shop" />
					{card.nova_enabled && (
						<div className="nova-enabled">
							<div className="mt-2">
								<strong>
									<Icon
										className="nova-enabled-success-icon"
										name="check-circle"
										variant="solid"
									/>{' '}
									<span className="desktop-text">
										Accepts foreign credit history through Nova Credit
									</span>
									<span className="mobile-text">
										Accepts foreign credit history
									</span>{' '}
								</strong>
								<Tooltip
									position="bottom"
									theme="light"
									content={
										<p className="muted tooltip-text">
											This card issuer accepts foreign credit history in
											select countries.
										</p>
									}
									tooltipTrigger={<Icon name="info-circle" variant="light" />}
									arrow
								/>
							</div>
						</div>
					)}
				</Col>
			</Row>
		</div>
	);
};

export default CardListItem;
