import React from 'react';
import classNames from 'classnames';
import { Form } from 'formik';
import type { FormikFormProps } from 'formik';

/**
 * A custom form component which ensures that user submitted data
 * will be masked from Fullstory recordings by appending the "fs-mask"
 * class to the form element. Use this whenever the form will collect
 * PII.
 *
 * Renders Formik's Form by default, but callsites can use a native HTML
 * form by specifying the property as="form".
 */
const PIISafeForm = ({
	children,
	className,
	as = Form,
	...rest
}: {
	children: React.ReactNode;
	className?: string;
	as?: typeof Form | 'form';
} & React.ComponentPropsWithoutRef<'form'> &
	FormikFormProps) => {
	const Tag = as;

	return (
		<Tag className={classNames(className, 'fs-mask')} {...rest}>
			{children}
		</Tag>
	);
};

export default PIISafeForm;
