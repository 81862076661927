import React, { createContext, useReducer } from 'react';
import type { CreditRatingName } from '@novacredit/frontend-common/utils/creditRating';

import type { Card } from 'types';

export const ACTIONS = {
	RESET_FILTERS: 'RESET_FILTERS',
	UPDATE_FEATURES: 'UPDATE_FEATURES',
	UPDATE_REWARDS: 'UPDATE_REWARDS',
	UPDATE_CREDIT_RATING: 'UPDATE_CREDIT_RATING',
} as const;

export const FILTER_TYPES = {
	FEATURES: 'FEATURES',
	REWARDS: 'REWARDS',
	CREDIT_RATING: 'CREDIT_RATING',
} as const;

export type FilterType = keyof typeof FILTER_TYPES;

/* eslint-disable camelcase */
// We have this as camelcase here for legacy reasons, to match with how these are passed
// through to the server

type FeaturesState = {
	no_annual_fee: boolean;
	no_deposit_required: boolean;
	accepts_ssn_or_itin: boolean;
	nova_enabled: boolean;
	reports_to_some_major_us_bureaus: boolean;
	zero_intro_purchase_apr: boolean;
	zero_intro_transfer_apr: boolean;
};

type RewardsState = {
	airline: boolean;
	cash_back: boolean;
	hotel: boolean;
	travel: boolean;
	gas: boolean;
};
/* eslint-enable camelcase */

export type FilterKey = keyof FeaturesState | keyof RewardsState;

type CardShopContextState = {
	features: FeaturesState;
	rewards: RewardsState;
	creditRating: CreditRatingName;
};

type FilterAction = {
	payload?: {
		// Used for UPDATE_FEATURES, UPDATE_REWARDS, and UPDATE_CREDIT_RATING
		filters?: string;

		// Use for ADD_CARD and REMOVE_CARD
		card?: Card;

		// Used for INIT_CARDS_FROM_QUERY
		queryParams?: string;
	};
	type: keyof typeof ACTIONS;
};

export type CardShopContextShape = {
	state: CardShopContextState;
	dispatch: (action: FilterAction) => void;
	clear: () => void;
};

const CardShopContext = createContext<CardShopContextShape>(null);

const INITIAL_STATE: CardShopContextState = {
	features: {
		no_annual_fee: false,
		no_deposit_required: false,
		accepts_ssn_or_itin: false,
		nova_enabled: false,
		reports_to_some_major_us_bureaus: false,
		zero_intro_purchase_apr: false,
		zero_intro_transfer_apr: false,
	},
	rewards: {
		airline: false,
		cash_back: false,
		hotel: false,
		travel: false,
		gas: false,
	},
	creditRating: null,
};

function updateFilterState<FilterStateType extends 'rewards' | 'features'>(
	filterType: FilterStateType,
	pastState: CardShopContextState[FilterStateType],
	currentFilters: string,
): CardShopContextState[FilterStateType] {
	const state = { ...pastState };
	const filters = currentFilters.toLowerCase().split(',');

	Object.keys(pastState).forEach(filterName => {
		state[filterName] = filters.includes(filterName);
	});

	return state;
}

export const reducer = (
	state: CardShopContextState,
	action: FilterAction,
): CardShopContextState => {
	switch (action.type) {
		// Filter actions
		case ACTIONS.RESET_FILTERS:
			return {
				...state,
				features: INITIAL_STATE.features,
				rewards: INITIAL_STATE.rewards,
				creditRating: INITIAL_STATE.creditRating,
			};
		case ACTIONS.UPDATE_FEATURES:
			return {
				...state,
				features: updateFilterState('features', state.features, action.payload.filters),
			};
		case ACTIONS.UPDATE_REWARDS:
			return {
				...state,
				rewards: updateFilterState('rewards', state.rewards, action.payload.filters),
			};
		case ACTIONS.UPDATE_CREDIT_RATING:
			return {
				...state,
				creditRating: action.payload.filters as CreditRatingName,
			};
		default:
			throw new Error();
	}
};

export const CardShopProvider = ({ children }: { children: React.ReactNode }) => {
	const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
	const clear = () => dispatch({ type: ACTIONS.RESET_FILTERS });
	const value = { state, dispatch, clear };

	return <CardShopContext.Provider value={value}>{children}</CardShopContext.Provider>;
};

export const getEnabledFilters = (state: FeaturesState | RewardsState): string[] => {
	const enabledFilters = [];

	Object.keys(state).forEach(filterName => {
		if (state[filterName]) {
			enabledFilters.push(filterName.toUpperCase());
		}
	});

	return enabledFilters;
};

export default CardShopContext;
