import React, { useState, useRef } from 'react';
import { Intercom } from '@novacredit/frontend-common/vendors';

import { tracker } from 'modules/tracking';
import Icon from './Icon';

import './EmailForm.scss';

const EmailForm = props => {
	const [succeed, setSucceed] = useState(false);
	const emailField = useRef(null);
	const location = 'Footer Email Form';
	const handleSubmit = event => {
		event.preventDefault();
		Intercom.subscribeToEmailMarketing({
			email: emailField.current.value,
			consentToMarketing: true,
			tags: ['consumer', 'd2cConsumer'],
			subscriptionSource: {
				app: 'consumer-dashboard',
				location,
			},
			trackingFn: () => {
				tracker.track('nova.client.EMAIL_SUBSCRIBED', {
					location,
				});
			},
		});
		setSucceed(true);
	};

	if (succeed) {
		return (
			<div className="uq-succeed">
				<Icon icon="check-circle" width="30px" />
				Thanks for subscribing!
			</div>
		);
	}
	return (
		<form className="uq-email-form" onSubmit={handleSubmit}>
			<input
				className="uq-input"
				placeholder="Enter your email address"
				type="email"
				name="email"
				autoComplete="email"
				required
				ref={emailField}
				{...props}
			/>
			<div>
				<button type="submit" className="uq-button">
					<span>Subscribe</span>
					<Icon icon="arrow-right" width="14px" />
				</button>
			</div>
		</form>
	);
};

export default EmailForm;
