import { getOrCreateNovaAnonymousId } from '@novacredit/frontend-common/utils/novaAnonymousId';

import { consentGrantedForService } from '../consent/common';
import type { ConsentMap } from '../consent/common';
import { captureException } from '../services/sentry';
import type { CountryCode } from '../utils/countries';
import { listToFlags } from '../utils/transforms';
import { BASE_CONSUMERCORE_DOMAIN } from '../utils/url';
import { getEnvFromLocationOrUrl } from '../utils/env';
import type { Env } from '../types';

/**
 * Adapter for calling a subset of methods exposed in:
 *
 * https://developers.intercom.com/installing-intercom/docs/intercom-javascript
 */

type IntercomTag =
	| 'consumer'
	| 'd2cConsumer'
	| 'enterpriseConsumer'
	| 'consumerDashboardUser'
	| 'countryBacklog'
	| 'referrerUser'
	| 'referee'
	| 'onboarding';

type IntercomSubscriptionSource = {
	app: 'novaconnect' | 'consumer-dashboard' | 'cms';
	location:
		| 'Footer'
		| 'Referral Program Sign-Up'
		| 'Referee Modal'
		| 'Country Backlog Form'
		| 'Consumer Dashboard Credit Passport Marketing Consent'
		| 'Credit Passport Marketing Consent'
		| 'Email Signup CTA'
		| 'Form CTA'
		| 'AmEx Interstitial'
		| 'Eligibility Questionnaire';
};

export type IntercomUserProperties = {
	// date that the user plans to move to the US, whether in the past or future
	moveDate?: string;
	// country that the user is arriving from, as an ISO-3 code
	country?: CountryCode | 'OTHER' | '';
	// NOTE: this form accepts any text input. We should update it to a country dropdown,
	// deprecate this field, and push the country data to the `country` user property.
	countryBacklogCountry?: string;
	// the status of the user's report on the consumer dashboard, e.g. "SUCCESS"
	reportStatus?: string;
	// full name of the user
	name?: string;
	// first name of the user
	firstName?: string;
	// last name of the user
	lastName?: string;
	// true if the user has been referred by another consumer.
	// NOTE: this is redundant with the `referee` tag
	referredUser?: boolean;
	// true if the user qualifies for Amex cards
	amexEligible?: boolean;
	// true if the user qualifies for Citi cards
	citiEligible?: boolean;
	// true if the user signed up to refer others to Nova
	// NOTE: this is redundant with the `referrer` tag
	referrer?: boolean;
	// the referral code for this user, provided they signed up for the referral program
	referralCode?: string;
	// the most recent customer that this consumer applied via Credit Passport for
	customerName?: string;
	// the most recent data supplier (e.g. bureau) that this consumer applied via Credit Passport with
	dataSupplier?: string;
	// topics that the user is interested in hearing from us about
	interestedInCreditCards?: boolean;
	interestedInSmallBusinessLoans?: boolean;
	interestedInPhonePlans?: boolean;
	interestedInApartmentRentals?: boolean;
	interestedInStudentLoans?: boolean;
	// true if the user is already in the US or plans on moving to the US
	movingToUS?: boolean;
	// true if the user has already moved to the US
	alreadyMoved?: boolean;
	// true if the user moved to the US within the past six months (at time of response)
	justMoved?: boolean;
	// true if the user plans to the move to the US or moved within the past six months (at time of response)
	justOrGoingToMove?: boolean;
	// These fields have been masked. See https://app.tettra.co/teams/novacredit/pages/client-side-event-lookups
	superhero?: string;
	drinksCoffee?: boolean;
	hasAPet?: boolean;
	hasACat?: boolean;
	petsAge?: string;
	likesPizza?: string;
	date?: string;
	plannedDate?: string;
};

let isIntercomEnabled = false;

const sendRequest = async <T>({
	path,
	body,
	method,
	skipCheck = false,
}: {
	path: string;
	body: object;
	method: 'GET' | 'POST';
	skipCheck?: boolean;
}): Promise<null | T> => {
	if (!isIntercomEnabled && !skipCheck) {
		return Promise.resolve(null);
	}
	return new Promise(resolve =>
		fetch(`${BASE_CONSUMERCORE_DOMAIN[getEnvFromLocationOrUrl()]}/${path}`, {
			method,
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		})
			.then(async response => {
				if (!response.ok) {
					throw new Error(response.statusText);
				}
				try {
					resolve(await response.json());
				} catch (e) {
					resolve({} as T);
				}
			})
			.catch(error => {
				// If there's an issue, we want to fail nicely
				captureException(error);
				resolve(null);
			}),
	);
};

const initialize = (nodeEnv: Env, consents: ConsentMap | null = null): Promise<void> => {
	if (!consents || consentGrantedForService(consents, 'Intercom')) {
		isIntercomEnabled = true;
	}
	return Promise.resolve();
};

const update = (userProperties: IntercomUserProperties): Promise<void> => {
	return sendRequest({
		path: 'dashboard/intercom/user',
		method: 'POST',
		body: {
			novaAnonymousId: getOrCreateNovaAnonymousId(),
			userProperties,
		},
	});
};

const trackEvent = (event: string, metadata?: object): Promise<void> => {
	return sendRequest({
		path: 'dashboard/intercom/event',
		method: 'POST',
		body: {
			novaAnonymousId: getOrCreateNovaAnonymousId(),
			event,
			metadata,
		},
	});
};

const subscribeToEmailMarketing = ({
	email,
	consentToMarketing,
	name,
	subscriptionSource,
	tags,
	trackingFn,
	userProperties = {},
}: {
	email: string;
	name?: string;
	consentToMarketing: boolean;
	subscriptionSource: IntercomSubscriptionSource;
	tags: IntercomTag[];
	trackingFn: () => void;
	userProperties?: IntercomUserProperties;
}): Promise<void> => {
	return sendRequest({
		// since this is an user-initiated action, it bypasses the consent check
		skipCheck: true,
		path: 'dashboard/marketing-signup',
		method: 'POST',
		body: {
			novaAnonymousId: getOrCreateNovaAnonymousId(),
			consentToMarketing,
			email,
			name,
			subscriptionSourceApp: subscriptionSource.app,
			subscriptionSourceLocation: subscriptionSource.location,
			subscriptionSourceURL: window.location.href,
			tags: listToFlags(tags),
			userProperties,
		},
	}).then(response => {
		if (response !== null && consentToMarketing) {
			trackingFn();
		}
	});
};

export default {
	initialize,
	update,
	trackEvent,
	subscribeToEmailMarketing,
};
