import classnames from 'classnames';
import React from 'react';

import Header from 'components/cms/Menu';
import Footer from 'components/cms/Footer';
import './Page.scss';

const Page = ({
	children,
	className = '',
	fsMask = true, // By default, hide pages from FS
	fullscreen = false,
}: {
	children: React.ReactNode[] | React.ReactNode;
	className?: string;
	fsMask?: boolean;
	fullscreen?: boolean;
}) => {
	return (
		<div className="app">
			<Header />
			<div className={classnames({ container: !fullscreen })}>
				<div
					className={classnames('page top-margin-small', className, {
						'fs-mask': fsMask,
					})}
				>
					{children}
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Page;
