import React from 'react';
import { decodeHtmlEntities } from '@novacredit/frontend-common/utils/transforms';
import { ORDERED_CREDIT_RATINGS } from '@novacredit/frontend-common/utils/creditRating';

import Markdown from 'components/common/Markdown';
import RatesAndFeesLink from 'components/common/RatesAndFeesLink';

import './CardDetails.scss';

type Props = {
	card: any;
};

const CardDetails = ({ card }: Props) => {
	const applicableCreditRatings = Array.isArray(card.credit_rating)
		? ORDERED_CREDIT_RATINGS.filter(rating => card.credit_rating.includes(rating.name))
		: [];

	const creditRatingsDisplay = applicableCreditRatings
		.map(creditCardRating => creditCardRating.displayName)
		.join(', ');

	return (
		<div className="card-details">
			{card.description && <p className="card-description muted">{card.description}</p>}
			<h5 className="card-description__header">Details</h5>
			<ul className="card-bullets muted">
				{card.bullets.map(
					bullet =>
						bullet && (
							<li key={bullet}>
								<p>{decodeHtmlEntities(bullet)}</p>
							</li>
						),
				)}
			</ul>

			{card.legal_description && (
				<div className="muted card-legal-description">
					<Markdown content={card.legal_description} />
				</div>
			)}

			<h5 className="card-description__header">APRs and Fees</h5>
			<table className="card-details-table">
				<tbody>
					<tr>
						<th>Annual Fee</th>
						<td>{card.annual_fee_description}</td>
					</tr>
					<tr>
						<th>Purchase Intro APR</th>
						<td>
							{card.intro_purchase_apr_description}
							{card.intro_purchase_apr !== null &&
								card.intro_purchase_apr_period_description &&
								card.intro_purchase_apr_period_description !== 'N/A' && (
									<> for {card.intro_purchase_apr_period_description}</>
								)}
						</td>
					</tr>
					<tr>
						<th>Transfer Intro APR</th>
						<td>
							{card.intro_transfer_apr_description}
							{card.intro_transfer_apr !== null &&
								card.intro_transfer_apr_period_description &&
								card.intro_transfer_apr_period_description !== 'N/A' && (
									<> for {card.intro_transfer_apr_period_description}</>
								)}
						</td>
					</tr>
					<tr>
						<th>Regular APR</th>
						<td>{card.regular_purchase_apr_description}</td>
					</tr>
					<tr>
						<th colSpan={2}>
							<RatesAndFeesLink href={card.terms_url} className="mt-2" />
						</th>
					</tr>
				</tbody>
			</table>

			<h5 className="card-description__header">Additional Info</h5>
			<table className="card-details-table">
				<tbody>
					<tr>
						<th>Processing Network</th>
						<td>{card.processing_network}</td>
					</tr>
					<tr>
						<th>Credit Rating</th>
						<td>{creditRatingsDisplay}</td>
					</tr>
					<tr>
						<th>Max Late Fee</th>
						<td>{card.late_payment_fee_description}</td>
					</tr>
					<tr>
						<th>Max Overlimit Fee</th>
						<td>{card.over_limit_fee_description}</td>
					</tr>
					<tr>
						<th>Max Penalty APR</th>
						<td>{card.penalty_apr_description}</td>
					</tr>
					<tr>
						<th>Balance Transfer Fee</th>
						<td>{card.balance_transfer_fee_description}</td>
					</tr>
					<tr>
						<th>Cash Advance Fee</th>
						<td>{card.cash_advance_fee_description}</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default CardDetails;
