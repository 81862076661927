import React, { createContext, useState, useEffect } from 'react';
import type { ExperimentsClient } from '@novacredit/frontend-common/experiments';
import type { ConsentMap } from '@novacredit/frontend-common/consent/common';
import { updateGoogleConsents } from '@novacredit/frontend-common/services/googleConsent';
import { onConsentUpdate as novaCookiesOnConsentUpdate } from '@novacredit/frontend-common/utils/novaCookies';
import {
	trackConsentReady,
	useConsentTrackBannerDisplayed,
	useConsentReadyListener,
} from '@novacredit/frontend-common/consent/cookiebot';
import { Intercom, Userleap } from '@novacredit/frontend-common/vendors';

import { createExperimentsClient } from 'modules/experiments';

import { tracker } from 'modules/tracking';
import { getNodeEnv } from 'utils';

const SessionContext = createContext<{
	cookieConsents: ConsentMap;
	redirectPath: string;
	setRedirectPath: (val: string) => void;
	experimentsClient: ExperimentsClient;
	loading: boolean;
}>({
	cookieConsents: null,
	redirectPath: null,
	setRedirectPath: () => {},
	experimentsClient: null,
	loading: true,
});

type Props = {
	children: React.ReactNode;
};

export const SessionProvider = ({ children }: Props) => {
	const [cookieConsents, setCookieConsents] = useState<ConsentMap>(null);
	const [redirectPath, setRedirectPath] = useState<string>(null);
	const [experimentsClient, setExperimentsClient] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setExperimentsClient(createExperimentsClient());
		tracker.setDefaultProperties({ loggedIn: false });

		// Mark that the Session is finished loading now that we've set everything above
		setLoading(false);

		/**
		 * Initialize tracking here so that we can have the default properties set for
		 * previously queued tracking events as well as events that follow.
		 * */
		tracker.initialize({
			nodeEnv: getNodeEnv(),
			userEnv: 'consumer',
			options: {
				enableCookieConsent: true,
			},
		});
	}, []);

	useConsentTrackBannerDisplayed(tracker);

	useConsentReadyListener(consent => {
		updateGoogleConsents(window.dataLayerGTM, consent);
		tracker.cookieConsentUpdated(consent);
		trackConsentReady(tracker);

		Userleap.initialize(getNodeEnv(), consent);
		Intercom.initialize(getNodeEnv(), consent);

		novaCookiesOnConsentUpdate(consent);

		setCookieConsents(consent);
	});

	return (
		<SessionContext.Provider
			value={{
				cookieConsents,
				loading,
				experimentsClient,
				redirectPath,
				setRedirectPath,
			}}
		>
			{children}
		</SessionContext.Provider>
	);
};

export default SessionContext;
