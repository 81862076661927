import dsColors from '../dist/style-dictionary/json/color.json';

// A bit of trickeration to get this to export in index.ts properly
export default dsColors;
export const designSystemColors = dsColors;

// Not from style guide:
export const black = '#000000';
export const grayLight = '#b0b5c2';
export const grayLightest = '#eaebef';
export const grayNormal = '#656e83';
export const linkColor = '#4069b4';
export const mutedColor = '#6c758c';
export const novaBlue = '#495d83';
export const redNormal = '#de071c';
export const snow = '#f2f4f7';
export const white = '#ffffff';

// From new marketing material for Nova's logo
export const marketingOffWhite = '#fbfbfd';
export const marketingOffBlack = '#0f0b09';

// TODO: Not all colors ADA-compliant, update naming
export const consumerDashColors = {
	gray1: '#f9f9fb',
	gray2: '#e4e7eb',
	gray3: '#cbd0d8',
	gray4: '#4c5965',
	gray5: '#2e343f',
	danger: '#cc4754',
	info: '#417bd8',
	primary: '#333BCC',
	secondary: '#43576b',
	success: '#009688',
	warning: '#e67716',
};
