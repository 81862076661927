const ColorBaseLogo = "#1e3a8a";
const ColorBaseLogotype = "#6b7280";
const ColorBaseMainRed = "#f43f5e";
const ColorBaseMainBlue = "#3b82f6";
const ColorBaseMainGreen = "#34d399";
const ColorBaseMainYellow = "#facc15";
const ColorBaseMainViolet = "#a78bfa";
const ColorCoolGray50 = "#f9fafb";
const ColorCoolGray100 = "#f3f4f6";
const ColorCoolGray200 = "#e5e7eb";
const ColorCoolGray300 = "#d1d5db";
const ColorCoolGray400 = "#9ca3af";
const ColorCoolGray500 = "#6b7280";
const ColorCoolGray600 = "#4b5563";
const ColorCoolGray700 = "#374151";
const ColorCoolGray800 = "#1f2937";
const ColorCoolGray900 = "#111827";
const ColorRose50 = "#fff1f2";
const ColorRose100 = "#ffe4e6";
const ColorRose200 = "#fecdd3";
const ColorRose300 = "#fda4af";
const ColorRose400 = "#fb7185";
const ColorRose500 = "#f43f5e";
const ColorRose600 = "#e11d48";
const ColorRose700 = "#be123c";
const ColorRose800 = "#9f1239";
const ColorRose900 = "#881337";
const ColorYellow50 = "#fefce8";
const ColorYellow100 = "#fef9c3";
const ColorYellow200 = "#fef08a";
const ColorYellow300 = "#fde047";
const ColorYellow400 = "#facc15";
const ColorYellow500 = "#eab308";
const ColorYellow600 = "#ca8a04";
const ColorYellow700 = "#a16207";
const ColorYellow800 = "#854d0e";
const ColorYellow900 = "#713f12";
const ColorBlue50 = "#eff6ff";
const ColorBlue100 = "#dbeafe";
const ColorBlue200 = "#bfdbfe";
const ColorBlue300 = "#93c5fd";
const ColorBlue400 = "#60a5fa";
const ColorBlue500 = "#3b82f6";
const ColorBlue600 = "#2563eb";
const ColorBlue700 = "#1d4ed8";
const ColorBlue800 = "#1e40af";
const ColorBlue900 = "#1e3a8a";
const ColorOrange50 = "#fff7ed";
const ColorOrange100 = "#ffedd5";
const ColorOrange200 = "#fed7aa";
const ColorOrange300 = "#fdba74";
const ColorOrange400 = "#fb923c";
const ColorOrange500 = "#f97316";
const ColorOrange600 = "#ea580c";
const ColorOrange700 = "#c2410c";
const ColorOrange800 = "#9a3412";
const ColorOrange900 = "#7c2d12";
const ColorEmerald50 = "#ecfdf5";
const ColorEmerald100 = "#d1fae5";
const ColorEmerald200 = "#a7f3d0";
const ColorEmerald300 = "#6ee7b7";
const ColorEmerald400 = "#34d399";
const ColorEmerald500 = "#10b981";
const ColorEmerald600 = "#059669";
const ColorEmerald700 = "#047857";
const ColorEmerald800 = "#065f46";
const ColorEmerald900 = "#064e3b";
const ColorViolet50 = "#f5f3ff";
const ColorViolet100 = "#ede9fe";
const ColorViolet200 = "#ddd6fe";
const ColorViolet300 = "#c4b5fd";
const ColorViolet400 = "#a78bfa";
const ColorViolet500 = "#8b5cf6";
const ColorViolet600 = "#7c3aed";
const ColorViolet700 = "#6d28d9";
const ColorViolet800 = "#5b21b6";
const ColorViolet900 = "#4c1d95";
const ColorFormSuccess = "#047857";
const ColorFormError = "#e11d48";
const ColorFormHover = "#4b5563";
const ColorFormFocus = "#2563eb";
const ColorFormDefault = "#4b5563";
var dsColors = {
	ColorBaseLogo: ColorBaseLogo,
	ColorBaseLogotype: ColorBaseLogotype,
	ColorBaseMainRed: ColorBaseMainRed,
	ColorBaseMainBlue: ColorBaseMainBlue,
	ColorBaseMainGreen: ColorBaseMainGreen,
	ColorBaseMainYellow: ColorBaseMainYellow,
	ColorBaseMainViolet: ColorBaseMainViolet,
	ColorCoolGray50: ColorCoolGray50,
	ColorCoolGray100: ColorCoolGray100,
	ColorCoolGray200: ColorCoolGray200,
	ColorCoolGray300: ColorCoolGray300,
	ColorCoolGray400: ColorCoolGray400,
	ColorCoolGray500: ColorCoolGray500,
	ColorCoolGray600: ColorCoolGray600,
	ColorCoolGray700: ColorCoolGray700,
	ColorCoolGray800: ColorCoolGray800,
	ColorCoolGray900: ColorCoolGray900,
	ColorRose50: ColorRose50,
	ColorRose100: ColorRose100,
	ColorRose200: ColorRose200,
	ColorRose300: ColorRose300,
	ColorRose400: ColorRose400,
	ColorRose500: ColorRose500,
	ColorRose600: ColorRose600,
	ColorRose700: ColorRose700,
	ColorRose800: ColorRose800,
	ColorRose900: ColorRose900,
	ColorYellow50: ColorYellow50,
	ColorYellow100: ColorYellow100,
	ColorYellow200: ColorYellow200,
	ColorYellow300: ColorYellow300,
	ColorYellow400: ColorYellow400,
	ColorYellow500: ColorYellow500,
	ColorYellow600: ColorYellow600,
	ColorYellow700: ColorYellow700,
	ColorYellow800: ColorYellow800,
	ColorYellow900: ColorYellow900,
	ColorBlue50: ColorBlue50,
	ColorBlue100: ColorBlue100,
	ColorBlue200: ColorBlue200,
	ColorBlue300: ColorBlue300,
	ColorBlue400: ColorBlue400,
	ColorBlue500: ColorBlue500,
	ColorBlue600: ColorBlue600,
	ColorBlue700: ColorBlue700,
	ColorBlue800: ColorBlue800,
	ColorBlue900: ColorBlue900,
	ColorOrange50: ColorOrange50,
	ColorOrange100: ColorOrange100,
	ColorOrange200: ColorOrange200,
	ColorOrange300: ColorOrange300,
	ColorOrange400: ColorOrange400,
	ColorOrange500: ColorOrange500,
	ColorOrange600: ColorOrange600,
	ColorOrange700: ColorOrange700,
	ColorOrange800: ColorOrange800,
	ColorOrange900: ColorOrange900,
	ColorEmerald50: ColorEmerald50,
	ColorEmerald100: ColorEmerald100,
	ColorEmerald200: ColorEmerald200,
	ColorEmerald300: ColorEmerald300,
	ColorEmerald400: ColorEmerald400,
	ColorEmerald500: ColorEmerald500,
	ColorEmerald600: ColorEmerald600,
	ColorEmerald700: ColorEmerald700,
	ColorEmerald800: ColorEmerald800,
	ColorEmerald900: ColorEmerald900,
	ColorViolet50: ColorViolet50,
	ColorViolet100: ColorViolet100,
	ColorViolet200: ColorViolet200,
	ColorViolet300: ColorViolet300,
	ColorViolet400: ColorViolet400,
	ColorViolet500: ColorViolet500,
	ColorViolet600: ColorViolet600,
	ColorViolet700: ColorViolet700,
	ColorViolet800: ColorViolet800,
	ColorViolet900: ColorViolet900,
	ColorFormSuccess: ColorFormSuccess,
	ColorFormError: ColorFormError,
	ColorFormHover: ColorFormHover,
	ColorFormFocus: ColorFormFocus,
	ColorFormDefault: ColorFormDefault
};

const designSystemColors = dsColors; // Not from style guide:

const black = '#000000';
const grayLight = '#b0b5c2';
const grayLightest = '#eaebef';
const grayNormal = '#656e83';
const linkColor = '#4069b4';
const mutedColor = '#6c758c';
const novaBlue = '#495d83';
const redNormal = '#de071c';
const snow = '#f2f4f7';
const white = '#ffffff'; // From new marketing material for Nova's logo

const marketingOffWhite = '#fbfbfd';
const marketingOffBlack = '#0f0b09'; // TODO: Not all colors ADA-compliant, update naming

const consumerDashColors = {
  gray1: '#f9f9fb',
  gray2: '#e4e7eb',
  gray3: '#cbd0d8',
  gray4: '#4c5965',
  gray5: '#2e343f',
  danger: '#cc4754',
  info: '#417bd8',
  primary: '#333BCC',
  secondary: '#43576b',
  success: '#009688',
  warning: '#e67716'
};

export { black, consumerDashColors, dsColors as default, designSystemColors, grayLight, grayLightest, grayNormal, linkColor, marketingOffBlack, marketingOffWhite, mutedColor, novaBlue, redNormal, snow, white };
