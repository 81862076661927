import React, { useEffect } from 'react';
import { useLocation, Switch, Route } from 'react-router-dom';
import { GoogleAnalyticsTag } from '@novacredit/frontend-common/services/googleAnalytics';
import { GoogleTagManagerTag } from '@novacredit/frontend-common/services/googleTagManager';
import { captureException } from '@novacredit/frontend-common/services/sentry';
import { getPageName } from '@novacredit/frontend-common/utils/pages';

import * as utils from 'utils';
import { PROD_URL, ENV, PAGES } from 'consts';
import { APP_PATHS } from 'modules/paths';
import { GTM_CONTAINER_IDS, tracker } from 'modules/tracking';
import { SessionProvider } from 'contexts/SessionContext';
import { StoredCountryProvider } from 'contexts/StoredCountryContext';
import { LanguageProvider } from 'contexts/LanguageContext';
import AmexRedirectPage from 'components/common/AmexRedirect';
import AuthRoute from 'components/common/AuthRoute';
import CardDetailsPage from 'components/pages/CardDetailsPage';
import CardShopPage from 'components/pages/CardShopPage';
import CookiePolicy from 'components/pages/CookiePolicy';
import HomePage from 'components/pages/HomePage';
import ReferAFriend from 'components/pages/ReferAFriend';
import YourReferralLink from 'components/pages/YourReferralLink';
import ReferralLandingPage from 'components/pages/ReferralLandingPage';
import NotFoundPage from 'components/pages/NotFoundPage';
import Disputes from 'components/pages/Disputes';
import ConsumerType from 'components/pages/ConsumerRequestForm/ConsumerType';
import MarketplacePage from 'components/pages/MarketplacePage';
import NewcomerGuidePage from 'components/pages/NewcomerGuidePage';
import GetStartedPage from 'components/pages/GetStartedPage';
import CookiebotScript from 'components/cms/CookiebotScript';

const App = () => {
	useEffect(() => {
		// In the staging app, if the referrer is Nova Credit, but not from staging (aphrodite)
		// then we want to redirect the user to the production app.
		const isStaging = utils.getNodeEnv() === ENV.staging;
		const referrer = document.referrer || '';
		const isReferredByProd =
			referrer.includes('novacredit.com') && !referrer.includes('aphrodite');

		if (isStaging && isReferredByProd) {
			captureException(
				new Error(
					'User referred by production site is on staging, redirecting them to production',
				),
			);

			const { pathname, search } = window.location;
			window.location.href = PROD_URL + pathname + search;
		}
	}, []);

	const location = useLocation();

	useEffect(() => {
		utils.trackUtmParameters(location.search);
	}, [location.search]);

	useEffect(() => {
		utils.trackReferrerUrl();
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	useEffect(() => {
		tracker.trackPage(location.pathname, { pageName: getPageName(location.pathname, PAGES) });
	}, [location.pathname]);

	useEffect(() => {
		tracker.setUserProps({ visitedD2C: true });
		tracker.track('nova.client.DIRECT_TO_CONSUMER_VISITED', null);
	}, []);

	return (
		<SessionProvider>
			<LanguageProvider>
				<StoredCountryProvider>
					{/* Cookiebot needs to be added before all other scripts */}
					<CookiebotScript />
					<GoogleAnalyticsTag nodeEnv={utils.getNodeEnv()} />
					<GoogleTagManagerTag
						containerId={GTM_CONTAINER_IDS[utils.getNodeEnv()]}
						enableCookieConsent
					/>
					<Switch>
						<Route path="/cards">
							<Switch>
								<AuthRoute
									path={APP_PATHS.CARD_AMEX_REDIRECT}
									component={AmexRedirectPage}
								/>
								<AuthRoute
									path={APP_PATHS.CARD_DETAILS}
									component={CardDetailsPage}
								/>
								<AuthRoute
									exact
									path={APP_PATHS.CARDSHOP}
									component={CardShopPage}
								/>
							</Switch>
						</Route>
						<AuthRoute path={APP_PATHS.COOKIE_POLICY} component={CookiePolicy} />
						<AuthRoute path={APP_PATHS.DISPUTES} component={Disputes} />
						<AuthRoute path={APP_PATHS.CONSUMER_FORM} component={ConsumerType} />
						<AuthRoute path={APP_PATHS.GUIDE} component={NewcomerGuidePage} />
						<AuthRoute path={APP_PATHS.MARKETPLACE} component={MarketplacePage} />
						<AuthRoute path={APP_PATHS.REFER_A_FRIEND} component={ReferAFriend} />
						<AuthRoute
							path={APP_PATHS.REFERRAL_LANDING_PAGE}
							component={ReferralLandingPage}
						/>
						<AuthRoute path={APP_PATHS.GET_STARTED} component={GetStartedPage} />
						<AuthRoute exact path={APP_PATHS.HOME} component={HomePage} />
						<AuthRoute
							exact
							path={APP_PATHS.YOU_REFERRAL_LINK}
							component={YourReferralLink}
						/>
						{/* NotFoundPage must come last as a catch-all 404 page for a non-matched route */}
						<AuthRoute path={APP_PATHS.NOT_FOUND} component={NotFoundPage} />
					</Switch>
				</StoredCountryProvider>
			</LanguageProvider>
		</SessionProvider>
	);
};

export default App;
