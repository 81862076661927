import type { WORKFLOW_NAMES } from '@novacredit/global/const/orchestration';
import { NOVA_PRODUCTS, NOVA_PRODUCT_DISPLAY_NAME } from '@novacredit/global/const/products';
import { NOVA_SCORE_TYPES as GLOBAL_NOVA_SCORE_TYPES } from '@novacredit/global';

export { DESTINATION_COUNTRIES } from '@novacredit/global/const/countries';

// The following display names correspond to and are simultaneously
// maintained in the common maps.ts file
// used in both consumer-dashboard and Novaconnect
export const SUPPLIER_CODE_TO_NAME = {
	AUS_EFX: 'Equifax Australia',
	AUT_CRIF: 'CRIF GmbH Austria',
	BRA_XP: 'Experian Brazil',
	CAN_EFX: 'Equifax Canada',
	CAN_TU: 'Transunion Canada',
	CHE_CRIF: 'CRIF AG Switzerland',
	CHN_CCRC: "Credit Reference Center of the People's Bank of China",
	CHN_CCX: 'China Chengxin Credit',
	CHN_RONG: 'Rong360 (CCRC)',
	COL_TU: 'TransUnion Colombia',
	DEU_CRIF: 'CRIF GmbH Germany',
	DOM_TU: 'TransUnion',
	ESP_EFX: 'ASNEF-Equifax, S.L., and Equifax Ibérica, S.L. Spain',
	GBR_EFX: 'Equifax UK',
	GHA_XDS: 'XDS Ghana',
	IND_CHM: 'CRIF Highmark',
	IND_CIBIL: 'Transunion CIBIL',
	JAM_CI: 'CreditInfo Jamaica',
	KOR_NICE: 'NICE',
	KEN_CI: 'CreditInfo Kenya',
	MEX_BDC: 'Buro de Credito',
	MEX_CDC: 'Circulo de Credito',
	MANUAL_PASSPORT: 'Manual Passport',
	MITEK: 'Mitek Systems, Inc.',
	NGA_CRC: 'CRC Credit Bureau Limited',
	PHL_CRIF: 'CRIF Philippines',
	PHL_TU: 'Transunion Philippines',
	USA_FIN: 'Finicity USA',
	CLEARBIT: 'Clearbit',
	OCROLUS: 'Ocrolus',
	UKR_CI: 'CreditInfo - Ukraine',
	USA_XP: 'Experian USA',
	ZAF_TU: 'Transunion South Africa',
};

export const NON_CREDIT_BUREAU_SUPPLIERS = [
	'USA_AKOYA',
	'USA_FIN',
	'MULTI_AGG',
	'OCROLUS',
	'CLEARBIT',
	'PLAID',
	'MANUAL_PASSPORT',
	'USA_ATOMIC',
];

export const LANGUAGE_CODE_TO_LANGUAGE = {
	EN: 'ENGLISH',
};

export const NOVA_CREDIT_AFFILIATE_NAMES = {
	AUS: 'Nova Roo Pty. Ltd.',
	GBR: 'Nova Crumpet LTD',
	IND: 'Instalogic Technologies Private Limited',
	USA: 'Nova Credit Inc.',
};

// The following are maintained in the common consts.ts file
// used in dashboard.
export enum PDF_PASSWORD_TYPES {
	DOB = 'DOB',
}

export const MITEK_SEND_TO_MOBILE_TTL_MINUTE = 10;

export const MITEK_SEND_TO_MOBILE_TTL_MSEC = MITEK_SEND_TO_MOBILE_TTL_MINUTE * 60 * 1000;

export const USER_RISK_MAP = {
	good: 'Low Risk',
	meh: 'Medium Risk',
	bad: 'High Risk',
};

export const DATE_FORMATS = {
	DAY_MONTH_YEAR: 'DD/MM/YYYY',
	MONTH_DAY_YEAR: 'MM/DD/YYYY',
	HUMAN_READABLE: 'MMMM Do, YYYY',
};

export enum STATUSES {
	ERROR = 'ERROR',
	NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
	NOT_FOUND = 'NOT_FOUND',
	SUCCESS = 'SUCCESS',
	BUREAU_UNRESPONSIVE = 'BUREAU_UNRESPONSIVE',
	UNSUPPORTED_COUNTRY = 'UNSUPPORTED_COUNTRY',
	COMPLETION_PENDING = 'COMPLETION_PENDING',
	SKIPPED = 'SKIPPED',
	PENDING = 'PENDING',
	FINISH_LATER_INITIATED = 'FINISH_LATER_INITIATED',
	NON_CONTRIBUTING_APPLICANT = 'NON_CONTRIBUTING_APPLICANT',
	EXPIRED = 'EXPIRED',
	INVITED = 'INVITED',
	PROCESSING = 'PROCESSING',
	INTERNAL_ERROR = 'INTERNAL_ERROR',
	SUBMITTED = 'SUBMITTED',
	BOUNCED = 'BOUNCED',
}

export const WORKFLOW_NAMES_LABELS: Record<WORKFLOW_NAMES, string> = {
	AKOYA_ONLY: 'Akoya Only',
	ATOMIC_ONLY: 'Atomic Only',
	ATOMIC_MULTI_AGG_OCROLUS: 'Atomic or Multi Agg or Ocrolus with cascade',
	ATOMIC_FINICITY_OCROLUS: 'Atomic or Finicity or Ocrolus with cascade',
	AKOYA_OR_OCROLUS: 'Akoya or Ocrolus',
	CREDIT_PASSPORT_CUSTOMER_MITEK: 'Credit Passport Customer Mitek',
	CREDIT_PASSPORT_DEFAULT: 'Credit Passport Default',
	FINICITY_ONLY: 'Finicity Only',
	FINICITY_OR_OCROLUS: 'Finicity or Ocrolus',
	FINICITY_THEN_OCROLUS: 'Finicity then Ocrolus',
	FINICITY_THEN_OCROLUS_THEN_RESISTANT: 'Finicity then Ocrolus then Resistant AI',
	HSBC_CONNECT_API: 'HSBC Connect API',
	OCROLUS_ONLY: 'Ocrolus Only',
	OCROLUS_THEN_RESISTANT: 'Ocrolus then Resistant AI',
	PLAID_ONLY: 'Plaid Only',
	MULTI_AGG_ONLY: 'Multi Agg Only',
	MULTI_AGG_OR_OCROLUS: 'Multi Agg Or Ocrolus',
	FINICITY_OR_OCROLUS_THEN_ATOMIC: 'Finicity Or Ocrolus Then Atomic',
	FINICITY_THEN_ATOMIC_THEN_OCROLUS: 'Finicity Then Atomic Then Ocrolus',
	MULTI_AGG_THEN_ATOMIC_THEN_OCROLUS: 'MultiAgg Then Atomic Then Ocrolus',
};

export const NOVA_PRODUCTS_LABEL: Record<NOVA_PRODUCTS, string> = {
	CASH_FLOW_UNDERWRITING: NOVA_PRODUCT_DISPLAY_NAME[NOVA_PRODUCTS.CASH_FLOW_UNDERWRITING],
	CREDIT_PASSPORT: NOVA_PRODUCT_DISPLAY_NAME[NOVA_PRODUCTS.CREDIT_PASSPORT],
	VERIFICATION_OF_INCOME: NOVA_PRODUCT_DISPLAY_NAME[NOVA_PRODUCTS.VERIFICATION_OF_INCOME],
	NEWCOMER_KYC: NOVA_PRODUCT_DISPLAY_NAME[NOVA_PRODUCTS.NEWCOMER_KYC],
};

export const NOVA_SCORE_TYPES = Object.entries(GLOBAL_NOVA_SCORE_TYPES)
	.filter(([scoreType]) => scoreType !== GLOBAL_NOVA_SCORE_TYPES.FOREIGN_SCORE)
	.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}) as Record<string, string>;

export default {
	SUPPLIER_CODE_TO_NAME,
	PDF_PASSWORD_TYPES,
	MITEK_SEND_TO_MOBILE_TTL_MSEC,
	MITEK_SEND_TO_MOBILE_TTL_MINUTE,
	NON_CREDIT_BUREAU_SUPPLIERS,
	NOVA_SCORE_TYPES,
	USER_RISK_MAP,
	LANGUAGE_CODE_TO_LANGUAGE,
	DATE_FORMATS,
	STATUSES,
	WORKFLOW_NAMES_LABELS,
};
