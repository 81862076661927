import Helmet from 'react-helmet';
import React, { useEffect, useState } from 'react';

import { setDefaultConsents } from './googleConsent';

/**
 * This file contains code for adding Google Tag Manager.
 *
 * https://developers.google.com/tag-platform/tag-manager/web
 * Install: https://developers.google.com/tag-platform/devguides/datalayer#installation
 */

export type GTMContainerId =
	// cms
	| 'GTM-NDJGJC4'
	| 'GTM-55T5GLS'
	| 'GTM-WVBQHCH'
	// consumer-dashboard
	| 'GTM-WM3XXMR'
	| 'GTM-WSVSQLM'
	| 'GTM-KLLH2L4'
	// novaconnect
	| 'GTM-K53WP4Q'
	| 'GTM-PMXJZPW'
	| 'GTM-TDD5WJX';

/**
 * This is the script copied from Google Tag Manager.
 * The only exception is that we've renamed `dataLayer` to `dataLayerGTM`
 */
const getGTMScript = (containerId: GTMContainerId) => `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayerGTM','${containerId}');
`;

/**
 * Embed this tag in a site-wide component (e.g. App.jsx) to make
 * available a global GTM function.
 */
export const GoogleTagManagerTag = ({
	containerId,
	enableCookieConsent = false,
}: {
	containerId?: GTMContainerId;
	// Consent is required on some apps (CMS, consumer-dash, novaconnect)
	enableCookieConsent?: boolean;
}) => {
	const [defaultConsentApplied, setDefaultConsentApplied] = useState(false);
	const includeScript = defaultConsentApplied || !enableCookieConsent;

	window.dataLayerGTM = window.dataLayerGTM || [];

	useEffect(() => {
		if (!defaultConsentApplied) {
			if (enableCookieConsent) {
				setDefaultConsents(window.dataLayerGTM);
			}

			setDefaultConsentApplied(true);
		}
	}, [defaultConsentApplied, enableCookieConsent]);

	if (!containerId) {
		// If no container was passed in (such as in a test env), then don't add GTM script
		return null;
	}

	return (
		<>
			{includeScript && (
				<Helmet>
					<script type="text/javascript">{getGTMScript(containerId)}</script>
				</Helmet>
			)}
		</>
	);
};
