import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { captureException } from '@novacredit/frontend-common/services/sentry';
import type { CreditDuration } from '@novacredit/frontend-common/utils/consumer';
import { CONSUMER_US_CREDIT_DURATION_OPTIONS } from '@novacredit/frontend-common/utils/consumer';
import {
	CARDSHOP_LIVE_COUNTRY_CODES,
	COUNTRIES,
} from '@novacredit/frontend-common/utils/countries';
import { Intercom } from '@novacredit/frontend-common/vendors';
import {
	DesignSystemButton,
	RadioInputButtonGroup,
	Tooltip,
	Typography,
} from '@novacredit/pandorasbox';
import type { CountryCode } from '@novacredit/frontend-common/utils/countries';

import { isEligibleForNovaEnabledCards } from 'utils';
import { tracker, trackCountrySelected } from 'modules/tracking';

import CountrySelect from 'components/common/CountrySelect';
import type { EndStateName } from 'components/common/Onboarding/OnboardingEndStates';
import SurveyDatePicker from 'components/common/Onboarding/SurveyDatePicker';
import Icon from 'components/common/Icon';

import 'components/common/Onboarding/Onboarding.scss';

export type OnboardingQuestionName =
	| 'start'
	| 'countrySelect'
	| 'unsupportedCountry'
	| 'ssn'
	| 'creditHistory'
	| 'creditDuration'
	| 'arrivedInUs'
	| 'arrivalDate'
	| 'plannedArrivalDate';

type OnboardingQuestions = {
	[questionName in OnboardingQuestionName]: OnboardingQuestion;
};

export type SurveyResponses = {
	country?: CountryCode | '';
	unsupportedCountry?: CountryCode;
	hasSSN?: boolean;
	hasUSACreditHistory?: 'YES' | 'NO';
	USACreditDuration?: CreditDuration;
	currentlyInUSA?: boolean;
	arrivalDate?: Date;
	plannedArrivalDate?: Date;
	arrivalDateOther?: 'CANT_REMEMBER';
};

export type OnboardingQuestion = {
	title: React.ReactNode;
	subtitle: string | null;
	options: OnboardingQuestionOption[];
	questionType: string;
	onboardingAnswerKey: keyof SurveyResponses;
};

export type OnboardingQuestionOption = {
	label: string;
	nextQuestion:
		| (({
				value,
				surveyResponses,
		  }: {
				value: any;
				surveyResponses: SurveyResponses;
		  }) => EndStateName | OnboardingQuestionName)
		| string;
	prevQuestion:
		| (({
				value,
				surveyResponses,
		  }: {
				value: any;
				surveyResponses: SurveyResponses;
		  }) => EndStateName | OnboardingQuestionName)
		| string;
	value?: string;
	getCountryOptions?: () => CountryCode[];
};

type Props = {
	currQuestion: OnboardingQuestion;
	surveyResponses: SurveyResponses;
	updateResponses: (key, val) => void;
	onNextButtonClick: (value, nextQuestion) => void;
	onBackButtonClick: (value, nextQuestion) => void;
};

const OnboardingQuestionButtons = ({
	currQuestion,
	surveyResponses,
	updateResponses,
	onNextButtonClick,
	onBackButtonClick,
}: Props) => {
	const questionKey = currQuestion.onboardingAnswerKey;
	const [answer, setAnswer] = useState('');
	const firstOption = currQuestion.options[0];
	const prevQuestion = firstOption.prevQuestion;

	const nextQuestionBySelectedOptionValue = {};
	currQuestion.options.forEach(option => {
		nextQuestionBySelectedOptionValue[option.value] = option.nextQuestion;
	});
	const groupOptions = currQuestion.options.map((someOption: OnboardingQuestionOption) => {
		return {
			label: someOption.label as string,
			value: someOption.value as string,
		};
	});

	useEffect(() => {
		const selectedAnswer = surveyResponses[questionKey] as string;
		setAnswer(selectedAnswer);
	}, [surveyResponses, questionKey]);

	switch (currQuestion.questionType) {
		case 'buttonSelect':
			return (
				<div>
					<RadioInputButtonGroup
						inputName="us_credit_duration"
						labelId="us-credit-duration-label"
						className="onboarding-survey-question"
						value={answer}
						options={groupOptions}
						onCheck={e => {
							setAnswer(e.target.value);
						}}
					/>
					<div className="survey-button-container">
						{prevQuestion && (
							<DesignSystemButton
								className="survey-button"
								type="submit"
								tag="button"
								variant="outline"
								onClick={() => onBackButtonClick(answer, prevQuestion)}
							>
								Back
							</DesignSystemButton>
						)}
						<DesignSystemButton
							className="survey-button"
							type="submit"
							tag="button"
							disabled={!answer}
							onClick={() =>
								onNextButtonClick(answer, nextQuestionBySelectedOptionValue[answer])
							}
						>
							Next
						</DesignSystemButton>
					</div>
				</div>
			);
		case 'countryDropdown':
			return (
				<>
					<div className="country-select-container">
						<CountrySelect
							key={questionKey}
							countryCodes={firstOption.getCountryOptions()}
							onInit={country => {
								updateResponses(questionKey, country);
							}}
							onChange={country => {
								setAnswer(country);
							}}
							color="ColorCoolGray600"
							trackingPage="Onboarding Survey"
						/>
					</div>
					<div className="survey-button-container">
						<div className="survey-button">
							<DesignSystemButton
								className="onboarding-survey-button button"
								type="submit"
								tag="button"
								disabled={!answer}
								onClick={() => onNextButtonClick(answer, firstOption.nextQuestion)}
							>
								Next
							</DesignSystemButton>
						</div>
					</div>
				</>
			);
		case 'monthYearPicker':
			return (
				<SurveyDatePicker currQuestion={currQuestion} onButtonClick={onNextButtonClick} />
			);
		case 'start':
			return (
				<>
					<div className="survey-button-container">
						<div className="survey-button">
							<DesignSystemButton
								className="onboarding-survey-button button"
								type="submit"
								tag="button"
								onClick={() => onNextButtonClick(answer, firstOption.nextQuestion)}
							>
								Get Started
							</DesignSystemButton>
						</div>
					</div>
				</>
			);
		default:
			return null;
	}
};

/**
 * Given the survey answers the user has provided, determines which end state
 * to send them towards.
 */
export const getEndState = ({
	surveyResponses,
}: {
	surveyResponses: SurveyResponses;
}): EndStateName => {
	const { country, hasSSN, USACreditDuration, plannedArrivalDate, currentlyInUSA } =
		surveyResponses;

	// We don't know the user's credit score yet since they have not gone through
	// the Credit Passport. Ignore the credit score aspect of the eligibility check.
	const isCardshopEligible = isEligibleForNovaEnabledCards({
		countryCode: country as CountryCode,
		creditDuration: USACreditDuration,
		hasSSN,
		ignoreScore: true,
	});
	const movingInFuture = !!plannedArrivalDate;
	const cantRemember = USACreditDuration === CONSUMER_US_CREDIT_DURATION_OPTIONS.DONT_KNOW;
	const isUSCreditEligible =
		USACreditDuration === CONSUMER_US_CREDIT_DURATION_OPTIONS.MORE_THAN_12_MOS ||
		USACreditDuration === CONSUMER_US_CREDIT_DURATION_OPTIONS.BTW_6_12_MOS;

	if (!hasSSN && currentlyInUSA) {
		return 'happyWithExistingUSCredit';
	} else if (movingInFuture) {
		return 'movingInFuture';
	} else if (isCardshopEligible && cantRemember) {
		return 'cantRemember';
	} else if (isCardshopEligible && isUSCreditEligible) {
		return 'happyWithExistingUSCredit';
	} else if (isCardshopEligible) {
		return 'happy';
	} else if (isUSCreditEligible) {
		return 'happyWithExistingUSCredit';
	}

	// If we get here, then the user has found a path through the onboarding flow
	// that we have not accounted for with our end states.
	captureException(new Error('Could not resolve end state for consumer'));
	return 'happy';
};

export const getQuestionAfterCountrySelect = country => {
	// only countries that a customer is live in continue in the flow, others get sent to the unsupported country pages
	if (CARDSHOP_LIVE_COUNTRY_CODES.includes(country)) {
		return 'ssn';
	} else {
		return 'unsupportedCountry';
	}
};

export const onboardingQuestions: OnboardingQuestions = {
	start: {
		title: `Let's see if we can use your foreign credit history in the U.S.`,
		subtitle: 'Fill in a few simple questions to identify your eligibility',
		questionType: 'start',
		onboardingAnswerKey: null,
		options: [
			{
				label: 'Get started',
				nextQuestion: ({ surveyResponses }: { surveyResponses: SurveyResponses }) => {
					// track when the user starts the survey flow
					tracker.track('nova.consumer_dashboard.ONBOARDING_SURVEY_STARTED', null);
					const { country } = surveyResponses;
					if (!country) {
						return 'countrySelect';
					}
					// skip country select
					trackCountrySelected({ country, page: 'Onboarding Survey' });
					tracker.track('nova.consumer_dashboard.ONBOARDING_SURVEY_QUESTION_ANSWERED', {
						question: 'country',
						answer: country,
					});
					Intercom.update({
						country,
					});
					return getQuestionAfterCountrySelect(country);
				},
				value: null,
				prevQuestion: '',
			},
		],
	},
	countrySelect: {
		title: 'Where have you had a credit card or loan?',
		subtitle: null,
		questionType: 'countryDropdown',
		onboardingAnswerKey: 'country',
		options: [
			{
				label: 'Country',
				getCountryOptions: () => CARDSHOP_LIVE_COUNTRY_CODES,
				nextQuestion: ({ value: country }) => {
					trackCountrySelected({ country, page: 'Onboarding Survey' });
					return getQuestionAfterCountrySelect(country);
				},
				prevQuestion: '',
			},
		],
	},
	unsupportedCountry: {
		title: `We're working hard to add more countries`,
		subtitle: `We're sorry we couldn't help you. Tell us what country you need to help us better serve you in the future`,
		questionType: 'countryDropdown',
		onboardingAnswerKey: 'unsupportedCountry',
		options: [
			{
				label: 'Country',
				nextQuestion: 'submittedUnsupportedCountry',
				getCountryOptions: () => COUNTRIES.map(country => country.code),
				prevQuestion: '',
			},
		],
	},
	ssn: {
		title: (
			<>
				Do you already have an SSN
				<Tooltip
					position="bottom"
					theme="light"
					distance={0}
					tooltipTrigger={
						<Icon
							className="tooltip-icon"
							name="info-circle"
							variant="light"
							size="xs"
						/>
					}
					content={
						<Typography
							tag="p"
							variant="Caption-Regular"
							color="ColorCoolGray900"
							className="tooltip-text mb-0"
						>
							A Social Security Number is an identification number issued by the
							Social Security Administration to U.S. citizens, permanent residents,
							and temporary residents.
						</Typography>
					}
				/>{' '}
				or ITIN
				<Tooltip
					position="bottom"
					theme="light"
					distance={0}
					tooltipTrigger={
						<Icon
							className="tooltip-icon"
							name="info-circle"
							variant="light"
							size="xs"
						/>
					}
					content={
						<Typography
							tag="p"
							variant="Caption-Regular"
							color="ColorCoolGray900"
							className="tooltip-text mb-0"
						>
							An Individual Taxpayer Identification Number is a identification number
							issued by the Internal Revenue Service to individuals who are required
							to have a U.S. taxpayer identification number but are not eligible to
							obtain a Social Security number.
						</Typography>
					}
				/>
				?
			</>
		),
		subtitle: `Some products require an SSN or ITIN. We won't ask you for the actual number`,
		questionType: 'buttonSelect',
		onboardingAnswerKey: 'hasSSN',
		options: [
			{
				label: 'Yes, I have an SSN or ITIN',
				nextQuestion: () => {
					if (window.sessionStorage.getItem('dnhsn')) {
						window.sessionStorage.removeItem('dnhsn');
					}
					return 'creditHistory';
				},
				prevQuestion: '',
				value: 'true',
			},
			{
				label: `No, I don't have one yet`,
				nextQuestion: () => {
					// obscuring this info about the consumer a little. It stands for Does Not Have SSN
					window.sessionStorage.setItem('dnhsn', 'y');
					return 'arrivedInUs';
				},
				prevQuestion: '',
				value: 'false',
			},
		],
	},
	creditHistory: {
		title: 'Do you already have a credit card or loan in the U.S.?',
		subtitle: `This will help us determine if you already have U.S. credit history. Don't worry, it's not required`,
		questionType: 'buttonSelect',
		onboardingAnswerKey: 'hasUSACreditHistory',
		options: [
			{
				label: 'Yes, I do',
				nextQuestion: 'creditDuration',
				prevQuestion: 'ssn',
				value: 'YES',
			},
			{
				label: `No, I don't`,
				nextQuestion: getEndState,
				prevQuestion: 'ssn',
				value: 'NO',
			},
		],
	},
	creditDuration: {
		title: 'How long have you had your U.S. credit card or loan?',
		subtitle: null,
		questionType: 'buttonSelect',
		onboardingAnswerKey: 'USACreditDuration',
		options: [
			{
				label: 'Less than 6 months',
				nextQuestion: getEndState,
				value: CONSUMER_US_CREDIT_DURATION_OPTIONS.LESS_THAN_6_MOS,
				prevQuestion: 'creditHistory',
			},
			{
				label: 'Between 6 and 12 months',
				nextQuestion: getEndState,
				value: CONSUMER_US_CREDIT_DURATION_OPTIONS.BTW_6_12_MOS,
				prevQuestion: 'creditHistory',
			},
			{
				label: 'More than 12 months',
				nextQuestion: getEndState,
				value: CONSUMER_US_CREDIT_DURATION_OPTIONS.MORE_THAN_12_MOS,
				prevQuestion: 'creditHistory',
			},
			{
				label: `I can't remember`,
				nextQuestion: getEndState,
				value: CONSUMER_US_CREDIT_DURATION_OPTIONS.DONT_KNOW,
				prevQuestion: 'creditHistory',
			},
		],
	},
	arrivedInUs: {
		title: 'Have you already arrived in the U.S.?',
		subtitle:
			'This will help us understand when you can use your foreign credit history to apply for a credit card',
		questionType: 'buttonSelect',
		onboardingAnswerKey: 'currentlyInUSA',
		options: [
			{
				label: `Yes, I'm already here`,
				nextQuestion: getEndState,
				prevQuestion: 'ssn',
				value: 'true',
			},
			{
				label: `No, I haven't arrived yet`,
				nextQuestion: 'plannedArrivalDate',
				prevQuestion: 'ssn',
				value: 'false',
			},
		],
	},
	arrivalDate: {
		title: 'When did you move to the U.S.?',
		subtitle: `This will help us determine if you already have U.S. credit history. Don't worry, it's not required`,
		questionType: 'monthYearPicker',
		onboardingAnswerKey: 'arrivalDate',
		options: [
			{
				label: 'Month/Year',
				nextQuestion: ({ value: date, surveyResponses }) => {
					// send to nova connect if user arrived in U.S. less than six months ago
					const sixMonthsAgo = moment().subtract(6, 'months');
					if (date >= sixMonthsAgo) {
						return getEndState({ surveyResponses });
					} else {
						return 'creditHistory';
					}
				},
				prevQuestion: 'arrivedInUs',
			},
			{
				label: `I can't remember`,
				nextQuestion: 'creditHistory',
				value: 'CANT_REMEMBER',
				prevQuestion: 'arrivedInUs',
			},
		],
	},
	plannedArrivalDate: {
		title: 'When do you plan to move to the U.S.?',
		subtitle: null,
		questionType: 'monthYearPicker',
		onboardingAnswerKey: 'plannedArrivalDate',
		options: [
			{
				label: 'Month/Year',
				nextQuestion: getEndState,
				prevQuestion: 'arrivedInUs',
			},
			{
				label: `I don't plan to move`,
				nextQuestion: 'notMoving',
				prevQuestion: 'arrivedInUs',
				value: 'NOT_MOVING',
			},
		],
	},
};
export default OnboardingQuestionButtons;
