import { useRef, useEffect, useState } from 'react';
import type { RefObject } from 'react';

const DEFAULT_OPTIONS = {
	root: null,
	rootMargin: '0px',
	threshold: 0,
};

export default function useElementOnScreen(
	options: Partial<IntersectionObserverInit> = {},
): [RefObject<HTMLElement>, boolean] {
	const containerRef = useRef<HTMLElement>(null);
	const [isVisible, setIsVisible] = useState(false);

	const callbackFn = ([entry]: IntersectionObserverEntry[]): void => {
		setIsVisible(entry.isIntersecting);
	};

	useEffect(() => {
		const observer = new IntersectionObserver(callbackFn, { ...options, ...DEFAULT_OPTIONS });
		if (containerRef.current) {
			observer.observe(containerRef.current);
		}

		return () => {
			observer.disconnect();
		};
	}, [containerRef, options]);

	return [containerRef, isVisible];
}
