/**
 * Use the `murmurhash` library, which is used by Optimizely's SDK as well
 * It is tree-shaking compatible so only v3 will be imported in this case.
 * This is not a cryptographic hash function. It's optimized for randomness
 * but not difficulty of reversing.
 */
import { v3 as mmhash } from 'murmurhash';

const MAX_HASH_VALUE = 4294967296; // 2^32
const NUM_BUCKETS = 10000;

type BucketedVariantConfig = {
	name: string;
	buckets: [number, number];
};

/**
 * Converts a list of variants with weights like [{ name: 'CONTROL', weight: 50 }]
 * to a list of variants with buckets like [{ name: 'CONTROL', buckets: [0, 4999] }]
 * There are a total of 10000 buckets to be allocated.
 */
export function bucketizeVariants(
	variants: ReadonlyArray<{
		name: string;
		weight: number;
	}>,
): BucketedVariantConfig[] {
	const bucketedVariants: BucketedVariantConfig[] = [];

	let currentBucketNumber = 0;
	variants.forEach(({ name, weight }) => {
		const numBucketsInVariant = Math.floor(NUM_BUCKETS * (weight / 100));
		bucketedVariants.push({
			name,
			buckets: [currentBucketNumber, currentBucketNumber + numBucketsInVariant - 1],
		});
		currentBucketNumber += numBucketsInVariant;
	});

	return bucketedVariants;
}

/**
 * Deterministically allocate a bucketingId to a bucket for a given experiment name
 * More info at https://app.tettra.co/teams/novacredit/pages/bucketing
 */
export function getBucket(bucketingId: string, experimentName: string): number {
	const hash = mmhash(`${bucketingId}${experimentName}`, 1);
	return Math.floor((hash / MAX_HASH_VALUE) * NUM_BUCKETS);
}
