import { useEffect, useState } from 'react';

import api from 'api';

const toQueryString = obj => {
	const filteredObj = Object.entries(obj).reduce((acc, [key, val]) => {
		if (['features', 'rewards'].includes(key) && val !== '') {
			acc[key] = val;
		} else if (val != null) {
			acc[key] = val;
		}

		return acc;
	}, {});

	return new URLSearchParams(filteredObj).toString();
};

const useApi = ({
	body,
	defer = false,
	method = 'GET',
	path,
	query,
	rootPath = '/api',
}: {
	body?: any;
	defer?: boolean;
	method?: string;
	path: string;
	query?: Record<string, any>;
	rootPath?: string;
}) => {
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(!defer);
	const [makeRequest, setMakeRequest] = useState(null);

	const searchParams = query && toQueryString(query);
	const formattedPath = [rootPath, path, ...(searchParams ? ['?', searchParams] : [])]
		.filter(Boolean)
		.join('');

	useEffect(() => {
		setMakeRequest(() => async (opts: any) => {
			if (error) {
				setError(null);
			}

			if (defer && !loading) {
				setLoading(true);
			}

			try {
				const res = await api.request(formattedPath, { body: body || opts?.body, method });
				const json = await res.json();

				if (res.ok) {
					setData(json);
				} else {
					setError(json);
				}

				return json;
			} catch (err) {
				setError(err);

				throw err;
			} finally {
				setLoading(false);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams]);

	useEffect(() => {
		if (makeRequest && !defer) {
			makeRequest();
		}
	}, [defer, makeRequest]);

	return {
		data,
		error,
		loading,
		makeRequest,
	};
};

export default useApi;
