import React, { useContext, useState } from 'react';
import classNames from 'classnames';

import LanguageContext from 'contexts/LanguageContext';
import { LANGUAGE_NAME_MAP } from 'consts';
import MenuDropdown from './MenuDropdown';
import Icon from './Icon';

import './LanguageDropdown.scss';

export const MobileLanguageDropdown = () => {
	const [active, setActive] = useState(false);
	const { language, setLanguage } = useContext(LanguageContext);
	// since we're not showing our international pages on `hello`, we don't need
	// to display the language selector. But it is needed to be able to display
	// the cookie consent in different languages
	const showComponent = false;

	if (!showComponent) {
		return <></>;
	}

	const languageOptions = Object.keys(LANGUAGE_NAME_MAP).map(lang => (
		<li className="uq-submenu-link">
			<button type="button" onClick={() => setLanguage(lang)}>
				{LANGUAGE_NAME_MAP[lang]}
			</button>
		</li>
	));

	return (
		<li className="uq-mobile-menu-item mobile-language-dropdown">
			<button
				type="button"
				className={classNames('uq-mobile-menu-item-trigger', { active })}
				onClick={() => setActive(!active)}
			>
				<Icon
					icon="translate"
					width="20"
					className={classNames('uq-menu-dropdown-left-icon', { active })}
				/>
				<span>{LANGUAGE_NAME_MAP[language]}</span>
				<Icon
					icon="chevron-down"
					width="16"
					className={classNames('uq-menu-dropdown-chevron', { active })}
				/>
			</button>
			<div className={classNames('uq-mobile-menu-item-contents', { active })}>
				<ul>
					<li className="uq-mobile-menu-item-section">
						<ul className="uq-mobile-menu-item-subcontents">{languageOptions}</ul>
					</li>
				</ul>
			</div>
		</li>
	);
};

const LanguageDropdown = () => {
	const { language, setLanguage } = useContext(LanguageContext);
	// since we're not showing our international pages on `hello`, we don't need
	// to display the language selector. But it is needed to be able to display
	// the cookie consent in different languages
	const showComponent = false;

	if (!showComponent) {
		return <></>;
	}

	const languageOptions = [
		{
			links: Object.keys(LANGUAGE_NAME_MAP).map(lang => {
				return {
					label: LANGUAGE_NAME_MAP[lang],
					onClick: () => setLanguage(lang),
				};
			}),
		},
	];

	return (
		<MenuDropdown
			className="language-dropdown"
			title={LANGUAGE_NAME_MAP[language]}
			columns={languageOptions}
			align="right"
			icon="translate"
		/>
	);
};

export default LanguageDropdown;
