import React from 'react';
import { HELP_CENTER_URLS } from '@novacredit/frontend-common/utils/url';

import Page from 'components/common/Page';
import PageTitle from 'components/common/PageTitle';

/**
 * This page is a temporary placeholder in case any old links end up here.
 * All links should go to the help center directly.
 */
const Disputes = () => {
	return (
		<Page>
			<PageTitle title="Dispute Resolution" />
			<h1>Dispute Resolution</h1>
			<p>
				Visit our <a href={HELP_CENTER_URLS.DISPUTES}>help center</a>.
			</p>
		</Page>
	);
};

export default Disputes;
