import type { Pages } from '../types/common';

/**
 * Returns the Page info that matches the passed in pathname.
 */
export const matchingPage = (rawPathname: string, pages: Pages) => {
	const pathname = rawPathname.replace(/\/$/, '');

	return pages.find(p => {
		const { method, pathname: pagePath } = p;
		if (method === 'include') {
			return pathname.includes(pagePath);
		}
		if (method === 'endsWith') {
			return pathname.indexOf(pagePath, pathname.length - pagePath.length) !== -1;
		}
		return pathname === pagePath;
	});
};

export const getPageName = (rawPathname: string, pages: Pages): string => {
	const page = matchingPage(rawPathname, pages);
	return page?.pageName || '';
};
