import React from 'react';

import './HexagonalButton.scss';

// eslint-disable-next-line react/prop-types
const HexagonalButton = ({ children, className, href, newTab, onClick = false }) => {
	const buttonInner = (
		<>
			<svg
				width="24"
				height="48"
				viewBox="0 0 24 48"
				fill="transparent"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M2.16506 26.75C1.18258 25.0483 1.18258 22.9517 2.16506 21.25L12.5574 3.25C13.5398 1.54829 15.3555 0.500001 17.3205 0.500001L38.1051 0.5C40.0701 0.5 41.8858 1.54829 42.8683 3.25L53.2606 21.25C54.243 22.9517 54.243 25.0483 53.2606 26.75L42.8683 44.75C41.8858 46.4517 40.0701 47.5 38.1051 47.5H17.3205C15.3555 47.5 13.5398 46.4517 12.5574 44.75L2.16506 26.75Z"
					stroke="currentColor"
					vectorEffect="non-scaling-stroke"
					fill="inherit"
				/>
			</svg>
			<span className="uq-hexagon-button-label">{children}</span>
			<svg
				width="24"
				height="49"
				viewBox="0 0 24 49"
				fill="transparent"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M-29.708 26.75C-30.6905 25.0483 -30.6905 22.9517 -29.708 21.25L-19.3157 3.25C-18.3332 1.54829 -16.5175 0.500001 -14.5525 0.500001L6.23207 0.5C8.19703 0.5 10.0127 1.54829 10.9952 3.25L21.3875 21.25C22.37 22.9517 22.37 25.0483 21.3875 26.75L10.9952 44.75C10.0127 46.4517 8.19703 47.5 6.23207 47.5H-14.5525C-16.5175 47.5 -18.3332 46.4517 -19.3157 44.75L-29.708 26.75Z"
					stroke="currentColor"
					vectorEffect="non-scaling-stroke"
					fill="inherit"
				/>
			</svg>
		</>
	);

	const wrapperClassName = `uq-hexagonal-button ${className}`;

	if (onClick) {
		return (
			<button type="button" onClick={onClick} className={wrapperClassName}>
				{buttonInner}
			</button>
		);
	}

	return (
		// eslint-disable-next-line react/jsx-no-target-blank
		<a
			href={href}
			target={newTab ? '_blank' : null}
			rel={newTab ? 'noreferrer noopener' : null}
			className={wrapperClassName}
		>
			{buttonInner}
		</a>
	);
};

export default HexagonalButton;
