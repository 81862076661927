import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import type { CountryCode } from '@novacredit/frontend-common/utils/countries';
import { getQueryParams } from '@novacredit/frontend-common/utils/location';
import { useMount } from '@novacredit/frontend-common/hooks';
import classnames from 'classnames';

import { tracker } from 'modules/tracking';
import type { Card } from 'types';

import { CardList } from 'components/common/CardList';
import CardShopFilters from 'components/common/CardShopFilters';
import PageHeader from 'components/common/PageHeader';
import { Row, Col, Container } from 'components/common/Layout';
import Loader from 'components/common/Loader';
import RefereeModal from 'components/common/RefereeModal';
import Page from 'components/common/Page';
import CardShopContext, { ACTIONS } from 'contexts/CardShopContext';
import useFiltersWithQueryParams from 'hooks/useFiltersWithQueryParams';

import cardBannerImg from 'assets/images/yellow-card-banner.jpg';

import './CardShop.scss';

const CardShop = ({
	country,
	onReset,
	filteredCards,
	loading,
	error,
	paginate,
	fsMask,
}: {
	country?: CountryCode;
	onReset: () => void;
	filteredCards: Card[];
	loading: boolean;
	error: boolean;
	paginate: boolean;
	fsMask: boolean;
}) => {
	// Context
	const cardshopContext = useContext(CardShopContext);
	// Query Params
	const location = useLocation();
	// @ts-expect-error
	const { referrer } = getQueryParams(location);
	// State
	const [showReferralModal, setShowReferralModal] = useState(!!referrer);

	useFiltersWithQueryParams();

	useMount(() => {
		tracker.track('nova.client.CARDSHOP_VISITED', null);
	});

	if (error) {
		return <p>Error fetching cards. Try again later.</p>;
	}

	if (loading) {
		return <Loader />;
	}

	const cardListWidth = 12;

	const onRefereeModalSubmit = () => {
		cardshopContext.dispatch({
			type: ACTIONS.UPDATE_FEATURES,
			payload: { filters: 'nova_enabled' },
		});
		setShowReferralModal(false);
	};
	const numCards = filteredCards.length;
	return (
		<Page className="cardshop pb-0" fullscreen fsMask={fsMask}>
			<main>
				{showReferralModal && referrer && (
					<RefereeModal
						referrer={referrer}
						onSubmit={onRefereeModalSubmit}
						onDismiss={() => setShowReferralModal(false)}
					/>
				)}
				<PageHeader
					trackingPage="Cardshop"
					country={country}
					className="cardshop-header"
					breadcrumb="Credit Cards"
					pageTitle="Credit cards"
					bannerImg={cardBannerImg}
					headerCopy="These credit cards from our partners are options for newcomers to the United States. Nova Credit helps newcomers use their foreign credit history to apply for products in the U.S.&nbsp;"
					showSwoosh="Select your country to see available cards"
				/>
				<div
					className={classnames('pb-5 cardshop-body', {
						'cardshop-blur': !country,
					})}
				>
					<Container>
						{/* Filters */}
						{country && (
							<Row>
								<Col size={{ lg: cardListWidth }}>
									<CardShopFilters cardshopContext={cardshopContext} />
								</Col>
							</Row>
						)}
						<Row>
							<Col size={{ lg: cardListWidth }}>
								<h1 className="card-number-text">Showing {numCards} cards...</h1>
								<hr className="horizonal-grey" />
							</Col>
						</Row>
						<Row>
							<Col size={{ lg: cardListWidth }}>
								<CardList
									onReset={onReset}
									cards={filteredCards}
									paginate={paginate}
								/>
							</Col>
						</Row>
					</Container>
				</div>
			</main>
		</Page>
	);
};

export default CardShop;
