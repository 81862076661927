import React from 'react';
import qs from 'qs';
import { isWestlakeCountry } from '@novacredit/frontend-common/utils/countries';
import { DesignSystemButton, ThumbnailCard } from '@novacredit/pandorasbox';

import { APP_PATHS } from 'modules/paths';
import { getCmsURL, getFriendlyUSACreditDuration } from 'utils';

import carsInRowImg from 'assets/images/cars-in-row.jpg';
import creditCardImg from 'assets/images/yellow-card.jpg';
import phoneOnDeskImg from 'assets/images/phone-on-desk.jpg';
import studentsTossingCapsImg from 'assets/images/students-tossing-caps.jpg';
import libertyNightImg from 'assets/images/liberty_night.jpg';

import type { SurveyResponses } from 'components/common/Onboarding/OnboardingQuestions';

export type EndStateName =
	| 'submittedUnsupportedCountry'
	| 'happy'
	| 'happyWithExistingUSCredit'
	| 'cantRemember'
	| 'movingInFuture'
	| 'notMoving';

type EndStates = {
	[endStateName in EndStateName]: EndState;
};

type EndState = {
	title: string;
	subtitle?: string;
	ctas: ((input?: { surveyResponses: SurveyResponses }) => JSX.Element)[];
};

type EndStateCTAProps = {
	surveyResponses: SurveyResponses;
	endState: EndState;
};

const getCardShopURL = ({ country, usaCreditDuration, hasSSN }) => {
	const urlBase = APP_PATHS.CARDSHOP;
	// Always let the CMS card shop know which country the user chose in the survey
	const params = { country, bracket: null, choice_a: hasSSN !== undefined ? hasSSN : null };

	// If the user specified a duration which they have had credit history in the usa,
	// add an additional query param to the url
	if (usaCreditDuration) {
		// Map the users answer to a PII-friendly version
		const friendlyUSACreditDuration = getFriendlyUSACreditDuration(usaCreditDuration);
		// `bracket` is the query parameter the CMS card shop will look for to determine which cards
		// to show based on the users usa credit duration
		params['bracket'] = friendlyUSACreditDuration;
	}

	const query = qs.stringify(params, { addQueryPrefix: true, skipNulls: true });

	return `${urlBase}${query}`;
};

const CardShopEndState = ({ surveyResponses }: { surveyResponses: SurveyResponses }) => (
	<ThumbnailCard
		key="card-shop"
		className="marketplace-product-card"
		href={getCardShopURL({
			country: surveyResponses.country,
			usaCreditDuration: surveyResponses.USACreditDuration,
			hasSSN: surveyResponses.hasSSN,
		})}
		imageSrc={creditCardImg}
		title="Explore Credit Cards"
		bodyText="Browse credit cards available to you."
		linkText="View card options"
	/>
);

const AutoLoanEndState = ({ surveyResponses }: { surveyResponses: SurveyResponses }) => {
	return isWestlakeCountry(surveyResponses.country) ? (
		<ThumbnailCard
			key="auto-loan"
			className="marketplace-product-card"
			href={getCmsURL('/partner/westlake-financial')}
			imageSrc={carsInRowImg}
			title="Auto Financing"
			bodyText="Find the right car for your new life in the U.S."
			linkText="View financing options"
		/>
	) : null;
};

const PhoneProviderEndState = () => (
	<ThumbnailCard
		key="phone"
		className="marketplace-product-card"
		title="Phone Providers"
		bodyText="Find great deals on phones and plans with our partners"
		linkText="View phone providers"
		href={getCmsURL('/partner/verizon')}
		imageSrc={phoneOnDeskImg}
	/>
);

const StudentLoanEndState = () => (
	<ThumbnailCard
		key="student-loan"
		className="marketplace-product-card"
		title="Student Loans"
		bodyText="Apply for a new student loan or refinance an existing one"
		linkText="View loan options"
		href={getCmsURL('/partner/mpower')}
		imageSrc={studentsTossingCapsImg}
	/>
);

const ImmigrationGuide = () => (
	<ThumbnailCard
		key="immigration-guide"
		className="marketplace-product-card"
		title="Immigration Guide"
		bodyText="Learn everything you need to know, including how to get an SSN or ITIN"
		linkText="View interactive guide"
		href="/guide#arrive"
		imageSrc={libertyNightImg}
	/>
);

const NewcomerGuidesEndState = () => (
	<DesignSystemButton
		key="newcomer-guides"
		className="onboarding-survey-button button"
		type="submit"
		href={getCmsURL('/resources')}
		tag="a"
	>
		See newcomer guides
	</DesignSystemButton>
);

export const endStates: EndStates = {
	submittedUnsupportedCountry: {
		title: `Thanks for your input!`,
		subtitle: `We'll take it into consideration when we add more countries in the future.`,
		ctas: [NewcomerGuidesEndState],
	},
	happy: {
		title: 'Great news! You should be eligible to use your foreign credit history',
		ctas: [CardShopEndState, AutoLoanEndState, PhoneProviderEndState],
	},
	happyWithExistingUSCredit: {
		title: 'Great news! You should be eligible to use your foreign credit history',
		ctas: [PhoneProviderEndState, AutoLoanEndState, ImmigrationGuide],
	},
	cantRemember: {
		title: 'You may be able to use your foreign credit history',
		subtitle:
			'Based on your answers, you may have enough credit history in the U.S. for some lenders to use your U.S. score for a credit card application, but others will still allow you to use your foreign credit history',
		ctas: [CardShopEndState, AutoLoanEndState, PhoneProviderEndState],
	},
	movingInFuture: {
		title: 'Great news! You should be eligible to use your foreign credit history',
		subtitle: `And you'll be eligible for more products once you arrive`,
		ctas: [StudentLoanEndState, ImmigrationGuide],
	},
	notMoving: {
		title: `Unfortunately, we can't help you`,
		subtitle: `U.S. financial services are only available to people who live in the United States. If you change your mind and plan to move, we hope you'll let us know!`,
		ctas: [],
	},
};

const EndStateCTA = ({ surveyResponses, endState }: EndStateCTAProps) => {
	const finalEndStates = endState.ctas.map(onboardingEndState =>
		onboardingEndState({ surveyResponses }),
	);
	return (
		<div className="marketplace-products-container">
			<div className="marketplace-products-row mt-lg-5 m-4">{finalEndStates}</div>
		</div>
	);
};

export default EndStateCTA;
