import React from 'react';
import FlagImg from '@novacredit/frontend-common/ui/Flag/FlagImg';
import { CARDSHOP_LIVE_COUNTRIES } from '@novacredit/frontend-common/utils/countries';
import type { CardshopCountry } from '@novacredit/frontend-common/utils/countries';

import './CountryFlagList.scss';

type Props = {
	countries?: readonly CardshopCountry[];
};

const CountryFlagList = ({ countries = CARDSHOP_LIVE_COUNTRIES }: Props) => (
	<>
		{countries.map(country => (
			<span className="country-flag-container" key={country.code}>
				<FlagImg width={20} country={country.code} alignMiddle /> {country.name}
			</span>
		))}
	</>
);

export default CountryFlagList;
