import classnames from 'classnames';
import React from 'react';

/**
 * This file should include all icons that represent user actions.
 */
// This is the larger X icon

const Close = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  className: classnames('ds-icon-close', className),
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...rest
}, title, React.createElement("g", {
  clipPath: "url(#clip0_120_37)"
}, React.createElement("path", {
  d: "M0.75 23.249L23.25 0.748993",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M23.25 23.249L0.75 0.748993",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
})), React.createElement("defs", null, React.createElement("clipPath", {
  id: "clip0_120_37"
}, React.createElement("rect", {
  width: "24",
  height: "24",
  fill: "white"
}))));
const Copy = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  className: classnames('ds-icon-copy', className),
  viewBox: "0 0 25 25",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...rest
}, title, React.createElement("path", {
  d: "M6.99999 23.2857L21.5714 23.2857C22.5182 23.2857 23.2857 22.5182 23.2857 21.5714L23.2857 7C23.2857 6.05322 22.5182 5.28571 21.5714 5.28571L6.99999 5.28571C6.05322 5.28571 5.28571 6.05322 5.28571 7L5.28571 21.5714C5.28571 22.5182 6.05322 23.2857 6.99999 23.2857Z",
  stroke: "currentColor",
  strokeWidth: "1.6",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M1 18.1429V2.71429C1 2.25963 1.18061 1.82359 1.5021 1.5021C1.82359 1.18061 2.25963 1 2.71429 1H18.1429",
  stroke: "currentColor",
  strokeWidth: "1.6",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const Download = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  className: classnames('ds-icon-download', className),
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...rest
}, title, React.createElement("g", {
  clipPath: "url(#clip0_6157_8297)"
}, React.createElement("path", {
  d: "M0.857147 18V19.7143C0.857147 20.6236 1.21837 21.4957 1.86135 22.1387C2.50433 22.7816 3.3764 23.1429 4.28572 23.1429H19.7143C20.6236 23.1429 21.4957 22.7816 22.1387 22.1387C22.7816 21.4957 23.1429 20.6236 23.1429 19.7143V18",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M6.85715 10.2857L12 16.2857L17.1429 10.2857",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M12 16.2857V0.857147",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
})), React.createElement("defs", null, React.createElement("clipPath", {
  id: "clip0_6157_8297"
}, React.createElement("rect", {
  width: "24",
  height: "24",
  fill: "white"
}))));
const Expand = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  className: classnames('ds-icon-expand', className),
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...rest
}, title, React.createElement("g", {
  clipPath: "url(#clip0_5685_7577)"
}, React.createElement("path", {
  d: "M23.1429 13.7143V21.4286C23.1429 21.8832 22.9622 22.3193 22.6408 22.6408C22.3193 22.9622 21.8832 23.1429 21.4286 23.1429H2.57143C2.11678 23.1429 1.68074 22.9622 1.35925 22.6408C1.03776 22.3193 0.857147 21.8832 0.857147 21.4286V2.57143C0.857147 2.11678 1.03776 1.68074 1.35925 1.35925C1.68074 1.03776 2.11678 0.857147 2.57143 0.857147H10.2857",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M17.1429 0.857147H23.1429V6.85715",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M23.1429 0.857147L12 12",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
})), React.createElement("defs", null, React.createElement("clipPath", {
  id: "clip0_5685_7577"
}, React.createElement("rect", {
  width: "24",
  height: "24",
  fill: "white"
}))));
const Filter = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  className: classnames('ds-icon-filter', className),
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...rest
}, title, React.createElement("g", {
  clipPath: "url(#clip0_5713_7574)"
}, React.createElement("path", {
  d: "M23.1429 0.857147H0.857147C0.858094 3.36611 1.70446 5.80156 3.25963 7.77041C4.8148 9.73925 6.98804 11.1266 9.42858 11.7086V23.1429L14.5714 19.7143V11.7086C17.012 11.1266 19.1852 9.73925 20.7404 7.77041C22.2956 5.80156 23.1419 3.36611 23.1429 0.857147Z",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
})), React.createElement("defs", null, React.createElement("clipPath", {
  id: "clip0_5713_7574"
}, React.createElement("rect", {
  width: "24",
  height: "24",
  fill: "white"
})))); // This is the smaller X icon

const Remove = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  className: classnames('ds-icon-remove', className),
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...rest
}, title, React.createElement("path", {
  d: "M4.5 19.5L19.5 4.5",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M4.5 4.5L19.5 19.5",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));

/**
 * This file should include all arrow and caret icons.
 */

const ArrowDown = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  className: classnames('ds-icon-arrow-down', className),
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...rest
}, title, React.createElement("path", {
  d: "M23.25 7.311L12.53 18.03C12.4604 18.0997 12.3778 18.1549 12.2869 18.1926C12.1959 18.2304 12.0984 18.2498 12 18.2498C11.9016 18.2498 11.8041 18.2304 11.7131 18.1926C11.6222 18.1549 11.5396 18.0997 11.47 18.03L0.75 7.311",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ArrowLeft = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  className: classnames('ds-icon-arrow-left', className),
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...rest
}, title, React.createElement("path", {
  d: "M16.25 23.25L5.53 12.53C5.46033 12.4604 5.40507 12.3778 5.36736 12.2869C5.32965 12.1959 5.31024 12.0984 5.31024 12C5.31024 11.9016 5.32965 11.8041 5.36736 11.7131C5.40507 11.6222 5.46033 11.5396 5.53 11.47L16.25 0.75",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ArrowRight = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  className: classnames('ds-icon-arrow-right', className),
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...rest
}, title, React.createElement("path", {
  d: "M5.5 0.75L16.22 11.47C16.2897 11.5396 16.3449 11.6222 16.3826 11.7131C16.4203 11.8041 16.4398 11.9016 16.4398 12C16.4398 12.0984 16.4203 12.1959 16.3826 12.2869C16.3449 12.3778 16.2897 12.4604 16.22 12.53L5.5 23.25",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ArrowUp = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  className: classnames('ds-icon-arrow-up', className),
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...rest
}, title, React.createElement("path", {
  d: "M0.75 17.189L11.47 6.47C11.5396 6.40033 11.6222 6.34507 11.7131 6.30736C11.8041 6.26965 11.9016 6.25024 12 6.25024C12.0984 6.25024 12.1959 6.26965 12.2869 6.30736C12.3778 6.34507 12.4604 6.40033 12.53 6.47L23.25 17.189",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const DropdownArrow = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  className: classnames('ds-icon-dropdown-arrow', className),
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "currentColor",
  role: "none",
  ...rest
}, title, React.createElement("path", {
  stroke: "currentColor",
  d: "M10.854 14.978a1.092 1.092 0 0 0 1.647 0l3.98-5.339a.545.545 0 0 0-.438-.87H7.315a.545.545 0 0 0-.438.87l3.977 5.34Z"
}));
const ReturnArrow = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  className: classnames('ds-icon-return-arrow', className),
  viewBox: "0 0 24 24",
  ...rest
}, title, React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1.5",
  d: "m5.25 21.747-4.5-4.5 4.5-4.5"
}), React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1.5",
  d: "M.75 17.247h15a7.5 7.5 0 0 0 0-15h-6"
}));
const ArrowRightLong = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  className: classnames('ds-icon-arrow-right-long', className),
  ...rest
}, React.createElement("path", {
  d: "M23.25 12.497H0.75",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M19.5 16.247L23.25 12.497L19.5 8.74701",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));

/**
 * This file should include all icons in a circle
 */

const AddCircle = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg",
  className: classnames('ds-icon-information-circle', className),
  ...rest,
  fill: "none"
}, title, React.createElement("g", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1.5",
  clipPath: "url(#a)"
}, React.createElement("path", {
  d: "M12 7.5v9M7.5 12h9M.75 12a11.25 11.25 0 1 0 22.5 0 11.25 11.25 0 0 0-22.5 0v0Z"
})), React.createElement("defs", null, React.createElement("clipPath", {
  id: "a"
}, React.createElement("path", {
  fill: "#fff",
  d: "M0 0h24v24H0z"
}))));
const InformationCircle = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg",
  className: classnames('ds-icon-information-circle', className),
  ...rest,
  fill: "none"
}, title, React.createElement("path", {
  d: "M14.25 16.5h-.75A1.5 1.5 0 0112 15v-3.75a.75.75 0 00-.75-.75h-.75",
  stroke: "currentColor"
}), React.createElement("path", {
  d: "M11.625 6.75a.375.375 0 100 .75.375.375 0 000-.75z",
  stroke: "currentColor"
}), React.createElement("path", {
  d: "M.75 12a11.25 11.25 0 1022.5 0 11.25 11.25 0 00-22.5 0v0z",
  stroke: "currentColor"
}));
const RemoveCircle = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg",
  className: classnames('ds-icon-remove-circle', className),
  ...rest,
  fill: "none"
}, title, React.createElement("g", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1.5",
  clipPath: "url(#a)"
}, React.createElement("path", {
  d: "M.75 11.999a11.25 11.25 0 1 0 22.5 0 11.25 11.25 0 0 0-22.5 0v0ZM7.5 16.499l9-9M16.5 16.499l-9-9"
})), React.createElement("defs", null, React.createElement("clipPath", {
  id: "a"
}, React.createElement("path", {
  fill: "#fff",
  d: "M0 0h24v24H0z"
}))));
const CheckCircle = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  className: classnames('ds-icon-check-circle', className),
  ...rest
}, React.createElement("title", {
  id: "icon-check-circle-title"
}, title), React.createElement("g", {
  clipPath: "url(#clip0_8_54)"
}, React.createElement("path", {
  d: "M6 13.223L8.45 16.7C8.54293 16.8388 8.66768 16.9534 8.81385 17.0343C8.96002 17.1152 9.12339 17.16 9.29037 17.165C9.45735 17.17 9.6231 17.135 9.77384 17.063C9.92458 16.991 10.0559 16.884 10.157 16.751L18 6.828",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M0.75 11.999C0.75 14.9827 1.93526 17.8442 4.04505 19.9539C6.15483 22.0637 9.01631 23.249 12 23.249C14.9837 23.249 17.8452 22.0637 19.955 19.9539C22.0647 17.8442 23.25 14.9827 23.25 11.999C23.25 9.01531 22.0647 6.15383 19.955 4.04404C17.8452 1.93426 14.9837 0.748993 12 0.748993C9.01631 0.748993 6.15483 1.93426 4.04505 4.04404C1.93526 6.15383 0.75 9.01531 0.75 11.999V11.999Z",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
})), React.createElement("defs", null, React.createElement("clipPath", {
  id: "clip0_8_54"
}, React.createElement("rect", {
  width: "24",
  height: "24",
  fill: "white"
}))));
const CheckCircleSolid = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg",
  className: classnames('ds-icon-check-circle', className),
  ...rest,
  fill: "none"
}, React.createElement("title", {
  id: "icon-check-circle-title"
}, title), React.createElement("g", {
  clipPath: "url(#a)"
}, React.createElement("path", {
  d: "M12 0a12 12 0 1 0 12 12A12.014 12.014 0 0 0 12 0Zm6.171 10.114-6.816 6.813a1.715 1.715 0 0 1-2.425 0L5.829 13.83a1.717 1.717 0 0 1 2.43-2.424l1.886 1.886 5.596-5.601a1.716 1.716 0 1 1 2.43 2.422Z",
  fill: "currentColor"
})), React.createElement("defs", null, React.createElement("clipPath", {
  id: "a"
}, React.createElement("path", {
  fill: "#fff",
  d: "M0 0h24v24H0z"
}))));
const InformationCircleSolid = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "none",
  className: classnames('ds-icon-info-circle', className),
  ...rest
}, title, React.createElement("g", {
  clipPath: "url(#a)"
}, React.createElement("path", {
  fill: "currentColor",
  d: "M12 0a12 12 0 1 0 12 12A12.013 12.013 0 0 0 12 0Zm.25 5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm2.25 13.5h-4a1 1 0 0 1 0-2h.75a.25.25 0 0 0 .25-.25v-4.5a.25.25 0 0 0-.25-.25h-.75a1 1 0 0 1 0-2h1a2 2 0 0 1 2 2v4.75a.25.25 0 0 0 .25.25h.75a1 1 0 0 1 0 2Z"
})), React.createElement("defs", null, React.createElement("clipPath", {
  id: "a"
}, React.createElement("path", {
  fill: "#fff",
  d: "M0 0h24v24H0z"
}))));
const QuestionCircleSolid = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  className: classnames('ds-icon-question-circle', className),
  ...rest
}, title, React.createElement("g", {
  fill: "currentColor"
}, React.createElement("path", {
  d: "M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm1.063 15.106h-1.987v-1.71h1.987v1.71zm.023-3.95v.97h-2.01v-2.843h.994c.832 0 1.501-.67 1.501-1.501 0-.832-.67-1.502-1.501-1.502-.833 0-1.502.67-1.502 1.502h-2.01A3.51 3.51 0 0112.07 6.27a3.51 3.51 0 013.512 3.51 3.507 3.507 0 01-2.496 3.374z"
})));

const Checkmark = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  className: classnames('ds-icon-checkmark', className),
  ...rest
}, title, React.createElement("path", {
  d: "M5.91045 12.5597L10.0896 17L20.0896 7",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
/**
 * This file should include all icons that represent symbols.
 */

const Email = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  className: classnames('ds-icon-email', className),
  ...rest
}, title, React.createElement("path", {
  d: "M22.161 5.3L14.017 11.564C13.4387 12.0089 12.7296 12.2501 12 12.2501C11.2704 12.2501 10.5613 12.0089 9.983 11.564L1.839 5.3M1.5 4.75H22.5V19.75H1.5V4.75Z",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const Files = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  className: classnames('ds-icon-files', className),
  fill: "currentColor",
  viewBox: "0 0 16 16",
  ...rest
}, title, React.createElement("g", null, React.createElement("path", {
  d: "m13,0l-7,0a2,2 0 0 0 -2,2a2,2 0 0 0 -2,2l0,10a2,2 0 0 0 2,2l7,0a2,2 0 0 0 2,-2a2,2 0 0 0 2,-2l0,-10a2,2 0 0 0 -2,-2zm0,13l0,-9a2,2 0 0 0 -2,-2l-6,0a1,1 0 0 1 1,-1l7,0a1,1 0 0 1 1,1l0,10a1,1 0 0 1 -1,1zm-10,-9a1,1 0 0 1 1,-1l7,0a1,1 0 0 1 1,1l0,10a1,1 0 0 1 -1,1l-7,0a1,1 0 0 1 -1,-1l0,-10z"
}), React.createElement("path", {
  d: "m4.50002,5.18752l5.87497,0",
  stroke: "currentColor"
}), React.createElement("path", {
  d: "m4.50002,8l5.87497,0",
  stroke: "currentColor"
}), React.createElement("path", {
  d: "m4.43752,10.93748l3.43748,0",
  stroke: "currentColor"
})));
const Flash = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24",
  className: classnames('ds-icon-flash', className),
  ...rest
}, title, React.createElement("path", {
  stroke: "currentColor",
  d: "M17.247 8.241 8.55 20.415l1.739-7.826H3.334L12.029.415 10.29 8.241h6.957Z"
}));
const FolderCash = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24",
  className: classnames('ds-icon-folder-cash', className),
  ...rest
}, title, React.createElement("g", {
  fill: "currentColor",
  clipPath: "url(#a)"
}, React.createElement("path", {
  d: "m13.385 13.307-2.427-.971a.578.578 0 0 1 .214-1.115h2.392a1 1 0 0 0 0-2h-.637a.127.127 0 0 1-.127-.127V8.75a1 1 0 0 0-2 0v.428a.107.107 0 0 1-.071.1 2.568 2.568 0 0 0-.514 4.918l2.427.971a.578.578 0 0 1-.214 1.115h-2.393a1 1 0 0 0 0 2h.641a.124.124 0 0 1 .124.124v.347a1 1 0 0 0 2 0v-.424a.112.112 0 0 1 .079-.1 2.567 2.567 0 0 0 .506-4.916v-.006Z"
}), React.createElement("path", {
  d: "M24 6.3a1.95 1.95 0 0 0-1.95-1.95H12a.5.5 0 0 1-.447-.277l-.747-1.494A1.95 1.95 0 0 0 9.061 1.5H1.95A1.95 1.95 0 0 0 0 3.45v17.1a1.95 1.95 0 0 0 1.95 1.95h20.1A1.95 1.95 0 0 0 24 20.55V6.3ZM22.048 20a.5.5 0 0 1-.5.5L2.5 20.549a.5.5 0 0 1-.5-.5L1.952 4a.5.5 0 0 1 .5-.5l6.256-.025a.5.5 0 0 1 .449.277l.761 1.523A1.941 1.941 0 0 0 11.66 6.35l9.84-.05a.5.5 0 0 1 .5.5l.048 13.2Z"
})), React.createElement("defs", null, React.createElement("clipPath", {
  id: "a"
}, React.createElement("path", {
  fill: "#fff",
  d: "M0 0h24v24H0z"
}))));
const IdCard = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24",
  className: classnames('ds-icon-id-card', className),
  ...rest
}, title, React.createElement("g", {
  fill: "currentColor",
  clipPath: "url(#a)"
}, React.createElement("path", {
  d: "M24 4.5a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-.75a.752.752 0 0 1 .75-.75.761.761 0 0 1 .75.75v.75a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1v-.75a.752.752 0 0 1 .75-.75.761.761 0 0 1 .75.75v.75a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-15ZM22 19a.5.5 0 0 1-.5.5h-1.292a.251.251 0 0 1-.247-.208 2.75 2.75 0 0 0-5.422 0 .25.25 0 0 1-.247.209H9.708a.251.251 0 0 1-.247-.208 2.749 2.749 0 0 0-5.422 0 .25.25 0 0 1-.247.209H2.5A.5.5 0 0 1 2 19V5a.5.5 0 0 1 .5-.5h19a.5.5 0 0 1 .5.5v14Z"
}), React.createElement("path", {
  d: "M15 12.25h4.782a.75.75 0 1 0 0-1.5H15a.75.75 0 1 0 0 1.5ZM14.25 14.5a.75.75 0 0 0 .75.75h2.608a.75.75 0 1 0 0-1.5H15a.75.75 0 0 0-.75.75ZM15 9.25h5a.75.75 0 1 0 0-1.5h-5a.75.75 0 1 0 0 1.5ZM4 15.768h8a.502.502 0 0 0 .492-.586c-.067-.4-.19-.79-.368-1.155-.348-.7-1.2-1.129-2.667-1.657a.894.894 0 0 1-.026-.391 4.033 4.033 0 0 0 .869-2.766 2.3 2.3 0 1 0-4.59 0c-.067.98.23 1.949.833 2.723.043.089.053.355.076.382-1.543.58-2.39 1-2.738 1.709a4.247 4.247 0 0 0-.368 1.155.5.5 0 0 0 .487.586Z"
})), React.createElement("defs", null, React.createElement("clipPath", {
  id: "a"
}, React.createElement("path", {
  fill: "#fff",
  d: "M0 0h24v24H0z"
}))));
const SinglePerson = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "none",
  className: classnames('ds-icon-single-person', className),
  ...rest
}, React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1.5",
  d: "M6.75 6a5.25 5.25 0 1 0 10.5 0 5.25 5.25 0 0 0-10.5 0ZM2.25 23.25a9.75 9.75 0 1 1 19.5 0"
}));
const Money = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "none",
  className: classnames('ds-icon-single-person', className),
  ...rest
}, title, React.createElement("g", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1.5",
  clipPath: "url(#a)"
}, React.createElement("path", {
  d: "M8.25 8.25a7.5 7.5 0 1 0 15 0 7.5 7.5 0 0 0-15 0ZM6.375 14.092a1.341 1.341 0 0 0 .843 1.245l2.064.826a1.342 1.342 0 0 1-.5 2.587H6.75M8.25 20.25v-1.5"
}), React.createElement("path", {
  d: "M5.281 8.867a7.5 7.5 0 1 0 9.853 9.852M17.25 5.25h-2.033a1.342 1.342 0 0 0-.5 2.587l2.064.826a1.342 1.342 0 0 1-.5 2.587H14.25M15.75 5.25v-1.5M15.75 12.75v-1.5"
})), React.createElement("defs", null, React.createElement("clipPath", {
  id: "a"
}, React.createElement("path", {
  fill: "#fff",
  d: "M0 0h24v24H0z"
}))));
const PhoneCall = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  className: classnames('ds-icon-single-person', className),
  ...rest
}, title, React.createElement("g", {
  clipPath: "url(#clip0_1848_2655)"
}, React.createElement("path", {
  d: "M15.25 8.09258V4.39258C15.25 3.59693 14.9339 2.83387 14.3713 2.27126C13.8087 1.70865 13.0456 1.39258 12.25 1.39258H4C3.20435 1.39258 2.44129 1.70865 1.87868 2.27126C1.31607 2.83387 1 3.59693 1 4.39258V8.89258C1 9.68823 1.31607 10.4513 1.87868 11.0139C2.44129 11.5765 3.20435 11.8925 4 11.8925V15.6425L7.75 11.8925H10.343",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M4.375 7.01367C4.16789 7.01367 4 6.84577 4 6.63867C4 6.43156 4.16789 6.26367 4.375 6.26367",
  stroke: "currentColor",
  strokeWidth: "1.5"
}), React.createElement("path", {
  d: "M4.375 7.01367C4.58211 7.01367 4.75 6.84577 4.75 6.63867C4.75 6.43156 4.58211 6.26367 4.375 6.26367",
  stroke: "currentColor",
  strokeWidth: "1.5"
}), React.createElement("path", {
  d: "M8.125 7.01367C7.91789 7.01367 7.75 6.84577 7.75 6.63867C7.75 6.43156 7.91789 6.26367 8.125 6.26367",
  stroke: "currentColor",
  strokeWidth: "1.5"
}), React.createElement("path", {
  d: "M8.125 7.01367C8.33211 7.01367 8.5 6.84577 8.5 6.63867C8.5 6.43156 8.33211 6.26367 8.125 6.26367",
  stroke: "currentColor",
  strokeWidth: "1.5"
}), React.createElement("path", {
  d: "M11.875 7.01367C11.6679 7.01367 11.5 6.84577 11.5 6.63867C11.5 6.43156 11.6679 6.26367 11.875 6.26367",
  stroke: "currentColor",
  strokeWidth: "1.5"
}), React.createElement("path", {
  d: "M11.875 7.01367C12.0821 7.01367 12.25 6.84577 12.25 6.63867C12.25 6.43156 12.0821 6.26367 11.875 6.26367",
  stroke: "currentColor",
  strokeWidth: "1.5"
}), React.createElement("path", {
  d: "M12.683 20.5476H12.27C11.8327 20.5476 11.4132 20.374 11.1036 20.0651C10.7941 19.7561 10.6198 19.3369 10.619 18.8996V17.2456C10.6203 16.8086 10.7949 16.3899 11.1043 16.0814C11.4138 15.7728 11.833 15.5996 12.27 15.5996H12.683C12.7924 15.5998 12.8972 15.6435 12.9744 15.7209C13.0516 15.7983 13.095 15.9032 13.095 16.0126V20.1396C13.0937 20.2481 13.0497 20.3517 12.9726 20.428C12.8955 20.5044 12.7915 20.5473 12.683 20.5476Z",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M21.349 20.5477H20.937C20.8828 20.5477 20.8291 20.537 20.779 20.5162C20.7288 20.4955 20.6833 20.4651 20.645 20.4267C20.6066 20.3884 20.5762 20.3428 20.5554 20.2927C20.5347 20.2426 20.524 20.1889 20.524 20.1347V16.0077C20.524 15.8981 20.5675 15.7931 20.645 15.7156C20.7224 15.6382 20.8275 15.5947 20.937 15.5947H21.349C21.7869 15.5947 22.2068 15.7686 22.5164 16.0782C22.8261 16.3879 23 16.8078 23 17.2457V18.8997C22.9992 19.337 22.8249 19.7562 22.5154 20.0652C22.2058 20.3741 21.7864 20.5477 21.349 20.5477Z",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M12.27 15.5995C12.27 15.0034 12.3874 14.4131 12.6156 13.8623C12.8438 13.3115 13.1782 12.8111 13.5998 12.3896C14.0214 11.9681 14.5219 11.6338 15.0727 11.4058C15.6235 11.1777 16.2138 11.0604 16.81 11.0605C18.0138 11.0605 19.1683 11.5387 20.0196 12.39C20.8708 13.2412 21.349 14.3957 21.349 15.5995",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M18.46 22.6109H19.7C20.1379 22.6109 20.5578 22.4369 20.8674 22.1273C21.1771 21.8177 21.351 21.3977 21.351 20.9599V20.5479",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
})), React.createElement("defs", null, React.createElement("clipPath", {
  id: "clip0_1848_2655"
}, React.createElement("rect", {
  width: "24",
  height: "24",
  fill: "white"
}))));
const Upload = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "none",
  className: classnames('ds-icon-single-person', className),
  ...rest
}, title, React.createElement("g", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1.5",
  clipPath: "url(#a)"
}, React.createElement("path", {
  d: "M11.274 17.086a5.813 5.813 0 1 0 11.625 0 5.813 5.813 0 0 0-11.626 0ZM17.086 19.992v-5.813M17.086 14.18l-2.18 2.18M17.086 14.18l2.18 2.179"
}), React.createElement("path", {
  d: "M8.367 19.992H2.554a1.453 1.453 0 0 1-1.452-1.453V2.554a1.453 1.453 0 0 1 1.452-1.452h10.298c.385 0 .754.153 1.027.425l2.781 2.782c.273.272.426.642.426 1.027v3.031"
})), React.createElement("defs", null, React.createElement("clipPath", {
  id: "a"
}, React.createElement("path", {
  fill: "#fff",
  d: "M0 0h24v24H0z"
}))));
const LockSquare = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "none",
  className: classnames('ds-icon-single-person', className),
  ...rest
}, title, React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1.5",
  d: "M5.25 9.75h13.5s1.5 0 1.5 1.5v10.5s0 1.5-1.5 1.5H5.25s-1.5 0-1.5-1.5v-10.5s0-1.5 1.5-1.5ZM6.75 9.75V6a5.25 5.25 0 1 1 10.5 0v3.75M12 15v3"
}));
const RoadSignWarningSolid = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "none",
  className: classnames('ds-icon-road-sign-warning-solid', className),
  ...rest
}, title, React.createElement("g", {
  clipPath: "url(#a)"
}, React.createElement("path", {
  fill: "currentColor",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1.5",
  d: "M13.313 2.275a1.5 1.5 0 0 0-2.626 0l-9.75 18A1.5 1.5 0 0 0 2.25 22.5h19.5a1.5 1.5 0 0 0 1.313-2.225l-9.75-18Z"
}), React.createElement("path", {
  fill: "currentColor",
  d: "M12 18a.375.375 0 0 1 .375.375"
}), React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1.5",
  d: "M12 18a.375.375 0 0 1 .375.375"
}), React.createElement("path", {
  fill: "currentColor",
  d: "M11.625 18.375A.375.375 0 0 1 12 18"
}), React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1.5",
  d: "M11.625 18.375A.375.375 0 0 1 12 18"
}), React.createElement("path", {
  fill: "currentColor",
  d: "M12 18.75a.375.375 0 0 1-.375-.375"
}), React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1.5",
  d: "M12 18.75a.375.375 0 0 1-.375-.375"
}), React.createElement("path", {
  fill: "currentColor",
  d: "M12.375 18.375a.375.375 0 0 1-.375.375"
}), React.createElement("path", {
  stroke: "#fff",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1.5",
  d: "M12.375 18.375a.375.375 0 0 1-.375.375"
}), React.createElement("path", {
  fill: "currentColor",
  d: "M12 15V8.25 15Z"
}), React.createElement("path", {
  stroke: "#fff",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1.5",
  d: "M12 15V8.25"
})), React.createElement("defs", null, React.createElement("clipPath", {
  id: "a"
}, React.createElement("path", {
  fill: "#fff",
  d: "M0 0h24v24H0z"
}))));
const RoadSignWarning = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  className: classnames('ds-icon-road-sign-warning', className),
  ...rest
}, title, React.createElement("path", {
  d: "M13.3131 2.27521C13.1834 2.04051 12.9933 1.84486 12.7623 1.70861C12.5314 1.57235 12.2682 1.50049 12.0001 1.50049C11.732 1.50049 11.4688 1.57235 11.2378 1.70861C11.0069 1.84486 10.8167 2.04051 10.6871 2.27521L0.93709 20.2752C0.811008 20.5036 0.74666 20.7609 0.750398 21.0217C0.754136 21.2825 0.82583 21.5379 0.958404 21.7625C1.09098 21.9872 1.27985 22.1734 1.50637 22.3028C1.73289 22.4321 1.98923 22.5002 2.25009 22.5002H21.7501C22.0109 22.5002 22.2673 22.4321 22.4938 22.3028C22.7203 22.1734 22.9092 21.9872 23.0418 21.7625C23.1743 21.5379 23.246 21.2825 23.2498 21.0217C23.2535 20.7609 23.1892 20.5036 23.0631 20.2752L13.3131 2.27521Z",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M12 18C12.0995 18 12.1948 18.0395 12.2652 18.1098C12.3355 18.1802 12.375 18.2755 12.375 18.375",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M11.625 18.375C11.625 18.2755 11.6645 18.1802 11.7348 18.1098C11.8052 18.0395 11.9005 18 12 18",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M12 18.75C11.9005 18.75 11.8052 18.7105 11.7348 18.6402C11.6645 18.5698 11.625 18.4745 11.625 18.375",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M12.375 18.375C12.375 18.4745 12.3355 18.5698 12.2652 18.6402C12.1948 18.7105 12.0995 18.75 12 18.75",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M12 15V8.25",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const Link = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  className: classnames('ds-icon-link', className),
  viewBox: "0 0 24 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...rest
}, title, React.createElement("path", {
  d: "M9.364 16.5L8.432 17.432C7.58808 18.2759 6.44348 18.75 5.25 18.75C4.05652 18.75 2.91192 18.2759 2.068 17.432C1.22408 16.5881 0.749969 15.4435 0.749969 14.25C0.749969 13.0565 1.22408 11.9119 2.068 11.068L6.841 6.29399C7.64122 5.49196 8.71452 5.02129 9.84658 4.97597C10.9786 4.93066 12.0861 5.31403 12.9479 6.04953C13.8097 6.78503 14.3623 7.81853 14.4954 8.94365C14.6285 10.0688 14.3323 11.2027 13.666 12.119M14.818 3.56699L15.568 2.81699C16.4119 1.97307 17.5565 1.49896 18.75 1.49896C19.9435 1.49896 21.0881 1.97307 21.932 2.81699C22.7759 3.66091 23.25 4.80551 23.25 5.99899C23.25 7.19247 22.7759 8.33707 21.932 9.18099L17.159 13.954C16.3586 14.7557 15.2853 15.226 14.1534 15.271C13.0214 15.3161 11.9141 14.9325 11.0526 14.197C10.191 13.4614 9.63857 12.428 9.50555 11.303C9.37254 10.178 9.66873 9.04419 10.335 8.12799",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const QRcode = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  className: classnames('ds-icon-qr-code', className),
  ...rest
}, title, React.createElement("g", {
  clipPath: "url(#clip0_345_578)"
}, React.createElement("path", {
  d: "M5.67188 5.67188H8.48438V8.48438H5.67188V5.67188Z",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M5.67188 15.5156H8.48438V18.3281H5.67188V15.5156Z",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M15.5156 5.67188H18.3281V8.48438H15.5156V5.67188Z",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M5.67188 12.7031H11.2969V14.1094",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M14.1094 14.1094V18.3281H18.3281V14.1094H16.9219",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M11.2969 16.9219V18.3281",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M11.2969 5.67188V9.89062H12.7031",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M15.5156 11.2969H18.3281",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M1.45312 6.375V2.85938C1.45312 2.48641 1.60128 2.12873 1.86501 1.86501C2.12873 1.60128 2.48641 1.45313 2.85938 1.45312H6.375",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M17.625 1.45312H21.1406C21.5136 1.45312 21.8713 1.60128 22.135 1.86501C22.3987 2.12873 22.5469 2.48641 22.5469 2.85938V6.375",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M22.5469 17.625V21.1406C22.5469 21.5136 22.3987 21.8713 22.135 22.135C21.8713 22.3987 21.5136 22.5469 21.1406 22.5469H17.625",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M6.375 22.5469H2.85938C2.48641 22.5469 2.12873 22.3987 1.86501 22.135C1.60128 21.8713 1.45313 21.5136 1.45312 21.1406V17.625",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
})), React.createElement("defs", null, React.createElement("clipPath", {
  id: "clip0_345_578"
}, React.createElement("rect", {
  width: "24",
  height: "24",
  fill: "white"
}))));
const Refresh = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  className: classnames('ds-icon-refresh', className),
  ...rest
}, title, React.createElement("path", {
  d: "M18.362 7.00001C17.2808 5.69042 15.8219 4.74639 14.1842 4.29678C12.5466 3.84716 10.8101 3.91388 9.21183 4.48781C7.61353 5.06174 6.23128 6.11492 5.25377 7.5036C4.27626 8.89228 3.75111 10.5488 3.75 12.247V14.5",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M6 17.908C7.13379 19.1094 8.60214 19.9428 10.2149 20.3002C11.8277 20.6576 13.5107 20.5227 15.0459 19.9128C16.5811 19.3029 17.8979 18.2462 18.8257 16.8795C19.7535 15.5127 20.2497 13.8989 20.25 12.247V10.747",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M0.75 11.497L3.75 14.497L6.75 11.497",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M23.25 13.747L20.25 10.747L17.25 13.747",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const Translate = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  width: "20",
  height: "20",
  viewBox: "0 0 24 24",
  className: classnames('ds-icon-translate', className),
  ...rest
}, React.createElement("g", null, React.createElement("path", {
  d: "M19.652 0.747986L15.902 2.99799L18.152 6.74799",
  stroke: "#111827",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M23.25 8.187C22.7442 6.79873 21.7969 5.61478 20.5533 4.81687C19.3098 4.01896 17.8387 3.65119 16.366 3.77",
  stroke: "#111827",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M4.34801 23.248L8.09801 20.998L5.84801 17.248",
  stroke: "#111827",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M0.75 15.808C1.25575 17.1963 2.2031 18.3802 3.44666 19.1781C4.69022 19.976 6.16126 20.3438 7.634 20.225",
  stroke: "#111827",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M0.75 0.747986H12.75V12.748H0.75V0.747986Z",
  stroke: "#111827",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M15.75 11.248H21.75C22.1478 11.248 22.5294 11.406 22.8107 11.6873C23.092 11.9686 23.25 12.3502 23.25 12.748V21.748C23.25 22.1458 23.092 22.5273 22.8107 22.8086C22.5294 23.09 22.1478 23.248 21.75 23.248H12.75C12.3522 23.248 11.9706 23.09 11.6893 22.8086C11.408 22.5273 11.25 22.1458 11.25 21.748V15.748",
  stroke: "#111827",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M15.75 20.248V15.748C15.75 15.3502 15.908 14.9686 16.1893 14.6873C16.4706 14.406 16.8522 14.248 17.25 14.248C17.6478 14.248 18.0294 14.406 18.3107 14.6873C18.592 14.9686 18.75 15.3502 18.75 15.748V20.248",
  stroke: "#111827",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M15.75 18.748H18.75",
  stroke: "#111827",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M6.75 3.74799V5.24799",
  stroke: "#111827",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M3.75 5.24799H9.75",
  stroke: "#111827",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M8.25 5.24799C8.25 5.24799 6.75 9.74799 3.75 9.74799",
  stroke: "#111827",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M6.74899 8.01398C7.08344 8.5129 7.52771 8.92859 8.04774 9.22916C8.56777 9.52974 9.14973 9.70722 9.74899 9.74798",
  stroke: "#111827",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
})));
const Module = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  viewBox: "0 0 16 16",
  fill: "none",
  className: classnames('ds-icon-translate', className),
  ...rest
}, title, React.createElement("path", {
  d: "M1.63545 3.32766L7.32295 1.09766C7.75697 0.925781 8.24018 0.925781 8.6742 1.09766L14.3617 3.32766C14.5643 3.41406 14.7364 3.55921 14.8557 3.74436C14.975 3.92952 15.0361 4.14619 15.0311 4.36641V11.6339C15.0363 11.8538 14.9756 12.0703 14.8567 12.2554C14.7379 12.4406 14.5664 12.5859 14.3642 12.6727L8.6767 14.9033C8.24244 15.0739 7.75971 15.0739 7.32545 14.9033L1.63795 12.6727C1.43533 12.5863 1.26326 12.4411 1.14394 12.2559C1.02462 12.0708 0.96354 11.8541 0.968574 11.6339V4.36641C0.963335 4.14647 1.02405 3.93001 1.1429 3.74488C1.26175 3.55976 1.4333 3.41444 1.63545 3.32766Z",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M7.99994 6.34578L1.18994 3.67578",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M8 6.34578L14.81 3.67578",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M8 15.0313V6.3457",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const Globe = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  viewBox: "0 0 16 16",
  fill: "none",
  className: classnames('ds-icon-translate', className),
  ...rest
}, title, React.createElement("path", {
  d: "M0.96875 8C0.96875 8.92336 1.15062 9.83767 1.50397 10.6907C1.85733 11.5438 2.37524 12.3189 3.02816 12.9718C3.68107 13.6248 4.45619 14.1427 5.30926 14.496C6.16233 14.8494 7.07664 15.0313 8 15.0313C8.92336 15.0313 9.83767 14.8494 10.6907 14.496C11.5438 14.1427 12.3189 13.6248 12.9718 12.9718C13.6248 12.3189 14.1427 11.5438 14.496 10.6907C14.8494 9.83767 15.0313 8.92336 15.0313 8C15.0313 7.07664 14.8494 6.16233 14.496 5.30926C14.1427 4.45619 13.6248 3.68107 12.9718 3.02816C12.3189 2.37524 11.5438 1.85733 10.6907 1.50397C9.83767 1.15062 8.92336 0.96875 8 0.96875C7.07664 0.96875 6.16233 1.15062 5.30926 1.50397C4.45619 1.85733 3.68107 2.37524 3.02816 3.02816C2.37524 3.68107 1.85733 4.45619 1.50397 5.30926C1.15062 6.16233 0.96875 7.07664 0.96875 8Z",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M6.675 14.9063C5.69375 13.5313 5.03125 10.95 5.03125 8.03125C5.03125 5.1125 5.69375 2.46875 6.675 1.125",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M9.32471 14.9063C10.306 13.5313 10.9685 10.95 10.9685 8.03125C10.9685 5.1125 10.306 2.46875 9.32471 1.125",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M0.96875 8H15.0313",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M1.78125 11.2812H14.2188",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M1.78125 4.71875H14.2188",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const Database = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  viewBox: "0 0 16 16",
  fill: "none",
  className: classnames('ds-icon-translate', className),
  ...rest
}, title, React.createElement("path", {
  d: "M0.8125 3.68799C0.8125 4.45049 1.56975 5.18175 2.91767 5.72092C4.26559 6.26009 6.09376 6.56299 8 6.56299C9.90624 6.56299 11.7344 6.26009 13.0823 5.72092C14.4302 5.18175 15.1875 4.45049 15.1875 3.68799C15.1875 2.92549 14.4302 2.19422 13.0823 1.65506C11.7344 1.11589 9.90624 0.812988 8 0.812988C6.09376 0.812988 4.26559 1.11589 2.91767 1.65506C1.56975 2.19422 0.8125 2.92549 0.8125 3.68799Z",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M0.8125 3.68799V7.99985C0.8125 9.58749 4.03058 10.8748 8 10.8748C11.9694 10.8748 15.1875 9.58813 15.1875 7.99985V3.68799",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M0.8125 8V12.3125C0.8125 13.9001 4.03058 15.1875 8 15.1875C11.9694 15.1875 15.1875 13.9001 15.1875 12.3125V8",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const InvertedPyramid = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  viewBox: "0 0 16 16",
  fill: "none",
  className: classnames('ds-icon-translate', className),
  ...rest
}, title, React.createElement("path", {
  d: "M0.96875 3.18311H15.0312",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M2.46631 6.39453H13.5338",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M5.94995 12.8169H10.05",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), React.createElement("path", {
  d: "M3.96436 9.60547H12.0356",
  stroke: "currentColor",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const EmptySection = ({
  className,
  title,
  ...rest
}) => React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  className: classnames('ds-icon-empty-section', className),
  ...rest
}, React.createElement("path", {
  d: "M1.8 3h20.4s1 0 1 1v16s0 1-1 1H1.9s-1 0-1-1V4s0-1 1-1ZM16 8l-8 8M8 8l8 8",
  stroke: "#4B5563",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));

const ICON_MAP = {
  // actions
  close: Close,
  copy: Copy,
  download: Download,
  expand: Expand,
  filter: Filter,
  remove: Remove,
  // arrows
  arrowDown: ArrowDown,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  arrowRightLong: ArrowRightLong,
  arrowUp: ArrowUp,
  dropdownArrow: DropdownArrow,
  returnArrow: ReturnArrow,
  // circles
  addCircle: AddCircle,
  checkCircle: CheckCircle,
  checkCircleSolid: CheckCircleSolid,
  informationCircle: InformationCircle,
  questionCircleSolid: QuestionCircleSolid,
  removeCircle: RemoveCircle,
  // symbols
  checkmark: Checkmark,
  database: Database,
  email: Email,
  emptySection: EmptySection,
  files: Files,
  flash: Flash,
  folderCash: FolderCash,
  globe: Globe,
  idCard: IdCard,
  informationCircleSolid: InformationCircleSolid,
  invertedPyramid: InvertedPyramid,
  link: Link,
  lockSquare: LockSquare,
  module: Module,
  money: Money,
  phoneCall: PhoneCall,
  qrCode: QRcode,
  refresh: Refresh,
  roadSignWarning: RoadSignWarning,
  roadSignWarningSolid: RoadSignWarningSolid,
  singlePerson: SinglePerson,
  translate: Translate,
  upload: Upload
};
/**
 * Used to render an icon from our Design System.
 */

const DesignSystemIcon = ({
  className,
  icon,
  tabIndex,
  titleInfo
}) => {
  const IconClass = ICON_MAP[icon];
  const {
    title,
    titleId
  } = titleInfo || {};
  const hasTitle = Boolean(title && title.length && !!titleId && titleId.length);
  const titleEl = hasTitle ? React.createElement("title", {
    id: titleId
  }, title) : React.createElement(React.Fragment, null);
  let finalTabIndex = tabIndex;

  if (finalTabIndex === undefined) {
    finalTabIndex = hasTitle ? 0 : -1;
  }

  return React.createElement(IconClass, {
    "aria-hidden": !hasTitle,
    "aria-labelledby": titleId,
    className: classnames('ds-icon', className),
    height: "1em",
    width: "1em",
    role: "img",
    tabIndex: finalTabIndex,
    title: titleEl
  });
};

export { DesignSystemIcon, ICON_MAP, DesignSystemIcon as default };
