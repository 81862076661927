import React from 'react';
import {
	CARDSHOP_LIVE_COUNTRY_CODES,
	COUNTRIES,
} from '@novacredit/frontend-common/utils/countries';
import type { CountryCode } from '@novacredit/frontend-common/utils/countries';
import type { EventSchema } from '@novacredit/frontend-common/tracking';
import { Typography } from '@novacredit/pandorasbox';

import AdDisclosure from 'components/common/AdDisclosure';
import CountrySelect from 'components/common/CountrySelect';
import Breadcrumbs from 'components/common/Breadcrumbs';
import { Row, Col, Container } from 'components/common/Layout';

import swooshArrow from 'assets/graphics/swoosh-arrow.svg';

import './PageHeader.scss';

const unsupportedCountries = COUNTRIES.filter(
	someCountry =>
		!(CARDSHOP_LIVE_COUNTRY_CODES as readonly CountryCode[]).includes(someCountry.code),
).map(someCountry => someCountry.code);
const allCountries: CountryCode[] = [...CARDSHOP_LIVE_COUNTRY_CODES, ...unsupportedCountries];

const PageHeader = ({
	trackingPage,
	country,
	countries = allCountries,
	className = '',
	breadcrumb,
	pageTitle,
	bannerImg,
	headerCopy,
	labelCopy = 'I moved to the U.S. from',
	showSwoosh = '',
	adDisclosure = true,
}: {
	trackingPage: EventSchema['nova.client.COUNTRY_SELECTED']['page'];
	country: CountryCode;
	countries?: CountryCode[];
	className?: string;
	breadcrumb: string;
	pageTitle: string;
	bannerImg: string;
	headerCopy: string;
	labelCopy?: string;
	showSwoosh?: string;
	adDisclosure?: boolean;
}) => (
	<div className={`page-header pt-4 pb-4 ${className}`}>
		<Container>
			<Row>
				<Col className="header-content-container">
					<Breadcrumbs crumbs={[{ title: breadcrumb, isCurrentPage: true }]} />
					<Typography
						color="ColorCoolGray900"
						className="header-content_heading"
						tag="h1"
						variant="Heading-3"
					>
						{pageTitle}
					</Typography>
					<Typography
						className="header-content_copy"
						tag="p"
						color="ColorCoolGray600"
						variant="Body-Regular"
					>
						{headerCopy}
					</Typography>
					{adDisclosure && <AdDisclosure />}
					<Typography
						tag="label"
						htmlFor="countrySelect"
						variant="Body-Semibold"
						color="ColorCoolGray600"
						className="header-label"
					>
						{labelCopy}
					</Typography>
					<CountrySelect
						maxWidth="560px"
						countryCodes={countries}
						trackingPage={trackingPage}
					/>
					{!country && !!showSwoosh.length && (
						<div className="country-select-helper pl-5 pr-2">
							<img className="swoosh-arrow" src={swooshArrow} alt="" />
							<Typography
								variant="Heading-4"
								variantMobile="Subtitle-2"
								tag="p"
								className="country-select-helper_text mb-1 pl-3"
							>
								{showSwoosh}
							</Typography>
						</div>
					)}
				</Col>
			</Row>
			<div className="banner-image-container">
				<img className="banner-image" alt="" src={bannerImg} />
			</div>
		</Container>
	</div>
);

export default PageHeader;
