import React from 'react';
import classnames from 'classnames';

import './FlagImg.scss';

// Flag images from https://github.com/lipis/flag-icon-css
import ausFlag from './img/aus.svg';
import autFlag from './img/aut.svg';
import braFlag from './img/bra.svg';
import canFlag from './img/can.svg';
import cheFlag from './img/che.svg';
import chnFlag from './img/chn.svg';
import deuFlag from './img/deu.svg';
import domFlag from './img/dom.svg';
import espFlag from './img/esp.svg';
import fraFlag from './img/fra.svg';
import gbrFlag from './img/gbr.svg';
import indFlag from './img/ind.svg';
import jamFlag from './img/jam.svg';
import kenFlag from './img/ken.svg';
import korFlag from './img/kor.svg';
import mexFlag from './img/mex.svg';
import ngaFlag from './img/nga.svg';
import phlFlag from './img/phl.svg';
import usaFlag from './img/usa.svg';
import ukrFlag from './img/ukr.svg';
import zafFlag from './img/zaf.svg';

const flagImages: { [countryCode: string]: string } = {
	AUS: ausFlag,
	AUT: autFlag,
	BRA: braFlag,
	CAN: canFlag,
	CHE: cheFlag,
	CHN: chnFlag,
	DEU: deuFlag,
	DOM: domFlag,
	ESP: espFlag,
	FRA: fraFlag,
	GBR: gbrFlag,
	IND: indFlag,
	JAM: jamFlag,
	KEN: kenFlag,
	KOR: korFlag,
	MEX: mexFlag,
	NGA: ngaFlag,
	PHL: phlFlag,
	USA: usaFlag,
	UKR: ukrFlag,
	ZAF: zafFlag,
};

const ASPECT_RATIO = 4 / 3;
const DEFAULT_HEIGHT = 30;
const DEFAULT_WIDTH = 40;

const calculateDimensions = (width?: number, height?: number) => {
	let imgWidth = width;
	let imgHeight = height;
	if (!imgWidth && imgHeight) {
		imgWidth = Math.round(imgHeight * ASPECT_RATIO);
	} else if (!imgHeight && imgWidth) {
		imgHeight = Math.round(imgWidth / ASPECT_RATIO);
	} else {
		imgWidth = DEFAULT_WIDTH;
		imgHeight = DEFAULT_HEIGHT;
	}

	return { imgHeight, imgWidth };
};

// TODO(ENG-18627): Move to pandorasbox
const Flag = ({
	country,
	width,
	height,
	alt = '',
	rounded = false,
	alignMiddle = false,
}: {
	country: string;
	width?: number;
	height?: number;
	alt?: string;
	rounded?: boolean;
	alignMiddle?: boolean;
}) => {
	const normalizedCountryCode = country.toUpperCase();
	if (flagImages.hasOwnProperty(normalizedCountryCode)) {
		const imgSrc = flagImages[normalizedCountryCode];
		const { imgHeight, imgWidth } = calculateDimensions(width, height);

		return (
			<img
				className={classnames('nova-fec-flag-img', {
					'align-middle': alignMiddle,
					rounded,
				})}
				src={imgSrc}
				height={imgHeight}
				width={imgWidth}
				alt={alt}
			/>
		);
	} else {
		return null;
	}
};

export const hasFlagForCountry = (country: string) =>
	flagImages.hasOwnProperty(country.toUpperCase());

export default Flag;
