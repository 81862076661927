import React, { useEffect } from 'react';
import { DesignSystemSelect, Flag } from '@novacredit/pandorasbox';
import type { EventSchema } from '@novacredit/frontend-common/tracking';

import { getIsMobileScreenWidth } from '@novacredit/frontend-common/utils/browser';
import { COUNTRIES } from '@novacredit/frontend-common/utils/countries';
import type { CountryCode } from '@novacredit/frontend-common/utils/countries';

import { useStoredCountryContext } from 'contexts/StoredCountryContext';

import './CountrySelect.scss';

const CountrySelect = ({
	countryCodes,
	trackingLocation = 'Country Filter',
	trackingPage,
	color = 'ColorBlue600',
	placeholder = 'Select Country...',
	maxWidth = '370px',
	onChange = () => {},
	onInit = () => {},
}: {
	countryCodes: CountryCode[];
	color?: string;
	placeholder?: string;
	maxWidth?: string;
	onChange?: (country: CountryCode) => void;
	onInit?: (country: CountryCode) => void;
	trackingLocation?: EventSchema['nova.client.COUNTRY_SELECTED']['location'];
	trackingPage: EventSchema['nova.client.COUNTRY_SELECTED']['page'];
}) => {
	const { storedCountry, setStoredCountry } = useStoredCountryContext();

	useEffect(() => {
		// We want to provide a hook here so that parent components know what country
		// was initialized (aka shown by default)
		const validCountry = countryCodes.find(code => code === storedCountry);
		if (validCountry) {
			onInit(validCountry);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isMobile = getIsMobileScreenWidth();

	// Determine what options are shown
	const countriesToDisplay = countryCodes.map(code => {
		return COUNTRIES.find(country => country.code === code);
	});
	const selectOptions: {
		value: string;
		label: JSX.Element | string;
		searchLabel: string;
	}[] = [
		...countriesToDisplay.map(country => ({
			value: country.name,
			label: isMobile ? (
				country.name
			) : (
				<div className="country-option">
					<Flag countryCode={country.code} size="sm" />

					<span className="ml-2">{country.name}</span>
				</div>
			),
			searchLabel: country.name,
		})),
	];

	const internalOnChange = newDisplayValue => {
		// Check that we have a valid select value first. If invalid, do nothing.
		const countrySelectValidValues = selectOptions.map(option => option.value);
		if (!countrySelectValidValues.includes(newDisplayValue)) {
			return;
		}

		const countryCode = countriesToDisplay.find(
			country => country.name === newDisplayValue,
		).code;

		setStoredCountry(countryCode, {
			location: trackingLocation,
			page: trackingPage,
		});

		onChange(countryCode);
	};

	// Determine what value should be displayed
	const storedCountryOption = countriesToDisplay.find(country => country.code === storedCountry);

	let currentValue;
	if (storedCountryOption) {
		currentValue = storedCountryOption.name;
	} else {
		currentValue = '';
	}

	return (
		<div className="country-select" style={{ maxWidth }}>
			<DesignSystemSelect
				className="text-left"
				classNamePrefix="country-select"
				inputName="countrySelect"
				value={currentValue}
				color={color}
				placeholder={placeholder}
				isMobile={isMobile}
				onChange={internalOnChange}
				data={selectOptions}
				maxHeight={330}
				menuPortalTarget={document.body}
			/>
		</div>
	);
};

export default CountrySelect;
