import { devWarn } from '../utils/debug';
import { EXPERIMENTS, FEATURE_FLAGS_CONFIG } from './experiments';
import type { FeatureFlagConfig } from './experiments';
import { bucketizeVariants, getBucket } from './bucketing';

export type TrackingFn = (expName: string, variant: string) => void;
export type ExperimentOverrides = {
	[experimentName: string]: string;
};

/**
 * Utility class that helps our packages initialize and run A/B style experiments.
 */
export default class ExperimentsClient {
	bucketingId: string | null;
	trackingFn: TrackingFn;
	overrides: ExperimentOverrides;
	_alreadyTrackedExperiments: Set<string>;

	constructor({
		bucketingId = null,
		trackingFn = () => null,
		overrides = {},
	}: {
		/**
		 * Unique identifier for the user, which could be a value from a database for
		 * authenticated users, or could be an anonymous ID for a non-authed user.
		 *
		 * If not provided, this experiments client will still return the variant
		 * for feature flags, but a/b style experiment activation will return `null`
		 * since the user's experiment group cannot be determined without an ID.
		 */
		bucketingId?: string | null;

		/**
		 * A callback that gets called when the user is activated into an experiment. The
		 * callback arguments includes the experiment name and variant name for that user.
		 * For example: `tracking.track('$experiment_started', { expName, variant });`
		 */
		trackingFn?: TrackingFn;

		/**
		 * An object that maps experiment names to variants. When an activated experiment
		 * matches an override, the user will automatically be placed in the mapped
		 * variant. That user will *not* be tracked as part of the experiment. This is most
		 * useful for our own testing purposes when we need to force a certain experience
		 * but don't want out testing to impact experiment analyses.
		 *
		 * For example, a consumer of ExperimentClient could look for a URL query parameter
		 * like `force_new_experience=true` and use that to create an overrides like:
		 * { 'SOME_TEST': 'newExperience' }
		 */
		overrides?: ExperimentOverrides;
	}) {
		this.bucketingId = bucketingId;
		this.trackingFn = trackingFn;
		this.overrides = overrides;
		this._alreadyTrackedExperiments = new Set<string>();
	}

	_bucket(experimentName: string, shouldTrack: boolean): string | null {
		const overrideVariant = this.overrides[experimentName];

		if (overrideVariant) {
			return overrideVariant;
		}

		const featureFlag: FeatureFlagConfig = FEATURE_FLAGS_CONFIG[experimentName];

		if (featureFlag) {
			return featureFlag.activeVariant;
		}

		const experiment = EXPERIMENTS[experimentName];

		if (!experiment) {
			devWarn(`Attempted to get the variant for non-existent experiment: ${experimentName}`);
			return null;
		}

		if (!this.bucketingId) {
			return null;
		}

		const bucketized = bucketizeVariants(experiment.variants);
		const bucket = getBucket(this.bucketingId, experimentName);

		const variant = bucketized.find(
			grp => bucket >= grp.buckets[0] && bucket <= grp.buckets[1],
		);

		if (!variant) {
			// The experiment is valid, but the user was not assigned a bucket.
			// This can happen when the experiment weights do not add up to 100.
			return null;
		}

		if (shouldTrack && !this._alreadyTrackedExperiments.has(experimentName)) {
			// don't send another tracking event for an experiment we've
			// already sent an event for
			this._alreadyTrackedExperiments.add(experimentName);
			this.trackingFn(experimentName, variant.name);
		}

		return variant.name;
	}

	/**
	 * Returns the variant assigned to the current user. Also calls
	 * the appropriate tracking functions to log the start of the
	 * experiment
	 */
	activate(experimentName: string): string | null {
		return this._bucket(experimentName, true);
	}

	/**
	 * Returns the variant assigned to the current user without
	 * calling any tracking functions
	 */
	peek(experimentName: string): string | null {
		return this._bucket(experimentName, false);
	}

	/**
	 * Returns the variant that is currently active. By contrast with
	 * experiments, which show some users one variant and other users
	 * a different variant, a feature flag will show the same variant
	 * to all users.
	 *
	 * To view a variant besides the active one, an experiment override must
	 * be set, often through URL params.
	 *
	 * featureFlagName should match a definition in the FEATURE_FLAGS registry.
	 */
	getFeatureFlag(featureFlagName: string): string {
		const variant = this._bucket(featureFlagName, false);

		if (variant === null) {
			throw new Error('Attempted to determine variant for feature flag but got null.');
		}

		return variant;
	}
}
