import React, { useContext, useEffect } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { getOrCreateNovaAnonymousId } from '@novacredit/frontend-common/utils/novaAnonymousId';
import { HELP_CENTER_URLS } from '@novacredit/frontend-common/utils/url';
import {
	COOKIES,
	getNovaCookie,
	isCookieConsentGranted,
} from '@novacredit/frontend-common/utils/novaCookies';
import { DesignSystemButton, Typography, CheckCircleIcon } from '@novacredit/pandorasbox';
import qs from 'qs';

import AmexPartner from 'components/common/AmexPartner';
import AmexRedirectAccordion from 'components/common/AmexRedirectAccordion';
import CardImage from 'components/common/CardImage';
import Loader from 'components/common/Loader';
import NotFound from 'components/common/NotFound';
import Page from 'components/common/Page';
import PageTitle from 'components/common/PageTitle';
import useApi from 'hooks/useApi';
import { APP_PATHS } from 'modules/paths';
import { getTrackingPropsForCreditCard, tracker } from 'modules/tracking';
import StoredCountryContext from 'contexts/StoredCountryContext';

import helpChatImage from 'assets/images/help-chat.png';
import checkboxImage from 'assets/images/checkbox-ss.png';
import { IMPACT_RADIUS_SUBID_INFO } from 'consts';

import './AmexRedirect.scss';

// See: https://novacredit.atlassian.net/browse/ENG-14644
const CheckCircle = CheckCircleIcon as React.ElementType;

function getUtmInformation() {
	const cookie = getNovaCookie(COOKIES.UTM_INFO);
	if (cookie) {
		return cookie;
	}

	if (!isCookieConsentGranted(COOKIES.UTM_INFO)) {
		return IMPACT_RADIUS_SUBID_INFO.NO_COOKIES;
	} else {
		return IMPACT_RADIUS_SUBID_INFO.NO_UTM_INFO;
	}
}

function getReferralInformation() {
	const cookie = getNovaCookie(COOKIES.REFERRAL);
	if (cookie) {
		return cookie;
	}

	if (!isCookieConsentGranted(COOKIES.REFERRAL)) {
		return IMPACT_RADIUS_SUBID_INFO.NO_COOKIES;
	} else {
		return IMPACT_RADIUS_SUBID_INFO.NO_REFERRAL_INFO;
	}
}

/**
 * For ImpactRadius tracking, we need to append specific queryParams to the
 * Apply Now URLs in order for us to track a pull completely.
 *
 * see: https://impact-helpdesk.freshdesk.com/support/solutions/articles/48001144228-guide-to-sub-id-and-shared-id-parameters
 */
function appendSubIds(url: string): string {
	const queryParams = qs.stringify(
		{
			subId1: getOrCreateNovaAnonymousId(),
			subId2: `utm=${getUtmInformation()}&referrer=${getReferralInformation()}`,
		},
		{ skipNulls: true },
	);

	if (!queryParams) {
		return url;
	}

	const prefix = url.includes('?') ? '&' : '?';
	return `${url}${prefix}${queryParams}`;
}

const AmexRedirectPage = ({ match }: { match: { params: { slug: string } } }) => {
	const { slug } = match.params;
	const { data, loading, error } = useApi({
		path: `/credit-cards/${slug}`,
	});
	const creditCard = data;
	const isCardPresent = !!creditCard;
	const history = useHistory();
	const { storedCountry } = useContext(StoredCountryContext);

	useEffect(() => {
		if (!isCardPresent) {
			return;
		}

		tracker.track('nova.consumer_dashboard.CREDIT_CARD_REDIRECT_VIEWED', {
			country: storedCountry,
			...getTrackingPropsForCreditCard(creditCard),
		});
	}, [creditCard, isCardPresent, storedCountry]);

	if (loading) {
		return (
			<Page>
				<Loader text="Please wait while we get your offer..." />
			</Page>
		);
	}

	if (error) {
		return (
			<NotFound
				header="Could not get offer"
				subheader="Unfortunately we could not find the application for this credit card."
				ctaTag={Link}
				ctaText="Return to the card shop"
				ctaUri={APP_PATHS.CARDSHOP}
			/>
		);
	}

	if (!creditCard) {
		// This is the case where we were not able to find a card for the given :slug
		return <Redirect to={APP_PATHS.CARDSHOP} />;
	}
	const { name: cardName, apply_url: applyUrl } = creditCard;
	const appendedUrl = appendSubIds(applyUrl);

	const submitHandler = () => {
		const promisesToAwait = [
			tracker.track('nova.consumer_dashboard.AMEX_REDIRECTED_TO', {
				country: storedCountry,
				...getTrackingPropsForCreditCard(creditCard),
			}),
			tracker.track('nova.client.REDIRECTED_TO_CUSTOMER', {
				customerName: creditCard.manufacturer,
				productName: cardName,
				page: 'Redirect Page',
				...getTrackingPropsForCreditCard(creditCard),
			}),
		];

		window.open(appendedUrl, '_blank');
		history.push(`${APP_PATHS.MARKETPLACE}#products`);
		Promise.all(promisesToAwait);
	};

	return (
		<Page>
			<PageTitle title={cardName} />
			<div className="amex-redirect">
				<div className="card-content-container">
					<div className="info-section">
						<CardImage card={creditCard} className="amex-card-image text-center" />
						<Typography
							tag="h1"
							variant="Heading-4"
							className="amex-redirect-title text-center mb-2"
						>
							How to apply for your card with foreign credit history
						</Typography>
						<Typography tag="p" variant="Body-Regular" className="mb-0 text-center">
							Two things to note for a successful application
						</Typography>
						<div>
							<div className="instruction-header">
								<CheckCircle className="check-circle-icon" />
								<Typography
									tag="p"
									variant="Body-Semibold"
									className="instruction-title"
								>
									Select the checkbox shown below &ldquo;Social Security
									Number&rdquo;
								</Typography>
							</div>
							<Typography
								tag="p"
								variant="Caption-Regular"
								color="ColorCoolGray600"
								className="instruction-detail"
							>
								Clicking the checkbox lets American Express know that you would like
								to use your foreign credit history. ITIN is also accepted.
							</Typography>
						</div>
						<img
							alt="American Express Application Form"
							className="checkbox-image"
							src={checkboxImage}
						/>
						<div>
							<div className="instruction-header">
								<CheckCircle className="check-circle-icon" />
								<Typography
									tag="p"
									variant="Body-Semibold"
									className="instruction-title"
								>
									Enter your name as it appears on your foreign government ID
								</Typography>
							</div>
							<Typography
								tag="p"
								variant="Caption-Regular"
								color="ColorCoolGray600"
								className="instruction-detail"
							>
								Entering a name that doesn&apos;t match your credit report may
								create complications for your application.
							</Typography>
						</div>
						<DesignSystemButton
							onClick={submitHandler}
							tag="button"
							className="redirect-to-amex-btn"
						>
							Continue to American Express
						</DesignSystemButton>
					</div>
				</div>
				<div className="amex-partner-container">
					<AmexPartner />
				</div>
				<div className="text-left mt-5">
					<Typography className="my-0 text-center" tag="h3" variant="Subtitle-1">
						Frequently Asked Questions
					</Typography>
					<AmexRedirectAccordion />
				</div>
				<div className="need-help">
					<img alt="" className="need-help-image" src={helpChatImage} />
					<div className="need-help-text">
						<strong>Need help?</strong>
						<p>
							<a href={HELP_CENTER_URLS.CONTACT}>Get in touch</a>
						</p>
					</div>
				</div>
			</div>
		</Page>
	);
};

export default AmexRedirectPage;
