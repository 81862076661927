import type { ConsentMap } from '../../consent/common';
import type { Env } from '../../types';
import { devWarn } from '../../utils/debug';
import { Intercom as IntercomService } from '../../vendors';
import AsyncTaskQueue from '../AsyncTaskQueue';
import { getEvent } from '../events';
import type { TrackingEvent } from '../events';
import type { EventSchema } from '../eventSchema';
import type { InitializeOptions, TrackingDestination, TrackingOptions } from './destination';

/**
 * Forwards event data directly to Intercom.
 *
 * Reads the docs:
 *  - https://developers.intercom.com/installing-intercom/docs/intercom-for-web
 */
class Intercom implements TrackingDestination {
	name = 'Intercom';
	nodeEnv: Env | null;
	queue: AsyncTaskQueue;
	appName: string;
	category: string;
	options: InitializeOptions['Intercom'];
	consents: ConsentMap;

	constructor({ appName, category }: { appName: string; category: string }) {
		this.appName = appName;
		this.category = category;
		this.nodeEnv = null;
		this.queue = new AsyncTaskQueue();
		this.consents = null;
	}

	initialize({ nodeEnv, options }: { nodeEnv: Env; options?: InitializeOptions }): Promise<void> {
		this.nodeEnv = nodeEnv;
		this.options = options?.Intercom;

		if (options?.enableCookieConsent && !this.consents) {
			// We do not want to enable Intercom until the user has consented to cookies
			return Promise.resolve();
		}

		return IntercomService.initialize(nodeEnv).then(() => {
			return this.queue.activate();
		});
	}

	cookieConsentUpdated(consents: ConsentMap): void {
		// store consents in case `initialize` gets called after `cookieConsentUpdated`
		this.consents = consents;

		if (!this.nodeEnv) {
			devWarn('TrackingClient Intercom: cookieConsentUpdated called before initialization');
			return;
		}

		IntercomService.initialize(this.nodeEnv, consents).then(() => {
			return this.queue.activate();
		});
	}

	track({
		event,
		properties,
		getDefaultProperties,
	}: {
		event: TrackingEvent;
		properties: object;
		getDefaultProperties: () => object;
	}): Promise<void> {
		return this._enqueue(() =>
			IntercomService.trackEvent(event.displayName, {
				appName: this.appName,
				category: this.category,
				...getDefaultProperties(),
				...properties,
			}),
		);
	}

	// eslint-disable-next-line class-methods-use-this
	alias(): Promise<void> {
		return Promise.resolve();
	}

	identify({ properties }: { properties: EventSchema['nova.meta.IDENTIFY'] }): Promise<void> {
		return this._enqueue(() =>
			// We intentionally omit userId here since it can take on multiple values
			// for a given user (e.g. multiple visitId's, or a visitId and a consumerId).
			IntercomService.update(properties),
		);
	}

	trackPage({
		properties,
		getDefaultProperties,
	}: {
		properties: object;
		getDefaultProperties: () => object;
		options?: TrackingOptions;
	}): Promise<void> {
		const pageViewedEvent = getEvent('nova.client.PAGE_VIEWED');
		return this._enqueue(() =>
			IntercomService.trackEvent(pageViewedEvent.displayName, {
				appName: this.appName,
				category: this.category,
				...getDefaultProperties(),
				...properties,
			}),
		);
	}

	setUserProps({ properties }: { properties: EventSchema['nova.meta.IDENTIFY'] }): Promise<void> {
		return this._enqueue(() => IntercomService.update(properties));
	}

	_enqueue(task: () => Promise<void>): Promise<void> {
		return this.queue.enqueue(() => {
			if (this.options?.skip) {
				return Promise.resolve();
			}

			return task();
		});
	}
}

export default Intercom;
