import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { getQueryParams } from '@novacredit/frontend-common/utils/location';

import { getValidUSACreditDuration } from 'utils';
import CardShopContext, { CardShopProvider, getEnabledFilters } from 'contexts/CardShopContext';
import CardShop from 'components/common/CardShop';
import PageTitle from 'components/common/PageTitle';
import StoredCountryContext from 'contexts/StoredCountryContext';
import useApi from 'hooks/useApi';

const CardShopPage = () => {
	const filters = useContext(CardShopContext);
	const location = useLocation();
	// @ts-expect-error
	const queryParams = getQueryParams(location);
	const { storedCountry } = useContext(StoredCountryContext);

	// bracket and choice are passed from the onboarding survey.
	const bracket = queryParams.bracket;
	const choiceA = queryParams.choice_a;
	const hasSSN = choiceA ? choiceA === 'true' : null;
	const usaCreditDuration = getValidUSACreditDuration(bracket);

	const { data, error } = useApi({
		path: '/credit-cards',
		query: {
			country: storedCountry,
			recommendations: true,
			usaCreditDuration,
			hasSSN,
			features: getEnabledFilters(filters.state.features),
			rewards: getEnabledFilters(filters.state.rewards),
			creditRating: filters.state.creditRating,
		},
	});

	// Check for the absence of data instead of using the
	// `loading` state from useQuery so that we do not flash
	// the loading spinner as filters are applied or removed.
	const loading = !data;

	const cards = data?.rows || [];

	return (
		<>
			<PageTitle soloTitle="Credit Cards for Newcomers" />
			<CardShop
				country={storedCountry}
				onReset={filters.clear}
				filteredCards={cards}
				loading={loading}
				error={!!error}
				paginate={false}
				fsMask={false}
			/>
		</>
	);
};

const WrappedCardShopPage = () => (
	<CardShopProvider>
		<CardShopPage />
	</CardShopProvider>
);

export default WrappedCardShopPage;
