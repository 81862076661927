import React from 'react';
import { DesignSystemCheckbox, Tooltip } from '@novacredit/pandorasbox';

import Icon from 'components/common/Icon';
import type { FilterKey, FilterType } from 'contexts/CardShopContext';

import './CardShopFilterCheckbox.scss';

type OnChangeProps = {
	category: FilterType;
	name: FilterKey;
	label: string;
	isChecked: boolean;
};

type Props = {
	label: string;
	category?: FilterType;
	name: FilterKey;
	onChange: (props: OnChangeProps) => void;
	onTooltipShown?: (category: FilterType, label: string) => void;
	checked?: boolean;
	tooltip?: string;
	variant?: 'default' | 'button';
	simple?: boolean;
};

const CardShopFilterCheckbox = ({
	label,
	category,
	name,
	onChange,
	onTooltipShown,
	checked = false,
	tooltip,
	variant = 'default',
	simple = false,
}: Props) => {
	const onShown = () => {
		if (onTooltipShown) {
			onTooltipShown(category, label);
		}
	};
	const className = simple ? 'card-shop-filter-checkbox-simple' : 'card-shop-filter-checkbox';
	return (
		<div className={className}>
			<DesignSystemCheckbox
				checked={checked}
				disabled={false}
				indeterminate={false}
				inputName={`${category.toLowerCase()}_${name}`}
				label={label}
				onChange={event =>
					onChange({ category, name, label, isChecked: event.target.checked })
				}
				variant={variant}
			/>
			{tooltip && (
				<Tooltip
					position="top"
					theme="light"
					content={<p className="muted tooltip-text">{tooltip}</p>}
					tooltipTrigger={
						<Icon name="question-circle" className="card-shop-filter-tooltip" />
					}
					arrow
					onShown={onShown}
				/>
			)}
		</div>
	);
};

export default CardShopFilterCheckbox;
