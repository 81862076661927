import React from 'react';
import { Accordion, Typography } from '@novacredit/pandorasbox';
import { tracker } from 'modules/tracking';
import type { EventSchema } from 'modules/tracking';

import './FAQ.scss';

const items: {
	title: EventSchema['nova.consumer_dashboard.MVM_FAQ_EXPANDED']['question'];
	content: string;
}[] = [
	{
		title: 'How does Nova Credit work with lenders?',
		content: `Nova Credit has partnered with lenders and foreign credit bureaus to help newcomers 
			apply for products and services using their foreign credit history. When you apply with 
			Nova Credit, you generally have the option to add your foreign credit history to your application.`,
	},
	{
		title: 'Is my information safe using Nova Credit?',
		content: `We take compliance and information security very seriously. We incorporate security best practices 
			into all aspects of our business and are audited by third parties against industry standards (e.g. SOC 2 Type II) 
			and have received applicable certifications (e.g. ISO 27001) covering our information security management systems.`,
	},
	{
		title: 'How is Nova Credit different from other options?',
		content: `Typically, newcomers to the U.S. have trouble accessing financial products like credit cards because 
			they do not have a U.S. credit score. With Nova Credit, you can use your foreign credit history.`,
	},
	{
		title: 'Is Nova Credit free to use?',
		content: `Using Nova Credit is free for consumers like you. We get paid by our partners who want 
			to serve newcomers to the United States.`,
	},
];

const FAQ = () => {
	return (
		<div className="marketplace-faq mt-5">
			<Typography className="marketplace-read" tag="h2" variant="Heading-3">
				Frequently Asked Questions
			</Typography>

			<Typography className="marketplace-questions" tag="p" variant="Body-Regular">
				Have more questions? Try the{' '}
				<Typography
					tag="a"
					variant="Body-Regular"
					href="https://help.novacredit.com"
					target="_blank"
					rel="noopener noreferrer"
				>
					Nova Credit Help Center
				</Typography>
			</Typography>

			<Accordion
				id="marketplace-faq"
				items={items}
				heading="h3"
				onToggle={({ index }) =>
					tracker.track('nova.consumer_dashboard.MVM_FAQ_EXPANDED', {
						question: items[index].title,
					})
				}
			/>
		</div>
	);
};

export default FAQ;
