import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import { DesignSystemButton, Typography } from '@novacredit/pandorasbox';

import ContentTabsTask from 'components/common/ContentTabs/ContentTabsTask';
import { useStoredCountryContext } from 'contexts/StoredCountryContext';
import { tracker } from 'modules/tracking';

import 'components/common/ContentTabs/ContentTabsBody.scss';

export const CIRCLE_D = 48;
export const CIRCLE_R = CIRCLE_D / 2;

export const percentToAngle = (percent: number): number => (percent / 100) * 360;

export const degToRad = (deg: number): number => (deg * Math.PI) / 180;

export const getPointOnCircle = (percent: number) => {
	const angle = degToRad(percentToAngle(percent));
	const xFactor = Math.sin(angle);
	const yFactor = Math.cos(angle) * -1;

	return {
		x: xFactor * CIRCLE_R + 24,
		y: yFactor * CIRCLE_R + 24,
	};
};

export const drawArc = (percent: number): string => {
	const init = `M${CIRCLE_R} 0 L${CIRCLE_R} ${CIRCLE_R}`;
	const init2 = `M${CIRCLE_R} ${CIRCLE_D} L${CIRCLE_R} ${CIRCLE_R}`;
	const size = ` ${CIRCLE_R} ${CIRCLE_R}`;
	const flags1 = ' 0 0 0';
	const flags2 = ' 0 0 0';
	const end = ` ${CIRCLE_R} 0`;
	const end2 = ` ${CIRCLE_R} ${CIRCLE_D}`;

	const firstFifty = Math.min(percent, 50);
	const { x, y } = getPointOnCircle(firstFifty);
	const intersect1 = `${x} ${y}`;
	let pathDString = `${init} L${intersect1} A${size}${flags1}${end}`;

	if (percent > 50) {
		const { x: x2, y: y2 } = getPointOnCircle(percent - 50);
		const intersect2 = `${CIRCLE_R - (x2 - CIRCLE_R)} ${CIRCLE_R - (y2 - CIRCLE_R)}`;
		pathDString += ` ${init2} L${intersect2} A${size}${flags2}${end2}`;
	}

	return pathDString;
};

export const TaskCompletionMeter = ({ tasks }: { tasks: Object }) => {
	const taskValues = Object.values(tasks);
	const taskLen = taskValues.length;
	const totalDone = taskValues.reduce((total, task) => total + task, 0);
	const percentDone = Math.round((totalDone / taskLen) * 100);

	return (
		<div className="tasks-complete">
			<Typography
				tag="span"
				variant="Heading-4"
				variantMobile="Subtitle-2"
				className="tasks-complete_text"
			>
				{`${percentDone}% done`}
			</Typography>
			<svg
				className="tasks-complete_graphic"
				viewBox={`0 0 ${CIRCLE_D} ${CIRCLE_D}`}
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d={drawArc(percentDone)} />
				<circle className="svg-circle_outer" cx={CIRCLE_R} cy={CIRCLE_R} r={CIRCLE_R - 2} />
				<circle className="svg-circle_inner" cx={CIRCLE_R} cy={CIRCLE_R} r={CIRCLE_R - 6} />
			</svg>
		</div>
	);
};

export const TasksComplete = ({
	completedTabs,
	setActiveTab,
}: {
	completedTabs: Array<string>;
	setActiveTab: Function;
}) => {
	const completeRef = useRef(null);

	useEffect(() => {
		// fade in
		const element = completeRef.current;
		const elementStyle = window.getComputedStyle(element);
		const elHeight = elementStyle.height;

		element.classList.add('fade-in');

		const elDuration = parseFloat(elementStyle.transitionDuration) * 1000;

		window.setTimeout(() => {
			element.style.height = elHeight;
			window.setTimeout(() => {
				element.style.height = 'auto';
			}, elDuration);
		}, 1);
	}, []);

	let completeText = (
		<Typography tag="p" variant="Body-Regular" className="content-tab_complete-body">
			Feel free to continue with the{' '}
			<a
				href="#arrive"
				onClick={() => {
					setActiveTab('WHEN_ARRIVE');
				}}
			>
				When you arrive
			</a>{' '}
			or{' '}
			<a
				href="#after"
				onClick={() => {
					setActiveTab('AFTER_MOVE');
				}}
			>
				After your move
			</a>{' '}
			steps above.
		</Typography>
	);
	if (completedTabs.indexOf('AFTER_MOVE') > -1) {
		completeText = (
			<Typography tag="p" variant="Body-Regular" className="content-tab_complete-body">
				Share this resource with others in need.
			</Typography>
		);
	} else if (completedTabs.indexOf('WHEN_ARRIVE') > -1) {
		completeText = (
			<Typography tag="p" variant="Body-Regular" className="content-tab_complete-body">
				Feel free to continue with the{' '}
				<a
					href="#after"
					onClick={() => {
						setActiveTab('AFTER_MOVE');
					}}
				>
					After your move
				</a>{' '}
				steps above.
			</Typography>
		);
	}

	return (
		<div className="content-tab_complete" ref={completeRef}>
			<Typography tag="p" variant="Heading-4" className="content-tab_complete-heading">
				Congratulations!
				<br />
				You have completed all the recommended tasks!
			</Typography>
			{completeText}
		</div>
	);
};

const ContentTabsBody = ({
	id,
	title,
	tasks,
	isActive,
	completedTabs,
	onTasksChange,
	setActiveTab,
	showBookmarkGuidesPage,
	acknowledgeBookmarkTask,
}: {
	id: string;
	title: string;
	tasks: {
		taskId: string;
		taskTitle: string;
		taskDetail: string;
		taskImage?: string;
		taskLink: {
			text: string;
			src: string;
		};
	}[];
	isActive: boolean;
	completedTabs: Array<string>;
	onTasksChange: (tab: string, remove: boolean) => void;
	setActiveTab: Function;
	showBookmarkGuidesPage: boolean;
	acknowledgeBookmarkTask: Function;
}) => {
	const initialTasksState = {};
	const taskStorage = JSON.parse(localStorage.getItem('guideTasks'));
	tasks.forEach(task => {
		initialTasksState[task.taskId] = !!taskStorage[task.taskId];
	});

	const { storedCountry: currentCountry } = useStoredCountryContext();
	const [tasksComplete, setTasksComplete] = useState(initialTasksState);
	const [fromCountry, setFromCountry] = useState(currentCountry);

	const tabsBodyRef = useRef(null);
	const incompleteTasks = [];
	const completeTasks = [];

	const BookmarkNewcomerGuidePage = () => {
		const completeRef = useRef(null);

		useEffect(() => {
			// fade in
			const element = completeRef.current;
			const elementStyle = window.getComputedStyle(element);
			const elHeight = elementStyle.height;

			element.classList.add('fade-in');

			const elDuration = parseFloat(elementStyle.transitionDuration) * 1000;

			window.setTimeout(() => {
				element.style.height = elHeight;
				window.setTimeout(() => {
					element.style.height = 'auto';
				}, elDuration);
			}, 1);
		}, []);

		return (
			<div className="content-tab_complete" ref={completeRef}>
				<Typography tag="p" variant="Heading-4" className="content-tab_complete-heading">
					Awesome!
					<br />
				</Typography>
				<Typography tag="p" variant="Body-Regular" className="content-tab_complete-heading">
					Bookmark this page to continue your journey later.
					<br />
					Completed tasks are shown below.
				</Typography>
				<DesignSystemButton tag="button" onClick={() => acknowledgeBookmarkTask()}>
					Got it
				</DesignSystemButton>
			</div>
		);
	};

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		if (fromCountry && currentCountry !== fromCountry) {
			// reset all tasks to incomplete
			const updatedTasksComplete = Object.assign({}, tasksComplete);
			Object.keys(tasksComplete).forEach(taskId => {
				updatedTasksComplete[taskId] = false;
			});

			Object.keys(taskStorage).forEach(taskId => {
				taskStorage[taskId] = 0;
			});
			localStorage.setItem('guideTasks', JSON.stringify(taskStorage));
			setTasksComplete(updatedTasksComplete);
			setFromCountry(currentCountry);
		}
	}, [currentCountry, fromCountry]);
	/* eslint-enable react-hooks/exhaustive-deps */

	const onCheckboxChange = (taskId: string, complete: boolean) => {
		const updatedTasksComplete = Object.assign({}, tasksComplete);
		updatedTasksComplete[taskId] = complete;
		taskStorage[taskId] = complete ? 1 : 0;
		localStorage.setItem('guideTasks', JSON.stringify(taskStorage));

		const taskElement = tabsBodyRef.current.querySelector(`[data-taskname="${taskId}"]`);
		const elementHeight = getComputedStyle(taskElement).height;
		const duration = parseFloat(getComputedStyle(taskElement).transitionDuration) * 1000;

		taskElement.style.height = elementHeight;
		// we need a chance to set the style before adding the class
		window.setTimeout(() => {
			taskElement.classList.add('task-transition');
		}, 1);

		window.setTimeout(() => {
			tracker.track('nova.consumer_dashboard.GUIDE_TASK_TOGGLED', {
				taskName: taskId,
				completed: complete,
				taskStage: id,
			});
			setTasksComplete(updatedTasksComplete);
			const tasksIncomplete = Object.values(updatedTasksComplete).some(val => !val);
			onTasksChange(id, tasksIncomplete);
		}, duration);
	};

	tasks.forEach(task => {
		const isComplete = tasksComplete[task.taskId];
		const taskComponent = (
			<ContentTabsTask
				{...task}
				onTaskMarked={onCheckboxChange}
				isComplete={isComplete}
				key={`task-${task.taskId}`}
			/>
		);

		if (isComplete) {
			completeTasks.push(taskComponent);
		} else {
			incompleteTasks.push(taskComponent);
		}
	});

	let completeTitle = null;
	if (completeTasks.length) {
		completeTitle = (
			<Typography tag="p" variant="Subtitle-2">
				Completed tasks
			</Typography>
		);
	}

	return (
		<div
			className={classnames('content-tab_body', { 'is-active': isActive })}
			id={`content-tab-body-${id}`}
			role="tabpanel"
			ref={tabsBodyRef}
			data-id={id}
		>
			<div className="content-tab-body_header">
				<Typography tag="h2" variant="Heading-4" variantMobile="Subtitle-2">
					{title}
				</Typography>
				<TaskCompletionMeter tasks={tasksComplete} />
			</div>
			<div className="content-tab-body_main">
				<Typography tag="p" variant="Subtitle-2">
					Recommended tasks
				</Typography>
				{showBookmarkGuidesPage ? <BookmarkNewcomerGuidePage /> : null}
				{incompleteTasks}
				{!incompleteTasks.length ? (
					<TasksComplete completedTabs={completedTabs} setActiveTab={setActiveTab} />
				) : null}
				{completeTitle}
				{completeTasks}
			</div>
		</div>
	);
};

export default ContentTabsBody;
