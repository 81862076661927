import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { Typography, DesignSystemButton } from '@novacredit/pandorasbox';
import type { Channel } from '@novacredit/frontend-common/utils/social';
import { CHANNEL } from '@novacredit/frontend-common/utils/social';
import type { EventSchema } from '@novacredit/frontend-common/tracking';
import { useMount } from '@novacredit/frontend-common/hooks';

import { FACEBOOK_APP_ID, ENV } from 'consts';
import { getNodeEnv } from 'utils';
import { tracker } from 'modules/tracking';

import './ShareMenu.scss';
import iconFacebook from 'assets/images/social-icons/social-facebook.svg';
import iconTwitter from 'assets/images/social-icons/social-twitter.svg';
import iconWhatsapp from 'assets/images/social-icons/social-whatsapp.svg';
import iconLinkedin from 'assets/images/social-icons/social-linkedin.svg';
import iconEmail from 'assets/images/social-icons/social-email.svg';
import iconCopyLink from 'assets/images/social-icons/social-link.svg';
import iconShare from 'assets/images/social-icons/social-share.svg';

type ChannelInfo = {
	channel: Channel;
	shareText?: string;
};

const SHARE_MENU_CHANNELS = {};
SHARE_MENU_CHANNELS[CHANNEL.FACEBOOK] = {
	icon: iconFacebook,
	getShareURL: ({ encodedLink }) =>
		`https://www.facebook.com/dialog/share?app_id=${FACEBOOK_APP_ID}&href=${encodedLink}`,
};
SHARE_MENU_CHANNELS[CHANNEL.TWITTER] = {
	icon: iconTwitter,
	getShareURL: ({ encodedLink, encodedShareText }) =>
		`https://www.twitter.com/share?url=${encodedLink}&text=${encodedShareText}`,
};
SHARE_MENU_CHANNELS[CHANNEL.LINKEDIN] = {
	icon: iconLinkedin,
	getShareURL: ({ encodedLink }) =>
		`https://www.linkedin.com/shareArticle?mini=true&url=${encodedLink}`,
};
SHARE_MENU_CHANNELS[CHANNEL.EMAIL] = {
	icon: iconEmail,
	getShareURL: ({ emailSubject, emailBody }) =>
		`mailto:?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`,
};
SHARE_MENU_CHANNELS[CHANNEL.WHATSAPP] = {
	icon: iconWhatsapp,
	getShareURL: ({ encodedLink, encodedShareText }) =>
		`https://wa.me/?text=${encodedShareText}${encodedLink}`,
};
SHARE_MENU_CHANNELS[CHANNEL.COPYLINK] = {
	icon: iconCopyLink,
	getShareURL: ({ encodedLink }) => encodedLink,
};

export const openInPopup = ({
	encodedLink,
	shareText,
	emailSubject,
	emailBody,
	content,
	channel,
	callback,
}: {
	encodedLink: string;
	shareText?: string;
	emailSubject?: string;
	emailBody?: string;
	content: EventSchema['nova.consumer_dashboard.CONTENT_SHARED']['content'];
	channel: Channel;
	callback?: Function;
}) => {
	tracker.track('nova.consumer_dashboard.CONTENT_SHARED', { channel, content });

	if (channel === CHANNEL.COPYLINK) {
		try {
			navigator.clipboard.writeText(decodeURIComponent(encodedLink));
		} catch (err) {
			if (getNodeEnv() !== ENV.production) {
				// eslint-disable-next-line no-console
				console.warn(err);
			}
		}
	} else {
		const encodedShareText = encodeURIComponent(shareText);
		const url = SHARE_MENU_CHANNELS[channel].getShareURL({
			encodedLink,
			encodedShareText,
			emailSubject,
			emailBody,
		});

		window.open(
			url,
			'shareWindow',
			`height=450, width=550, top=${window.innerHeight / 2 - 275}, left=${
				window.innerWidth / 2 - 225
			}, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`
		);
	}

	return typeof callback === 'function' ? callback(channel) : false;
};

export const ShareMenu = ({
	className,
	link,
	emailSubject,
	emailBody,
	content,
	display = 'column',
	inline = false,
	channels = [
		{ channel: 'WhatsApp' },
		{ channel: 'Facebook' },
		{ channel: 'Twitter' },
		{ channel: 'LinkedIn' },
		{ channel: 'Email' },
		{ channel: 'Copy link' },
	],
}: {
	className?: string;
	link: string;
	inline?: boolean;
	emailSubject?: string;
	emailBody?: string;
	content: EventSchema['nova.consumer_dashboard.CONTENT_SHARED']['content'];
	display: 'row' | 'column';
	channels?: ChannelInfo[];
}) => {
	const menuRef = useRef(null);
	const [menuShown, setMenuShown] = useState(false);
	const [copyConfirmShown, setCopyConfirmShown] = useState(false);
	const encodedLink = encodeURIComponent(link);

	const closeMenu = (event: any) => {
		if (event.target.closest('.share-component') === menuRef.current) {
			return false;
		}
		window.removeEventListener('click', closeMenu);
		return setMenuShown(false);
	};
	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		if (menuShown) {
			window.addEventListener('click', closeMenu);
		} else {
			window.removeEventListener('click', closeMenu);
		}
	}, [menuShown]);

	useMount(
		() =>
			function cleanup() {
				window.removeEventListener('click', closeMenu);
			}
	);
	/* eslint-enable react-hooks/exhaustive-deps */
	const toggleShareMenu = (channel: any) => {
		setMenuShown(!menuShown);
		if (channel === 'Copy link') {
			setCopyConfirmShown(true);
		}
	};

	const removeConfirm = () => {
		setCopyConfirmShown(false);
	};

	const CopyConfirm = () => (
		<div className="copy-confirmed" onAnimationEnd={removeConfirm}>
			<Typography tag="p" variant="Body-Regular" className="copy-confirmed_text">
				Copied to clipboard
			</Typography>
		</div>
	);

	return (
		<div className="share-component" ref={menuRef}>
			<div className="share-cta">
				{inline && (
					<Typography tag="p" variant="Body-Regular" className="mt-3 mb-0">
						Share
					</Typography>
				)}
				{!inline && (
					<DesignSystemButton
						tag="button"
						variant="ghost"
						className="share-cta_button"
						onClick={toggleShareMenu}
					>
						<img className="share-icon" src={iconShare} alt="" />
						<Typography
							className="share-cta_button-span"
							tag="span"
							variant="Body-Regular"
						>
							Share
						</Typography>
					</DesignSystemButton>
				)}
			</div>
			<div
				className={classnames(`share-menu display-${display}`, className, {
					'is-active': menuShown || inline,
					inline,
				})}
			>
				{channels.map(({ channel, shareText }) => (
					<Typography
						tag="button"
						variant="Body-Regular"
						key={channel}
						type="button"
						className={`share-button share-button_${channel
							.toLowerCase()
							.replace(/\s/g, '-')} display-${display}`}
						onClick={() =>
							openInPopup({
								encodedLink,
								emailSubject,
								emailBody,
								channel,
								content,
								shareText,
								callback: toggleShareMenu,
							})
						}
					>
						<img
							className="share-icon"
							src={SHARE_MENU_CHANNELS[channel].icon}
							alt=""
						/>
						<span className="share-channel">{channel}</span>
					</Typography>
				))}
			</div>
			{copyConfirmShown ? <CopyConfirm /> : null}
		</div>
	);
};

export default ShareMenu;
