"use strict";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.COUNTRY_LIST = exports.COUNTRY_MAP = void 0;
exports.COUNTRY_MAP = {
  ABW: {
    name: 'Aruba',
    alpha2: 'AW'
  },
  AFG: {
    name: 'Afghanistan',
    alpha2: 'AF'
  },
  AGO: {
    name: 'Angola',
    alpha2: 'AO'
  },
  AIA: {
    name: 'Anguilla',
    alpha2: 'AI'
  },
  ALA: {
    name: 'Aland Islands',
    alpha2: 'AX'
  },
  ALB: {
    name: 'Albania',
    alpha2: 'AL'
  },
  AND: {
    name: 'Andorra',
    alpha2: 'AD'
  },
  ARE: {
    name: 'United Arab Emirates',
    alpha2: 'AE'
  },
  ARG: {
    name: 'Argentina',
    alpha2: 'AR'
  },
  ARM: {
    name: 'Armenia',
    alpha2: 'AM'
  },
  ASM: {
    name: 'American Samoa',
    alpha2: 'AS'
  },
  ATF: {
    name: 'French Southern Territories',
    alpha2: 'TF'
  },
  ATG: {
    name: 'Antigua and Barbuda',
    alpha2: 'AG'
  },
  AUS: {
    name: 'Australia',
    alpha2: 'AU'
  },
  AUT: {
    name: 'Austria',
    alpha2: 'AT'
  },
  AZE: {
    name: 'Azerbaijan',
    alpha2: 'AZ'
  },
  BDI: {
    name: 'Burundi',
    alpha2: 'BI'
  },
  BEL: {
    name: 'Belgium',
    alpha2: 'BE'
  },
  BEN: {
    name: 'Benin',
    alpha2: 'BJ'
  },
  BES: {
    name: 'Bonaire, Sint Eustatius and Saba',
    alpha2: 'BQ'
  },
  BFA: {
    name: 'Burkina Faso',
    alpha2: 'BF'
  },
  BGD: {
    name: 'Bangladesh',
    alpha2: 'BD'
  },
  BGR: {
    name: 'Bulgaria',
    alpha2: 'BG'
  },
  BHR: {
    name: 'Bahrain',
    alpha2: 'BH'
  },
  BHS: {
    name: 'Bahamas',
    alpha2: 'BS'
  },
  BIH: {
    name: 'Bosnia and Herzegovina',
    alpha2: 'BA'
  },
  BLM: {
    name: 'Saint-Barthélemy',
    alpha2: 'BL'
  },
  BLR: {
    name: 'Belarus',
    alpha2: 'BY'
  },
  BLZ: {
    name: 'Belize',
    alpha2: 'BZ'
  },
  BMU: {
    name: 'Bermuda',
    alpha2: 'BM'
  },
  BOL: {
    name: 'Bolivia',
    alpha2: 'BO'
  },
  BRA: {
    name: 'Brazil',
    alpha2: 'BR'
  },
  BRB: {
    name: 'Barbados',
    alpha2: 'BB'
  },
  BRN: {
    name: 'Brunei Darussalam',
    alpha2: 'BN'
  },
  BTN: {
    name: 'Bhutan',
    alpha2: 'BT'
  },
  BVT: {
    name: 'Bouvet Island',
    alpha2: 'BV'
  },
  BWA: {
    name: 'Botswana',
    alpha2: 'BW'
  },
  CAF: {
    name: 'Central African Republic',
    alpha2: 'CF'
  },
  CAN: {
    name: 'Canada',
    alpha2: 'CA'
  },
  CCK: {
    name: 'Cocos (Keeling) Islands',
    alpha2: 'CC'
  },
  CHE: {
    name: 'Switzerland',
    alpha2: 'CH'
  },
  CHL: {
    name: 'Chile',
    alpha2: 'CL'
  },
  CHN: {
    name: 'China',
    alpha2: 'CN'
  },
  CIV: {
    name: "Côte d'Ivoire",
    alpha2: 'CI'
  },
  CMR: {
    name: 'Cameroon',
    alpha2: 'CM'
  },
  COD: {
    name: 'Congo',
    alpha2: 'CD'
  },
  COG: {
    name: 'Congo',
    alpha2: 'CG'
  },
  COK: {
    name: 'Cook Islands',
    alpha2: 'CK'
  },
  COL: {
    name: 'Colombia',
    alpha2: 'CO'
  },
  COM: {
    name: 'Comoros',
    alpha2: 'KM'
  },
  CPV: {
    name: 'Cape Verde',
    alpha2: 'CV'
  },
  CRI: {
    name: 'Costa Rica',
    alpha2: 'CR'
  },
  CUB: {
    name: 'Cuba',
    alpha2: 'CU'
  },
  CUW: {
    name: 'Curaçao',
    alpha2: 'CW'
  },
  CXR: {
    name: 'Christmas Island',
    alpha2: 'CX'
  },
  CYM: {
    name: 'Cayman Islands',
    alpha2: 'KY'
  },
  CYP: {
    name: 'Cyprus',
    alpha2: 'CY'
  },
  CZE: {
    name: 'Czech Republic',
    alpha2: 'CZ'
  },
  DEU: {
    name: 'Germany',
    alpha2: 'DE'
  },
  DJI: {
    name: 'Djibouti',
    alpha2: 'DJ'
  },
  DMA: {
    name: 'Dominica',
    alpha2: 'DM'
  },
  DNK: {
    name: 'Denmark',
    alpha2: 'DK'
  },
  DOM: {
    name: 'Dominican Republic',
    alpha2: 'DO'
  },
  DZA: {
    name: 'Algeria',
    alpha2: 'DZ'
  },
  ECU: {
    name: 'Ecuador',
    alpha2: 'EC'
  },
  EGY: {
    name: 'Egypt',
    alpha2: 'EG'
  },
  ERI: {
    name: 'Eritrea',
    alpha2: 'ER'
  },
  ESH: {
    name: 'Western Sahara',
    alpha2: 'EH'
  },
  ESP: {
    name: 'Spain',
    alpha2: 'ES'
  },
  EST: {
    name: 'Estonia',
    alpha2: 'EE'
  },
  ETH: {
    name: 'Ethiopia',
    alpha2: 'ET'
  },
  FIN: {
    name: 'Finland',
    alpha2: 'FI'
  },
  FJI: {
    name: 'Fiji',
    alpha2: 'FJ'
  },
  FLK: {
    name: 'Falkland Islands (Malvinas)',
    alpha2: 'FK'
  },
  FRA: {
    name: 'France',
    alpha2: 'FR'
  },
  FRO: {
    name: 'Faroe Islands',
    alpha2: 'FO'
  },
  FSM: {
    name: 'Micronesia, Federated States of',
    alpha2: 'FM'
  },
  GAB: {
    name: 'Gabon',
    alpha2: 'GA'
  },
  GBR: {
    name: 'United Kingdom',
    alpha2: 'GB'
  },
  GEO: {
    name: 'Georgia',
    alpha2: 'GE'
  },
  GGY: {
    name: 'Guernsey',
    alpha2: 'GG'
  },
  GHA: {
    name: 'Ghana',
    alpha2: 'GH'
  },
  GIB: {
    name: 'Gibraltar',
    alpha2: 'GI'
  },
  GIN: {
    name: 'Guinea',
    alpha2: 'GN'
  },
  GLP: {
    name: 'Guadeloupe',
    alpha2: 'GP'
  },
  GMB: {
    name: 'Gambia',
    alpha2: 'GM'
  },
  GNB: {
    name: 'Guinea-Bissau',
    alpha2: 'GW'
  },
  GNQ: {
    name: 'Equatorial Guinea',
    alpha2: 'GQ'
  },
  GRC: {
    name: 'Greece',
    alpha2: 'GR'
  },
  GRD: {
    name: 'Grenada',
    alpha2: 'GD'
  },
  GRL: {
    name: 'Greenland',
    alpha2: 'GL'
  },
  GTM: {
    name: 'Guatemala',
    alpha2: 'GT'
  },
  GUF: {
    name: 'French Guiana',
    alpha2: 'GF'
  },
  GUM: {
    name: 'Guam',
    alpha2: 'GU'
  },
  GUY: {
    name: 'Guyana',
    alpha2: 'GY'
  },
  HKG: {
    name: 'Hong Kong',
    alpha2: 'HK'
  },
  HMD: {
    name: 'Heard Island and Mcdonald Islands',
    alpha2: 'HM'
  },
  HND: {
    name: 'Honduras',
    alpha2: 'HN'
  },
  HRV: {
    name: 'Croatia',
    alpha2: 'HR'
  },
  HTI: {
    name: 'Haiti',
    alpha2: 'HT'
  },
  HUN: {
    name: 'Hungary',
    alpha2: 'HU'
  },
  IDN: {
    name: 'Indonesia',
    alpha2: 'ID'
  },
  IMN: {
    name: 'Isle of Man',
    alpha2: 'IM'
  },
  IND: {
    name: 'India',
    alpha2: 'IN'
  },
  IOT: {
    name: 'British Indian Ocean Territory',
    alpha2: 'IO'
  },
  IRL: {
    name: 'Ireland',
    alpha2: 'IE'
  },
  IRN: {
    name: 'Iran, Islamic Republic of',
    alpha2: 'IR'
  },
  IRQ: {
    name: 'Iraq',
    alpha2: 'IQ'
  },
  ISL: {
    name: 'Iceland',
    alpha2: 'IS'
  },
  ISR: {
    name: 'Israel',
    alpha2: 'IL'
  },
  ITA: {
    name: 'Italy',
    alpha2: 'IT'
  },
  JAM: {
    name: 'Jamaica',
    alpha2: 'JM'
  },
  JEY: {
    name: 'Jersey',
    alpha2: 'JE'
  },
  JOR: {
    name: 'Jordan',
    alpha2: 'JO'
  },
  JPN: {
    name: 'Japan',
    alpha2: 'JP'
  },
  KAZ: {
    name: 'Kazakhstan',
    alpha2: 'KZ'
  },
  KEN: {
    name: 'Kenya',
    alpha2: 'KE'
  },
  KGZ: {
    name: 'Kyrgyzstan',
    alpha2: 'KG'
  },
  KHM: {
    name: 'Cambodia',
    alpha2: 'KH'
  },
  KIR: {
    name: 'Kiribati',
    alpha2: 'KI'
  },
  KNA: {
    name: 'Saint Kitts and Nevis',
    alpha2: 'KN'
  },
  KOR: {
    name: 'Korea, Republic of',
    alpha2: 'KR'
  },
  KWT: {
    name: 'Kuwait',
    alpha2: 'KW'
  },
  LAO: {
    name: 'Lao PDR',
    alpha2: 'LA'
  },
  LBN: {
    name: 'Lebanon',
    alpha2: 'LB'
  },
  LBR: {
    name: 'Liberia',
    alpha2: 'LR'
  },
  LBY: {
    name: 'Libya',
    alpha2: 'LY'
  },
  LCA: {
    name: 'Saint Lucia',
    alpha2: 'LC'
  },
  LIE: {
    name: 'Liechtenstein',
    alpha2: 'LI'
  },
  LKA: {
    name: 'Sri Lanka',
    alpha2: 'LK'
  },
  LSO: {
    name: 'Lesotho',
    alpha2: 'LS'
  },
  LTU: {
    name: 'Lithuania',
    alpha2: 'LT'
  },
  LUX: {
    name: 'Luxembourg',
    alpha2: 'LU'
  },
  LVA: {
    name: 'Latvia',
    alpha2: 'LV'
  },
  MAC: {
    name: 'Macao',
    alpha2: 'MO'
  },
  MAF: {
    name: 'Saint-Martin (French part)',
    alpha2: 'MF'
  },
  MAR: {
    name: 'Morocco',
    alpha2: 'MA'
  },
  MCO: {
    name: 'Monaco',
    alpha2: 'MC'
  },
  MDA: {
    name: 'Moldova',
    alpha2: 'MD'
  },
  MDG: {
    name: 'Madagascar',
    alpha2: 'MG'
  },
  MDV: {
    name: 'Maldives',
    alpha2: 'MV'
  },
  MEX: {
    name: 'Mexico',
    alpha2: 'MX'
  },
  MHL: {
    name: 'Marshall Islands',
    alpha2: 'MH'
  },
  MKD: {
    name: 'Macedonia, Republic of',
    alpha2: 'MK'
  },
  MLI: {
    name: 'Mali',
    alpha2: 'ML'
  },
  MLT: {
    name: 'Malta',
    alpha2: 'MT'
  },
  MMR: {
    name: 'Myanmar',
    alpha2: 'MM'
  },
  MNE: {
    name: 'Montenegro',
    alpha2: 'ME'
  },
  MNG: {
    name: 'Mongolia',
    alpha2: 'MN'
  },
  MNP: {
    name: 'Northern Mariana Islands',
    alpha2: 'MP'
  },
  MOZ: {
    name: 'Mozambique',
    alpha2: 'MZ'
  },
  MRT: {
    name: 'Mauritania',
    alpha2: 'MR'
  },
  MSR: {
    name: 'Montserrat',
    alpha2: 'MS'
  },
  MTQ: {
    name: 'Martinique',
    alpha2: 'MQ'
  },
  MUS: {
    name: 'Mauritius',
    alpha2: 'MU'
  },
  MWI: {
    name: 'Malawi',
    alpha2: 'MW'
  },
  MYS: {
    name: 'Malaysia',
    alpha2: 'MY'
  },
  MYT: {
    name: 'Mayotte',
    alpha2: 'YT'
  },
  NAM: {
    name: 'Namibia',
    alpha2: 'NA'
  },
  NCL: {
    name: 'New Caledonia',
    alpha2: 'NC'
  },
  NER: {
    name: 'Niger',
    alpha2: 'NE'
  },
  NFK: {
    name: 'Norfolk Island',
    alpha2: 'NF'
  },
  NGA: {
    name: 'Nigeria',
    alpha2: 'NG'
  },
  NIC: {
    name: 'Nicaragua',
    alpha2: 'NI'
  },
  NIU: {
    name: 'Niue',
    alpha2: 'NU'
  },
  NLD: {
    name: 'Netherlands',
    alpha2: 'NL'
  },
  NOR: {
    name: 'Norway',
    alpha2: 'NO'
  },
  NPL: {
    name: 'Nepal',
    alpha2: 'NP'
  },
  NRU: {
    name: 'Nauru',
    alpha2: 'NR'
  },
  NZL: {
    name: 'New Zealand',
    alpha2: 'NZ'
  },
  OMN: {
    name: 'Oman',
    alpha2: 'OM'
  },
  PAK: {
    name: 'Pakistan',
    alpha2: 'PK'
  },
  PAN: {
    name: 'Panama',
    alpha2: 'PA'
  },
  PCN: {
    name: 'Pitcairn',
    alpha2: 'PN'
  },
  PER: {
    name: 'Peru',
    alpha2: 'PE'
  },
  PHL: {
    name: 'Philippines',
    alpha2: 'PH'
  },
  PLW: {
    name: 'Palau',
    alpha2: 'PW'
  },
  PNG: {
    name: 'Papua New Guinea',
    alpha2: 'PG'
  },
  POL: {
    name: 'Poland',
    alpha2: 'PL'
  },
  PRI: {
    name: 'Puerto Rico',
    alpha2: 'PR'
  },
  PRK: {
    name: "Korea, Democratic People's Republic of",
    alpha2: 'KP'
  },
  PRT: {
    name: 'Portugal',
    alpha2: 'PT'
  },
  PRY: {
    name: 'Paraguay',
    alpha2: 'PY'
  },
  PSE: {
    name: 'Palestinian Territory',
    alpha2: 'PS'
  },
  PYF: {
    name: 'French Polynesia',
    alpha2: 'PF'
  },
  QAT: {
    name: 'Qatar',
    alpha2: 'QA'
  },
  REU: {
    name: 'Réunion',
    alpha2: 'RE'
  },
  ROU: {
    name: 'Romania',
    alpha2: 'RO'
  },
  RUS: {
    name: 'Russian Federation',
    alpha2: 'RU'
  },
  RWA: {
    name: 'Rwanda',
    alpha2: 'RW'
  },
  SAU: {
    name: 'Saudi Arabia',
    alpha2: 'SA'
  },
  SDN: {
    name: 'Sudan',
    alpha2: 'SD'
  },
  SEN: {
    name: 'Senegal',
    alpha2: 'SN'
  },
  SGP: {
    name: 'Singapore',
    alpha2: 'SG'
  },
  SGS: {
    name: 'South Georgia and the South Sandwich Islands',
    alpha2: 'GS'
  },
  SHN: {
    name: 'Saint Helena',
    alpha2: 'SH'
  },
  SJM: {
    name: 'Svalbard and Jan Mayen Islands',
    alpha2: 'SJ'
  },
  SLB: {
    name: 'Solomon Islands',
    alpha2: 'SB'
  },
  SLE: {
    name: 'Sierra Leone',
    alpha2: 'SL'
  },
  SLV: {
    name: 'El Salvador',
    alpha2: 'SV'
  },
  SMR: {
    name: 'San Marino',
    alpha2: 'SM'
  },
  SOM: {
    name: 'Somalia',
    alpha2: 'SO'
  },
  SPM: {
    name: 'Saint Pierre and Miquelon',
    alpha2: 'PM'
  },
  SRB: {
    name: 'Serbia',
    alpha2: 'RS'
  },
  SSD: {
    name: 'South Sudan',
    alpha2: 'SS'
  },
  STP: {
    name: 'Sao Tome and Principe',
    alpha2: 'ST'
  },
  SUR: {
    name: 'Suriname',
    alpha2: 'SR'
  },
  SVK: {
    name: 'Slovakia',
    alpha2: 'SK'
  },
  SVN: {
    name: 'Slovenia',
    alpha2: 'SI'
  },
  SWE: {
    name: 'Sweden',
    alpha2: 'SE'
  },
  SWZ: {
    name: 'Swaziland',
    alpha2: 'SZ'
  },
  SXM: {
    name: 'Sint Maarten (Dutch part)',
    alpha2: 'SX'
  },
  SYC: {
    name: 'Seychelles',
    alpha2: 'SC'
  },
  SYR: {
    name: 'Syrian Arab Republic',
    alpha2: 'SY'
  },
  TCA: {
    name: 'Turks and Caicos Islands',
    alpha2: 'TC'
  },
  TCD: {
    name: 'Chad',
    alpha2: 'TD'
  },
  TGO: {
    name: 'Togo',
    alpha2: 'TG'
  },
  THA: {
    name: 'Thailand',
    alpha2: 'TH'
  },
  TJK: {
    name: 'Tajikistan',
    alpha2: 'TJ'
  },
  TKL: {
    name: 'Tokelau',
    alpha2: 'TK'
  },
  TKM: {
    name: 'Turkmenistan',
    alpha2: 'TM'
  },
  TLS: {
    name: 'Timor-Leste',
    alpha2: 'TL'
  },
  TON: {
    name: 'Tonga',
    alpha2: 'TO'
  },
  TTO: {
    name: 'Trinidad and Tobago',
    alpha2: 'TT'
  },
  TUN: {
    name: 'Tunisia',
    alpha2: 'TN'
  },
  TUR: {
    name: 'Turkey',
    alpha2: 'TR'
  },
  TUV: {
    name: 'Tuvalu',
    alpha2: 'TV'
  },
  TWN: {
    name: 'Taiwan',
    alpha2: 'TW'
  },
  TZA: {
    name: 'Tanzania',
    alpha2: 'TZ'
  },
  UGA: {
    name: 'Uganda',
    alpha2: 'UG'
  },
  UKR: {
    name: 'Ukraine',
    alpha2: 'UA'
  },
  UMI: {
    name: 'United States Minor Outlying Islands',
    alpha2: 'UM'
  },
  URY: {
    name: 'Uruguay',
    alpha2: 'UY'
  },
  USA: {
    name: 'United States of America',
    alpha2: 'US'
  },
  UZB: {
    name: 'Uzbekistan',
    alpha2: 'UZ'
  },
  VAT: {
    name: 'Holy See',
    alpha2: 'VA'
  },
  VCT: {
    name: 'Saint Vincent and Grenadines',
    alpha2: 'VC'
  },
  VEN: {
    name: 'Venezuela',
    alpha2: 'VE'
  },
  VGB: {
    name: 'British Virgin Islands',
    alpha2: 'VG'
  },
  VIR: {
    name: 'Virgin Islands, US',
    alpha2: 'VI'
  },
  VNM: {
    name: 'Vietnam',
    alpha2: 'VN'
  },
  VUT: {
    name: 'Vanuatu',
    alpha2: 'VU'
  },
  WLF: {
    name: 'Wallis and Futuna Islands',
    alpha2: 'WF'
  },
  WSM: {
    name: 'Samoa',
    alpha2: 'WS'
  },
  YEM: {
    name: 'Yemen',
    alpha2: 'YE'
  },
  ZAF: {
    name: 'South Africa',
    alpha2: 'ZA'
  },
  ZMB: {
    name: 'Zambia',
    alpha2: 'ZM'
  },
  ZWE: {
    name: 'Zimbabwe',
    alpha2: 'ZW'
  }
};
exports.COUNTRY_LIST = Object.entries(exports.COUNTRY_MAP).map(function (_ref) {
  var _ref2 = _slicedToArray(_ref, 2),
      alpha3 = _ref2[0],
      _ref2$ = _ref2[1],
      name = _ref2$.name,
      alpha2 = _ref2$.alpha2;

  return {
    alpha3: alpha3,
    alpha2: alpha2,
    name: name
  };
});
exports["default"] = {
  COUNTRY_LIST: exports.COUNTRY_LIST,
  COUNTRY_MAP: exports.COUNTRY_MAP
};