import type React from 'react';

import { isLinkInternal } from '../utils/links';

type CallbackResult = void | void[];

/**
 * A decorator that will retry the provided function at a set interval
 * until either the function successfully resolves or an overall time
 * limit is reached.
 *
 * @template T the return type of retryFn function
 * @param retryFn an async function
 * @param timeoutAfter ms to wait before giving up entirely
 * @param retryInterval ms to wait in between each retry
 * @returns {Promise<T>} resolves with return value of retryFn, rejects with last error value
 */
export const retryWithTimeout = <T>(
	retryFn: () => Promise<T>,
	timeoutAfter: number,
	retryInterval: number,
): Promise<T> => {
	let timeout: number;
	let interval: number;
	let error: Error;

	const timeoutPromise = new Promise<T>((resolve, reject) => {
		timeout = window.setTimeout(() => {
			clearTimeout(timeout);
			clearInterval(interval);
			reject(error);
		}, timeoutAfter);
	});

	const intervalPromise = new Promise<T>(resolve => {
		interval = window.setInterval(() => {
			retryFn()
				.then(result => {
					clearInterval(interval);
					clearTimeout(timeout);
					resolve(result);
				})
				.catch(err => {
					error = err;
				});
		}, retryInterval);
	});

	return Promise.race([timeoutPromise, intervalPromise]);
};

/**
 * Takes a list of promises and can:
 *
 * a) resolve with all results
 * b) reject if any one of the promises rejects
 * c) reject if the time limit specified by timeoutIn is reached first
 *
 * @template T
 * @param {Promise<T>[]} promises
 * @param {number} timeoutIn
 * @returns {Promise<T[]>} the resolution values of the provided promises
 */
export const promiseAllWithTimeout = <T>(
	promises: Promise<T>[],
	timeoutIn: number,
): Promise<T[]> => {
	return new Promise<T[]>((resolve, reject) => {
		// Prep the error outside the timeout so we get a helpful stacktrace.
		const timeoutError = new Error('Timed Out');

		setTimeout(() => {
			reject(timeoutError);
		}, timeoutIn);

		Promise.all(promises).then(resolve).catch(reject);
	});
};

/**
 * Like `promiseAllWithTimeout`, this resolves when all provided promises resolve.
 * Unlike `promiseAllWithTimeout`, any rejections will be automatically caught,
 * and it does not pass back the resolved values.
 *
 * @template T
 * @param {Promise<T>[]} promises
 * @param {number} timeoutIn
 * @returns {Promise<void>} a promise with no
 */
export const resolveAllWithTimeout = <T>(
	promises: Promise<T>[],
	timeoutIn: number,
): Promise<void> => {
	return promiseAllWithTimeout(promises, timeoutIn)
		.catch(() => {
			/* no-op */
		})
		.then();
};

// Replaces the use of `@segment/is-meta`.
//
// Export only for testing.
export const wouldOpenInNewWindow = ({
	evt,
	navigate,
	target,
}: {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	evt: any;
	navigate?: (href: string) => void;
	target?: HTMLLinkElement;
}): boolean => {
	if (!navigate && target && target.target === '_blank') {
		return true;
	}

	if (evt.metaKey || evt.altKey || evt.ctrlKey || evt.shiftKey) {
		return true;
	}

	const { button, which } = evt;

	// Favor `event.button` over the deprecated `event.which`.
	if (button) {
		// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
		return button === 1;
		// Legacy support.
	} else if (which) {
		// https://developer.mozilla.org/en-US/docs/Web/API/UIEvent/which
		return which === 2;
	}

	return false;
};

/**
 * Helper method that returns an onClick handler for internal <Link> links
 * or external <a> links where the redirect is only executed after the
 * callbacks have resolved.
 *
 * Inspired by Segment's analytics.trackLink
 *
 * @param object.navigate a function that will execute internal navigation (e.g. Reach Router navigate)
 * @param object.callbacks  one or more functions that return a promise.
 */
export const getRedirectAfterClickHandler =
	({
		navigate,
		callbacks,
	}: {
		navigate?: (href: string) => void;
		callbacks: (() => Promise<CallbackResult>) | (() => Promise<CallbackResult>)[];
	}): ((evt: Event | React.MouseEvent<HTMLAnchorElement>) => void) =>
	(evt: Event | React.MouseEvent<HTMLAnchorElement>) => {
		// Can be called with either an array of callbacks or just one callback
		const callbackArray = Array.isArray(callbacks) ? callbacks : [callbacks];

		// Execute all of the callbacks
		const promises = callbackArray.map(callback => callback());

		// Use `evt.currentTarget` since that's the element that the click handler was actually
		// bound to. If there is an image within the anchor tag and the user clicks on the image,
		// `evt.target.tagName === 'IMG'` while `evt.currentTarget.tagName === 'A'`.
		const target = evt.currentTarget as HTMLLinkElement;
		const href = target.getAttribute('href') as string;

		const shouldNavigate = href && !wouldOpenInNewWindow({ evt, navigate, target });

		if (shouldNavigate) {
			evt.preventDefault();
		}

		// don't wait for promises to resolve before navigating internally
		const isInternal = isLinkInternal(href);
		const promisesToAwait = isInternal ? [] : promises;
		Promise.all(promisesToAwait).finally(() => {
			if (shouldNavigate) {
				if (navigate && isInternal) {
					navigate(href);
				} else {
					window.location.href = href;
				}
			}
		});
	};

export default {
	getRedirectAfterClickHandler,
	promiseAllWithTimeout,
	retryWithTimeout,
};
