import type { EventSchema } from './eventSchema';

export type TrackingEventName = keyof EventSchema;

/**
 * Lists allowed product analytics tracking events.
 *
 * Event properties can be typechecked by defining  a `properties` function.
 * These properties should either be a more specific typing of `EventProperties`
 * or `null`.
 */
const EVENTS: Record<TrackingEventName, { displayName: string }> = {
	/* meta */

	'nova.meta.IDENTIFY': {
		displayName: 'Identify',
	},
	'nova.meta.ALIAS': {
		displayName: 'Alias',
	},

	/* client */

	'nova.client.PAGE_VIEWED': {
		// Named for backwards compatibility with what Segment
		// calls this event, but we could standardize in the future
		// as `Page Viewed`, which uses the object-action framework.
		displayName: 'Loaded a Page',
	},
	'nova.client.CARDSHOP_VISITED': {
		displayName: 'Visited Card Shop Page',
	},
	'nova.client.FAQ_EXPANDED': {
		displayName: 'FAQ Question Expanded',
	},
	'nova.client.CREDIT_CARD_APPLY_CLICKED': {
		displayName: 'Clicked Credit Card Apply',
	},
	'nova.client.COUNTRY_SELECTED': {
		displayName: 'Country Selected',
	},
	'nova.client.COOKIE_CONSENT_DISPLAYED': {
		displayName: 'Cookie Consent Displayed',
	},
	'nova.client.COOKIE_CONSENT_READY': {
		displayName: 'Cookie Consent Ready',
	},
	'nova.client.DIRECT_TO_CONSUMER_VISITED': {
		displayName: 'Visited D2C',
	},
	'nova.client.EXPERIMENT_STARTED': {
		displayName: '$experiment_started',
	},
	'nova.client.PURCHASE': {
		displayName: 'Purchase',
	},
	'nova.client.REDIRECTED_TO_CUSTOMER': {
		displayName: 'Redirected to Customer',
	},
	'nova.client.EMAIL_SUBSCRIBED': {
		displayName: 'Email Subscribed',
	},
	'nova.client.VIDEO_PLAYED': {
		displayName: 'Video Played',
	},
	'nova.client.VIDEO_PAUSED': {
		displayName: 'Video Paused',
	},
	'nova.client.VIDEO_ENDED': {
		displayName: 'Video Ended',
	},
	'nova.client.VIDEO_SEEKED': {
		displayName: 'Video Seeked',
	},
	// Useful for single page applications where the server may never actually
	// return a 404 error but instead it is the client that is handling the
	// routing and showing a 404 page.
	'nova.client.PAGE_NOT_FOUND': {
		displayName: 'Page Not Found',
	},

	/* consumer dashboard */

	'nova.consumer_dashboard.ACCOUNT_CREATED': {
		displayName: 'Signed Up',
	},
	'nova.consumer_dashboard.ACCOUNT_LOGGED_INTO': {
		displayName: 'Logged In',
	},
	'nova.consumer_dashboard.ACCOUNT_LOGGED_OUT_OF': {
		displayName: 'Logged Out',
	},
	'nova.consumer_dashboard.ACCOUNT_DEACTIVATED': {
		displayName: 'Deactivated Consumer Account',
	},
	'nova.consumer_dashboard.AMEX_REDIRECTED_TO': {
		displayName: 'Redirected to Amex',
	},
	'nova.consumer_dashboard.CARDSHOP_FILTER_TOGGLED': {
		displayName: 'Cardshop Filter Toggled',
	},
	'nova.consumer_dashboard.CARDSHOP_FILTER_HELP_TOGGLED': {
		displayName: 'Cardshop Filter Help Toggled',
	},
	'nova.consumer_dashboard.RATES_AND_FEES_CLICKED': {
		displayName: 'Rates and Fees link clicked',
	},
	'nova.consumer_dashboard.CREDIT_CARD_DESCRIPTION_EXPANDED': {
		displayName: 'See More toggled on card description',
	},
	'nova.consumer_dashboard.CREDIT_CARD_DETAILS_CLICKED': {
		displayName: 'Link to Card Details page clicked',
	},
	'nova.consumer_dashboard.CREDIT_CARD_DETAILS_VIEWED': {
		displayName: 'Visited Card Details Page',
	},
	'nova.consumer_dashboard.CREDIT_CARD_REDIRECT_VIEWED': {
		displayName: 'Viewed Credit Card Redirect',
	},
	'nova.consumer_dashboard.CREDIT_REPORT_DOWNLOADED': {
		displayName: 'Downloaded Credit Report',
	},
	'nova.consumer_dashboard.REFERRAL_PROGRAM_HOMEPAGE_CTA_CLICKED': {
		displayName: 'Referral Program Homepage CTA Clicked',
	},
	'nova.consumer_dashboard.REPORT_SECTION_VIEWED': {
		displayName: 'Clicked View Report Section',
	},
	'nova.consumer_dashboard.NAVIGATION_ITEM_CLICKED': {
		displayName: 'Clicked Navigation Bar',
	},
	'nova.consumer_dashboard.HAS_US_CREDIT_QUESTION_ANSWERED': {
		displayName: 'Has U.S. Credit Question Answered',
	},
	'nova.consumer_dashboard.LANDING_PAGE_VISITED': {
		displayName: 'Visited Consumer Dashboard Landing Page',
	},
	'nova.consumer_dashboard.NOVACONNECT_FINISHED': {
		displayName: 'Finished Consumer Connect',
	},
	'nova.consumer_dashboard.NOVACONNECT_FINISHED_WITH_SUCCESS_OUTCOME': {
		displayName: 'Finished Consumer Connect With A Successful Outcome',
	},
	'nova.consumer_dashboard.MOVE_SURVEY_SUBMITTED': {
		displayName: 'Submitted Move Survey',
	},
	'nova.consumer_dashboard.VIEW_RECOMMENDED_CARDS_CLICKED': {
		displayName: 'Clicked View Recommended Cards',
	},
	'nova.consumer_dashboard.ONBOARDING_SURVEY_STARTED': {
		displayName: 'Onboarding Survey Started',
	},
	'nova.consumer_dashboard.ONBOARDING_SURVEY_QUESTION_ANSWERED': {
		displayName: 'Onboarding Survey Question Answered',
	},
	'nova.consumer_dashboard.ONBOARDING_SURVEY_FINISHED': {
		displayName: 'Onboarding Survey Finished',
	},
	'nova.consumer_dashboard.CONTENT_SHARED': {
		displayName: 'Content Shared',
	},
	'nova.consumer_dashboard.GUIDE_TAB_CHANGED': {
		displayName: 'Guide Tab Changed',
	},
	'nova.consumer_dashboard.GUIDE_TASK_TOGGLED': {
		displayName: 'Guide Task Done Toggled',
	},
	'nova.consumer_dashboard.REFERRAL_PROGRAM_LEARN_MORE_CLICKED': {
		displayName: 'Referral Program Learn More Clicked',
	},
	'nova.consumer_dashboard.REFERRAL_PROGRAM_PLP_COPIED': {
		displayName: 'Referral Program PLP Copied',
	},
	'nova.consumer_dashboard.REFERRAL_PROGRAM_ACCOUNT_CREATED': {
		displayName: 'Referral Program Account Created',
	},
	'nova.consumer_dashboard.REFERRAL_PROGRAM_PLP_REDEEMED': {
		displayName: 'Referral Program PLP Redeemed',
	},

	/* customer_dashboard */

	'nova.customer_dashboard.ACCOUNT_LOGGED_INTO': {
		displayName: 'User Logged In',
	},
	'nova.customer_dashboard.ACCOUNT_LOGGED_OUT_OF': {
		displayName: 'User Logged Out',
	},
	'nova.customer_dashboard.API_EXPLORER_JSON_COPIED': {
		displayName: 'API Explorer JSON Copied',
	},
	'nova.customer_dashboard.API_EXPLORER_CUSTOMER_PDF_DOWNLOADED': {
		displayName: 'API Explorer Customer PDF Downloaded',
	},
	'nova.customer_dashboard.API_EXPLORER_UNDERWRITER_PDF_DOWNLOADED': {
		displayName: 'API Explorer Underwriter PDF Downloaded',
	},
	'nova.customer_dashboard.APPLICATION_ARCHIVED': {
		displayName: 'Application Archived',
	},
	'nova.customer_dashboard.APPLICATION_UNARCHIVED': {
		displayName: 'Application Unarchived',
	},
	'nova.customer_dashboard.APPLICANT_INVITE_SENT': {
		displayName: 'Applicant Invite Email Sent',
	},
	'nova.customer_dashboard.APPLICANT_INVITE_LINK_GENERATED': {
		displayName: 'Applicant Invite Link Generated',
	},
	'nova.customer_dashboard.CALLBACK_URL_CHANGED': {
		displayName: 'Customer Dashboard CALLBACK URL CHANGED',
	},
	'nova.customer_dashboard.CASH_API_EXPLORER_JSON_DOWNLOADED': {
		displayName: 'Cash API Explorer JSON Downloaded',
	},
	'nova.customer_dashboard.CIBIL_REPORT_LINK_CLICKED': {
		displayName: 'Customer Dashboard CIBIL REPORT LINK CLICKED',
	},
	'nova.customer_dashboard.CIBIL_REPORT_LINK_FETCHED': {
		displayName: 'Customer Dashboard CIBIL REPORT LINK FETCHED',
	},
	'nova.customer_dashboard.CLEARED_BRANDING_CUSTOMIZATION': {
		displayName: 'Branding reverted back to last published customizations',
	},
	'nova.customer_dashboard.CLEARED_EMAIL_CUSTOMIZATION': {
		displayName: 'Email reverted back to last published customizations',
	},
	'nova.customer_dashboard.CONTACT_US_LINK_CLICKED': {
		displayName: 'Contact Us Link Clicked',
	},
	'nova.customer_dashboard.CUSTOMER_REPORT_DOWNLOADED': {
		displayName: 'Customer Report Downloaded',
	},
	'nova.customer_dashboard.CUSTOMIZATION_PREVIEW_LOADED': {
		displayName: 'Customization preview loaded',
	},
	'nova.customer_dashboard.DISCARDED_BRANDING_CUSTOMIZATION': {
		displayName: 'Discarded temporary branding customization changes',
	},
	'nova.customer_dashboard.DISCARDED_EMAIL_CUSTOMIZATION': {
		displayName: 'Discarded temporary email customization changes',
	},
	'nova.customer_dashboard.EVENT_SUBSCRIPTIONS_CHANGED': {
		displayName: 'Customer Dashboard EVENT SUBSCRIPTIONS CHANGED',
	},
	'nova.customer_dashboard.EXPORT_TO_CSV': {
		displayName: 'Reports Exported to CSV',
	},
	'nova.customer_dashboard.EXPORT_TO_XLS': {
		displayName: 'Reports Exported to XLS',
	},
	'nova.customer_dashboard.FILTER_UPDATED': {
		displayName: 'Customer Dashboard Filter Selected',
	},
	'nova.customer_dashboard.FORM_CLOSED': {
		displayName: 'Form Closed',
	},
	'nova.customer_dashboard.FORM_OPENED': {
		displayName: 'Form Opened',
	},
	'nova.customer_dashboard.FORM_SUBMITTED': {
		displayName: 'Form Submitted',
	},
	'nova.customer_dashboard.INVITED_TEAM_MEMBER': {
		displayName: 'Invited New Team Member',
	},
	'nova.customer_dashboard.CONSUMER_LANGUAGE_SELECTED': {
		displayName: 'Customer Dashboard Language Selected',
	},
	'nova.customer_dashboard.LEARN_MORE_LINK_CLICKED': {
		displayName: 'Learn More Link Clicked',
	},
	'nova.customer_dashboard.MFA_ENABLED': {
		displayName: 'Customer Dashboard MFA Enabled',
	},
	'nova.customer_dashboard.MFA_SETTINGS_RESET': {
		displayName: 'Customer Dashboard User Asked To Reset MFA',
	},
	'nova.consumer_dashboard.MVM_FAQ_EXPANDED': {
		displayName: 'Consumer Dashboard MVM FAQ Question Expanded',
	},
	'nova.customer_dashboard.PASSWORD_CHANGED': {
		displayName: 'Customer Dashboard Password Changed',
	},
	'nova.customer_dashboard.PASSWORD_REQUIREMENT_FAILED': {
		displayName: 'Customer Dashboard Password Requirement Failed',
	},
	'nova.customer_dashboard.PREVIEW_EMAIL_SENT': {
		displayName: 'Preview email sent',
	},
	'nova.customer_dashboard.PRODUCT_CREATED': {
		displayName: 'Customer Dashboard Product Created',
	},
	'nova.customer_dashboard.PRODUCT_EDITED': {
		displayName: 'Customer Dashboard Product Edited',
	},
	'nova.customer_dashboard.PRODUCT_DELETED': {
		displayName: 'Customer Dashboard Product Deleted',
	},
	'nova.customer_dashboard.REPORT_NOTIFICATIONS_TOGGLED': {
		displayName: 'Customer Dashboard Report Notification Toggled',
	},
	'nova.customer_dashboard.REMOVED_TEAM_MEMBER': {
		displayName: 'Removed Member from Team',
	},
	'nova.customer_dashboard.RESET_BRANDING_CUSTOMIZATION': {
		displayName: 'Branding reset to defaults',
	},
	'nova.customer_dashboard.RESET_EMAIL_CUSTOMIZATION': {
		displayName: 'Email customizations to defaults',
	},
	'nova.customer_dashboard.SAVED_BRANDING_CUSTOMIZATION': {
		displayName: 'Saved branding customizations',
	},
	'nova.customer_dashboard.SAVED_EMAIL_CUSTOMIZATION': {
		displayName: 'Saved email customizations',
	},
	'nova.customer_dashboard.SECRET_KEY_CHANGED': {
		displayName: 'Customer Dashboard SECRET KEY CHANGED',
	},
	'nova.customer_dashboard.SET_TEAM_MEMBER_PERMISSIONS': {
		displayName: 'Set Permissions for Team Member',
	},
	'nova.customer_dashboard.USER_ENV_TOGGLED': {
		displayName: 'Customer Dashboard User Env Toggled',
	},
	'nova.customer_dashboard.DATE_RANGE_CHANGED': {
		displayName: 'Customer Dashboard Analytics Date Range Changed',
	},
	'nova.customer_dashboard.CONSUMER_REPORT_DOWNLOADED': {
		displayName: 'Customer Dashboard Consumer Report Downloaded',
	},
	'nova.customer_dashboard.CONSUMER_REPORT_DOWNLOAD_DISPATCHED': {
		displayName: 'Customer Dashboard Consumer Report Download PDF Redux Action Dispatched',
	},
	'nova.customer_dashboard.CONSUMER_REPORT_DOWNLOAD_REQUEST_SENT': {
		displayName: 'Customer Dashboard Consumer Report Download POST Request Sent',
	},
	'nova.customer_dashboard.CONSUMER_LANDING_PAGE_VIEWED': {
		displayName: 'Customer Dashboard Consumer Landing Page Viewed',
	},
	'nova.customer_dashboard.BULK_INVITE_CSV_UPLOADED': {
		displayName: 'Customer Dashboard Bulk Invite CSV Uploaded',
	},
	'nova.customer_dashboard.WEBHOOK_SIGNATURE_SECRET_KEY_CHANGED': {
		displayName: 'Customer Dashboard Webhook Signature Secret Key Changed',
	},
	'nova.customer_dashboard.OPEN_INVITE_APPLICATION_CREATED': {
		displayName: 'Consumer created an application through an open invite link',
	},
	'nova.customer_dashboard.OPEN_INVITE_LINK_GENERATED': {
		displayName: 'Open invite link generated in Customer Dashboard',
	},

	/* novaconnect */

	'nova.novaconnect.WIDGET_READY': {
		displayName: 'Connect Ready',
	},
	'nova.novaconnect.WIDGET_OPENED': {
		displayName: 'Connect Opened',
	},
	'nova.novaconnect.WIDGET_REGISTERED': {
		displayName: 'Connect Registered',
	},
	'nova.novaconnect.WIDGET_COMPLETED': {
		displayName: 'Completed',
	},
	'nova.novaconnect.WIDGET_COMPLETED_SUCCESSFULLY': {
		displayName: 'Completed Successfully',
	},
	'nova.novaconnect.WIDGET_EXITED': {
		displayName: 'Connect Exited',
	},
	'nova.novaconnect.WIDGET_ERROR': {
		displayName: 'Error',
	},
	'nova.novaconnect.WIDGET_TIMED_OUT': {
		displayName: 'Extended Timeout',
	},
	'nova.novaconnect.WIDGET_TRIED_AGAIN': {
		displayName: 'Tried Again',
	},
	'nova.novaconnect.DATA_SUPPLIER_FLOW_STARTED': {
		displayName: 'Data supplier flow started',
	},
	'nova.novaconnect.CONSENT_GRANTED': {
		displayName: 'Consent Granted',
	},
	'nova.novaconnect.CONSENT_REVOKED': {
		displayName: 'Consent Revoked',
	},
	'nova.novaconnect.FIELD_FOCUSED': {
		displayName: 'Field Focused',
	},
	'nova.novaconnect.KYC_CONSENT_GRANTED': {
		displayName: 'KYC Consent Granted',
	},
	'nova.novaconnect.KYC_ATTEMPTS_EXCEEDED': {
		displayName: 'KYC Attempts Exceeded or Retry Not Allowed',
	},
	'nova.novaconnect.KYC_AUTHENTICATED': {
		displayName: 'KYC Authenticated',
	},
	'nova.novaconnect.KYC_NOT_AUTHENTICATED': {
		displayName: 'KYC Not Authenticated',
	},
	'nova.novaconnect.KYC_SKIPPED': {
		displayName: 'KYC Skipped',
	},
	'nova.novaconnect.KYC_AUTO_CAPTURE_FAILURE': {
		displayName: 'KYC Auto Capture Failure',
	},
	'nova.novaconnect.KYC_AUTO_CAPTURE_INITIATED': {
		displayName: 'KYC Auto Capture Initiated',
	},
	'nova.novaconnect.KYC_AUTO_CAPTURE_TIMEOUT': {
		displayName: 'KYC Auto Capture Timeout',
	},
	'nova.novaconnect.KYC_AUTO_CAPTURE_SUCCESS': {
		displayName: 'KYC Auto Capture Success',
	},
	'nova.novaconnect.KYC_MANUAL_CAPTURE_FAILURE': {
		displayName: 'KYC Manual Capture Failure',
	},
	'nova.novaconnect.KYC_MANUAL_CAPTURE_INITIATED': {
		displayName: 'KYC Manual Capture Initiated',
	},
	'nova.novaconnect.KYC_MANUAL_CAPTURE_SUCCESS': {
		displayName: 'KYC Manual Capture Success',
	},
	'nova.novaconnect.LANGUAGE_SELECTED': {
		displayName: 'Language Selected',
	},
	'nova.novaconnect.PERSONAL_INFO_SUBMITTED': {
		displayName: 'Personal Info Form Submitted',
	},
	'nova.novaconnect.PERSONAL_INFO_MANUAL_ADDRESS_USED': {
		displayName: 'Used Manual Address Form',
	},
	'nova.novaconnect.PERSONAL_INFO_DETAILS_EDITED': {
		displayName: 'Edit Details',
	},
	'nova.novaconnect.AUTHENTICATION_SUBMITTED': {
		displayName: 'Submitted Authentication',
	},
	'nova.novaconnect.DOCUMENT_SUBMITTED': {
		displayName: 'Submitted Document',
	},
	'nova.novaconnect.PHASE_SUBMITTED': {
		displayName: 'Submitted Phase',
	},
	'nova.novaconnect.SURVEY_SUBMITTED': {
		displayName: 'Submitted Survey',
	},
	'nova.novaconnect.UNSUPPORTED_COUNTRY_SUBMITTED': {
		displayName: 'Submitted Unsupported Country',
	},
	'nova.novaconnect.PAGE_VIEWED': {
		displayName: 'Viewed Page',
	},
	'nova.novaconnect.TOMBSTONE_CUSTOMER_GETMETA': {
		displayName: 'Tombstone Customer GetMeta',
	},
	'nova.novaconnect.TOMBSTONE_GO_TO_COUNTRY': {
		displayName: 'Tombstone GoToCountry',
	},
	'nova.novaconnect.FINISH_LATER_FORM_VIEWED': {
		displayName: 'Finish Later Form Viewed',
	},
	'nova.novaconnect.FINISH_LATER_INITIATED': {
		displayName: 'Finish Later Initiated',
	},
	'nova.novaconnect.EXPERIMENTAL_REFERRAL_FLAGS': {
		displayName: 'Experimental Referral Flags',
	},
	'nova.novaconnect.PAGE_TIMING': {
		displayName: 'Page Timing',
	},
	'nova.novaconnect.INSTITUTION_SEARCHED': {
		displayName: 'Institution Searched',
	},
	'nova.novaconnect.INSTITUTION_SELECTED': {
		displayName: 'Institution Selected',
	},
	'nova.novaconnect.AKOYA_OAUTH_LAUNCHED': {
		displayName: 'Akoya OAUTH Launched',
	},
	'nova.novaconnect.CLOSE_PAGE_DISMISSED': {
		displayName: 'Close Page Dismissed',
	},
	'nova.novaconnect.REPORT_UPLOAD_ATTEMPTED': {
		displayName: 'Report Upload Attempted',
	},
	'nova.novaconnect.REPORT_UPLOAD_SUBMITTED': {
		displayName: 'Report Upload Submitted',
	},
	'nova.novaconnect.REPORT_UPLOAD_FAILED': {
		displayName: 'Report Upload Failed',
	},
	'nova.novaconnect.PAYSTUB_UPLOAD_ATTEMPTED': {
		displayName: 'Paystub Upload Attempted',
	},
	'nova.novaconnect.PAYSTUB_UPLOAD_SUBMITTED': {
		displayName: 'Paystub Upload Submitted',
	},
	'nova.novaconnect.PAYSTUB_INFORMATION_VIEWED': {
		displayName: 'Paystub Information Viewed',
	},
	'nova.novaconnect.PAYSTUB_UPLOAD_FAILED': {
		displayName: 'Paystub Upload Failed',
	},
	'nova.novaconnect.NONCONTRIBUTING_OPTION_SELECTED': {
		displayName: 'Noncontributing Option Selected',
	},
	'nova.novaconnect.FINICITY_CONNECT_WIDGET_OPENED': {
		displayName: 'Finicity Connect Widget Opened',
	},
	'nova.novaconnect.FINICITY_CONNECT_WIDGET_CANCELED': {
		displayName: 'Finicity Connect Widget Canceled',
	},
	'nova.novaconnect.FINICITY_CONNECT_WIDGET_ERROR': {
		displayName: 'Finicity Connect Widget Error',
	},
	'nova.novaconnect.FINICITY_CONNECT_WIDGET_SUCCESS': {
		displayName: 'Finicity Connect Widget Success',
	},
	'nova.novaconnect.FINICITY_CONNECT_USER_EVENT': {
		displayName: 'Finicity Connect User Event',
	},
	'nova.novaconnect.FINICITY_CONNECT_ROUTE_EVENT': {
		displayName: 'Finicity Connect Route Event',
	},
	'nova.novaconnect.ATOMIC_PAGE_VIEW': {
		displayName: 'Atomic Page View',
	},
	'nova.novaconnect.ATOMIC_AUTH': {
		displayName: 'Atomic Authentication',
	},
	'nova.novaconnect.ATOMIC_SEARCH': {
		displayName: 'Atomic Search',
	},
	'nova.novaconnect.ATOMIC_SELECT': {
		displayName: 'Atomic Select',
	},
	'nova.novaconnect.PREFILL_RECEIVED': {
		displayName: 'Prefill received',
	},

	/* voe-frontend */
	'nova.voe_frontend.WIDGET_READY': {
		displayName: 'VOEI Widget Ready',
	},
	'nova.voe_frontend.WIDGET_OPENED': {
		displayName: 'VOEI Widget Opened',
	},
	'nova.voe_frontend.WIDGET_CLOSED': {
		displayName: 'VOEI Widget Closed',
	},
	'nova.voe_frontend.WIDGET_SUCCESS': {
		displayName: 'VOEI Widget Success',
	},
	'nova.voe_frontend.WIDGET_ERROR': {
		displayName: 'VOEI Widget Error',
	},
	'nova.voe_frontend.CONSENT_CHECKBOX_TOGGLED': {
		displayName: 'VOEI Consent Checkbox Toggled',
	},
	'nova.voe_frontend.FINICITY_CONNECT_WIDGET_OPENED': {
		displayName: 'Finicity Connect Widget Opened',
	},
	'nova.voe_frontend.FINICITY_CONNECT_WIDGET_CANCELED': {
		displayName: 'Finicity Connect Widget Canceled',
	},
	'nova.voe_frontend.FINICITY_CONNECT_WIDGET_SUCCESS': {
		displayName: 'Finicity Connect Widget Success',
	},
	'nova.voe_frontend.FINICITY_CONNECT_USER_EVENT': {
		displayName: 'Finicity Connect User Event',
	},
	'nova.voe_frontend.OCROLUS_PAYSTUB_UPLOAD_ATTEMPTED': {
		displayName: 'Ocrolus Paystub Upload Attempted',
	},
	// Deprecated, use OCROLUS_PAYSTUB_UPLOAD_FAILED instead
	'nova.voe_frontend.OCROLUS_PAYSTUB_UPLOAD_UNSUPPORTED': {
		displayName: 'Ocrolus Paystub Upload Unsupported File Type',
	},
	'nova.voe_frontend.OCROLUS_PAYSTUB_UPLOAD_FAILED': {
		displayName: 'Ocrolus Paystub Upload Failed',
	},
	'nova.voe_frontend.OCROLUS_PAYSTUB_UPLOAD_SUBMITTED': {
		displayName: 'Ocrolus Paystub Upload Submitted',
	},
	'nova.voe_frontend.NON_CONTRIBUTING_APPLICANT_SELECTED': {
		displayName: 'Non-Contributing Applicant Selected',
	},
	'nova.novaconnect.DATA_PRIVACY_POPUP_OPENED': {
		displayName: 'Data Privacy AB Test Popup opened',
	},
} as const;

export type TrackingEvent = { name: TrackingEventName; displayName: string };

export type UnvalidatedTrackingEvent = {
	name: string;
	displayName: string;
};

const getUnvalidatedEvent = (eventName: string): UnvalidatedTrackingEvent => ({
	name: eventName,
	// Just copy over the name to the displayName
	displayName: eventName,
});

const getEvent = (eventName: TrackingEventName): TrackingEvent => {
	const event = EVENTS[eventName];

	if (event === undefined) {
		throw new Error(`getEvent received unknown event: ${eventName}`);
	}

	return { displayName: event.displayName, name: eventName };
};

export { getEvent, getUnvalidatedEvent };
