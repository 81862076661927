export enum NOVA_SCORE_TYPES {
	FOREIGN_SCORE = 'FOREIGN_SCORE',
	NOVA_SCORE_BETA = 'NOVA_SCORE_BETA',
	NOVA_SCORE_AMEX = 'NOVA_SCORE_AMEX', // AMEX's FICO v8
	NOVA_SCORE_AECB = 'NOVA_SCORE_AECB',
	NOVA_SCORE_GAMMA = 'NOVA_SCORE_GAMMA',
	NOVA_SCORE_GOEASY = 'NOVA_SCORE_GOEASY',
	NOVA_SCORE_HSBC = 'NOVA_SCORE_HSBC',
	NOVA_SCORE_BMO = 'NOVA_SCORE_BMO',
	NOVA_SCORE_RBC = 'NOVA_SCORE_RBC',
	NOVA_SCORE_CASH_FLOW = 'NOVA_SCORE_CASH_FLOW',
}

export enum RECOMMENDATION_LEVELS {
	VERY_HIGH_RISK = 'VERY_HIGH_RISK',
	HIGH_RISK = 'HIGH_RISK',
	MEDIUM_RISK = 'MEDIUM_RISK',
	LOW_RISK = 'LOW_RISK',
	VERY_LOW_RISK = 'VERY_LOW_RISK',
}

/*
 * DESTINATION_COUNTRY_INTEGRATION
 * Recommendation for requested products to be provided with NovaScore
 */

/*
 * Target: USA
 */
const USA = [
	{
		scoreRange: [300, 499],
		recommendation: RECOMMENDATION_LEVELS.VERY_HIGH_RISK,
	},
	{
		scoreRange: [500, 599],
		recommendation: RECOMMENDATION_LEVELS.HIGH_RISK,
	},
	{
		scoreRange: [600, 659],
		recommendation: RECOMMENDATION_LEVELS.MEDIUM_RISK,
	},
	{
		scoreRange: [660, 779],
		recommendation: RECOMMENDATION_LEVELS.LOW_RISK,
	},
	{
		scoreRange: [780, 850],
		recommendation: RECOMMENDATION_LEVELS.VERY_LOW_RISK,
	},
];

/*
 * Target: CAN
 */
const CAN = {
	[NOVA_SCORE_TYPES.NOVA_SCORE_BETA]: [
		{
			scoreRange: [300, 499],
			recommendation: RECOMMENDATION_LEVELS.VERY_HIGH_RISK,
		},
		{
			scoreRange: [500, 574],
			recommendation: RECOMMENDATION_LEVELS.HIGH_RISK,
		},
		{
			scoreRange: [575, 649],
			recommendation: RECOMMENDATION_LEVELS.MEDIUM_RISK,
		},
		{
			scoreRange: [650, 749],
			recommendation: RECOMMENDATION_LEVELS.LOW_RISK,
		},
		{
			scoreRange: [750, 900],
			recommendation: RECOMMENDATION_LEVELS.VERY_LOW_RISK,
		},
	],
	[NOVA_SCORE_TYPES.NOVA_SCORE_GAMMA]: [
		{
			scoreRange: [300, 620],
			recommendation: RECOMMENDATION_LEVELS.VERY_HIGH_RISK,
		},
		{
			scoreRange: [621, 660],
			recommendation: RECOMMENDATION_LEVELS.HIGH_RISK,
		},
		{
			scoreRange: [661, 720],
			recommendation: RECOMMENDATION_LEVELS.MEDIUM_RISK,
		},
		{
			scoreRange: [721, 800],
			recommendation: RECOMMENDATION_LEVELS.LOW_RISK,
		},
		{
			scoreRange: [801, 900],
			recommendation: RECOMMENDATION_LEVELS.VERY_LOW_RISK,
		},
	],
	[NOVA_SCORE_TYPES.NOVA_SCORE_GOEASY]: [
		{
			scoreRange: [300, 499],
			recommendation: RECOMMENDATION_LEVELS.VERY_HIGH_RISK,
		},
		{
			scoreRange: [500, 574],
			recommendation: RECOMMENDATION_LEVELS.HIGH_RISK,
		},
		{
			scoreRange: [575, 649],
			recommendation: RECOMMENDATION_LEVELS.MEDIUM_RISK,
		},
		{
			scoreRange: [650, 749],
			recommendation: RECOMMENDATION_LEVELS.LOW_RISK,
		},
		{
			scoreRange: [750, 900],
			recommendation: RECOMMENDATION_LEVELS.VERY_LOW_RISK,
		},
	],
	[NOVA_SCORE_TYPES.NOVA_SCORE_BMO]: [
		{
			scoreRange: [300, 499],
			recommendation: RECOMMENDATION_LEVELS.VERY_HIGH_RISK,
		},
		{
			scoreRange: [500, 574],
			recommendation: RECOMMENDATION_LEVELS.HIGH_RISK,
		},
		{
			scoreRange: [575, 649],
			recommendation: RECOMMENDATION_LEVELS.MEDIUM_RISK,
		},
		{
			scoreRange: [650, 749],
			recommendation: RECOMMENDATION_LEVELS.LOW_RISK,
		},
		{
			scoreRange: [750, 900],
			recommendation: RECOMMENDATION_LEVELS.VERY_LOW_RISK,
		},
	],
	[NOVA_SCORE_TYPES.NOVA_SCORE_RBC]: [
		{
			scoreRange: [160, 249],
			recommendation: RECOMMENDATION_LEVELS.VERY_HIGH_RISK,
		},
		{
			scoreRange: [250, 319],
			recommendation: RECOMMENDATION_LEVELS.HIGH_RISK,
		},
		{
			scoreRange: [320, 359],
			recommendation: RECOMMENDATION_LEVELS.MEDIUM_RISK,
		},
		{
			scoreRange: [360, 419],
			recommendation: RECOMMENDATION_LEVELS.LOW_RISK,
		},
		{
			scoreRange: [420, 800],
			recommendation: RECOMMENDATION_LEVELS.VERY_LOW_RISK,
		},
	],
};

/*
 * Target: ARE
 */
const ARE = [
	{
		scoreRange: [300, 540],
		recommendation: RECOMMENDATION_LEVELS.VERY_HIGH_RISK,
	},
	{
		scoreRange: [541, 650],
		recommendation: RECOMMENDATION_LEVELS.HIGH_RISK,
	},
	{
		scoreRange: [651, 710],
		recommendation: RECOMMENDATION_LEVELS.MEDIUM_RISK,
	},
	{
		scoreRange: [711, 745],
		recommendation: RECOMMENDATION_LEVELS.LOW_RISK,
	},
	{
		scoreRange: [746, 900],
		recommendation: RECOMMENDATION_LEVELS.VERY_LOW_RISK,
	},
];

/*
 * Target: SGP
 * SGP should follow USA values
 */
const SGP = USA;

/*
 * Target: GBR
 */
const GBR = [
	{
		scoreRange: [0, 278],
		recommendation: RECOMMENDATION_LEVELS.VERY_HIGH_RISK,
	},
	{
		scoreRange: [279, 366],
		recommendation: RECOMMENDATION_LEVELS.HIGH_RISK,
	},
	{
		scoreRange: [367, 419],
		recommendation: RECOMMENDATION_LEVELS.MEDIUM_RISK,
	},
	{
		scoreRange: [420, 466],
		recommendation: RECOMMENDATION_LEVELS.LOW_RISK,
	},
	{
		scoreRange: [467, 700],
		recommendation: RECOMMENDATION_LEVELS.VERY_LOW_RISK,
	},
];

/*
 * Target: IND
 * IND should follow USA values
 */
const IND = USA;

type RecommendationScale = typeof USA;

// DESTINATION_COUNTRY_INTEGRATION
export const recommendationMaps: Record<
	string,
	RecommendationScale | Record<string, RecommendationScale>
> = {
	ARE,
	CAN,
	GBR,
	IND,
	SGP,
	USA,
};
