/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import classNames from 'classnames';

import Icon from './Icon';
import SubmenuLink from './SubmenuLink';

import './MobileMenuItem.scss';

const MobileMenuItem = ({ label, menu }) => {
	const [active, setActive] = useState(false);
	return (
		<li className="uq-mobile-menu-item">
			<button
				type="button"
				className={classNames('uq-mobile-menu-item-trigger', {
					active,
				})}
				onClick={() => setActive(!active)}
			>
				<span>{label}</span>
				<Icon
					icon="chevron-down"
					width="16"
					className={classNames('uq-mobile-menu-item-icon', {
						active,
					})}
				/>
			</button>
			<div className={classNames('uq-mobile-menu-item-contents', { active })}>
				<ul>
					{menu.map(({ ctas, links, mobileHeadline, includeCtaOnMobile }, index) => {
						return (
							<li className="uq-mobile-menu-item-section" key={index}>
								<h3 className="uq-mobile-headline">{mobileHeadline}</h3>
								<ul className="uq-mobile-menu-item-subcontents">
									{links.map(link => (
										<SubmenuLink
											key={link.label}
											href={link.href}
											label={link.label}
											icon={link.icon}
											newTab={link.newTab}
										/>
									))}
									{includeCtaOnMobile &&
										ctas &&
										ctas.map((cta, ctaIndex) => (
											<SubmenuLink
												key={ctaIndex}
												href={cta.href}
												label={cta.label}
												icon={cta.icon}
												newTab={cta.newTab}
												isMobileCta
											/>
										))}
								</ul>
							</li>
						);
					})}
				</ul>
			</div>
		</li>
	);
};

export default MobileMenuItem;
