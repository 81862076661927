import React from 'react';

type OwnProps = {
	alt?: string;
	className?: string;
	src: string;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
// eslint-disable-next-line no-use-before-define
type Props = OwnProps & typeof Illustration.defaultProps;

const Illustration = ({ alt, className, src, ...rest }: Props) => {
	return <img className={className} src={src} alt={alt} {...rest} />;
};

Illustration.defaultProps = {
	alt: '',
	className: '',
};

export default Illustration;
