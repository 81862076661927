import React from 'react';

import { Typography } from '@novacredit/pandorasbox';

import 'components/common/ContentTabs/ContentTabsMarkDone.scss';

const MarkDone = ({ isChecked, callback }: { isChecked: boolean; callback?: Function }) => {
	const onCheckboxChange = ({ currentTarget }: { currentTarget: HTMLInputElement }) => {
		const { checked } = currentTarget;
		if (typeof callback === 'function') {
			callback(checked);
		}
	};

	return (
		<Typography tag="label" variant="Body-Regular" className="mark-done">
			<span className="mark-done_label">{isChecked ? 'done' : 'mark done'}</span>
			<input
				type="checkbox"
				className="mark-done_input"
				name="mark-done"
				checked={isChecked}
				onChange={onCheckboxChange}
			/>
		</Typography>
	);
};

export default MarkDone;
