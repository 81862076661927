import React from 'react';
import './CardPill.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../../cms/Icon';

const POSSIBLE_COLORS = new Set(['emerald']); // define new colors here and in the .scss

/**
 * A "badge" that is displayed next to the name of the credit card.
 * @param text - displayed by the pill
 * @param color - the color; possible colors are defined in the scss and appended as a classname
 * @param iconName - gives the icon to include
 */
const CardPill = ({ text, color, iconName }) => {
	return (
		<span
			className={classNames('card-pill', {
				[color]: POSSIBLE_COLORS.has(color),
			})}
		>
			{text}
			<Icon icon={iconName} className="icon" />
		</span>
	);
};

CardPill.propTypes = {
	text: PropTypes.string.isRequired,
	color: PropTypes.oneOf([...POSSIBLE_COLORS]),
	iconName: PropTypes.string,
};

export default CardPill;
