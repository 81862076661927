/**
 * Utility module for generic data manipulation.
 */

// Note: this encoding map only has one html entity in it
// but could be extended to handle others.
const HTML_ENTITY_MAPPING = {
	'&#10003;': '✓',
};

/**
 * Transform HTML entities within the string to characters in
 * a way that isn't susceptible to XSS attacks like
 * `dangerouslySetInnerHTML` would be.
 *
 * Currently supports a limited html entity set. We could generalize
 * in the future with a library like:
 *
 * https://github.com/mathiasbynens/he
 */
export const decodeHtmlEntities = (input: string): string => {
	let output = input;

	Object.entries(HTML_ENTITY_MAPPING).forEach(([key, value]) => {
		output = output.replace(key, value);
	});

	return output;
};

/**
 * Converts an object of boolean flags to a list of strings, for example:
 *
 * { creditCards: true, studentLoans: true, autoLoans: false } -> ['creditCards', 'studentLoans']
 */
export const flagsToList = <T extends string>(flags: { [key in T]?: boolean }): T[] => {
	const list: T[] = [];
	const keys = Object.keys(flags) as T[];
	keys.forEach((key: T) => {
		if (flags[key]) {
			list.push(key);
		}
	});

	return list;
};

/**
 * Converts a list of strings to an object of boolean flags, for example:
 *
 * ['creditCards', 'studentLoans'] -> { creditCards: true, studentLoans: true }
 */
export const listToFlags = <T extends string>(list: T[]): { [key in T]?: boolean } => {
	const flags: { [key in T]?: boolean } = {};

	list.forEach((item: T) => {
		flags[item] = true;
	});

	return flags;
};

/**
 * Helper to make the first letter in a string uppercase, and the rest lowercase
 * e.g. "HELLO WORLD" => "Hello world"
 * @param {String} string    string of words to sentence case
 * @return {String}          sentence-cased sentence
 */
export const toSentenceCase = (string: string | null | undefined): string => {
	if (!string) {
		return '';
	}
	return string.toLowerCase().replace(/^\w/, word => word.toUpperCase());
};

/**
 * Converts the raw HTML to escaped plain HTML text
 * @param rawHtml
 * @returns Escaped HTML text
 */
export const escapeHtml = (rawHtml: string): string => {
	if (!rawHtml) {
		return '';
	}

	const decoder = document.createElement('div');
	decoder.innerText = rawHtml;
	return decoder.innerHTML;
};

export default {
	escapeHtml,
};
