import React from 'react';
import { COUNTRY_INFO } from '@novacredit/frontend-common/utils/countries';
import { Accordion } from '@novacredit/pandorasbox';
import { tracker } from 'modules/tracking';

const AMEX_COUNTRIES = COUNTRY_INFO.filter(country => country.isAmex);
const HARD_PULL_NAMES = AMEX_COUNTRIES.filter(country => country.isHardPull).map(
	country => country.name,
);

// PHL can be soft or hard depending on bureau
const SOFT_PULL_COUNTRIES = AMEX_COUNTRIES.filter(
	country => !country.isHardPull && country.code !== 'PHL',
).map(country => country.name);

const AmexRedirectAccordion = () => {
	const onAccordionToggle = ({ item }) => {
		tracker.track('nova.client.FAQ_EXPANDED', { question: item.key });
	};

	return (
		<Accordion
			id="amex-faq"
			variant="ghost"
			className="mt-3 mb-3"
			onToggle={onAccordionToggle}
			items={[
				{
					key: 'Which documents are needed to use Nova Credit',
					title: 'Which documents do I need to apply for this card?',
					content: (
						<>
							Applicants using their foreign credit report may need the following
							items to apply:
							<ul>
								<li>Most recent foreign home address</li>
								<li>Foreign passport or resident ID</li>
								<li>
									Other requirements may depend on the individual credit bureau
									from your home country of which you are transferring your credit
									report from
								</li>
							</ul>
						</>
					),
				},
				{
					key: 'How Amex sees foreign credit reports',
					title: `How can American Express see my foreign credit report?`,
					content: `American Express can accept foreign credit reports in their
				credit card applications through their partnership with us, Nova Credit. If
				Amex can't find a U.S. credit report, we enable you to connect to your foreign credit
				bureau and we translate your credit report into a U.S.-equivalent
				credit rating that American Express can use as part of your application.`,
				},
				{
					key: 'Will this affect my credit score question',
					title: `Will this affect my credit?`,
					content: `Amex will check your U.S. credit history first, which may affect
				your U.S. credit score. Depending on the country you choose to leverage your
				credit history from, your foreign credit bureau report score may be impacted.
				Applying with the Credit Passport®, powered by Nova Credit, will result in a hard inquiry 
				(${HARD_PULL_NAMES.join(', ')}) or soft inquiry (${SOFT_PULL_COUNTRIES.join(', ')}). For 
				the Philippines, the use of TransUnion  will result in a hard inquiry or a soft inquiry 
				if CRIF (The Center for Research in International Finance) is used.`,
				},
				{
					key: 'Will use foreign credit',
					title: 'Will I definitely be able to use my foreign credit history?',
					content: `American Express will check your U.S. credit report first. If you have enough credit history in
				the U.S., American Express may use your U.S. credit history rather than your foreign credit
				history to make a decision.`,
				},
			]}
		/>
	);
};

export default AmexRedirectAccordion;
