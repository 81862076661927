import { useMount } from '../hooks';
import type { TrackingClient } from '../tracking';
import { COOKIE_TYPES_DEFAULT } from './common';
import type { ConsentMap, OnUpdateConsentCallback } from './common';

/**
 * We use the Cookiebot library to receive consent from our consumers to use cookies on
 * their browser. The code here is used to interface with the library.
 *
 * Cookiebot SDK: https://www.cookiebot.com/en/developer/
 * Guide: https://support.cookiebot.com/hc/en-us/articles/4405978132242-Manual-Markup-Guide
 */

export const COOKIEBOT_ID = 'ee907feb-d966-409a-a6aa-42f8b2276877';
export const COOKIEBOT_ID_CCPA = '505a6b34-b62e-4ab5-aede-fecd5877ecc5';
export const COOKIEBOT_ID_CAN = '700286f3-b4cd-4609-82b9-1efd86d0978c';

export const COOKIEBOT_URLS = {
	CONSENT: 'https://consent.cookiebot.com/uc.js',
	DECLARATION: `https://consent.cookiebot.com/${COOKIEBOT_ID}/cd.js`,
};

declare global {
	interface Window {
		Cookiebot: {
			consent: ConsentMap & { stamp: string };
			regulations: {
				ccpaApplies: boolean;
				gdprApplies: boolean;
			};
			show: Function;
		};
	}
}

/**
 * `CookiebotOnConsentReady` is triggered by the Cookiebot library either when:
 * 1) a user submits their consent
 * 2) a user's previously submitted consent that has been stored is loaded
 * 3) a user is not in a territory being asked for consent (ex. non EU user),
 *    and in that case this will be triggered with all consents being "true"
 */
export const useConsentReadyListener = (updateConsentFn: OnUpdateConsentCallback) => {
	useMount(() => {
		const listener = () => {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { stamp, ...consents } = window.Cookiebot.consent;
			updateConsentFn(consents);
		};

		window.addEventListener('CookiebotOnConsentReady', listener);

		return () => {
			window.removeEventListener('CookiebotOnConsentReady', listener);
		};
	});
};

export const trackConsentReady = (tracker: TrackingClient) => {
	const consent = window.Cookiebot.consent;

	tracker.track('nova.client.COOKIE_CONSENT_READY', {
		ccpaApplies: window.Cookiebot.regulations.ccpaApplies,
		gdprApplies: window.Cookiebot.regulations.gdprApplies,
		necessary: consent.necessary,
		marketing: consent.marketing,
		preferences: consent.preferences,
		statistics: consent.statistics,
	});
};

/**
 * `CookiebotOnDialogInit` is triggered when the cookie consent banner is initialized.
 * Was hoping to use `CookiebotOnDialogDisplay` instead, but it does not consistently fire
 * due to a race condition. It seems like this is a close enough eqivalent.
 */
export const useConsentTrackBannerDisplayed = (tracker: TrackingClient) => {
	const listener = () => {
		tracker.track('nova.client.COOKIE_CONSENT_DISPLAYED', null);
	};

	useMount(() => {
		window.addEventListener('CookiebotOnDialogInit', listener);

		return () => {
			window.removeEventListener('CookiebotOnDialogInit', listener);
		};
	});
};

export const hasConsent = (consentType: keyof ConsentMap): boolean => {
	if (!window.Cookiebot) {
		// If Cookiebot does not exist in this environment, just default to true
		return true;
	}

	// Need a default in the case where the user hasn't submitted consent yet
	const consent = window.Cookiebot.consent || COOKIE_TYPES_DEFAULT;
	return consent[consentType];
};
