import { useState, useMemo, useEffect } from 'react';
import debounce from 'lodash.debounce';

/**
 * useState with an additional state that "catches up" after the state stops
 * changing for `delay` timeout — useful to keep search boxes snappy
 * @param initialState the initial value of the state
 * @param delay the debounce delay, defaults to 200ms
 * @returns a tuple of [state, setState, debouncedState]
 */

function useDebouncedState(initialState, delay = 200) {
  const [state, setState] = useState(initialState);
  const [debouncedState, setDebouncedState] = useState(initialState);
  const debouncedSetDebouncedState = useMemo(() => debounce(setDebouncedState, delay), [delay]);
  useEffect(() => {
    debouncedSetDebouncedState(state);
  }, [debouncedSetDebouncedState, state]);
  return [state, setState, debouncedState];
}

export { useDebouncedState as default };
