import useEventListener from './useEventListener.js';
import useDebouncedState from './useDebouncedState.js';
import 'react';
import 'lodash.debounce';

/**
 * Custom hook that provides the current height and width of the screen.
 *
 * Also listens for changes via a 'resize' event listener. This is debounced
 * to avoid unneeded resizing state updates, and the debounce delay
 * can be set via `resizeDebounceDelay`.
 */

function useScreenSize(resizeDebounceDelay = 50) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_size, setSize, debouncedSize] = useDebouncedState({
    height: window.innerHeight,
    width: window.innerWidth
  }, resizeDebounceDelay);
  useEventListener('resize', () => setSize({
    width: window.innerWidth,
    height: window.innerHeight
  }), window);
  return debouncedSize;
}

export { useScreenSize as default };
