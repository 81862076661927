import type { Env, UserEnv } from '../types';

export const ENV: { [key in Env]: Env } = {
	development: 'development',
	staging: 'staging',
	production: 'production',
};

export const USER_ENVIRONMENTS: { [key in UserEnv]: UserEnv } = {
	consumer: 'consumer',
	sandbox: 'sandbox',
	production: 'production',
};

export const sandboxSimulation = (userEnv: UserEnv, nodeEnv: Env): boolean => {
	if (userEnv === USER_ENVIRONMENTS.sandbox) {
		return true;
	}
	if (userEnv === USER_ENVIRONMENTS.production) {
		return false;
	}
	if (userEnv === USER_ENVIRONMENTS.consumer) {
		return nodeEnv !== 'production';
	}
	throw new Error('Incorrect userEnv or no userEnv passed in');
};

export const convertToEnterpriseEnv = (userEnv: UserEnv, nodeEnv: Env): UserEnv => {
	if (userEnv === USER_ENVIRONMENTS.consumer) {
		return sandboxSimulation(userEnv, nodeEnv) ? 'sandbox' : 'production';
	}
	return userEnv;
};

export const getEnvFromLocationOrUrl = ((): (() => Env) => {
	if (typeof window === 'undefined') {
		return () => {
			throw new Error('Env cannot be determined from location or URL server-side');
		};
	}

	const href = window.location.href;
	if (/localhost.*|bobacredit/.test(href) || process.env.NODE_ENV === ENV.development) {
		return () => ENV.development;
	}
	if (/aphrodite.*/.test(href)) {
		return () => ENV.staging;
	}
	return () => ENV.production;
})();
