import { ExperimentsClient } from '@novacredit/frontend-common/experiments';
import { getOverridesFromQueryParams } from '@novacredit/frontend-common/experiments-frontend';
import { getOrCreateNovaAnonymousId } from '@novacredit/frontend-common/utils/novaAnonymousId';
import { tracker } from './tracking';

/**
 * Experiments client for use when the a/b test under study is on a part of
 * the Consumer Dashboard.
 *
 * Experiment group assignment is based on the user's nova anonymous ID.
 */
const createExperimentsClient = (): ExperimentsClient =>
	new ExperimentsClient({
		bucketingId: getOrCreateNovaAnonymousId(),
		trackingFn: (experimentName, variant) => {
			tracker.track('nova.client.EXPERIMENT_STARTED', {
				'Experiment name': experimentName,
				'Variant name': variant,
			});
		},
		overrides: getOverridesFromQueryParams(),
	});

export { createExperimentsClient };
