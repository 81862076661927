/**
 * A set of credit ratings which can be used to describe:
 *
 * 1. the credit quality of a user, e.g. "Congrats, you have `great` credit"
 * 2. the credit quality of a card, e.g. "This card is intended for applicants with `excellent` credit"
 *
 * Users have a single credit score, and therefore fall into a single
 * credit rating based on the min-max thresholds.
 *
 * Credit cards can be intended for applicants within multiple credit ratings.
 *
 * The `name` field is the canonical name for the credit rating and matches up against
 * strings stored in the database for our credit cards. The `displayName` should be used
 * when rendering to the UI.
 *
 * In some cases, a card provider may have card eligibility logic that overrides the min/max
 * credit score thresholds here. For example, we wish to show American Express cards to
 * all users above a score of 580, even though American Express cards are labelled with
 * the `EXCELLENT` and `GOOD` credit ratings, suggesting that a user would need at least
 * a 660 score to be approved.
 *
 * See also: https://app.tettra.co/teams/novacredit/pages/credit-card-ratings
 */
export const CREDIT_RATINGS = {
	EXCELLENT: {
		name: 'EXCELLENT',
		displayName: 'Excellent',
		min: 780,
		max: 850,
	},
	GOOD: {
		name: 'GOOD',
		displayName: 'Good',
		min: 660,
		max: 779,
	},
	FAIR: {
		name: 'FAIR',
		displayName: 'Fair',
		min: 600,
		max: 659,
	},
	POOR: {
		name: 'POOR',
		displayName: 'Poor',
		min: 500,
		max: 599,
	},
	DAMAGED: {
		name: 'DAMAGED',
		displayName: 'Damaged',
		min: 300,
		max: 499,
	},
} as const;

// Defines the lowest Novascore in which we should show AMEX cards
export const AMEX_MIN_SCORE = 580;

export type CreditRating = typeof CREDIT_RATINGS[keyof typeof CREDIT_RATINGS];
export type CreditRatingName = CreditRating['name'];

export const ORDERED_CREDIT_RATINGS = Object.values(CREDIT_RATINGS).sort(
	(creditRatingA, creditRatingB) => {
		return creditRatingB.min - creditRatingA.min;
	},
);

export const getCreditRatingByName = (name: CreditRatingName): CreditRating | undefined => {
	return Object.values(CREDIT_RATINGS).find(creditRating => creditRating.name === name);
};

export const getCreditRatingByDisplayName = (displayName: string): CreditRating | undefined => {
	return Object.values(CREDIT_RATINGS).find(
		creditRating => creditRating.displayName === displayName,
	);
};

export const CREDIT_RATING_NAMES = Object.values(CREDIT_RATINGS).map(
	creditRating => creditRating.name,
);
