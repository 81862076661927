/* eslint-disable nova/no-delete-operator */
import React, { useContext, useEffect, useRef } from 'react';
import {
	COOKIEBOT_ID,
	COOKIEBOT_ID_CAN,
	COOKIEBOT_ID_CCPA,
	COOKIEBOT_URLS,
} from '@novacredit/frontend-common/consent/cookiebot';

import LanguageContext from 'contexts/LanguageContext';

export const CookiebotScript = () => {
	const instance = useRef(null);
	const { language } = useContext(LanguageContext);

	useEffect(() => {
		// cleanup old instance
		const oldDialog = document.getElementById('CybotCookiebotDialog');
		if (oldDialog) {
			delete window.Cookiebot;
			delete window.CookieConsent;
			delete window.CookieControl;
		}
		const oldScript = document.querySelector('script[src^="https://consent.cookiebot.com/"]');
		if (oldScript) {
			oldScript.parentNode.removeChild(oldScript);
		}
		const oldIframe = document.querySelector(
			'iframe[src^="https://consentcdn.cookiebot.com/"]',
		);
		if (oldIframe) {
			oldIframe.parentNode.removeChild(oldIframe);
		}
		instance.current.innerHTML = '';

		// insert new instance
		const cookieBotScript = document.createElement('script');
		cookieBotScript.id = 'Cookiebot';
		cookieBotScript.src = COOKIEBOT_URLS.CONSENT;
		cookieBotScript.setAttribute('data-cbid', COOKIEBOT_ID);
		cookieBotScript.setAttribute(
			'data-georegions',
			`{'region':'US-06','cbid':'${COOKIEBOT_ID_CCPA}'},{'region':'CA','cbid':'${COOKIEBOT_ID_CAN}'}`,
		);
		cookieBotScript.setAttribute('data-culture', language);
		cookieBotScript.setAttribute('data-framework', 'TCFv2.2');
		instance.current.appendChild(cookieBotScript);
	}, [language]);

	return <div ref={instance} />;
};

export default CookiebotScript;
