import qs from 'qs';

/**
 * We enforce that all query parameters should map a string key
 * to a string value. The qs library tries to interpret arrays
 * and objects, but we do not use those features. If we do wish
 * to use those features of the qs library in the future, then
 * the types here should be amended.
 */
export type QueryParams = { [key: string]: string | void };

/**
 * Returns the current URL query parameters as a key-value object.
 */
export const getQueryParams = (location: Location): QueryParams => {
	return qs.parse(location.search, {
		ignoreQueryPrefix: true,
		parseArrays: false,
	}) as QueryParams;
};

/**
 * Transforms a key-value object into a string of URL query parameters.
 */
export const stringifyQueryParams = (params: QueryParams, caseSensitive = false): string => {
	let sanitizedParams = params;

	// Convert to all lower-case if called for
	if (!caseSensitive) {
		sanitizedParams = Object.keys(sanitizedParams).reduce(
			(result: { [key: string]: string | void }, paramKey) => {
				const value = params[paramKey];
				result[paramKey.toLowerCase()] = value ? value.toLowerCase() : value;
				return result;
			},
			{},
		);
	}

	return qs.stringify({ ...sanitizedParams }, { addQueryPrefix: true, skipNulls: true });
};
