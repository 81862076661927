import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Dedupe, ExtraErrorData } from '@sentry/integrations';
import {
	COMMON_DENY_URLS,
	COMMON_IGNORE_ERRORS,
} from '@novacredit/frontend-common/services/sentry';
import { filterSentryEventUrls } from '@novacredit/frontend-common/utils/url';
import { getOrCreateNovaAnonymousId } from '@novacredit/frontend-common/utils/novaAnonymousId';

import App from 'components/App';
import { SENTRY_DSN } from 'consts';
import { getNodeEnv } from 'utils';
import 'styles/index.scss';

Sentry.init({
	dsn: SENTRY_DSN,
	environment: getNodeEnv(),
	integrations: [new Dedupe(), new ExtraErrorData()],
	ignoreErrors: COMMON_IGNORE_ERRORS,
	denyUrls: COMMON_DENY_URLS,
	beforeSend(event) {
		return filterSentryEventUrls(event as any);
	},
});

Sentry.configureScope(scope => {
	scope.setTag('novaAnonymousId', getOrCreateNovaAnonymousId());
});

ReactDOM.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>,
	document.getElementById('root'),
);
