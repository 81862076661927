import Helmet from 'react-helmet';
import React, { useEffect, useState } from 'react';

import { setDefaultConsents } from './googleConsent';
import type { Env } from '../types';

/**
 * This file contains code for Google Analytics.
 *
 * https://developers.google.com/analytics/devguides/collection/gtagjs
 */

const GA_MEASUREMENT_IDS = {
	development: 'G-9TWW7VRQK2',
	staging: 'G-2L8V3EP6ZK',
	production: 'UA-119692704-1',
};

const getGtagScript = (gaMeasurementId: string) => `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', '${gaMeasurementId}', { 'send_page_view': false });
`;

/**
 * Embed this tag in a site-wide component (e.g. App.jsx) to make
 * available a global gtag function.
 * Adapted from https://developers.google.com/analytics/devguides/collection/gtagjs
 */
export const GoogleAnalyticsTag = ({ nodeEnv }: { nodeEnv: Env }) => {
	const [defaultConsentApplied, setDefaultConsentApplied] = useState(false);
	const gaMeasurementId = GA_MEASUREMENT_IDS[nodeEnv];
	const includeScript = defaultConsentApplied;

	window.dataLayer = window.dataLayer || [];

	useEffect(() => {
		if (!defaultConsentApplied) {
			setDefaultConsents(window.dataLayer);
			setDefaultConsentApplied(true);
		}
	}, [defaultConsentApplied]);

	return (
		<>
			{includeScript && (
				<Helmet>
					<script type="text/javascript">{getGtagScript(gaMeasurementId)}</script>
					<script
						async
						src={`https://www.googletagmanager.com/gtag/js?id=${gaMeasurementId}`}
					/>
				</Helmet>
			)}
		</>
	);
};
