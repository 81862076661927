import type { Env } from '@novacredit/frontend-common/types';

import ausIllustration from 'assets/images/country-illustrations/aus.png';
import gbrIllustration from 'assets/images/country-illustrations/gbr.png';
import indIllustration from 'assets/images/country-illustrations/ind.png';

export const PROD_URL = 'https://hello.novacredit.com';

export const ENV: { [key in Env]: Env } = {
	development: 'development',
	staging: 'staging',
	production: 'production',
};

// Get either the localhost URL or the PR preview URL.
export const getDevUrl = ({ localhostPort = '' } = {}) => {
	const { hostname, port, protocol } = window.location;

	return `${protocol}//${hostname}${port && `:${localhostPort}`}`;
};

export const BASE_API_URLS = {
	development: getDevUrl({ localhostPort: '8012' }),
	staging: '',
	production: '',
};

// Get either the localhost URL or the PR preview URL.
const getDevConnectUrl = () => {
	const { hostname, protocol } = window.location;

	if (hostname === 'localhost') {
		return 'http://localhost:3000';
	}

	const staticHostname = hostname.replace('.my.', '.static.');

	return `${protocol}//${staticHostname}`;
};

export const BASE_CONNECT_URLS = {
	development: `${getDevConnectUrl()}/connect/v2/init.js`,
	staging: 'https://static.aphrodite.novacredit.com/connect/v2/init.js',
	production: 'https://static.novacredit.com/connect/v2/init.js',
};

export const CARDSHOP_SECURED_CARDS_PATH = '/resources/best-secured-credit-card/';

export const SENTRY_DSN =
	'https://fca9eff23cac4d8d8e3c2699457f3a82@o82735.ingest.sentry.io/5203328';

/* This is associated with the Nova Credit Facebook business account and
 * is required by Facebook when initializing their share content links. */
export const FACEBOOK_APP_ID = '168205158084341';

// See https://github.com/apollographql/apollo-client/blob/master/src/core/networkStatus.ts
export const NETWORK_STATUS_CODES = {
	LOADING: 1,
	SET_VARIABLES: 2,
	FETCH_MORE: 3,
	REFETCH: 4,
	POLL: 6,
	READY: 7,
	ERROR: 8,
};

// These categories reflect the impact of a credit score factor on
// a consumer's overall credit. They do not reflect score ranges
// required for a particular credit card.
export const SCORE_STATUS = {
	DEFAULT: 'default',
	BAD: 'bad',
	POOR: 'poor',
	FAIR: 'fair',
	GOOD: 'good',
	EXCELLENT: 'excellent',
};

export const COUNTRY_NAMES = {
	AUS: 'Australia',
	BRA: 'Brazil',
	CAN: 'Canada',
	DOM: 'Dominican Republic',
	GBR: 'United Kingdom',
	IND: 'India',
	KOR: 'South Korea',
	MEX: 'Mexico',
	NGA: 'Nigeria',
	KEN: 'Kenya',
	PHL: 'Philippines',
};

// Use in combination with COUNTRY_NAMES for when countries
// do not have a colloquial name. Often more natural sounding
// when the name of the country is embedded in a sentence.
export const COLLOQUIAL_COUNTRY_NAMES = {
	GBR: 'the United Kingdom',
	DOM: 'the Dominican Republic',
};

export const COUNTRY_ADJECTIVES = {
	AUS: 'Australian',
	BRA: 'Brazilian',
	CAN: 'Canadian',
	DOM: 'Dominican',
	GBR: 'UK',
	IND: 'Indian',
	KOR: 'South Korean',
	MEX: 'Mexican',
	NGA: 'Nigerian',
	KEN: 'Kenyan',
	PHL: 'Filipino',
};

// These should match https://github.com/neednova/zeus/blob/development/packages/novascore/src/const.js
export const COUNTRY_SCORE_RANGES = {
	AUS: { start: -200, end: 1200 },
	GBR: { start: -1000, end: 1000 },
	IND: { start: 300, end: 900 },
	KEN: { start: 250, end: 900 },
	PHL: { start: 150, end: 540 },
	KOR: { start: 0, end: 1000 },
	USA: { start: 300, end: 850 },
};

export const COUNTRY_ILLUSTRATIONS = {
	AUS: ausIllustration,
	GBR: gbrIllustration,
	IND: indIllustration,
};

export const ACCOUNT_CATEGORIES = {
	CREDIT_CARDS: 'CREDIT_CARDS',
	HOME_LOANS: 'HOME_LOANS',
	STUDENT_LOANS: 'STUDENT_LOANS',
	AUTO_LOANS: 'AUTO_LOANS',
	OTHER_LOANS: 'OTHER_LOANS',
};

export const ACCOUNT_CATEGORY_LABELS = {
	CREDIT_CARDS: {
		label: 'Credit Cards',
		icon: 'credit-card',
	},
	HOME_LOANS: {
		label: 'Home Loans',
		icon: 'home',
	},
	STUDENT_LOANS: {
		label: 'Student Loans',
		icon: 'graduation-cap',
	},
	AUTO_LOANS: {
		label: 'Auto Loans',
		icon: 'car',
	},
	OTHER_LOANS: {
		label: 'Other Loans',
		icon: 'university',
	},
};

export const ACCOUNT_TYPE_CATEGORIES = {
	CREDIT_CARD: ACCOUNT_CATEGORIES.CREDIT_CARDS,
	CHARGE_CARD: ACCOUNT_CATEGORIES.CREDIT_CARDS,
	MORTGAGE: ACCOUNT_CATEGORIES.HOME_LOANS,
	HOME_EQUITY: ACCOUNT_CATEGORIES.HOME_LOANS,
	LEASE_RENTAL: ACCOUNT_CATEGORIES.HOME_LOANS,
	STUDENT: ACCOUNT_CATEGORIES.STUDENT_LOANS,
	VEHICLE: ACCOUNT_CATEGORIES.AUTO_LOANS,
	OVERDRAFT: ACCOUNT_CATEGORIES.OTHER_LOANS,
	CREDIT_LINE: ACCOUNT_CATEGORIES.OTHER_LOANS,
	PERSONAL: ACCOUNT_CATEGORIES.OTHER_LOANS,
	RETAIL: ACCOUNT_CATEGORIES.OTHER_LOANS,
	ONLINE_RETAIL: ACCOUNT_CATEGORIES.OTHER_LOANS,
	FLEX_SPENDING: ACCOUNT_CATEGORIES.OTHER_LOANS,
	PAYDAY_LOAN: ACCOUNT_CATEGORIES.OTHER_LOANS,
	UTILITY_TELCO_CABLE: ACCOUNT_CATEGORIES.OTHER_LOANS,
	MEDICAL: ACCOUNT_CATEGORIES.OTHER_LOANS,
	INSURANCE_CLAIM: ACCOUNT_CATEGORIES.OTHER_LOANS,
	MICROFINANCE: ACCOUNT_CATEGORIES.OTHER_LOANS,
};

export const ACCOUNT_TYPE_LABELS = {
	CREDIT_CARD: 'Credit Card',
	CHARGE_CARD: 'Charge Card',
	MORTGAGE: 'Mortgage',
	HOME_EQUITY: 'Home Equity',
	LEASE_RENTAL: 'Lease Rental',
	STUDENT: 'Student Loan',
	VEHICLE: 'Auto Loan',
	OVERDRAFT: 'Overdraft',
	CREDIT_LINE: 'Credit Line',
	PERSONAL: 'Personal Loan',
	RETAIL: 'Retail Loan',
	ONLINE_RETAIL: 'Online Retail',
	FLEX_SPENDING: 'Flexible Spending',
	PAYDAY_LOAN: 'Payday Loan',
	UTILITY_TELCO_CABLE: 'Utilities Loan',
	MEDICAL: 'Medical Loan',
	INSURANCE_CLAIM: 'Insurance Claim',
	MICROFINANCE: 'Microloan',
	OTHER: 'Other',
};

export const COUNTRY_BUREAU_DEFAULT_MAPPING = {
	AUS: 'AUS_EFX',
	GBR: 'GBR_EFX',
	KEN: 'KEN_CI',
	PHL: 'PHL_CRIF',
	KOR: 'KOR_NICE',
	IND: 'IND_CHM',
};

export const CONDITION_STATUS_LABELS = {
	ACTIVE: 'Active',
	INACTIVE: 'Inactive',
	CONSUMER_INITIATED: 'Consumer Initiated Close',
	LENDER_INITIATED: 'Lender Initiated Close',
	OTHER: 'Other',
};

export const TRADELINE_SUBSTATUS_LABELS = {
	CHARGE_OFF: 'Charge Off',
	BANKRUPTCY: 'Bankruptcy',
	FORECLOSURE: 'Foreclosure',
	SETTLED: 'Settled',
	REPOSSESSION: 'Repossession',
	EXTERNAL_COLLECTIONS: 'External Collections',
	GARNISHMENT: 'Garnishment',
	OTHER: 'Other',
};

export const CONSUMER_PUBLIC_ID = 'd3fd8332-a1a5-418b-b21d-16e2494351b8';
export const CONSUMER_PRODUCT_ID = '33106284-570c-4400-9d8a-38dfb8a138e7';

export const NON_ERROR_STATUSES = {
	SUCCESS: 'SUCCESS',
	PENDING: 'PENDING',
};

export const FORM_NAMES = {
	EMAIL: 'email',
	PASSWORD: 'password',
};

export const CHANGE_PASSWORD_ERROR_MESSAGES = {
	INCORRECT_PASSWORD: 'The password you entered is incorrect',
	DEFAULT: 'There was an error changing your password. Please try again later.',
};

export const DESCRIPTION_MAP = {
	AGE_TRADELINE_OLDEST: {
		good: 'Maintaining your accounts over longer periods of time shows that you have established a deeper credit history which can help improve your credit score. Your credit reports shows that you have accounts that are at least 8 years old. Continuing to maintain these accounts over time can help to improve your score.',
		fair: 'Maintaining your accounts over longer periods of time shows that you have established a deeper credit history which can help improve your credit score. Continuing to maintain these accounts over time can help to improve your score.',
		poor: 'Maintaining your accounts over longer periods of time shows that you have established a deeper credit history which can help improve your credit score. Your oldest account is less than three years old. Continuing to maintain these accounts over time can help to improve your score.',
		default:
			'Maintaining your accounts over longer periods of time shows that you have established a deeper credit history which can help improve your credit score. Continuing to maintain these accounts over time can help to improve your score.',
	},
	COUNT_DEROGATORY_MARKS: {
		good: 'Good news! There are no major derogatory events such as bankruptcy, tax liens, external collections on your credit report.',
		poor: 'Events like bankruptcy filings, tax liens, external collections are indicators of high risk and can cause a significant drop in your credit score. These events can impact your credit score for 7-10 years. Check your credit report to confirm that the information is accurate. If you keep paying your new debts on time, your score will recover over time.',
		default:
			'Events like bankruptcy filings, tax liens, external collections are indicators of high risk and can cause a significant drop in your credit score. These events can impact your credit score for 7-10 years. Check your credit report to confirm that the information is accurate. If you keep paying your new debts on time, your score will recover over time.',
	},
	COUNT_INQUIRIES_LAST_SIX_MONTHS: {
		good: 'When you approach a lender for a loan, they will check your credit score by making an inquiry to the credit bureau. This inquiry can cause your score to drop. Too many inquiries can cause a concern that you are financially overextended. Your credit report shows only a few inquiries in the last six months. Only apply for credit if you need it and be sure to pay your debts on time.',
		fair: 'When you approach a lender for a loan, they will check your credit score by making an inquiry to the credit bureau. This inquiry can cause your score to drop. Too many inquiries can cause a concern that you are financially overextended. Your credit report shows at least three inquiries in the last six months. The impact to your score from these inquiries will disappear wtihin a short time. Only apply for credit if you need it and be sure to pay your debts on time.',
		poor: 'When you approach a lender for a loan, they will check your credit score by making an inquiry to the credit bureau. This inquiry can cause your score to drop. Too many inquiries can cause a concern that you are financially overextended. Your credit report shows a high number of inquiries in the last six months. Only apply for credit if you need it and be sure to pay your debts on time.',
		default:
			'When you approach a lender for a loan, they will check your credit score by making an inquiry to the credit bureau. This inquiry can cause your score to drop. Too many inquiries can cause a concern that you are financially overextended.',
	},
	COUNT_TRADELINES: {
		good: 'Handling multiple credit accounts well demonstrates that you are a good credit manager which results in a higher credit score. Your credit reports shows that you have many credit accounts. Continuing to make on time payments on these accounts can improve your credit score.',
		fair: 'Handling multiple credit accounts well demonstrates that you are a good credit manager which results in a higher credit score. Continuing to make on time payments on these accounts can improve your credit score.',
		poor: 'Handling multiple credit accounts well demonstrates that you are a good credit manager which results in a higher credit score. Your credit report shows that you have only a few credit accounts. Continuing to make on time payments on these accounts can improve your credit score.',
		default:
			'Handling multiple credit accounts well demonstrates that you are a good credit manager which results in a higher credit score. Continuing to make on time payments on these accounts can improve your credit score.',
	},
	RATIO_CREDIT_UTILIZATION_REVOLVING: {
		good: 'The sum of your revolving bankcard balances compared to the sum of your credit limits is low. This helps to improve your credit score. Keep your balances low, ideally to less than 30% of the credit limit on your revolving account.',
		fair: 'The sum of your revolving bankcard balances compared to the sum of your credit limits is high. This can cause your credit score to drop. Paying down your balances on revolving accounts, ideally to less than 30% of the credit limit, can improve your score.',
		poor: 'The sum of your revolving bankcard balances compared to the sum of your credit limits is high. This can cause your credit score to drop. Paying down your balances on revolving accounts, ideally to less than 30% of the credit limit, can improve your score.',
		default:
			'The sum of your revolving bankcard balances compared to the sum of your credit limits affects your credit score. Paying or keeping down your balances on revolving accounts, ideally to less than 30% of the credit limit, can improve your score.',
	},
	COUNT_TRADELINES_PAST_DUE_1_OR_MORE_PAY_CYCLES_LAST_12_MONTHS: {
		good: 'Your credit report shows that you have paid all of your debts on time in the last year. Great job!',
		fair: 'Your credit report shows several accounts that have been one or more payments late in the last year.',
		poor: 'Your credit report shows at least three accounts that have been one or more payments late in the last year.',
		default:
			'Paying your debts on time helps you obtain a good credit score. Payment history is the most important driver of your credit score.',
	},
};

export const THRESHOLD_MAP = {
	// AGE_TRADELINE_OLDEST is calculated in months, but rendered in years on the page
	AGE_TRADELINE_OLDEST: {
		good: 96,
		fair: 36,
		poor: 0,
	},
	COUNT_DEROGATORY_MARKS: {
		good: 0,
		poor: 1,
	},
	COUNT_INQUIRIES_LAST_SIX_MONTHS: {
		good: 0,
		fair: 3,
		poor: 10,
	},
	COUNT_TRADELINES: {
		good: 9,
		fair: 5,
		poor: 0,
	},
	RATIO_CREDIT_UTILIZATION_REVOLVING: {
		good: 0,
		fair: 30,
		poor: 70,
	},
	COUNT_TRADELINES_PAST_DUE_1_OR_MORE_PAY_CYCLES_LAST_12_MONTHS: {
		good: 0,
		fair: 1,
		poor: 3,
	},
};

export const PAY_STATUS_LABELS = {
	CURRENT: 'Current',
	EARLY_DELINQUENT: 'Early Delinquent',
	DELINQUENT: 'Delinquent',
	DEFAULT: 'Default',
	DEROGATORY: 'Derogatory',
	SEVERE_DEROGATORY: 'Severe Derogatory',
	OTHER: 'Other',
};

export const VALID_NAME_REGEX = /^\s*[^(0-9!^$%&*@#()_+|~=`{}[\]:";<>?\\/)]*\s*$/;

export const VALID_PASSWORD_REGEX = /^.*(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?!.*\s).*$/;

export const USA_CREDIT_HISTORY_OPTIONS = {
	YES: 'YES',
	NO: 'NO',
	// deprecated -- we initially allowed this response but no longer.
	MAYBE: 'MAYBE',
};

export const SCORE_TYPES = {
	NOVA_SCORE_AMEX: 'NOVA_SCORE_AMEX',
	NOVA_SCORE_BETA: 'NOVA_SCORE_BETA',
	FOREIGN_SCORE: 'FOREIGN_SCORE',
};

export const PORTFOLIO_TYPE_LABELS = {
	REAL_ESTATE: 'Real Estate',
	INSTALLMENT: 'Installment',
	REVOLVING: 'Revolving',
	UNSPECIFIED_LIMIT: 'No Limit',
	OTHER: 'Other',
};

export const PAGES = [
	{ pathname: '/guide', pageName: 'Immigration Guide (CD)' },
	{ pathname: '/cards/redirect/', pageName: 'Card Redirect (CD)', method: 'include' },
	// Placed before /cards/ since getPageName looks for the first occurrence in the array
	{ pathname: '/cards', pageName: 'Card Shop (CD)' },
	{ pathname: '/cards/', pageName: 'Card Details (CD)', method: 'include' },
	{ pathname: '/consumer-request-form/consumer-type', pageName: 'Consumer Type (CD)' },
	{
		pathname: '/consumer-request-form/newcomer-submission-success',
		pageName: 'Newcomer Submission Confirmation (CD)',
	},
	{
		pathname: '/consumer-request-form/voi-submission-success',
		pageName: 'VOI Submission Confirmation (CD)',
	},
	{
		pathname: '/consumer-request-form/partnership-contact-info',
		pageName: 'Partnership Contact Info (CD)',
	},
	{ pathname: '/consumer-request-form/newcomer-request', pageName: 'Newcomer Request Form (CD)' },
	{
		pathname: '/consumer-request-form/verification-of-income-applicant',
		pageName: 'VOI Request Form (CD)',
	},
	{ pathname: '/cookie-policy', pageName: 'Cookie Policy (CD)' },
	{ pathname: '/marketplace', pageName: 'Multi-Vertical Marketplace Overview (CD)' },
	// Placed last since getPageName looks for the first occurrence in the array
	{ pathname: '', pageName: 'Home (CD)' },
];

export const LANGUAGE_NAME_MAP = {
	en: 'English',
	fr: 'Français Canadien',
};

export const IMPACT_RADIUS_SUBID_INFO = {
	// no cookies allowed, so not possible to check for the extra information
	NO_COOKIES: 'NO_COOKIES',
	// cookies allowed and no UTM information present
	NO_UTM_INFO: 'NO_UTM_INFO',
	// cookies allowed and no referral information present
	NO_REFERRAL_INFO: 'NO_REFERRAL_INFO',
};
