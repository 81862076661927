/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import classNames from 'classnames';

import { usePageScrolled, useScrollDirection } from './hooks';
import HexagonalCTA from './HexagonalCTA';
import Icon from './Icon';
import MenuDropdown from './MenuDropdown';
import MobileMenuItem from './MobileMenuItem';
import menuConfig from './menuConfig';
import LanguageDropdown, { MobileLanguageDropdown } from './LanguageDropdown';
import './Menu.scss';

const Menu = () => {
	const MENU_CONTENT = menuConfig.tabs;
	const ABOUT_DROPDOWN_CONTENT = {
		title: menuConfig.aboutUsDropdownTitle,
		dropdown: menuConfig.aboutUsDropdownLinks,
	};

	// While on the Hello app, "Personal" is always the active tab.
	const activeTab = 1;
	const [burgerOpen, setBurgerOpen] = useState(false);
	const scrollDirection = useScrollDirection();
	const pageScrolled = usePageScrolled();

	return (
		<div
			className={classNames('uq-menu', {
				'scrolling-down': scrollDirection === 'down' && !burgerOpen,
				'at-top': !pageScrolled,
			})}
		>
			<div className="uq-menu-inner">
				<a className="uq-logo-link" href={MENU_CONTENT[activeTab].href}>
					<Icon icon="logo" width="177" />
				</a>
				<div role="tablist" className="uq-menu-tab-list">
					{MENU_CONTENT.map(({ name, label, href, cta, menu }, index) => (
						<div
							className={classNames('uq-menu-tab', {
								default: activeTab === index,
							})}
							key={name}
						>
							<a
								className={classNames('uq-menu-tab-name', {
									'uq-menu-tab-name-active': activeTab === index,
								})}
								id={name}
								role="tab"
								href={href}
							>
								{label}
							</a>
							<div
								className={classNames('uq-menu-tab-content', {
									'uq-menu-tab-content-scrolled': pageScrolled,
									'uq-menu-tab-content-hidden': activeTab !== index,
								})}
								role="tabpanel"
								aria-labelledby={name}
							>
								<nav aria-label={name}>
									<ul className="uq-menu-secondary-list">
										{menu.map(menuItem => {
											const { title, columns } = menuItem;
											return (
												<MenuDropdown
													title={title}
													columns={columns}
													key={title}
												/>
											);
										})}
										<li>
											<a
												href={cta.href}
												target={cta.newTab ? '_blank' : null}
												rel={cta.newTab ? 'noreferrer noopener' : null}
												className="uq-menu-dropdown-cta"
											>
												<span>{cta.label}</span>
											</a>
										</li>
									</ul>
								</nav>
							</div>
						</div>
					))}
				</div>
				<div className="uq-menu-about-container">
					<MenuDropdown
						title={ABOUT_DROPDOWN_CONTENT.title}
						columns={[ABOUT_DROPDOWN_CONTENT.dropdown]}
						align="right"
					/>
					<LanguageDropdown />
				</div>
				<button
					type="button"
					className="uq-menu-burger"
					onClick={() => setBurgerOpen(!burgerOpen)}
				>
					<Icon icon={burgerOpen ? 'close' : 'burger'} className="uq-burger-icon" />
				</button>
			</div>
			<div
				className={classNames('uq-menu-mobile-content', {
					open: burgerOpen,
				})}
			>
				<div className="uq-menu-mobile-content-inner">
					<HexagonalCTA
						className="uq-mobile-cta-button"
						text={MENU_CONTENT[activeTab].cta.label}
						href={MENU_CONTENT[activeTab].cta.href}
						noArrow
					/>
					<nav className="uq-menu-mobile-nav">
						<ul className="uq-menu-mobile-list">
							{MENU_CONTENT.map((menuItem, index) => {
								const { label, mobileMenu } = menuItem;
								return (
									<MobileMenuItem
										label={label}
										menu={mobileMenu}
										// eslint-disable-next-line react/no-array-index-key
										key={index}
									/>
								);
							})}
							<MobileMenuItem
								label={ABOUT_DROPDOWN_CONTENT.title}
								menu={[ABOUT_DROPDOWN_CONTENT.dropdown]}
							/>
							<MobileLanguageDropdown />
						</ul>
					</nav>
				</div>
			</div>
		</div>
	);
};

export default Menu;
