import React from 'react';
import { NovaLogo } from '@novacredit/pandorasbox';

import Illustration from 'components/common/Illustration';

import amexLogo from 'assets/images/amex.png';

import './AmexPartner.scss';

const AmexPartner = () => {
	return (
		<div className="amex-partner text-left pt-lg-2">
			<div className="logos">
				<a
					className="amex-logo-link"
					href="https://www.americanexpress.com/us/credit-cards/features-benefits/us-newcomers/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<Illustration className="partners" src={amexLogo} />
				</a>
				<p className="separator" />
				<NovaLogo size="small" />
			</div>
			<p className="mt-1 amex-partner-text">
				Proud Partner of <strong>Nova Credit</strong>
			</p>
		</div>
	);
};

export default AmexPartner;
