import AsyncTaskQueue from '../AsyncTaskQueue';
import { retryWithTimeout } from '../helpers';
import type { InitializeOptions, TrackingDestination, TrackingOptions } from './destination';
import type { Env } from '../../types';
import type { TrackingEvent } from '../events';

declare global {
	interface Window {
		dataLayerGTM: object[];
	}
}

/**
 * Forwards event data to Google Tag Manager (GTM).
 *
 * Read the docs:
 *
 * https://developers.google.com/tag-platform/tag-manager/web
 * https://developers.google.com/tag-platform/tag-manager/web/datalayer
 *
 * Note: the `GoogleTagManager` must be rendered within the application using
 * this destination to persist events to GTM.
 */
class GoogleTagManager implements TrackingDestination {
	name = 'GoogleTagManager';
	queue: AsyncTaskQueue;
	appName: string;
	category: string;
	nodeEnv: Env | null = null;
	options: InitializeOptions['GoogleTagManager'] | null = null;

	constructor({ appName, category }: { appName: string; category: string }) {
		this.appName = appName;
		this.category = category;
		this.queue = new AsyncTaskQueue();
	}

	initialize({ nodeEnv, options }: { nodeEnv: Env; options?: InitializeOptions }): Promise<void> {
		this.nodeEnv = nodeEnv;
		this.options = options?.GoogleTagManager;
		return retryWithTimeout(this._checkGTMLoaded.bind(this), 10000, 250);
	}

	_checkGTMLoaded(): Promise<void> {
		return new Promise((resolve, reject) => {
			if (!window.dataLayerGTM) {
				reject(
					new Error(
						'window.dataLayerGTM not found. Is the GoogleTagManager component rendered?',
					),
				);
			} else {
				this.queue.activate();
				resolve();
			}
		});
	}

	track({
		event,
		properties,
		getDefaultProperties,
		options,
	}: {
		event: TrackingEvent;
		properties: object;
		getDefaultProperties: () => object;
		options?: TrackingOptions;
	}): Promise<void> {
		return this._track(() => {
			return new Promise<void>(resolve => {
				window.dataLayerGTM.push({
					event: event.displayName,
					appName: this.appName,
					category: this.category,
					...getDefaultProperties(),
					...properties,
				});
				resolve();
			});
		}, options);
	}

	trackPage({
		properties,
		getDefaultProperties,
		options,
	}: {
		properties: object;
		getDefaultProperties: () => object;
		options?: TrackingOptions;
	}): Promise<void> {
		return this._track(() => {
			return new Promise<void>(resolve => {
				window.dataLayerGTM.push({
					event: 'page_view',
					appName: this.appName,
					category: this.category,
					...getDefaultProperties(),
					...properties,
				});
				resolve();
			});
		}, options);
	}

	// eslint-disable-next-line class-methods-use-this
	alias(): Promise<void> {
		return Promise.resolve();
	}

	// eslint-disable-next-line class-methods-use-this
	identify(): Promise<void> {
		return Promise.resolve();
	}

	// eslint-disable-next-line class-methods-use-this
	setUserProps(): Promise<void> {
		return Promise.resolve();
	}

	_track(task: () => Promise<void>, options?: TrackingOptions): Promise<void> {
		if (options?.GoogleTagManager?.skip) {
			return Promise.resolve();
		}

		return this.queue.enqueue(() => {
			if (this.options?.skip) {
				return Promise.resolve();
			}

			if (!window.dataLayerGTM) {
				throw new Error(
					'Google Tag Manager tracking was called before Google Tag Manager loaded',
				);
			}

			return task();
		});
	}
}

export default GoogleTagManager;
