import React from 'react';

type Props = {
	href: string;
	children: React.ReactNode;
};

const MarkdownLink = ({ href, children }: Props) => {
	return (
		<a href={href} target="_blank" rel="noopener noreferrer">
			{children}
		</a>
	);
};

export default MarkdownLink;
