"use strict";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.removeValidFields = exports.isServer = exports.getValidationErrorHandler = exports.getListOfValidYears = exports.validateExactlyOneOf = exports.validateOneOf = exports.validateConditionallyRequired = exports.validateOptional = exports.validateRequired = exports.validate = void 0;
/**
 * Validate value using provided condition functions.
 * @param {any} value Value to be validated
 * @param {...Function} conditions Condition functions used to validate value
 * @return {Record<string, unknown>|undefined} undefined if value passes validations, error object otherwise
 */

var validate = function validate(value) {
  for (var i = 0; i < (arguments.length <= 1 ? 0 : arguments.length - 1); i++) {
    var condition = i + 1 < 1 || arguments.length <= i + 1 ? undefined : arguments[i + 1];
    var currentValidationResult = condition(value);

    if (typeof currentValidationResult === 'string' || _typeof(currentValidationResult) === 'object') {
      return currentValidationResult;
    }
  }

  return undefined;
};

exports.validate = validate;
/** @typedef {import('./types').FieldValidationResult} FieldValidationResult */

/**
 * Validate a required value. Trims whitespace from strings first.
 * @param {any} value Value to be validated
 * @param {...Function} conditions Condition functions used to validate value
 * @return {FieldValidationResult} undefined if value passes validations, error object otherwise
 */

var validateRequired = function validateRequired(value) {
  var trimmedValue = typeof value === 'string' ? value.trim() : value;

  for (var _len = arguments.length, conditions = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    conditions[_key - 1] = arguments[_key];
  }

  return trimmedValue || typeof trimmedValue === 'boolean' ? (0, exports.validate).apply(void 0, [trimmedValue].concat(conditions)) : {
    label: 'VALIDATION_ERROR_MESSAGE_REQUIRED'
  };
};

exports.validateRequired = validateRequired;
/**
 * Validate an optional value. Will not return an error if passed-in value is undefined. Trims
 * whitespace from strings first.
 * @param {any} value Value to be validated
 * @param {...Function} conditions Condition functions used to validate value
 * @return {FieldValidationResult} undefined if value passes validations, error object otherwise
 */

var validateOptional = function validateOptional(value) {
  var trimmedValue = typeof value === 'string' ? value.trim() : value;

  for (var _len2 = arguments.length, conditions = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
    conditions[_key2 - 1] = arguments[_key2];
  }

  return trimmedValue || typeof trimmedValue === 'boolean' ? (0, exports.validate).apply(void 0, [trimmedValue].concat(conditions)) : undefined;
};

exports.validateOptional = validateOptional;
/**
 * Conditionally validate a value to be required or optional.
 * Depending on condition, it will return an error if passed-in value is underfined.
 *
 * @param {bool} condition
 * @param {any} validateValue
 * @param  {...Function} validateConditions
 * @returns {void}
 */

var validateConditionallyRequired = function validateConditionallyRequired(condition, validateValue) {
  for (var _len3 = arguments.length, validateConditions = new Array(_len3 > 2 ? _len3 - 2 : 0), _key3 = 2; _key3 < _len3; _key3++) {
    validateConditions[_key3 - 2] = arguments[_key3];
  }

  return condition ? (0, exports.validateRequired).apply(void 0, [validateValue].concat(validateConditions)) : undefined;
};

exports.validateConditionallyRequired = validateConditionallyRequired;
/**
 * Validate one or more required members of set
 * @param {any} values Values to be validated
 * @return {FieldValidationResult} validation result
 */

var validateOneOf = function validateOneOf() {
  var values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var hasOne = values.some(function (value) {
    var trimmedValue = typeof value === 'string' ? value.trim() : value;
    return trimmedValue || typeof trimmedValue === 'boolean';
  });
  return hasOne ? undefined : {
    label: 'VALIDATION_ERROR_MESSAGE_REQUIRED'
  };
};

exports.validateOneOf = validateOneOf;
/**
 * Validate that exactly one of the members of the set exists
 * @param {Array<boolean>} values An array of values to check
 * @returns {Object|undefined} Will return undefined on success and an object describing an error on failure
 */

var validateExactlyOneOf = function validateExactlyOneOf() {
  var values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var res = values.map(function (value) {
    var trimmedValue = typeof value === 'string' ? value.trim() : value;
    return trimmedValue === '' || typeof trimmedValue === 'boolean' && Boolean(trimmedValue);
  }).filter(function (x) {
    return x === true;
  });
  return res.length === 1 ? undefined : {
    label: 'VALIDATION_ERROR_MESSAGE_REQUIRED'
  };
};

exports.validateExactlyOneOf = validateExactlyOneOf;
/**
 * Generate an array of valid birth years.
 * @param {number} [currentYear]
 * @returns {Array<Number>} List of valid years
 */

var getListOfValidYears = function getListOfValidYears() {
  var currentYear = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Date().getUTCFullYear();
  var MIN_AGE = 18;
  var MAX_AGE = 100;
  return Array.from({
    length: MAX_AGE - MIN_AGE + 1
  }, function (_, index) {
    return currentYear - MIN_AGE - index;
  });
};

exports.getListOfValidYears = getListOfValidYears;
/** @typedef {(errors: Record<string, FieldValidationResult>, scope: string) => Promise<void>} ValidationErrorHandler */

/**
 * Handle backend validation error responses. Curry function where
 * first evaluation provides zeus-specific logger config to keep the validations package isomorphic
 * @param  {Object} logger           log error
 * @param  {String} dataSupplierCode e.g. IND_CHM
 * @param  {Object} commonMaps)      to have our common error maps
 * @return {ValidationErrorHandler} validation handler
 */

var getValidationErrorHandler = function getValidationErrorHandler(logger, dataSupplierCode, commonMaps) {
  return function (errors) {
    var scope = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'unknown';
    var fieldsWithError = Object.entries(errors).filter(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          validationError = _ref2[1];

      return validationError && typeof validationError.label === 'string';
    }); // log error when zeus

    if (logger) {
      fieldsWithError.forEach(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
            field = _ref4[0],
            error = _ref4[1];

        logger.warnLevel(null, "".concat(dataSupplierCode, ".").concat(scope, ".").concat(field, " validation failed with error ").concat(error.label, "."), {
          errors: errors
        });
      });
    }

    if (fieldsWithError[0]) {
      var errorMsg = commonMaps ? commonMaps.errors.MALFORMED_BODY : 'Field failed validation';
      return Promise.reject(new Error(errorMsg));
    }

    return Promise.resolve();
  };
};

exports.getValidationErrorHandler = getValidationErrorHandler;
/**
 * Quick check to determine if we're running validation in a NodeJS context.
 * This depends on Node's immutable `process` global which is an instantiation of the `Process` class
 * @returns {Boolean} true if server
 */

var isServer = function isServer() {
  return Object.prototype.toString.call(typeof process !== 'undefined' ? process : 0) === '[object process]';
};

exports.isServer = isServer;
/**
 * Removes valid fields from errors obj because they are just `undefined`
 * @param  {Object} errors validation errors before being handled
 * @return {Object} new errors object only containing keys with truthy values
 */

var removeValidFields = function removeValidFields(errors) {
  return Object.entries(errors).reduce(function (acc, _ref5) {
    var _ref6 = _slicedToArray(_ref5, 2),
        key = _ref6[0],
        value = _ref6[1];

    if (value) {
      acc[key] = value;
    }

    return acc;
  }, {});
};

exports.removeValidFields = removeValidFields;
exports["default"] = {
  validate: exports.validate,
  validateRequired: exports.validateRequired,
  validateOptional: exports.validateOptional,
  validateConditionallyRequired: exports.validateConditionallyRequired,
  validateOneOf: exports.validateOneOf,
  validateExactlyOneOf: exports.validateExactlyOneOf,
  getListOfValidYears: exports.getListOfValidYears,
  getValidationErrorHandler: exports.getValidationErrorHandler,
  isServer: exports.isServer,
  removeValidFields: exports.removeValidFields
};