import type { ConsentMap } from '../../consent/common';
import { consentGrantedForService } from '../../consent/common';
import AsyncTaskQueue from '../AsyncTaskQueue';
import { loadFacebookPixel, updateFacebookConsent } from '../facebook';
import type { Env } from '../../types';
import type { InitializeOptions, TrackingDestination, TrackingOptions } from './destination';
import type { TrackingEvent } from '../events';

// The Facebook Pixel loading script in `loadFacebookPixel` sets `window.fbq`.
declare global {
	interface Window {
		fbq: Function | null;
	}
}

const FACEBOOK_PIXEL_ID = {
	development: '812299596296169',
	staging: '261116608949974',
	production: '297766240602824',
};

/**
 * This destination does not currently support Facebook's "Advanced Matching" tool
 * but could be enhanced to do so in the future. See:
 *  - https://www.facebook.com/business/help/611774685654668?id=1205376682832142
 *  - https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
 *
 * Advanced matching data must be passed in during the fbq('init') call, which
 * creates some implementation challenges since user PII likely is not available
 * until much later.
 */
const ADVANCED_MATCHING_DATA = {};

// https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
const STANDARD_EVENTS = [
	'AddPaymentInfo',
	'AddToCart',
	'AddToWishlist',
	'CompleteRegistration',
	'Contact',
	'CustomizeProduct',
	'Donate',
	'FindLocation',
	'InitiateCheckout',
	'Lead',
	'PageView',
	'Purchase',
	'Schedule',
	'Search',
	'StartTrial',
	'SubmitApplication',
	'Subscribe',
	'ViewContent',
];

/**
 * A tracking destination that forwards client-side tracking events to Facebook
 * Business manager via their Facebook Pixel platform.
 *
 * Read the documentation at:
 *    - https://developers.facebook.com/docs/facebook-pixel/implementation
 *
 * Facebook Pixel includes some "standard events" that we use like PageView
 * and Purchase. It's important that these events fire with event names that
 * conform to Facebook's expectations, as they may show up differently within
 * the Facebook Ads Manager UI.
 *
 * It also supports "custom events" that we generate for Nova-specific eventing
 * like "Country Selected" or "Widget Opened". Unlike "standard events", we can
 * name these whatever we'd like.
 *
 * When debugging this tracking destination, you may wish to use the Facebook Pixel
 * Helper, a chrome extension maintained by Facebook:
 *    - https://chrome.google.com/webstore/detail/facebook-pixel-helper/fdgfkebogiimcoedlicjlajpkdmockpc?hl=en
 *
 * When considering the nuances of this destination vs. others, the Segment documentation
 * provides some insights:
 *    - https://segment.com/docs/connections/destinations/catalog/facebook-pixel/
 */
class FacebookPixel implements TrackingDestination {
	name = 'Facebook Pixel';

	appName: string;
	category: string;
	consentGranted: boolean;
	options: InitializeOptions['FacebookPixel'] | null;
	queue: AsyncTaskQueue;

	constructor({ appName, category }: { appName: string; category: string }) {
		this.appName = appName;
		this.category = category;
		this.consentGranted = false;
		this.queue = new AsyncTaskQueue();
	}

	/**
	 * Calls the Facebook provided pixel loading script.
	 */
	initialize({ nodeEnv, options }: { nodeEnv: Env; options?: InitializeOptions }): Promise<void> {
		this.options = options?.FacebookPixel;
		const facebookPixelId = FACEBOOK_PIXEL_ID[nodeEnv];
		const consentGranted = options?.enableCookieConsent ? this.consentGranted : true;
		loadFacebookPixel(facebookPixelId, ADVANCED_MATCHING_DATA, consentGranted);

		this.queue.activate();
		return Promise.resolve();
	}

	cookieConsentUpdated(consents: ConsentMap): void {
		this.consentGranted = consentGrantedForService(consents, 'FacebookPixel');
		updateFacebookConsent(this.consentGranted);
	}

	/**
	 * Send tracking event data to Facebook. If the event.displayName matches a "standard event",
	 * we will send it to Facebook Pixel via `fbq('track', ...)`, otherwise we will treat the event
	 * as a "custom event" and call `fbq('trackCustom', ...)`.
	 */
	track({
		event,
		properties,
		getDefaultProperties,
		options,
	}: {
		event: TrackingEvent;
		properties: object;
		getDefaultProperties: () => object;
		options?: TrackingOptions;
	}): Promise<void> {
		const fbqMethod = STANDARD_EVENTS.includes(event.displayName) ? 'track' : 'trackCustom';
		return this._track({
			fbqMethod,
			eventName: event.displayName,
			properties,
			getDefaultProperties,
			options,
		});
	}

	/**
	 * Facebook Pixel has no analog for `alias` events.
	 */
	// eslint-disable-next-line class-methods-use-this
	alias(): Promise<void> {
		return Promise.resolve();
	}

	/**
	 * Facebook Pixel has no precise analog for `identify` events, though Segment routes some
	 * `identify` data to Facebook's Advanced Matching feature. Since we avoid putting PII in
	 * identify calls, replicating Segment's approach would have no effect.
	 */
	// eslint-disable-next-line class-methods-use-this
	identify(): Promise<void> {
		return Promise.resolve();
	}

	/**
	 * Facebook Pixel has no analog for `setUserProps` events.
	 */
	// eslint-disable-next-line class-methods-use-this
	setUserProps(): Promise<void> {
		return Promise.resolve();
	}

	/**
	 * Sends page view events to Facebook Pixel via fbq('track', 'PageView', ...)
	 */
	trackPage({
		properties,
		getDefaultProperties,
		options,
	}: {
		properties: object;
		getDefaultProperties: () => object;
		options?: TrackingOptions;
	}): Promise<void> {
		return this._track({
			fbqMethod: 'track',
			eventName: 'PageView',
			properties,
			getDefaultProperties,
			options,
		});
	}

	_track({
		fbqMethod,
		eventName,
		properties,
		getDefaultProperties,
		options,
	}: {
		fbqMethod: 'track' | 'trackCustom';
		eventName: string;
		properties: object;
		getDefaultProperties: () => object;
		options?: TrackingOptions;
	}): Promise<void> {
		if (options?.FacebookPixel?.skip) {
			return Promise.resolve();
		}

		return this.queue.enqueue(() => {
			if (this.options?.skip) {
				return Promise.resolve();
			}

			if (!window.fbq) {
				throw new Error('Facebook Pixel was called before being loaded');
			}

			const _properties = {
				appName: this.appName,
				category: this.category,
				...getDefaultProperties(),
				...properties,
			};

			window.fbq(fbqMethod, eventName, _properties);
			// The `fbq` method does not provide a callback or return with a promise, so we
			// assume that the call succeeded here and resolve.
			return Promise.resolve();
		});
	}
}

export default FacebookPixel;
