import React, { createContext, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMount } from '@novacredit/frontend-common/hooks';
import type { EventSchema } from '@novacredit/frontend-common/tracking';
import type { CountryCode } from '@novacredit/frontend-common/utils/countries';
import { getSimpleCountry } from '@novacredit/frontend-common/utils/countries';
import { getQueryParams } from '@novacredit/frontend-common/utils/location';
import {
	COOKIES,
	getNovaCookie,
	setNovaCookie,
} from '@novacredit/frontend-common/utils/novaCookies';

import { tracker } from 'modules/tracking';

type SetStoredCountryFn = (
	country: string,
	trackingProps: {
		location: EventSchema['nova.client.COUNTRY_SELECTED']['location'];
		page: EventSchema['nova.client.COUNTRY_SELECTED']['page'];
	},
) => Promise<void[]>;

type StoredCountryContextShape = {
	setStoredCountry: SetStoredCountryFn;
	storedCountry: CountryCode;
};

const StoredCountryContext = createContext<StoredCountryContextShape>({
	storedCountry: null,
	setStoredCountry: null,
});

type Props = {
	children: React.ReactNode;
};

/**
 * This React Context stores the user's arrival country
 * in cookies. On subsequent visits and pages, we can reload this state from cookies.
 */
const getCountryFromCookie = (): CountryCode => {
	const countryFromCookie = getNovaCookie(COOKIES.CARDSHOP_COUNTRY);

	// Check if cookie is present
	if (!countryFromCookie) {
		return null;
	}

	const countryCode = countryFromCookie.toUpperCase();
	const country = getSimpleCountry(countryCode);

	// Check if cookie contains a valid country code
	if (!country) {
		return null;
	}
	return country.code;
};

export const StoredCountryProvider = ({ children }: Props) => {
	const [countryState, setCountryState] = useState<CountryCode>(getCountryFromCookie());
	const location = useLocation();

	// Responsible for 1) updating react state, 2) updating cookie, 3) tracking
	// Returns a promise so that the caller can await tracking before executing
	// a subsequent task, like redirecting to a different website.
	const setStoredCountry: SetStoredCountryFn = (country, trackingProps): Promise<void[]> => {
		const { code: validCountryCode } = getSimpleCountry(country) || {};

		if (!validCountryCode) {
			return Promise.all([]);
		}

		setCountryState(validCountryCode);
		setNovaCookie(COOKIES.CARDSHOP_COUNTRY, validCountryCode || '');
		tracker.setDefaultProperties({ country: validCountryCode });
		return Promise.all([
			tracker.setUserProps({ country: validCountryCode }),
			tracker.track('nova.client.COUNTRY_SELECTED', {
				...trackingProps,
				country: validCountryCode,
			}),
		]);
	};

	useMount(() => {
		// @ts-expect-error
		const queryParams = getQueryParams(location);

		if (queryParams.country) {
			setStoredCountry(queryParams.country.toUpperCase(), {
				page: 'Consumer Dashboard Query Params (all)',
				location: 'URL Query Parameters',
			});
		}
	});

	return (
		<StoredCountryContext.Provider value={{ storedCountry: countryState, setStoredCountry }}>
			{children}
		</StoredCountryContext.Provider>
	);
};

export const useStoredCountryContext = () => {
	return useContext(StoredCountryContext);
};

export default StoredCountryContext;
