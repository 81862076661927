import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import { DesignSystemButton, DesignSystemSelect } from '@novacredit/pandorasbox';

import { Row, Col } from 'components/common/Layout';
import type { OnboardingQuestion } from 'components/common/Onboarding/OnboardingQuestions';
import 'components/common/Onboarding/SurveyDatePicker.scss';

const MOBILE_WIDTH = 720;

const MOVE_DATE_MONTHS = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

const thisYear = new Date().getFullYear();
const MOVE_DATE_YEARS_FUTURE = [thisYear, thisYear + 1, thisYear + 2, thisYear + 3];
const MOVE_DATE_YEARS_PAST = [thisYear, thisYear - 1, thisYear - 2, thisYear - 3];

type Props = {
	currQuestion: OnboardingQuestion;
	onButtonClick: (value, nextQuestion) => void;
};

const SurveyDatePicker = ({ currQuestion, onButtonClick }: Props) => {
	const [month, setMonth] = useState('');
	const [year, setYear] = useState('');
	const createDropdownOptions = optionArray => {
		return optionArray.map((option, index) => ({
			key: index,
			value: option,
			label: option,
		}));
	};
	// determine whether we show future or past move dates from the question
	const years =
		currQuestion.onboardingAnswerKey === 'arrivalDate'
			? MOVE_DATE_YEARS_PAST
			: MOVE_DATE_YEARS_FUTURE;
	const yearOptions = createDropdownOptions(years);
	const monthOptions = createDropdownOptions(MOVE_DATE_MONTHS);
	const datePickerOption = currQuestion.options[0];
	const ctaOption = currQuestion.options[1];

	const isMobile = useMemo(() => {
		if (typeof window !== 'undefined') {
			return window.innerWidth <= MOBILE_WIDTH;
		} else {
			return false;
		}
	}, []);

	return (
		<div className="survey-date-picker">
			<Row className="survey-date-picker-row">
				<Col size={{ sm: 3 }} className="survey-date-picker-dropdown">
					<DesignSystemSelect
						inputName="Month"
						placeholder="Month"
						isSearchable={false}
						data={monthOptions}
						maxHeight={330}
						onChange={newMonth => setMonth(newMonth as string)}
						isMobile={isMobile}
						value={month}
					/>
				</Col>
				<Col size={{ sm: 3 }} className="survey-date-picker-dropdown">
					<DesignSystemSelect
						inputName="Year"
						placeholder="Year"
						isSearchable={false}
						data={yearOptions}
						maxHeight={330}
						onChange={newYear => setYear(newYear as string)}
						isMobile={isMobile}
						value={year}
					/>
				</Col>
			</Row>

			<Row className="survey-date-picker-row">
				<DesignSystemButton
					className="skip-date-picker-question-button"
					color="ghost"
					type="submit"
					tag="button"
					variant="ghost"
					onClick={() => onButtonClick(ctaOption.value, ctaOption.nextQuestion)}
				>
					{ctaOption.label}
				</DesignSystemButton>
			</Row>
			<Row className="survey-button-container">
				<DesignSystemButton
					variant="outline"
					tag="button"
					onClick={() => {
						// convert date into isoString, use the first of the month by default
						const moveDate = new Date(`${year}, ${month}, 1`);
						onButtonClick(moveDate, datePickerOption.prevQuestion);
					}}
				>
					Back
				</DesignSystemButton>
				<DesignSystemButton
					className={classnames({
						disabled: !month || !year,
					})}
					color="primary"
					type="submit"
					tag="button"
					disabled={!month || !year}
					onClick={() => {
						// convert date into isoString, use the first of the month by default
						const moveDate = new Date(`${year}, ${month}, 1`);
						onButtonClick(moveDate, datePickerOption.nextQuestion);
					}}
				>
					Next
				</DesignSystemButton>
			</Row>
		</div>
	);
};

export default SurveyDatePicker;
