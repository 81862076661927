import classnames from 'classnames';
import React from 'react';

import './Heading.scss';

type Props = {
	className?: string;
	disclosure?: string;
	subtitle?: string | any[];
	subtitleAs?: React.ElementType;
	title: string;
	titleAs?: React.ElementType;
};

const Heading = ({
	className,
	subtitle,
	subtitleAs: Subtitle = 'p',
	title,
	titleAs: Title = 'h1',
	disclosure,
}: Props) => (
	<header className={classnames('text-header', className)}>
		<Title className="title">{title}</Title>
		{
			// can accept multiple subtitles, which will be displayed with a newline between each. If arguments are
			// just passed as as singular strings, only one subtitle will be shown.
			subtitle && Array.isArray(subtitle)
				? subtitle.map(elem => (
						<Subtitle key={elem.props.children} className="subtitle">
							{elem}
						</Subtitle>
				  ))
				: subtitle && <Subtitle className="subtitle">{subtitle}</Subtitle>
		}
		{disclosure && <div className="disclosure">{disclosure}</div>}
	</header>
);

export default Heading;
