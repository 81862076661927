import React from 'react';
import classNames from 'classnames';

import type { TypographicVariant } from './Typography.styled';
import Styled from './Typography.styled';

type TypographyProps<T extends React.ElementType> = {
	children: React.ReactNode;
	className?: string;
	/** The element's tag. This allows us to have h2's that look like Heading 1, for example */
	tag: T;
	color?: string;
	onClick?: React.MouseEventHandler;
	/** The variants defined in the design system */
	variant: TypographicVariant;
	/**
	 * Optionally allows for mobile-only styling, primarily used
	 * to reduce the font size on small screens. This is a temporary
	 * solution until we resolve https://novacredit.atlassian.net/browse/ENG-14235
	 */
	variantMobile?: TypographicVariant;
};

const Typography = <T extends React.ElementType>(
	{
		children,
		className = null,
		tag,
		variant,
		variantMobile,
		onClick,
		color,
		...attributes
	}: TypographyProps<T> & Omit<React.ComponentProps<T>, keyof TypographyProps<T>>,
	ref: React.Ref<HTMLDivElement>,
) => (
	<Styled.Typography
		as={tag}
		onClick={onClick}
		className={classNames('ds-typography', className)}
		variant={variant}
		variantMobile={variantMobile}
		color={color}
		ref={ref}
		{...attributes}
	>
		{children}
	</Styled.Typography>
);

const TypographyWrapper = React.forwardRef(Typography) as <T extends React.ElementType>(
	props: TypographyProps<T> &
		Omit<React.ComponentProps<T>, keyof TypographyProps<T>> & {
			ref?: React.Ref<HTMLDivElement>;
		},
) => React.ReactElement;

export default TypographyWrapper;
