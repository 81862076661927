import React from 'react';

import { ENV } from 'consts';
import { getCmsURL, getNodeEnv, isPrPreview } from 'utils';

const getCardImageUrl = card => {
	const nodeEnv = getNodeEnv();

	if (nodeEnv === ENV.development && !isPrPreview()) {
		return card.image_url;
	}

	return `${getCmsURL()}${card.image_url}`;
};

type Props = {
	card: any;
	className?: string;
};

const CardImage = ({ card, className }: Props) => {
	const imageUrl = getCardImageUrl(card);

	return <img className={className} alt={card.name} src={imageUrl} />;
};
export default CardImage;
