import React, { createContext, useCallback, useState } from 'react';
import {
	COOKIES,
	getNovaCookie,
	setNovaCookie,
} from '@novacredit/frontend-common/utils/novaCookies';
import { captureException } from '@novacredit/frontend-common/services/sentry';

import { LANGUAGE_NAME_MAP } from 'consts';

type LanguageContextShape = {
	language: string;
	setLanguage: (language: string) => void;
};

type Props = {
	children: React.ReactNode;
};

export const LanguageContext = createContext<LanguageContextShape>(null);

const DEFAULT_LANGUAGE = 'en';

const getInitialLanguage = () => {
	const fromCookie = getNovaCookie(COOKIES.LANGUAGE);
	if (fromCookie && LANGUAGE_NAME_MAP[fromCookie]) {
		return fromCookie;
	} else if (fromCookie) {
		// unforeseen language value, revert to default
		captureException(
			new Error(`Unforeseen language value received, reverting to english [${fromCookie}]`)
		);
	}
	setNovaCookie(COOKIES.LANGUAGE, DEFAULT_LANGUAGE);
	return DEFAULT_LANGUAGE;
};

export const LanguageProvider = ({ children }: Props) => {
	const [language, setLanguage] = useState<string>(getInitialLanguage());

	const setLanguageAndCookie = useCallback(
		newValue => {
			setNovaCookie(COOKIES.LANGUAGE, newValue);
			setLanguage(newValue);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[language]
	);

	return (
		<LanguageContext.Provider
			value={{
				language,
				setLanguage: setLanguageAndCookie,
			}}
		>
			{children}
		</LanguageContext.Provider>
	);
};

export default LanguageContext;
