const internetDisconnectedErrors = new Set([
	'network error', // Chrome
	'Failed to fetch', // Chrome
	'NetworkError when attempting to fetch resource.', // Firefox
	'The Internet connection appears to be offline.', // Safari 16
	'Load failed', // Safari 17+
]);

const isFailedToFetchError = (err: Error) =>
	err && err.name === 'TypeError' && internetDisconnectedErrors.has(err.message);

export const post = (url: string, body: {}): Promise<Response> => {
	return fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	});
};

/**
 * Function that returns a promise that resolves after specified delay.
 * @param {Number} delayPeriod Delay timeout period in milliseconds
 */
export const delay = (
	delayPeriod: number,
	options?: {
		onCreateTimeout: (timeoutId: NodeJS.Timeout) => void;
		onFinishTimeout: (timeoutId: NodeJS.Timeout) => void;
	},
) =>
	new Promise<void>(resolve => {
		const timeoutId = setTimeout(() => {
			resolve();
			options?.onFinishTimeout(timeoutId);
		}, delayPeriod);
		options?.onCreateTimeout(timeoutId);
	});

export type DelayOptions = {
	/** Number of retries before the promise is rejected */
	retries?: number;
	/** Initial delay before the first retry */
	initialDelay?: number;
	/** Maximum delay between retries */
	maxDelay?: number;
	/** Factor used to increase delay intervals (exp. base) */
	factor?: number;
	/** Delay function */
	delayFn?: (delayPeriod: number) => Promise<void>;
	isRetriableError: (err: Error) => boolean;
};

/**
 * Given a promise creator (function that returns a promise), wraps it in a new promise creator that
 * retries failed network requests. The retry logic uses exponential backoff. Only "retriable"
 * errors are retried, all other errors result in the whole promise rejecting immediately.
 * @param {Function} promiseCreator Any function that returns a promise
 * @param {DelayOptions} options
 * @returns {Function} A new promise creator with a timeout
 */
const withRetry =
	<TArgs extends unknown[], TResult>(
		promiseCreator: (...params: TArgs) => Promise<TResult>,
		{
			retries,
			initialDelay,
			maxDelay,
			factor,
			delayFn = delay,
			isRetriableError,
		}: DelayOptions,
	) =>
	(...args: TArgs): Promise<TResult> => {
		const retrier = (
			retriesRemaining: number,
			currentDelay: number,
			error = null,
			firstAttempt = false,
			// eslint-disable-next-line max-params
		) => {
			if (typeof retriesRemaining !== 'number' || retriesRemaining <= 0) {
				// No retries left, fail request
				return Promise.reject(new Error(error?.message || 'MAX_RETRIES_EXCEEDED'));
			}

			// Don't delay the first attempt
			const taskPromise = firstAttempt
				? promiseCreator(...args)
				: delayFn(currentDelay).then(() => promiseCreator(...args));
			return taskPromise.catch(err => {
				// Only retry certain errors, such as "Failed to fetch" or "Request timeout"
				if (isRetriableError(err)) {
					// Previous attempt failed with a retriable error type
					const nextDelay = currentDelay * factor ?? 0;
					return retrier(
						retriesRemaining - 1,
						Math.min(nextDelay, maxDelay ?? nextDelay),
						err,
						false,
					);
				}
				// Some other error occurred, bubble it up
				throw err;
			});
		};

		// Execute async task
		return retrier(retries, initialDelay, null, true);
	};

export default { delay, isFailedToFetchError, post, withRetry };
