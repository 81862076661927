import { useEffect } from 'react';

const useEventListener = (eventName, callback, target) => {
  useEffect(() => {
    // Default to `document` here rather than as a default parameter
    // to avoid breaking SSR on the CMS.
    const targetOrDocument = target || document;
    targetOrDocument.addEventListener(eventName, callback);
    return () => {
      targetOrDocument.removeEventListener(eventName, callback);
    };
  }, [eventName, callback, target]);
};

export { useEventListener as default };
