import React from 'react';
import classnames from 'classnames';
import RadioInputButton from './RadioInputButton.js';
import Fieldset from './Fieldset.js';
import { s as styleInject } from './style-inject.es-tgCJW-Cu.js';
import './DesignSystemFormLabel.js';
import './DesignSystemColors.js';

var css_248z = ":root {\n  /**\n   * These CSS variables can be directly overridden by a customer\n   * style configuration.\n   */\n  --color-primary: #2563eb;\n  --font-family: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;\n  --corner-radius: 4px;\n  /**\n   * These CSS variables can be indirectly overridden by a customer\n   * style configuration, for example, by passing in an alternate\n   * primary color.\n   */\n  --color-primary-hover: #1d4ed8;\n  --color-primary-active: #1e40af;\n  --color-primary-background: #eff6ff;\n  --color-primary-shade: #dbeafe;\n  /**\n   * Neutrals for elements like radio inputs, checkboxes, links, and menus\n   */\n  --color-neutral-active: #111827;\n  --color-neutral-inactive: #111827;\n  --color-neutral-active-secondary: #f3f4f6;\n  --color-neutral-hover: #f3f4f6;\n  /**\n   * Status related colors.\n   */\n  --color-info: #2563eb;\n  --color-success: #047857;\n  --color-error: #e11d48;\n  /**\n   * Focus\n   */\n  --color-focus: #2563eb;\n  /**\n   * These css variables cannot be overridden by a customer. They are included\n   * here for consistency should we want to make them customizable in the future.\n   */\n  --font-size-default: 16px;\n  --font-size-small: 14px;\n  --color-primary-invert: white;\n  --color-background: white;\n  --color-input-bg-hover: #f3f4f6;\n  --color-secondary: white;\n  --color-text: #111827;\n  --color-text-secondary: #4b5563;\n}\n\n.ds-radio-input-button-group .ds-form-label {\n  margin-bottom: 8px;\n}\n.ds-radio-input-button-group .fields {\n  gap: 16px;\n}";
styleInject(css_248z);

/**
 * Renders a set of a <input type="radio" /> controls as part of
 * a fieldset. All inputs will have the same name specified by
 * `inputName`. The `label` prop refers to the label of the overall
 * group, and each input option has its own `label` within the `options`
 * list.
 *
 * For use recommendations, see:
 * https://www.w3.org/TR/2016/WD-wai-aria-practices-1.1-20160317/examples/radio/radio.html
 */

const RadioInputButtonGroup = ({
  inputName,
  value,
  options,
  label,
  labelId,
  className,
  disabled,
  horizontalList,
  onCheck
}) => React.createElement(Fieldset, {
  label: label,
  labelId: labelId,
  role: "radiogroup",
  className: classnames('ds-radio-input-button-group', className),
  horizontalList: horizontalList
}, options.map(option => React.createElement(RadioInputButton, {
  className: "radio-button-in-group",
  key: option.value,
  label: option.label,
  value: option.value,
  inputName: inputName,
  onCheck: onCheck,
  checked: option.value === value,
  disabled: option.disabled || disabled
})));

export { RadioInputButtonGroup as default };
