const COUNTRY_MAP = {
	AFG: { name: 'Afghanistan', code: 'AFG' },
	// ALA: { name: 'Åland Islands', code: 'ALA' },
	ALB: { name: 'Albania', code: 'ALB' },
	DZA: { name: 'Algeria', code: 'DZA' },
	ASM: { name: 'American Samoa', code: 'ASM' },
	AND: { name: 'Andorra', code: 'AND' },
	AGO: { name: 'Angola', code: 'AGO' },
	AIA: { name: 'Anguilla', code: 'AIA' },
	// ATA: { name: 'Antarctica', code: 'ATA' },
	ATG: { name: 'Antigua and Barbuda', code: 'ATG' },
	ARG: { name: 'Argentina', code: 'ARG' },
	ARM: { name: 'Armenia', code: 'ARM' },
	ABW: { name: 'Aruba', code: 'ABW' },
	AUS: { name: 'Australia', code: 'AUS' },
	AUT: { name: 'Austria', code: 'AUT' },
	AZE: { name: 'Azerbaijan', code: 'AZE' },
	BHS: { name: 'Bahamas', code: 'BHS' },
	BHR: { name: 'Bahrain', code: 'BHR' },
	BGD: { name: 'Bangladesh', code: 'BGD' },
	BRB: { name: 'Barbados', code: 'BRB' },
	BLR: { name: 'Belarus', code: 'BLR' },
	BEL: { name: 'Belgium', code: 'BEL' },
	BLZ: { name: 'Belize', code: 'BLZ' },
	BEN: { name: 'Benin', code: 'BEN' },
	BMU: { name: 'Bermuda', code: 'BMU' },
	BTN: { name: 'Bhutan', code: 'BTN' },
	BOL: { name: 'Bolivia (Plurinational State of)', code: 'BOL' },
	BES: { name: 'Bonaire, Sint Eustatius and Saba', code: 'BES' },
	BIH: { name: 'Bosnia and Herzegovina', code: 'BIH' },
	BWA: { name: 'Botswana', code: 'BWA' },
	// BVT: { name: 'Bouvet Island', code: 'BVT' },
	BRA: { name: 'Brazil', code: 'BRA' },
	// IOT: { name: 'British Indian Ocean Territory', code: 'IOT' },
	BRN: { name: 'Brunei Darussalam', code: 'BRN' },
	BGR: { name: 'Bulgaria', code: 'BGR' },
	BFA: { name: 'Burkina Faso', code: 'BFA' },
	BDI: { name: 'Burundi', code: 'BDI' },
	CPV: { name: 'Cabo Verde', code: 'CPV' },
	KHM: { name: 'Cambodia', code: 'KHM' },
	CMR: { name: 'Cameroon', code: 'CMR' },
	CAN: { name: 'Canada', code: 'CAN' },
	CYM: { name: 'Cayman Islands', code: 'CYM' },
	CAF: { name: 'Central African Republic', code: 'CAF' },
	TCD: { name: 'Chad', code: 'TCD' },
	CHL: { name: 'Chile', code: 'CHL' },
	CHN: { name: 'China', code: 'CHN' },
	CXR: { name: 'Christmas Island', code: 'CXR' },
	CCK: { name: 'Cocos (Keeling) Islands', code: 'CCK' },
	COL: { name: 'Colombia', code: 'COL' },
	COM: { name: 'Comoros', code: 'COM' },
	COG: { name: 'Congo', code: 'COG' },
	COD: { name: 'Congo, Democratic Republic of the', code: 'COD' },
	COK: { name: 'Cook Islands', code: 'COK' },
	CRI: { name: 'Costa Rica', code: 'CRI' },
	CIV: { name: "Côte d'Ivoire", code: 'CIV' },
	HRV: { name: 'Croatia', code: 'HRV' },
	CUB: { name: 'Cuba', code: 'CUB' },
	CUW: { name: 'Curaçao', code: 'CUW' },
	CYP: { name: 'Cyprus', code: 'CYP' },
	CZE: { name: 'Czechia', code: 'CZE' },
	DNK: { name: 'Denmark', code: 'DNK' },
	DJI: { name: 'Djibouti', code: 'DJI' },
	DMA: { name: 'Dominica', code: 'DMA' },
	DOM: { name: 'Dominican Republic', code: 'DOM' },
	ECU: { name: 'Ecuador', code: 'ECU' },
	EGY: { name: 'Egypt', code: 'EGY' },
	SLV: { name: 'El Salvador', code: 'SLV' },
	GNQ: { name: 'Equatorial Guinea', code: 'GNQ' },
	ERI: { name: 'Eritrea', code: 'ERI' },
	EST: { name: 'Estonia', code: 'EST' },
	SWZ: { name: 'Eswatini', code: 'SWZ' },
	ETH: { name: 'Ethiopia', code: 'ETH' },
	FLK: { name: 'Falkland Islands (Malvinas)', code: 'FLK' },
	FRO: { name: 'Faroe Islands', code: 'FRO' },
	FJI: { name: 'Fiji', code: 'FJI' },
	FIN: { name: 'Finland', code: 'FIN' },
	FRA: { name: 'France', code: 'FRA' },
	GUF: { name: 'French Guiana', code: 'GUF' },
	PYF: { name: 'French Polynesia', code: 'PYF' },
	// ATF: { name: 'French Southern Territories', code: 'ATF' },
	GAB: { name: 'Gabon', code: 'GAB' },
	GMB: { name: 'Gambia', code: 'GMB' },
	GEO: { name: 'Georgia', code: 'GEO' },
	DEU: { name: 'Germany', code: 'DEU' },
	GHA: { name: 'Ghana', code: 'GHA' },
	GIB: { name: 'Gibraltar', code: 'GIB' },
	GRC: { name: 'Greece', code: 'GRC' },
	GRL: { name: 'Greenland', code: 'GRL' },
	GRD: { name: 'Grenada', code: 'GRD' },
	GLP: { name: 'Guadeloupe', code: 'GLP' },
	GUM: { name: 'Guam', code: 'GUM' },
	GTM: { name: 'Guatemala', code: 'GTM' },
	GGY: { name: 'Guernsey', code: 'GGY' },
	GIN: { name: 'Guinea', code: 'GIN' },
	GNB: { name: 'Guinea-Bissau', code: 'GNB' },
	GUY: { name: 'Guyana', code: 'GUY' },
	HTI: { name: 'Haiti', code: 'HTI' },
	// HMD: { name: 'Heard Island and McDonald Islands', code: 'HMD' },
	VAT: { name: 'Holy See', code: 'VAT' },
	HND: { name: 'Honduras', code: 'HND' },
	HKG: { name: 'Hong Kong', code: 'HKG' },
	HUN: { name: 'Hungary', code: 'HUN' },
	ISL: { name: 'Iceland', code: 'ISL' },
	IND: { name: 'India', code: 'IND' },
	IDN: { name: 'Indonesia', code: 'IDN' },
	IRN: { name: 'Iran (Islamic Republic of)', code: 'IRN' },
	IRQ: { name: 'Iraq', code: 'IRQ' },
	IRL: { name: 'Ireland', code: 'IRL' },
	IMN: { name: 'Isle of Man', code: 'IMN' },
	ISR: { name: 'Israel', code: 'ISR' },
	ITA: { name: 'Italy', code: 'ITA' },
	JAM: { name: 'Jamaica', code: 'JAM' },
	JPN: { name: 'Japan', code: 'JPN' },
	JEY: { name: 'Jersey', code: 'JEY' },
	JOR: { name: 'Jordan', code: 'JOR' },
	KAZ: { name: 'Kazakhstan', code: 'KAZ' },
	KEN: { name: 'Kenya', code: 'KEN' },
	KIR: { name: 'Kiribati', code: 'KIR' },
	PRK: { name: "Korea (Democratic People's Republic of)", code: 'PRK' },
	KOR: { name: 'South Korea', code: 'KOR' },
	KWT: { name: 'Kuwait', code: 'KWT' },
	KGZ: { name: 'Kyrgyzstan', code: 'KGZ' },
	LAO: { name: "Lao People's Democratic Republic", code: 'LAO' },
	LVA: { name: 'Latvia', code: 'LVA' },
	LBN: { name: 'Lebanon', code: 'LBN' },
	LSO: { name: 'Lesotho', code: 'LSO' },
	LBR: { name: 'Liberia', code: 'LBR' },
	LBY: { name: 'Libya', code: 'LBY' },
	LIE: { name: 'Liechtenstein', code: 'LIE' },
	LTU: { name: 'Lithuania', code: 'LTU' },
	LUX: { name: 'Luxembourg', code: 'LUX' },
	MAC: { name: 'Macao', code: 'MAC' },
	MDG: { name: 'Madagascar', code: 'MDG' },
	MWI: { name: 'Malawi', code: 'MWI' },
	MYS: { name: 'Malaysia', code: 'MYS' },
	MDV: { name: 'Maldives', code: 'MDV' },
	MLI: { name: 'Mali', code: 'MLI' },
	MLT: { name: 'Malta', code: 'MLT' },
	MHL: { name: 'Marshall Islands', code: 'MHL' },
	MTQ: { name: 'Martinique', code: 'MTQ' },
	MRT: { name: 'Mauritania', code: 'MRT' },
	MUS: { name: 'Mauritius', code: 'MUS' },
	MYT: { name: 'Mayotte', code: 'MYT' },
	MEX: { name: 'Mexico', code: 'MEX' },
	FSM: { name: 'Micronesia (Federated States of)', code: 'FSM' },
	MDA: { name: 'Moldova, Republic of', code: 'MDA' },
	MCO: { name: 'Monaco', code: 'MCO' },
	MNG: { name: 'Mongolia', code: 'MNG' },
	MNE: { name: 'Montenegro', code: 'MNE' },
	MSR: { name: 'Montserrat', code: 'MSR' },
	MAR: { name: 'Morocco', code: 'MAR' },
	MOZ: { name: 'Mozambique', code: 'MOZ' },
	MMR: { name: 'Myanmar', code: 'MMR' },
	NAM: { name: 'Namibia', code: 'NAM' },
	NRU: { name: 'Nauru', code: 'NRU' },
	NPL: { name: 'Nepal', code: 'NPL' },
	NLD: { name: 'Netherlands', code: 'NLD' },
	NCL: { name: 'New Caledonia', code: 'NCL' },
	NZL: { name: 'New Zealand', code: 'NZL' },
	NIC: { name: 'Nicaragua', code: 'NIC' },
	NER: { name: 'Niger', code: 'NER' },
	NGA: { name: 'Nigeria', code: 'NGA' },
	NIU: { name: 'Niue', code: 'NIU' },
	NFK: { name: 'Norfolk Island', code: 'NFK' },
	MKD: { name: 'North Macedonia', code: 'MKD' },
	MNP: { name: 'Northern Mariana Islands', code: 'MNP' },
	NOR: { name: 'Norway', code: 'NOR' },
	OMN: { name: 'Oman', code: 'OMN' },
	PAK: { name: 'Pakistan', code: 'PAK' },
	PLW: { name: 'Palau', code: 'PLW' },
	PSE: { name: 'Palestine, State of', code: 'PSE' },
	PAN: { name: 'Panama', code: 'PAN' },
	PNG: { name: 'Papua New Guinea', code: 'PNG' },
	PRY: { name: 'Paraguay', code: 'PRY' },
	PER: { name: 'Peru', code: 'PER' },
	PHL: { name: 'Philippines', code: 'PHL' },
	// PCN: { name: 'Pitcairn', code: 'PCN' },
	POL: { name: 'Poland', code: 'POL' },
	PRT: { name: 'Portugal', code: 'PRT' },
	PRI: { name: 'Puerto Rico', code: 'PRI' },
	QAT: { name: 'Qatar', code: 'QAT' },
	REU: { name: 'Réunion', code: 'REU' },
	ROU: { name: 'Romania', code: 'ROU' },
	RUS: { name: 'Russian Federation', code: 'RUS' },
	RWA: { name: 'Rwanda', code: 'RWA' },
	BLM: { name: 'Saint Barthélemy', code: 'BLM' },
	SHN: { name: 'Saint Helena, Ascension and Tristan da Cunha', code: 'SHN' },
	KNA: { name: 'Saint Kitts and Nevis', code: 'KNA' },
	LCA: { name: 'Saint Lucia', code: 'LCA' },
	// MAF: { name: 'Saint Martin (French part)', code: 'MAF' },
	SPM: { name: 'Saint Pierre and Miquelon', code: 'SPM' },
	VCT: { name: 'Saint Vincent and the Grenadines', code: 'VCT' },
	WSM: { name: 'Samoa', code: 'WSM' },
	SMR: { name: 'San Marino', code: 'SMR' },
	STP: { name: 'Sao Tome and Principe', code: 'STP' },
	SAU: { name: 'Saudi Arabia', code: 'SAU' },
	SEN: { name: 'Senegal', code: 'SEN' },
	SRB: { name: 'Serbia', code: 'SRB' },
	SYC: { name: 'Seychelles', code: 'SYC' },
	SLE: { name: 'Sierra Leone', code: 'SLE' },
	SGP: { name: 'Singapore', code: 'SGP' },
	SXM: { name: 'Sint Maarten (Dutch part)', code: 'SXM' },
	SVK: { name: 'Slovakia', code: 'SVK' },
	SVN: { name: 'Slovenia', code: 'SVN' },
	SLB: { name: 'Solomon Islands', code: 'SLB' },
	SOM: { name: 'Somalia', code: 'SOM' },
	ZAF: { name: 'South Africa', code: 'ZAF' },
	// SGS: { name: 'South Georgia and the South Sandwich Islands', code: 'SGS' },
	SSD: { name: 'South Sudan', code: 'SSD' },
	ESP: { name: 'Spain', code: 'ESP' },
	LKA: { name: 'Sri Lanka', code: 'LKA' },
	SDN: { name: 'Sudan', code: 'SDN' },
	SUR: { name: 'Suriname', code: 'SUR' },
	// SJM: { name: 'Svalbard and Jan Mayen', code: 'SJM' },
	SWE: { name: 'Sweden', code: 'SWE' },
	CHE: { name: 'Switzerland', code: 'CHE' },
	SYR: { name: 'Syrian Arab Republic', code: 'SYR' },
	TWN: { name: 'Taiwan', code: 'TWN' },
	TJK: { name: 'Tajikistan', code: 'TJK' },
	TZA: { name: 'Tanzania, United Republic of', code: 'TZA' },
	THA: { name: 'Thailand', code: 'THA' },
	TLS: { name: 'Timor-Leste', code: 'TLS' },
	TGO: { name: 'Togo', code: 'TGO' },
	TKL: { name: 'Tokelau', code: 'TKL' },
	TON: { name: 'Tonga', code: 'TON' },
	TTO: { name: 'Trinidad and Tobago', code: 'TTO' },
	TUN: { name: 'Tunisia', code: 'TUN' },
	TUR: { name: 'Turkey', code: 'TUR' },
	TKM: { name: 'Turkmenistan', code: 'TKM' },
	TCA: { name: 'Turks and Caicos Islands', code: 'TCA' },
	TUV: { name: 'Tuvalu', code: 'TUV' },
	UGA: { name: 'Uganda', code: 'UGA' },
	UKR: { name: 'Ukraine', code: 'UKR' },
	ARE: { name: 'United Arab Emirates', code: 'ARE' },
	GBR: { name: 'United Kingdom', code: 'GBR' },
	USA: { name: 'United States of America', code: 'USA' },
	// UMI: { name: 'United States Minor Outlying Islands', code: 'UMI' },
	URY: { name: 'Uruguay', code: 'URY' },
	UZB: { name: 'Uzbekistan', code: 'UZB' },
	VUT: { name: 'Vanuatu', code: 'VUT' },
	VEN: { name: 'Venezuela (Bolivarian Republic of)', code: 'VEN' },
	VNM: { name: 'Viet Nam', code: 'VNM' },
	VGB: { name: 'Virgin Islands (British)', code: 'VGB' },
	VIR: { name: 'Virgin Islands (U.S.)', code: 'VIR' },
	WLF: { name: 'Wallis and Futuna', code: 'WLF' },
	ESH: { name: 'Western Sahara', code: 'ESH' },
	YEM: { name: 'Yemen', code: 'YEM' },
	ZMB: { name: 'Zambia', code: 'ZMB' },
	ZWE: { name: 'Zimbabwe', code: 'ZWE' },
} as const;

const COUNTRIES = Object.values(COUNTRY_MAP);

// NOTE: We are temporarily shutting off Citi between the pilot and full launch,
// so we've manually overwritten isCiti to be false.
// ENG-13883 will re-enable this.

const COUNTRY_INFO_DRAFT = [
	{
		name: 'Australia',
		code: 'AUS',
		adjective: 'Australian',
		colloquial: 'Australia',
		cmsPath: '/countries/australia',
		isHardPull: false,
		isAmex: true,
		// isCiti: true,
		isCiti: false,
		isMpower: true,
		isWestlake: true,
		isVerizon: true,
	},
	{
		name: 'Austria',
		code: 'AUT',
		adjective: 'Austrian',
		colloquial: 'Austria',
		cmsPath: null,
		isHardPull: false,
		isAmex: false,
		// isCiti: true,
		isCiti: false,
		isMpower: true,
		isWestlake: true,
		isVerizon: false,
	},
	{
		name: 'Brazil',
		code: 'BRA',
		adjective: 'Brazilian',
		colloquial: 'Brazil',
		cmsPath: '/countries/brazil',
		isHardPull: true,
		isAmex: true,
		// isCiti: true,
		isCiti: false,
		isMpower: true,
		isWestlake: true,
		isVerizon: true,
	},
	{
		name: 'Canada',
		code: 'CAN',
		adjective: 'Canadian',
		colloquial: 'Canada',
		cmsPath: '/countries/canada',
		isHardPull: false,
		isAmex: true,
		isCiti: false,
		isMpower: true,
		isWestlake: true,
		isVerizon: true,
	},
	{
		name: 'Dominican Republic',
		code: 'DOM',
		adjective: 'Dominican',
		colloquial: 'Dominican Republic',
		cmsPath: '/countries/dominican-republic',
		isHardPull: false,
		isAmex: true,
		// isCiti: true,
		isCiti: false,
		isMpower: true,
		isWestlake: true,
		isVerizon: true,
	},
	{
		name: 'Germany',
		code: 'DEU',
		adjective: 'German',
		colloquial: 'Germany',
		cmsPath: null,
		isHardPull: false,
		isAmex: false,
		// isCiti: true,
		isCiti: false,
		isMpower: false,
		isWestlake: true,
		isVerizon: false,
	},
	{
		name: 'India',
		code: 'IND',
		adjective: 'Indian',
		colloquial: 'India',
		cmsPath: '/countries/india',
		isHardPull: false,
		isAmex: true,
		// isCiti: true,
		isCiti: false,
		isMpower: true,
		isWestlake: true,
		isVerizon: true,
	},
	{
		name: 'Kenya',
		code: 'KEN',
		adjective: 'Kenyan',
		colloquial: 'Kenya',
		cmsPath: '/countries/kenya',
		isHardPull: true,
		isAmex: true,
		// isCiti: true,
		isCiti: false,
		isMpower: true,
		isWestlake: true,
		isVerizon: true,
	},
	{
		name: 'Mexico',
		code: 'MEX',
		adjective: 'Mexican',
		colloquial: 'Mexico',
		cmsPath: '/countries/mexico',
		isHardPull: true,
		isAmex: true,
		// isCiti: true,
		isCiti: false,
		isMpower: true,
		isWestlake: true,
		isVerizon: true,
	},
	{
		name: 'Nigeria',
		code: 'NGA',
		adjective: 'Nigerian',
		colloquial: 'Nigeria',
		cmsPath: '/countries/nigeria',
		isHardPull: true,
		isAmex: true,
		// isCiti: true,
		isCiti: false,
		isMpower: true,
		isWestlake: true,
		isVerizon: true,
	},
	{
		name: 'Philippines',
		code: 'PHL',
		adjective: 'Filipino',
		colloquial: 'Philippines',
		cmsPath: null,
		isHardPull: false,
		isAmex: true,
		// isCiti: true,
		isCiti: false,
		isMpower: false,
		isWestlake: true,
		isVerizon: true,
	},
	{
		name: 'South Korea',
		code: 'KOR',
		adjective: 'South',
		colloquial: 'South Korea',
		cmsPath: '/countries/south-korea',
		isHardPull: false,
		isAmex: true,
		// isCiti: true,
		isCiti: false,
		isMpower: true,
		isWestlake: true,
		isVerizon: true,
	},
	{
		name: 'Spain',
		code: 'ESP',
		adjective: 'Spanish',
		colloquial: 'Spain',
		cmsPath: null,
		isHardPull: false,
		isAmex: false,
		// isCiti: true,
		isCiti: false,
		isMpower: true,
		isWestlake: true,
		isVerizon: true,
	},
	{
		name: 'Switzerland',
		code: 'CHE',
		adjective: 'Swiss',
		colloquial: 'Switzerland',
		cmsPath: null,
		isHardPull: false,
		isAmex: true,
		// isCiti: true,
		isCiti: false,
		isMpower: false,
		isWestlake: true,
		isVerizon: false,
	},
	{
		name: 'United Kingdom',
		code: 'GBR',
		adjective: 'UK',
		colloquial: 'the UK',
		cmsPath: '/countries/united-kingdom',
		isHardPull: false,
		isAmex: true,
		// isCiti: true,
		isCiti: false,
		isMpower: true,
		isWestlake: true,
		isVerizon: true,
	},
] as const;

const COUNTRY_INFO = COUNTRY_INFO_DRAFT.map(country => {
	return {
		...country,
		isCardshopLive: country.isAmex || country.isCiti,
	};
});

/**
 * Countries for which there exists a Nova Credit Customer
 * (e.g. American Express) who is live with Nova Credit for newcomers coming
 * to the U.S. from that country. The customer must have credit card offerings
 * in the cardshop to qualify.
 */
const CARDSHOP_LIVE_COUNTRIES = COUNTRY_INFO.filter(country => country.isCardshopLive);

export type CountryCode = typeof COUNTRIES[number]['code'];
export type SimpleCountry = typeof COUNTRIES[number];
export type Country = typeof COUNTRY_INFO[number];
export type CardshopCountry = typeof CARDSHOP_LIVE_COUNTRIES[number];
export type CardshopCountryCode = typeof CARDSHOP_LIVE_COUNTRIES[number]['code'];

const CARDSHOP_LIVE_COUNTRY_CODES = COUNTRY_INFO.filter(country => country.isCardshopLive).map(
	country => country.code,
);

/**
 * Countries that Amex is live with Nova Credit in. We should generally move
 * away from relying on this and towards relying on CARDSHOP_LIVE_COUNTRY_CODES
 * for customer agnostic logic.
 */
const AMEX_SUPPORTED_COUNTRY_CODES = COUNTRY_INFO.filter(country => country.isAmex).map(
	country => country.code,
);

const CITI_SUPPORTED_COUNTRY_CODES = COUNTRY_INFO.filter(country => country.isCiti).map(
	country => country.code,
);

const WESTLAKE_SUPPORTED_COUNTRY_CODES = COUNTRY_INFO.filter(country => country.isWestlake).map(
	country => country.code,
);

const getCountry = (code: string): Country | null => {
	return COUNTRY_INFO.find(country => country.code === code) || null;
};

const getSimpleCountry = (code: string): SimpleCountry | null => {
	return COUNTRY_MAP[code] || null;
};

const isAmexCountry = (code: string): boolean => {
	return (AMEX_SUPPORTED_COUNTRY_CODES as readonly string[]).includes(code);
};

const isCitiCountry = (code: string): boolean => {
	return (CITI_SUPPORTED_COUNTRY_CODES as readonly string[]).includes(code);
};

const isCardshopCountry = (code: string): boolean => {
	return (CARDSHOP_LIVE_COUNTRY_CODES as readonly string[]).includes(code);
};

const isWestlakeCountry = (code: string): boolean => {
	return (WESTLAKE_SUPPORTED_COUNTRY_CODES as readonly string[]).includes(code);
};

export {
	COUNTRIES,
	COUNTRY_INFO,
	CARDSHOP_LIVE_COUNTRIES,
	CARDSHOP_LIVE_COUNTRY_CODES,
	AMEX_SUPPORTED_COUNTRY_CODES,
	CITI_SUPPORTED_COUNTRY_CODES,
	WESTLAKE_SUPPORTED_COUNTRY_CODES,
	getCountry,
	getSimpleCountry,
	isAmexCountry,
	isCitiCountry,
	isCardshopCountry,
	isWestlakeCountry,
};
