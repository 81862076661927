/**
 * A queue that executes tasks in the order received,
 * proceeding to the next task only once the previous
 * task has resolved.
 *
 * use `activate` to signal tasks are ready to be executed
 * Use `enqueue` to queue and execute tasks. Tasks can be enqueued
 * prior to activation.
 */
class AsyncTaskQueue {
	tasks: (() => Promise<void>)[];
	isExhausting: boolean;
	active: boolean;

	constructor() {
		this.tasks = [];
		this.isExhausting = false;
		this.active = false;
	}

	/**
	 * Instruct the queue that tasks can now be executed. Prior to calling activate(),
	 * tasks will be queued but not executed. Calling activate() will execute
	 * any tasks queued prior to activation.
	 */
	activate(): Promise<void> {
		if (this.active) {
			return Promise.resolve();
		}
		this.active = true;
		this.isExhausting = true;
		return this._exhaust();
	}

	/**
	 * Add a task to the queue. Executes the task (preceded by previously
	 * queued tasks) if the queue has been activated.
	 */
	enqueue<T>(task: () => Promise<T>): Promise<T> {
		return new Promise<T>((resolve, reject) => {
			this.tasks.push(() => {
				return task()
					.catch(error => {
						reject(error);
						return error;
					})
					.then(resolve);
			});

			if (this.active && !this.isExhausting) {
				this.isExhausting = true;
				this._exhaust();
			}
		});
	}

	/**
	 * Executes the task that is first in the queue.
	 */
	_dequeue(): Promise<void> {
		const task = this.tasks.shift();

		if (task) {
			return task();
		} else {
			return Promise.resolve();
		}
	}

	/**
	 * Recursively executes tasks in the queue in FIFO order.
	 */
	_exhaust(): Promise<void> {
		// Stop when the queue is empty
		if (!this.tasks.length) {
			this.isExhausting = false;
			return Promise.resolve();
		}

		// Recursively execute tasks in the queue in FIFO order.
		return this._dequeue().then(() => this._exhaust());
	}
}

export default AsyncTaskQueue;
