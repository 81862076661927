import React from 'react';
import { DesignSystemButton } from '@novacredit/pandorasbox';

import defaultImage from 'assets/graphics/boy-binoculars.svg';
import Illustration from 'components/common/Illustration';
import Page from 'components/common/Page';
import PageTitle from 'components/common/PageTitle';
import { getCmsURL } from 'utils';
import './NotFound.scss';

const NotFound = ({
	ctaTag = 'a',
	ctaText = 'Go home',
	ctaUri = getCmsURL(),
	showPageTitle = true,
	subheader = 'Unfortunately we could not find the page you were looking for.',
	header = 'Page Not Found',
}: {
	ctaTag?: 'a' | 'button' | React.ComponentType;
	ctaText?: string;
	ctaUri?: string;
	showPageTitle?: boolean;
	subheader?: string;
	header?: string;
}) => {
	return (
		<Page className="not-found">
			{showPageTitle && <PageTitle title="Page Not Found" />}
			<Illustration alt="" className="not-found-image" src={defaultImage} />
			<h1 className="mb-2">{header}</h1>
			<p className="text-muted">{subheader}</p>
			<DesignSystemButton tag={ctaTag} href={ctaUri} to={ctaUri}>
				{ctaText}
			</DesignSystemButton>
		</Page>
	);
};

export default NotFound;
