export enum BULK_CREATE_PRODUCTS_ERROR {
	EMPTY_PRODUCTS = 'EMPTY_PRODUCTS',
	INVALID_PRODUCTS = 'INVALID_PRODUCTS',
	LIMIT_EXCEEDED = 'LIMIT_EXCEEDED',
}

export const BULK_CREATE_PRODUCTS_LIMIT = 5000;

export enum CREATABLE_PRODUCT_PARSE_STATUS {
	DUPLICATE_PRODUCT_ID_ALIAS = 'DUPLICATE_PRODUCT_ID_ALIAS',
	DUPLICATE_PRODUCT_NAME = 'DUPLICATE_PRODUCT_NAME',
	INCORRECT_FIELD_FORMAT = 'INCORRECT_FIELD_FORMAT',
	INVALID_PRODUCT_CATEGORY = 'INVALID_PRODUCT_CATEGORY',
	MISSING_FIELD = 'MISSING_FIELD',
	SUCCESS = 'SUCCESS',
}

export const PRODUCT_ID_ALIAS_MAX_LENGTH = 255;

export const PRODUCT_NAME_MAX_LENGTH = 255;

// We use this enum to validate products.inquiry_type in both the ORM and database layers.
// Therefore when making updates to this enum please create a database migration to update
// the DB check on the inquiry_type. Ex) https://github.com/neednova/zeus/pull/12165/files
export enum PRODUCT_INQUIRY_TYPES {
	COMMERCIAL = 'COMMERCIAL',
	CONSUMER = 'CONSUMER',
	CREDIT_CARD = 'CREDIT_CARD',
	MONITORING = 'MONITORING',
	MORTGAGE = 'MORTGAGE',
	PERSONAL = 'PERSONAL',
	STUDENT = 'STUDENT',
	TENANT = 'TENANT',
	UTILITY = 'UTILITY',
	VEHICLE_AUTO = 'VEHICLE_AUTO',
	VEHICLE_OTHER = 'VEHICLE_OTHER',
	OTHER = 'OTHER',
}

/**
 * Update "nova_products" type in the DB if you need to add/edit/remove elements from this enum
 * Related migration: file://./../../migrations/src/app/migrations/20240220173716-nova-product-config-option-checks.js
 */
export enum NOVA_PRODUCTS {
	CASH_FLOW_UNDERWRITING = 'CASH_FLOW_UNDERWRITING',
	CREDIT_PASSPORT = 'CREDIT_PASSPORT',
	VERIFICATION_OF_INCOME = 'VERIFICATION_OF_INCOME',
	NEWCOMER_KYC = 'NEWCOMER_KYC',
}

export const NOVA_PRODUCT_DISPLAY_NAME: Record<NOVA_PRODUCTS, string> = {
	[NOVA_PRODUCTS.CASH_FLOW_UNDERWRITING]: 'Cash Flow Underwriting',
	[NOVA_PRODUCTS.CREDIT_PASSPORT]: 'Credit Passport',
	[NOVA_PRODUCTS.NEWCOMER_KYC]: 'Newcomer KYC',
	[NOVA_PRODUCTS.VERIFICATION_OF_INCOME]: 'Income Navigator',
};

export const NOVA_PRODUCT_DISPLAY_NAME_SHORT: Record<NOVA_PRODUCTS, string> = {
	[NOVA_PRODUCTS.CASH_FLOW_UNDERWRITING]: 'CFUW',
	[NOVA_PRODUCTS.CREDIT_PASSPORT]: 'CP',
	[NOVA_PRODUCTS.NEWCOMER_KYC]: 'NKYC',
	[NOVA_PRODUCTS.VERIFICATION_OF_INCOME]: 'VOI',
};
