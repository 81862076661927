import React from 'react';
import classnames from 'classnames';

type OwnProps = {
	name?: string;
	className?: string;
	variant?: string;
	size?: string;
	tag?: string;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
// eslint-disable-next-line no-use-before-define
type Props = OwnProps & typeof Icon.defaultProps;

const Icon = ({ name, className, size, variant, tag: Tag, ...attributes }: Props) => {
	const light = variant === 'light';
	const solid = variant === 'solid';
	const regular = !light && !solid;

	const variantClass = {
		fal: light,
		fas: solid,
		far: regular,
	};

	const sizeClass = {
		[`fa-${size}`]: size,
	};

	return (
		<Tag
			className={classnames('icon', `fa-${name}`, variantClass, sizeClass, className)}
			{...attributes}
		/>
	);
};

Icon.defaultProps = {
	name: '',
	className: '',
	variant: null,
	size: null,
	tag: 'i',
};

export default Icon;
