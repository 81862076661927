import React from 'react';
import { getCmsURL } from 'utils';

/**
 * Content that is drafted by legal or would need approval from legal
 * before making any changes.
 */
export const TermsOfServiceLink = () => (
	<a className="legal link" href={getCmsURL('/terms')} target="_blank" rel="noopener noreferrer">
		Terms of Service
	</a>
);

export const PrivacyPolicyLink = () => (
	<a
		className="legal link"
		href={getCmsURL('/privacy')}
		target="_blank"
		rel="noopener noreferrer"
	>
		Privacy Policy
	</a>
);

export const ReferralProgramTermsOfService = () => (
	<a
		className="legal link"
		href={getCmsURL('/referral-terms')}
		target="_blank"
		rel="noopener noreferrer"
	>
		Referral Program Terms and Conditions
	</a>
);

export const ReferralProgramTOSAgreement = () => (
	<>
		By clicking Sign up, I confirm that I have read and agree to Nova Credit&apos;s{' '}
		<TermsOfServiceLink /> and <ReferralProgramTermsOfService />, and have read the{' '}
		<PrivacyPolicyLink />. Referral Credits are subject to verification and will generally be
		awarded within 60 days of verification via Amazon.com eGift Card. Nova Credit reserves the
		right modify or terminate the program at any time in its sole discretion.
	</>
);

export const RefereeProgramTOS = () => (
	<>
		By clicking See Products, I confirm that I have read and agree to Nova Credit&apos;s{' '}
		<TermsOfServiceLink /> and <ReferralProgramTermsOfService />, and have read the{' '}
		<PrivacyPolicyLink />. Referral Credits are subject to verification and will generally be
		awarded within 60 days of verification via Amazon.com eGiftCard. Nova Credit reserves the
		right to modify or terminate the program at any time in its sole discretion.
	</>
);
