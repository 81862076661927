import React, { useEffect } from 'react';

import { Col, Container, Row } from 'components/common/Layout';

import './Modal.scss';

const Modal = ({ children, onDismiss }: { children: React.ReactNode; onDismiss: () => void }) => {
	// Prevent the content behind the modal from scrolling when the modal is open,
	// and re-enable scrolling when the modal gets closed.
	useEffect(() => {
		document.body.style.overflowY = 'hidden';

		return () => {
			document.body.style.overflowY = 'auto';
		};
	}, []);

	return (
		<div className="modal" onClick={onDismiss}>
			<Container>
				<Row>
					<Col size={{ md: 8 }} offset={{ md: 2 }}>
						<div onClick={e => e.stopPropagation()} className="modal__content">
							{children}
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Modal;
