import React, { useContext, useState } from 'react';
import { Formik } from 'formik';
import { CARDSHOP_LIVE_COUNTRY_CODES } from '@novacredit/frontend-common/utils/countries';
import type { CountryCode } from '@novacredit/frontend-common/utils/countries';
import { getOrCreateNovaAnonymousId } from '@novacredit/frontend-common/utils/novaAnonymousId';
import { HELP_CENTER_URLS } from '@novacredit/frontend-common/utils/url';
import { Intercom } from '@novacredit/frontend-common/vendors';
import {
	DesignSystemButton,
	DesignSystemFormField,
	DesignSystemFormLabel,
	NovaLogo,
} from '@novacredit/pandorasbox';

import { tracker } from 'modules/tracking';
import { validateEmail, validateForm, validateRequired } from 'modules/validations';
import SessionContext from 'contexts/SessionContext';
import CountrySelect from 'components/common/CountrySelect';
import Checkbox from 'components/common/Checkbox';
import Illustration from 'components/common/Illustration';
import { Row, Col } from 'components/common/Layout';
import { RefereeProgramTOS } from 'components/common/Legal';
import Modal from 'components/common/Modal';
import PIISafeForm from 'components/common/PIISafeForm';
import { trackReferral } from 'utils';
import useApi from 'hooks/useApi';

import creditCardsImage from 'assets/images/credit-cards.svg';
import admitKardLogo from 'assets/referral-program/admit-kard.jpeg';
import americaJoshLogo from 'assets/referral-program/america-josh.jpg';
import australianCommunityLogo from 'assets/referral-program/australian-community.png';
import convergeLogo from 'assets/referral-program/converge.jpeg';
import eduloansLogo from 'assets/referral-program/eduloans.png';
import foreignAdmitsLogo from 'assets/referral-program/foreign-admits.png';
import gbxLogo from 'assets/referral-program/gbx.png';
import gradicleLogo from 'assets/referral-program/gradicle.png';
import gyandhanLogo from 'assets/referral-program/gyandhan.png';
import homeAbroadLogo from 'assets/referral-program/homeabroad.png';
import lcrCapitalPartnersLogo from 'assets/referral-program/lcr-capital-partners.png';
import moneygramLogo from 'assets/referral-program/moneygram.png';
import naaisLogo from 'assets/referral-program/naais.png';
import northLoopLogo from 'assets/referral-program/north-loop.jpg';
import redBus2USLogo from 'assets/referral-program/RedBus2US.png';
import vestiLogo from 'assets/referral-program/vesti.png';
import wemakescholarsLogo from 'assets/referral-program/wemakescholars.png';

import './RefereeModal.scss';

const HOW_IT_WORKS_LINK = 'https://help.novacredit.com/s/topic/0TO4z0000000qMiGAI/referral-program';

/**
 * These can be deprecated once https://novacredit.atlassian.net/browse/ENG-18749 is
 * complete AND someone from marketing has gone into athena and re-uploaded all of
 * these images for the existing affiliates.
 */
export const AFFILIATE_IMAGE_SOURCES = {
	admitkard: admitKardLogo,
	'america-josh': americaJoshLogo,
	'australian-community': australianCommunityLogo,
	converge: convergeLogo,
	eduloans: eduloansLogo,
	foreignadmits: foreignAdmitsLogo,
	gbx: gbxLogo,
	gradicle: gradicleLogo,
	gyandhan: gyandhanLogo,
	homeabroad: homeAbroadLogo,
	'lcr-capital-partners': lcrCapitalPartnersLogo,
	moneygram: moneygramLogo,
	'north-loop': northLoopLogo,
	naais: naaisLogo,
	redbus2us: redBus2USLogo,
	vesti: vestiLogo,
	wemakescholars: wemakescholarsLogo,
};

export type RefereeModalFormSchema = {
	country: CountryCode;
	email: string;
	marketingConsent: boolean;
	referrer: string;
};

const RefereeModal = ({
	onSubmit,
	referrer,
	onDismiss,
}: {
	onSubmit: (values: RefereeModalFormSchema) => void;
	referrer: string;
	onDismiss: () => void;
}) => {
	const { data, loading } = useApi({
		path: `/referrers/${referrer}`,
	});
	const { makeRequest: createReferredUser } = useApi({
		defer: true,
		method: 'POST',
		path: `/referrers/user`,
	});
	const [formHasError, setFormHasError] = useState(false);
	const { experimentsClient } = useContext(SessionContext);

	const validate = validateForm({
		email: validateEmail,
		country: validateRequired,
	});

	const initialValues: RefereeModalFormSchema = {
		country: null,
		email: '',
		marketingConsent: true,
		referrer,
	};

	const isReferralProgramActive =
		experimentsClient &&
		experimentsClient.getFeatureFlag('REFERRAL_PROGRAM_REACTIVATION') === 'ENABLED';
	const affiliate = data?.affiliate?.affiliateEnabled
		? data.affiliate
		: {};
	const referredByAffiliate = !!affiliate.affiliateId;
	const showProgramPausedNotice = !referredByAffiliate && !isReferralProgramActive;

	const onFormSubmit = (values: RefereeModalFormSchema) => {
		createReferredUser({
			body: {
				email: values.email,
				country: values.country,
				referralCode: referrer,
				novaAnonymousId: getOrCreateNovaAnonymousId(),
			},
		})
			.then(response => {
				const { data: responseData } = response;
				if (responseData && responseData['createReferredUser']) {
					Intercom.subscribeToEmailMarketing({
						email: values.email,
						consentToMarketing: values.marketingConsent,
						subscriptionSource: {
							app: 'cms',
							location: 'Referee Modal',
						},
						tags: ['consumer', 'd2cConsumer', 'referee'],
						userProperties: {
							referredUser: true,
							country: values.country,
						},
						trackingFn: () => {
							if (values.marketingConsent) {
								tracker.track('nova.client.EMAIL_SUBSCRIBED', {
									location: 'Referee Modal',
								});
							}
						},
					});
				}
				tracker.track('nova.consumer_dashboard.REFERRAL_PROGRAM_PLP_REDEEMED', {
					country: values.country,
					marketingConsent: values.marketingConsent,
					affiliateName: affiliate.affiliateName,
					referredByAffiliate,
				});
				trackReferral(data);
				onSubmit(values);
			})
			.catch(() => {
				setFormHasError(true);
			});
	};

	if (loading) {
		return null;
	}

	if (showProgramPausedNotice) {
		return (
			<Modal onDismiss={onDismiss}>
				<div className="referee-modal referee-modal__update">
					<h1 className="title">We&apos;re Updating Our Referral Program</h1>
					<p className="referee-modal__copy">
						We&apos;ve temporarily paused our referral program as we work on
						improvements. In the meantime, you can still explore credit cards that are
						available to newcomers.
					</p>
					<Illustration className="credit-cards-illustration" src={creditCardsImage} />
					<DesignSystemButton
						className="referee-modal__continue"
						tag="button"
						type="button"
						onClick={onDismiss}
					>
						Continue
					</DesignSystemButton>
					<p>
						If you have any further questions, please feel free to contact us through
						our{' '}
						<a className="referee-modal__help" href={HELP_CENTER_URLS.CONTACT}>
							customer help center
						</a>
						.
					</p>
				</div>
			</Modal>
		);
	}

	return (
		<Modal onDismiss={onDismiss}>
			<div className="referee-modal">
				{!!referredByAffiliate && (
					<Row className="referee-modal__affiliate-branding">
						<Col size={{ lg: 6 }} className="referee-modal__affiliate-col">
							<div className="referee-modal__brand-image">
								<img
									src={
										AFFILIATE_IMAGE_SOURCES[affiliate.referralCode] ||
										affiliate.imageUrl
									}
									width={200}
									alt={affiliate.name}
								/>
							</div>
						</Col>
						<Col size={{ lg: 6 }} className="referee-modal__affiliate-col">
							<div className="referee-modal__nova-logo">
								<NovaLogo />
							</div>
						</Col>
					</Row>
				)}
				<h1 className="title">
					Use your foreign credit history to apply for one of our partner’s products.
				</h1>
				<p className="referee-modal__subheader">
					Make sure you use the same email address when you apply for one of our
					partners&apos; products. Applicants must opt in to use their foreign credit
					history. Partner must retrieve your foreign credit history to qualify. Use of
					your foreign credit history is at the partner&apos;s discretion.{' '}
					<a
						href={HOW_IT_WORKS_LINK}
						className="referee-modal__help"
						target="_blank"
						rel="noopener noreferrer"
					>
						How it works
					</a>
				</p>
				<Formik initialValues={initialValues} onSubmit={onFormSubmit} validate={validate}>
					{({
						values,
						errors,
						touched,
						setFieldValue,
						setFieldTouched,
						isValid,
						dirty,
					}) => (
						<PIISafeForm className="referee-modal__form">
							<Row>
								<Col size={{ lg: 6 }} className="referee-modal__input-group">
									<DesignSystemFormLabel
										htmlFor="countrySelect"
										label="I recently moved from..."
									/>
									<CountrySelect
										countryCodes={CARDSHOP_LIVE_COUNTRY_CODES}
										onInit={country => {
											setFieldValue('country', country);
										}}
										onChange={country => {
											setFieldValue('country', country);
										}}
										color="ColorCoolGray600"
										trackingPage="Cardshop"
										trackingLocation="Referral Program Modal"
									/>
								</Col>
								<Col size={{ lg: 6 }} className="referee-modal__input-group">
									<DesignSystemFormField
										component="input"
										inputName="email"
										errorMessage={touched.email && errors.email}
										helpTextId="referee-modal-email-input-help-text"
										placeholder="Email"
										type="email"
										onBlur={() => setFieldTouched('email', true)}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
											setFieldValue('email', e.target.value)
										}
										label="Email address"
										required
									/>
								</Col>
							</Row>
							<div className="referee-modal__form-footer">
								<div className="referee-modal__marketing-container">
									<Checkbox
										name="marketingConsent"
										checked={values.marketingConsent}
										label="I'd also like to receive emails from Nova Credit with educational content, product updates and offers"
									/>
								</div>
								{formHasError ? (
									<div>
										<p className="referee-modal__error">
											Error. Please verify the referral code is correct or{' '}
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="https://help.novacredit.com/s/contactsupport"
											>
												contact support
											</a>
											.
										</p>
									</div>
								) : (
									<DesignSystemButton
										className="referee-modal__submit"
										tag="button"
										type="submit"
										disabled={!(isValid && dirty)}
									>
										See Products
									</DesignSystemButton>
								)}
							</div>
						</PIISafeForm>
					)}
				</Formik>
				<p className="referee-modal__terms">
					<RefereeProgramTOS />
				</p>
			</div>
		</Modal>
	);
};

export default RefereeModal;
