import { getSessionProperties, getDynamicProperties } from '../properties';
import type { ConsentMap } from '../../consent/common';
import { getOrCreateNovaAnonymousId } from '../../utils/novaAnonymousId';
import AsyncTaskQueue from '../AsyncTaskQueue';
import { postEvent } from '../eventApi';
import { getEvent } from '../events';
import type { InitializeOptions, TrackingDestination, TrackingOptions } from './destination';
import type { Env, UserEnv } from '../../types';
import type { Properties } from '../properties';
import type { TrackingEvent } from '../events';
import type { EventSchema } from '../eventSchema';
import { onConsentUpdate } from '../../utils/novaCookies';

class EventStore implements TrackingDestination {
	name = 'EventStore';

	initialized: boolean;
	appName: string;
	category: string;
	nodeEnv: Env | null;
	userEnv: UserEnv | null;
	sessionProperties: Properties | null;
	initializeOptions: InitializeOptions['EventStore'] | null;
	queue: AsyncTaskQueue;

	constructor({ appName, category }: { appName: string; category: string }) {
		this.initialized = false;
		this.appName = appName;
		this.category = category;
		this.queue = new AsyncTaskQueue();
		this.nodeEnv = null;
		this.userEnv = null;
		this.sessionProperties = null;
		this.initializeOptions = null;
	}

	initialize({
		nodeEnv,
		userEnv,
		options,
	}: {
		nodeEnv: Env;
		userEnv: UserEnv;
		options?: InitializeOptions;
	}): Promise<void> {
		this.initialized = true;
		this.nodeEnv = nodeEnv;
		this.userEnv = userEnv;
		this.initializeOptions = options?.EventStore;
		this.sessionProperties = getSessionProperties();
		this.queue.activate();

		return Promise.resolve();
	}

	// eslint-disable-next-line class-methods-use-this
	cookieConsentUpdated(consents: ConsentMap): void {
		// The EventStore relies on the `nova_anonymous_id` cookie. If cookie consent updates,
		// we want to save down the `nova_anonymous_id` to cookies, provided the user has
		// consented.
		onConsentUpdate(consents);
	}

	_getInitializeOptions(): InitializeOptions['EventStore'] | null {
		// Trying to read `initializeOptions` prior to calling `EventStore.initialize` will
		// always return null. Error out here to prevent us from accessing `initializeOptions`
		// before they have loaded.
		if (!this.initialized) {
			throw new Error('Cannot read from EventStore.options prior to initialization');
		}

		return this.initializeOptions;
	}

	track({
		event,
		properties,
		getDefaultProperties,
		timestamp,
		options,
	}: {
		event: TrackingEvent;
		properties: object;
		getDefaultProperties: () => object;
		timestamp: string;
		options?: TrackingOptions;
	}): Promise<void> {
		if (options?.EventStore?.skip) {
			return Promise.resolve();
		}

		return this.queue.enqueue(() => {
			// The queue.enqueue callback will only fire after initialize() is called,
			// so we can safely call `getInitializeOptions` here.
			const initializeOptions = this._getInitializeOptions();

			if (initializeOptions?.skip) {
				return Promise.resolve();
			}

			// This will swallow any errors returned from the eventing back-end. We
			// might consider passing on those errors to Sentry.
			return this._postEvent({
				event,
				properties: { ...getDefaultProperties(), ...properties },
				timestamp,
				options,
			})
				.catch()
				.then();
		});
	}

	identify({
		userId,
		properties,
		timestamp,
		options,
	}: {
		userId: string;
		properties: EventSchema['nova.meta.IDENTIFY'];
		timestamp: string;
		options?: TrackingOptions;
	}): Promise<void> {
		return this.track({
			event: getEvent('nova.meta.IDENTIFY'),
			properties: { ...properties, userId },
			getDefaultProperties: () => ({}),
			timestamp,
			options,
		});
	}

	alias({
		userId,
		timestamp,
		options,
	}: {
		userId: string;
		timestamp: string;
		options?: TrackingOptions;
	}): Promise<void> {
		return this.track({
			event: getEvent('nova.meta.ALIAS'),
			properties: { userId },
			getDefaultProperties: () => ({}),
			timestamp,
			options,
		});
	}

	trackPage({
		url,
		properties,
		getDefaultProperties,
		timestamp,
		options,
	}: {
		url: string;
		properties: object;
		getDefaultProperties: () => object;
		timestamp: string;
		options?: TrackingOptions;
	}): Promise<void> {
		// NOTE: NovaConnect dispatches a custom event for page tracking. We should consider
		// standardizing this in the future.
		return this.track({
			event: getEvent('nova.client.PAGE_VIEWED'),
			properties: { ...properties, url },
			getDefaultProperties,
			timestamp,
			options,
		});
	}

	setUserProps({
		properties,
		timestamp,
		options,
	}: {
		properties: EventSchema['nova.meta.IDENTIFY'];
		timestamp: string;
		options?: TrackingOptions;
	}): Promise<void> {
		return this.track({
			event: getEvent('nova.meta.IDENTIFY'),
			properties,
			getDefaultProperties: () => ({}),
			timestamp,
			options,
		});
	}

	_postEvent({
		event,
		properties,
		timestamp,
		options,
	}: {
		event: TrackingEvent;
		properties: object;
		timestamp: string;
		options?: TrackingOptions;
	}): Promise<Response | void> {
		if (this.nodeEnv === null) {
			throw Error('EventStore track called prior to initialization');
		}

		const eventIntegrations = options?.EventStore?.integrations;
		const initializeIntegrations = this._getInitializeOptions()?.integrations;
		const defaultIntegrations = {
			all: true,
		};

		// Allow overrides of the integrations (which destinations these events wind up at)
		// on the event or session level.
		const integrations = eventIntegrations || initializeIntegrations || defaultIntegrations;

		return postEvent({
			event: {
				...properties,
				event: event.name,
				context: {
					...this.sessionProperties,
					...getDynamicProperties(),
					eventDisplayName: event.displayName,
					novaAnonymousId: getOrCreateNovaAnonymousId(),
					originalTimestamp: timestamp,
					appName: this.appName,
					category: this.category,
					userEnv: this.userEnv,
					serverEnv: this.nodeEnv,
					integrations: {
						// Flag indicating whether event should be sent to Nova Credit [Production, Staging]
						mixpanelUserProject: true,
						...integrations,
					},
				},
			},
			nodeEnv: this.nodeEnv,
		});
	}
}

export default EventStore;
