import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from '@novacredit/pandorasbox';

import { Container } from 'components/common/Layout';
import StoredCountryContext from 'contexts/StoredCountryContext';
import { Redirect } from 'react-router-dom';
import { APP_PATHS } from 'modules/paths';
import useApi from 'hooks/useApi';

// To keep the link short and easy to remember for our users, we place
// this route at hello.novacredit.com/r/* where * becomes the referrer's
// code, usually in the form of `firstname-lastname`.

type Props = {
	match: any;
};

const ReferralProgramRedeemCode = ({ match }: Props) => {
	const { referralCode: rawReferralCode } = match.params;
	const referralCode = rawReferralCode
		.replace(/\/$/, '')
		.split('/')
		.slice(-1)[0];

	const { data: referrerData, loading } = useApi({
		path: `/referrers/${referralCode}`,
	});
	const { storedCountry } = useContext(StoredCountryContext);
	const [redirectTo, setRedirectTo] = useState<string>();

	useEffect(() => {
		if (!referralCode) {
			setRedirectTo('/404');
		}
	}, [referralCode]);

	useEffect(() => {
		if (loading || !referralCode) {
			return;
		}

		if (!referrerData) {
			setRedirectTo('/404');
			return;
		}

		const { referrer, affiliate } = referrerData;

		if (referrer.referrerId) {
			setRedirectTo(
				`${APP_PATHS.CARDSHOP}?referrer=${referrer.referralCode}&country=${storedCountry}`,
			);
		} else if (affiliate.affiliateId) {
			setRedirectTo(
				`${APP_PATHS.CARDSHOP}?referrer=${affiliate.referralCode}&country=${storedCountry}`,
			);
		}
	}, [loading, referrerData, referralCode, storedCountry]);

	if (redirectTo) {
		return <Redirect to={redirectTo} />;
	}

	return (
		<Container>
			<div className="page-loader">
				<Spinner />
			</div>
		</Container>
	);
};

export default ReferralProgramRedeemCode;
