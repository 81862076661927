import React from 'react';
import { HELP_CENTER_URLS } from '@novacredit/frontend-common/utils/url';
import { Spinner } from '@novacredit/pandorasbox';

import './Loader.scss';

const Loader = ({
	text = 'Loading...',
	subText,
}: {
	text?: string;
	subText?: string | React.ReactNode;
}) => {
	return (
		<div className="loader">
			<div className="spinner">
				<Spinner />
			</div>
			<h1 className="mb-2">{text}</h1>
			<p className="text-muted">
				{subText || (
					<>
						This may take up to a few minutes. If this takes more than a few minutes,{' '}
						<a
							href={HELP_CENTER_URLS.CONTACT}
							target="_blank"
							rel="noopener noreferrer"
						>
							contact us
						</a>
						.
					</>
				)}
			</p>
		</div>
	);
};

export default Loader;
