import { BASE_API_URLS } from 'consts';
import { getNodeEnv } from 'utils';

/**
 * Get base url for api
 */
export const getBaseURL = () => {
	return BASE_API_URLS[getNodeEnv()];
};

/**
 * Make api request
 * @param {string} path
 * @param {object} options
 * @param {string} options.method - request method
 * @param {object} options.body - request data
 * @return {Promise<any>} the fetch response promise
 */
export const request = (path, { method = 'POST', body = undefined } = {}) => {
	return fetch(`${getBaseURL()}${path}`, {
		method,
		credentials: 'include',
		headers: {
			'content-type': 'application/json',
		},
		body: JSON.stringify(body),
	});
};

export default {
	request,
};
