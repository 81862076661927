import { Typography } from '@novacredit/pandorasbox';
import React from 'react';

import './PageHero.scss';

const PageHero = ({
	title,
	subtitle,
	backgroundImgSrc,
}: {
	title: string;
	subtitle: string;
	backgroundImgSrc: string;
}) => {
	return (
		<div className="page-hero" style={{ backgroundImage: `url(${backgroundImgSrc})` }}>
			<div className="page-hero-background-gradient" />
			<div className="page-hero-content-container">
				<div className="page-hero-content text-center">
					<Typography
						tag="h1"
						variant="Heading-1"
						variantMobile="Heading-4"
						className="page-hero-title"
					>
						{title}
					</Typography>
					<Typography
						tag="p"
						variant="Subtitle-1"
						variantMobile="Caption-Regular"
						className="page-hero-subtitle"
					>
						{subtitle}
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default PageHero;
