import {
	EventStore,
	FacebookPixel,
	getRedirectAfterClickHandler,
	GoogleAnalytics,
	GoogleTagManager,
	TrackingClient,
	Intercom,
} from '@novacredit/frontend-common/tracking';
import type { CountryCode } from '@novacredit/frontend-common/utils/countries';
import type { EventSchema } from '@novacredit/frontend-common/tracking';

import type { Card } from 'types';

export { getRedirectAfterClickHandler } from '@novacredit/frontend-common/tracking';
export type { EventSchema } from '@novacredit/frontend-common/tracking';

export const GTM_CONTAINER_IDS = {
	development: 'GTM-WM3XXMR',
	staging: 'GTM-WSVSQLM',
	production: 'GTM-KLLH2L4',
} as const;

const CATEGORY = 'Consumer Dashboard';
const APP_NAME = 'consumer-dashboard';

export const tracker = new TrackingClient({
	destinations: [
		new EventStore({ appName: APP_NAME, category: CATEGORY }),
		new FacebookPixel({ appName: APP_NAME, category: CATEGORY }),
		new GoogleAnalytics({ appName: APP_NAME, category: CATEGORY }),
		new GoogleTagManager({ appName: APP_NAME, category: CATEGORY }),
		new Intercom({ appName: APP_NAME, category: CATEGORY }),
	],
	appName: APP_NAME,
});

/**
 * In addition to logging a `nova.client.COUNTRY_SELECTED` event, this helper
 * will send country as a user property to Mixpanel (and other destinations
 * that support user properties) and also ensure that `country` is included
 * as a property on all subsequent tracking calls.
 */
export const trackCountrySelected = ({
	country,
	page,
}: {
	country: CountryCode;
	page: EventSchema['nova.client.COUNTRY_SELECTED']['page'];
}) => {
	tracker.setUserProps({ country });
	tracker.setDefaultProperties({ country });
	tracker.track('nova.client.COUNTRY_SELECTED', { country, page });
};

export const getTrackingPropsForCreditCard = (card: Card) => {
	return {
		cardName: card.name,
		manufacturer: card.manufacturer,
		annualFee: card.annual_fee,
		cashAdvanceAprDescription: card.cash_advance_apr_description,
		creditRating: card.credit_rating,
		id: card.slug,
		introPurchaseApr: card.intro_purchase_apr,
		introTransferApr: card.intro_transfer_apr,
		labels: card.labels,
		acceptsSsnOrItin: card.accepts_ssn_or_itin,
		novaEnabled: card.nova_enabled,
		regularPurchaseApr: card.regular_purchase_apr,
		regularTransferApr: card.regular_transfer_apr,
		reportsToAllMajorUsBureaus: card.reports_to_all_major_us_bureaus,
		reportsToSomeMajorUsBureaus: card.reports_to_some_major_us_bureaus,
		secured: card.secured,
	};
};

export const getTrackingPropsForCreditCards = (cards: Card[]) => {
	return cards.map(card => {
		return getTrackingPropsForCreditCard(card);
	});
};

// Note that this module is mocked in tests. Exports here likely need to be mocked
// as well in test/setup.js
export default {
	getRedirectAfterClickHandler,
	tracker,
};
