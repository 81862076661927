import type sentry from '@sentry/types';
import { ENV } from './env';

/**
 * Potential URLs with referral codes
 * https://cms.aphrodite.novacredit.com/r/john-696237ba-4dbf
 * https://cms.aphrodite.novacredit.com/refer/your-referral-link?code=john-696237ba-4dbf
 * https://cms.aphrodite.novacredit.com/cards?referrer=john-696237ba-4dbf&country=bra
 */
export const filterReferralRequestPii = (url: string): string => {
	let urlParts;
	try {
		urlParts = new URL(url);
	} catch (e) {
		return url;
	}

	// https://cms.aphrodite.novacredit.com/r/john-696237ba-4dbf
	if (urlParts.pathname.startsWith('/r/')) {
		urlParts.pathname = '/r/******';
	}

	// https://cms.aphrodite.novacredit.com/refer/your-referral-link?code=john-696237ba-4dbf
	if (urlParts.pathname === '/refer/your-referral-link' && urlParts.searchParams.has('code')) {
		urlParts.searchParams.set('code', '******');
	}

	// https://cms.aphrodite.novacredit.com/cards?referrer=john-696237ba-4dbf&country=bra
	if (urlParts.pathname === '/cards' && urlParts.searchParams.has('referrer')) {
		urlParts.searchParams.set('referrer', '******');
	}

	return urlParts.toString();
};

export const filterSentryEventUrls = (event: sentry.Event): sentry.Event => {
	if (event?.request?.url) {
		event.request.url = filterReferralRequestPii(event.request.url);
	}
	if (event?.request?.headers?.Referer) {
		event.request.headers.Referer = filterReferralRequestPii(event.request.headers.Referer);
	}
	return event;
};

// Get either the localhost URL or the PR preview URL.
const getDevUrl = ({ localhostPort = '' } = {}) => {
	if (typeof window === 'undefined') {
		return '';
	}

	const { hostname, port, protocol } = window.location;

	return `${protocol}//${hostname}${port && `:${localhostPort}`}`;
};
/**
 * Specifically for the PR Preview environment
 * Replaces whatever is between the first two '.' in a url inclusively
 * with '.hello'
 * @param origin ideally window.location.origin
 * @returns modified url for PR Preview environment
 */
export const generatePRPreviewUrl = (origin: string): string => {
	const replaceRegex = /(\.)(.*)(?=bobacredit.com)/i;
	return origin.replace(replaceRegex, '.hello.');
};

const getConsumerDevUrl = (port: string) => {
	if (typeof window === 'undefined') {
		// Assume we're local not in PR previews here. Could be a little confusing in PR previews,
		// but feel like devs are most likely to think this is an issue if seen locally. Can
		// always swap this to the bobacredit URL if there's confusion.
		return 'http://localhost:3333';
	}
	return window.location.hostname === 'localhost'
		? getDevUrl({ localhostPort: port })
		: generatePRPreviewUrl(window.location.origin);
};

export const BASE_CONSUMERCORE_DOMAIN = {
	[ENV.development]: getConsumerDevUrl('8012'),
	[ENV.staging]: 'https://hello.aphrodite.novacredit.com',
	[ENV.production]: 'https://hello.novacredit.com',
};

export const BASE_CONSUMER_DASHBOARD_DOMAIN = {
	[ENV.development]: getConsumerDevUrl('3333'),
	[ENV.staging]: 'https://hello.aphrodite.novacredit.com',
	[ENV.production]: 'https://hello.novacredit.com',
};

export const HELP_CENTER_URLS = {
	CONTACT: 'https://help.novacredit.com/s/contactsupport',
	DISPUTES: 'https://help.novacredit.com/s/dispute',
} as const;

export const BASE_CMS_DOMAIN = {
	development:
		window.location.hostname === 'localhost'
			? 'http://localhost:8001'
			: `https://${window.location.hostname.replace('.my.', '.cms.')}`,
	staging: 'https://cms.aphrodite.novacredit.com',
	production: 'https://novacredit.com',
};
