type Experiment = {
	// Metadata to explain what this experiment is
	description: string;
	// Possible groups a user could be assigned to,
	// e.g. "CONTROL" or "VARIANT", as well as the %
	// of users to assign to each group.
	variants: ReadonlyArray<{
		name: string;
		weight: number;
	}>;
};

enum FEATURE_FLAG_VARIANTS {
	ENABLED = 'ENABLED',
	DISABLED = 'DISABLED',
}

export type FeatureFlagConfig = {
	// Metadata to explain what this feature flag controls
	description: string;
	// The variant to show to all users
	activeVariant: FEATURE_FLAG_VARIANTS;
};

/**
 * All experiments should be defined here.
 * TS + unit tests will confirm that the experiment has been defined properly.
 */
const EXPERIMENTS: { [experimentName: string]: Experiment } = {
	FINISH_LATER: {
		description:
			'A/B test for finish later. Control keeps the feature hidden while the variant will show the feature enabled.',
		variants: [
			{ name: 'CONTROL', weight: 50 },
			{ name: 'VARIANT', weight: 50 },
		],
	},
	AMEX_CMS_1: {
		description: 'Amex CMS Experiment 1',
		variants: [
			{ name: 'CONTROL', weight: 50 },
			{ name: 'VARIANT', weight: 50 },
		],
	},
	AMEX_CMS_2: {
		description: 'Amex CMS Experiment 2',
		variants: [
			{ name: 'CONTROL', weight: 50 },
			{ name: 'VARIANT', weight: 50 },
		],
	},
	AMEX_CMS_3: {
		description: 'Amex CMS Experiment 3',
		variants: [
			{ name: 'CONTROL', weight: 50 },
			{ name: 'VARIANT', weight: 50 },
		],
	},
	AMEX_CMS_4: {
		description: 'Amex CMS Experiment 4',
		variants: [
			{ name: 'CONTROL', weight: 50 },
			{ name: 'VARIANT', weight: 50 },
		],
	},
	AMEX_CMS_5: {
		description: 'Amex CMS Experiment 5',
		variants: [
			{ name: 'CONTROL', weight: 50 },
			{ name: 'VARIANT', weight: 50 },
		],
	},
	BANK_LOGIN_INTERSTITIAL: {
		description:
			'Displays a different bank login interstitial to tell users which accounts to select',
		variants: [
			{ name: 'CONTROL', weight: 50 },
			{ name: 'VARIANT', weight: 50 },
		],
	},
	CONSENT_DATA_PRIVACY: {
		description:
			'Displays a data privacy CTA with popup in the MultiAgg consent. Run only for Merrick.',
		variants: [
			{ name: 'CONTROL', weight: 50 },
			{ name: 'VARIANT', weight: 50 },
		],
	},
} as const;

enum FEATURE_FLAGS {
	LOCKED_FIELDS = 'LOCKED_FIELDS',
	REFERRAL_PROGRAM_REACTIVATION = 'REFERRAL_PROGRAM_REACTIVATION',
	WEBSITE_LANGUAGE_SELECTOR = 'WEBSITE_LANGUAGE_SELECTOR',
}

/**
 * All feature flags should be defined here.
 */
const FEATURE_FLAGS_CONFIG: Record<FEATURE_FLAGS, FeatureFlagConfig> = {
	[FEATURE_FLAGS.LOCKED_FIELDS]: {
		description: 'Enables the locked fields functionality',
		activeVariant: FEATURE_FLAG_VARIANTS.DISABLED,
	},
	[FEATURE_FLAGS.REFERRAL_PROGRAM_REACTIVATION]: {
		description:
			'Adds back the referral program flow where consumers can refer each other when applying for U.S. credit card products',
		activeVariant: FEATURE_FLAG_VARIANTS.ENABLED,
	},
	[FEATURE_FLAGS.WEBSITE_LANGUAGE_SELECTOR]: {
		description: 'Adds the language selector to CMS/Consumer dashboard.',
		activeVariant: FEATURE_FLAG_VARIANTS.ENABLED,
	},
};

export { EXPERIMENTS, FEATURE_FLAGS, FEATURE_FLAGS_CONFIG, FEATURE_FLAG_VARIANTS };
