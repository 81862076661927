import type React from 'react';
import styled from '@emotion/styled';

import breakpoints from '../../../breakpoints';
import designSystemFontTokens from '../../../../dist/style-dictionary/json/font.json';
import { designSystemColors as colors } from '../../../colors';

const VARIANT_STYLE_CONFIG = {
	'Heading-1': {
		fontSize: designSystemFontTokens.FontSize64,
		fontWeight: designSystemFontTokens.FontWeightLight,
	},
	'Heading-2': {
		fontSize: designSystemFontTokens.FontSize48,
		fontWeight: designSystemFontTokens.FontWeightSemibold,
	},
	'Heading-3': {
		fontSize: designSystemFontTokens.FontSize36,
		fontWeight: designSystemFontTokens.FontWeightRegular,
	},
	'Heading-4': {
		fontSize: designSystemFontTokens.FontSize24,
		fontWeight: designSystemFontTokens.FontWeightSemibold,
	},
	'Subtitle-1': {
		fontSize: designSystemFontTokens.FontSize20,
		fontWeight: designSystemFontTokens.FontWeightRegular,
	},
	'Subtitle-2': {
		fontSize: designSystemFontTokens.FontSize18,
		fontWeight: designSystemFontTokens.FontWeightSemibold,
	},
	'Body-Semibold': {
		fontSize: designSystemFontTokens.FontSize16,
		fontWeight: designSystemFontTokens.FontWeightSemibold,
	},
	'Body-Regular': {
		fontSize: designSystemFontTokens.FontSize16,
		fontWeight: designSystemFontTokens.FontWeightRegular,
	},
	'Caption-Semibold': {
		fontSize: designSystemFontTokens.FontSize14,
		fontWeight: designSystemFontTokens.FontWeightSemibold,
	},
	'Caption-Regular': {
		fontSize: designSystemFontTokens.FontSize14,
		fontWeight: designSystemFontTokens.FontWeightRegular,
	},
} as const;

export type TypographicVariant = keyof typeof VARIANT_STYLE_CONFIG;

const Typography = styled.div<{
	variant: string;
	variantMobile?: string;
	as: React.ElementType;
	color?: string;
}>(
	{
		fontFamily:
			"var(--font-family), 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif",
	},
	props => VARIANT_STYLE_CONFIG[props.variant],
	props =>
		props.color && {
			color: colors[props.color],
		},
	props =>
		props.variantMobile && {
			...VARIANT_STYLE_CONFIG[props.variantMobile],
			[breakpoints.small]: VARIANT_STYLE_CONFIG[props.variant],
		},
);

export default {
	Typography,
};
