import { useEffect } from 'react';

import { getCmsURL } from 'utils';

const CookiePolicyPage = () => {
	useEffect(() => {
		window.location.replace(getCmsURL('/cookie-policy'));
	}, []);

	return null;
};

export default CookiePolicyPage;
