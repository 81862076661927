import React from 'react';
import { Tooltip } from '@novacredit/pandorasbox';

import Icon from 'components/common/Icon';

const IndependentDisclosure = () => {
	return (
		<Tooltip
			position="bottom"
			theme="light"
			className="independent-disclosure ml-1"
			arrow
			tooltipTrigger={<Icon name="info-circle" variant="light" size="xs" />}
			content={
				<p className="muted tooltip-text">
					The information for this card has been collected independently by Nova Credit
					and has not been reviewed or provided by the card issuer.
				</p>
			}
		/>
	);
};

export default IndependentDisclosure;
