import React from 'react';

import { Typography, KeyboardArrowRightIcon } from '@novacredit/pandorasbox';
import MarkDone from 'components/common/ContentTabs/ContentTabsMarkDone';
import ShareMenu from 'components/common/ShareMenu';

import 'components/common/ContentTabs/ContentTabsTask.scss';

const ContentTabsTask = ({
	taskId,
	taskTitle,
	taskDetail,
	taskImage,
	taskLink,
	onTaskMarked,
	isComplete,
}: {
	taskId: string;
	taskTitle: string;
	taskDetail: string;
	taskImage?: string;
	taskLink: {
		text: string;
		src: string;
	};
	onTaskMarked: (taskId: string, complete: boolean) => void;
	isComplete: boolean;
}) => {
	const emailBody = `Hi,

I found this helpful guide about moving to the U.S. through this company called Nova Credit.

Check out this article: "${taskTitle}"
${taskLink.src}

Enjoy!
`;

	const image = taskImage ? (
		<img className="content-tab-task_img" alt="" src={taskImage} />
	) : null;

	const onCheckboxChange = (checked: boolean) => {
		onTaskMarked(taskId, checked);
	};

	return (
		<div className="content-tab-task" data-taskname={taskId}>
			{image}
			<div className="content-tab-task_body">
				<Typography tag="p" variant="Body-Semibold">
					{taskTitle}
				</Typography>
				<Typography tag="p" variant="Body-Regular" className="content-tab-task_description">
					{taskDetail}
				</Typography>
				<Typography
					className="content-tab-task_link"
					tag="a"
					href={taskLink.src}
					variant="Body-Regular"
				>
					{taskLink.text}
					<KeyboardArrowRightIcon />
				</Typography>
			</div>
			<div className="content-tab-task_footer">
				<ShareMenu
					link={taskLink.src}
					emailSubject="Thought you might find this helpful!"
					emailBody={emailBody}
					content="Article"
					display="column"
					channels={[
						{
							channel: 'WhatsApp',
							shareText: 'Check out this helpful article from Nova Credit: ',
						},
						{ channel: 'Facebook' },
						{
							channel: 'Twitter',
							shareText: 'Check out this helpful article from Nova Credit: ',
						},
						{ channel: 'LinkedIn' },
						{ channel: 'Copy link' },
						{ channel: 'Email' },
					]}
				/>
				<MarkDone isChecked={isComplete} callback={onCheckboxChange} />
			</div>
		</div>
	);
};

export default ContentTabsTask;
