import React, { useState } from 'react';
import classnames from 'classnames';
import { DesignSystemButton, ExpandIcon, Fieldset, RemoveIcon } from '@novacredit/pandorasbox';

import { FILTER_TYPES, ACTIONS } from 'contexts/CardShopContext';
import type { CardShopContextShape } from 'contexts/CardShopContext';
import CardShopFilterCheckbox from 'components/common/CardShopFilterCheckbox';
import Modal from 'components/common/Modal';
import { tracker } from 'modules/tracking';

import './CardShopFilters.scss';

const FilterFields = ({ cardshopContext }: { cardshopContext: CardShopContextShape }) => {
	const {
		state: { features, rewards },
		dispatch,
	} = cardshopContext;

	const onChange = ({ category, name, label, isChecked }) => {
		const newState = {
			...(category === FILTER_TYPES.FEATURES ? features : rewards),
			[name]: isChecked,
		};

		const enabledFilters = Object.keys(newState)
			.map(filterName => (newState[filterName] ? filterName : false))
			.filter(Boolean)
			.join();

		dispatch({
			type:
				category === FILTER_TYPES.FEATURES
					? ACTIONS.UPDATE_FEATURES
					: ACTIONS.UPDATE_REWARDS,
			payload: { filters: enabledFilters },
		});

		tracker.track('nova.consumer_dashboard.CARDSHOP_FILTER_TOGGLED', {
			category,
			filterName: label.toLowerCase(),
			enabled: isChecked,
		});
	};

	const onTooltipShown = (category, label) => {
		tracker.track('nova.consumer_dashboard.CARDSHOP_FILTER_HELP_TOGGLED', {
			category,
			filterName: label.toLowerCase(),
		});
	};

	return (
		<>
			<Fieldset
				label="Features"
				labelId="features-filter-group"
				styleLabelAs="Subtitle-2"
				className="card-shop-filters-category"
				key="features"
			>
				<CardShopFilterCheckbox
					category={FILTER_TYPES.FEATURES}
					name="nova_enabled"
					label="Accepts foreign credit history"
					checked={features.nova_enabled}
					onChange={onChange}
				/>
				<CardShopFilterCheckbox
					category={FILTER_TYPES.FEATURES}
					name="reports_to_some_major_us_bureaus"
					label="Builds U.S. credit history"
					checked={features.reports_to_some_major_us_bureaus}
					onChange={onChange}
				/>
				<CardShopFilterCheckbox
					category={FILTER_TYPES.FEATURES}
					name="accepts_ssn_or_itin"
					label="Accepts SSN or ITIN"
					tooltip="These credit cards are available for applicants who have a U.S. Social Security Number (SSN) or an Individual Taxpayer Identification Number (ITIN)."
					checked={features.accepts_ssn_or_itin}
					onChange={onChange}
					onTooltipShown={onTooltipShown}
				/>
			</Fieldset>

			<Fieldset
				label="Rates and Fees"
				labelId="rates-and-fees-filter-group"
				styleLabelAs="Subtitle-2"
				className="card-shop-filters-category"
				key="rates-and-fees"
			>
				<CardShopFilterCheckbox
					category={FILTER_TYPES.FEATURES}
					name="no_annual_fee"
					label="No annual fee"
					checked={features.no_annual_fee}
					onChange={onChange}
				/>
				<CardShopFilterCheckbox
					category={FILTER_TYPES.FEATURES}
					name="zero_intro_purchase_apr"
					label="0% intro APR"
					tooltip="Some cards offer to charge no interest for an introductory
							period. That means the purchases you make for that period
							won't generate interest fees if you don't pay the bill in
							full each month."
					checked={features.zero_intro_purchase_apr}
					onChange={onChange}
					onTooltipShown={onTooltipShown}
				/>
				<CardShopFilterCheckbox
					category={FILTER_TYPES.FEATURES}
					name="no_deposit_required"
					label="No deposit required"
					checked={features.no_deposit_required}
					onChange={onChange}
				/>
			</Fieldset>

			<Fieldset
				label="Rewards"
				labelId="rewards-filter-group"
				styleLabelAs="Subtitle-2"
				className="card-shop-filters-category"
				key="rewards"
			>
				<CardShopFilterCheckbox
					category={FILTER_TYPES.REWARDS}
					name="cash_back"
					label="Cash back"
					checked={rewards.cash_back}
					onChange={onChange}
				/>
				<CardShopFilterCheckbox
					category={FILTER_TYPES.REWARDS}
					name="travel"
					label="Travel"
					checked={rewards.travel}
					onChange={onChange}
				/>
				<CardShopFilterCheckbox
					category={FILTER_TYPES.REWARDS}
					name="gas"
					label="Gas"
					checked={rewards.gas}
					onChange={onChange}
				/>
			</Fieldset>
		</>
	);
};

const ToggleContainer = ({
	buttonText,
	children,
	className,
	toggleFilterModal,
}: {
	children: React.ReactNode;
	buttonText: string;
	className?: string;
	toggleFilterModal: () => void;
}) => (
	<div className={classnames(className, 'card-shop-filters-toggle-container pt-2')}>
		<DesignSystemButton variant="ghost" onClick={toggleFilterModal} tag="button">
			<span className="pr-2">{buttonText}</span>
			{children}
		</DesignSystemButton>
	</div>
);

const CardShopFilters = ({ cardshopContext }: { cardshopContext: CardShopContextShape }) => {
	const [showFilterModal, setShowFilterModal] = useState(false);

	const toggleFilterModal = () => {
		setShowFilterModal(!showFilterModal);
	};

	return (
		<div className="card-shop-filters">
			<div className="card-shop-filters-inner">
				<FilterFields cardshopContext={cardshopContext} />
				<ToggleContainer
					buttonText="View more filters"
					className="card-shop-filters-view-more mt-4"
					toggleFilterModal={toggleFilterModal}
				>
					<ExpandIcon />
				</ToggleContainer>
			</div>
			{showFilterModal && (
				<Modal onDismiss={toggleFilterModal}>
					<div className="card-shop-filters-modal">
						<div className="card-shop-filters-modal-inner">
							<FilterFields cardshopContext={cardshopContext} />
						</div>
						<ToggleContainer
							buttonText="Close filters"
							className="pb-2"
							toggleFilterModal={toggleFilterModal}
						>
							<RemoveIcon />
						</ToggleContainer>
					</div>
				</Modal>
			)}
		</div>
	);
};

export default CardShopFilters;
