import React from 'react';
import { HELP_CENTER_URLS } from '@novacredit/frontend-common/utils/url';

import Page from 'components/common/Page';
import PageTitle from 'components/common/PageTitle';

const ConsumerType = () => {
	return (
		<Page>
			<PageTitle title="Contact Us" />
			<h1>Contact Us</h1>
			<p>
				Visit our <a href={HELP_CENTER_URLS.CONTACT}>help center</a>.
			</p>
		</Page>
	);
};
export default ConsumerType;
