import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Spinner, Typography } from '@novacredit/pandorasbox';

import AdDisclosure from 'components/common/AdDisclosure';
import ApplyButton from 'components/common/ApplyButton';
import Breadcrumbs from 'components/common/Breadcrumbs';
import CardDetails from 'components/common/CardDetails';
import Heading from 'components/common/Heading';
import Page from 'components/common/Page';
import { Row, Col, Container } from 'components/common/Layout';
import RatesAndFeesLink from 'components/common/RatesAndFeesLink';
import PageTitle from 'components/common/PageTitle';
import { getTrackingPropsForCreditCard, tracker } from 'modules/tracking';
import { getNodeEnv, getCmsURL } from 'utils';
import { ENV } from 'consts';
import { APP_PATHS } from 'modules/paths';
import useApi from 'hooks/useApi';

import './CardDetailsPage.scss';

type Props = {
	match: any;
};

const CardDetailsPage = ({ match }: Props) => {
	const nodeEnv = getNodeEnv();

	const { slug } = match.params;
	const { data, error, loading } = useApi({
		path: `/credit-cards/${slug}`,
	});

	useEffect(() => {
		if (data && data.creditCard) {
			tracker.track(
				'nova.consumer_dashboard.CREDIT_CARD_DETAILS_VIEWED',
				getTrackingPropsForCreditCard(data.creditCard),
			);
		}
	}, [data]);

	if (loading) {
		return (
			<div className="page-loader">
				<Spinner />
			</div>
		);
	}
	if (error || !data) {
		return <div>Error: {error ? error.message : 'Could not retrieve data for this card'}</div>;
	}

	const creditCard = data;
	if (!creditCard) {
		// This is the case where we were not able to find a card for the given :slug
		return <Redirect to={APP_PATHS.CARDSHOP} />;
	}

	const imageUrl =
		nodeEnv === ENV.development ? creditCard.image_url : getCmsURL(creditCard.image_url);

	return (
		<Page className="card-details-page">
			<PageTitle title={creditCard.name} />
			<Container>
				<Row>
					<Col>
						<Breadcrumbs
							crumbs={[
								{ title: 'Credit Cards', url: APP_PATHS.CARDSHOP },
								{ isCurrentPage: true, title: creditCard.name },
							]}
						/>
					</Col>
					<Col className="text-right">
						<AdDisclosure align="right" />
						<Typography
							tag="p"
							variant="Caption-Regular"
							className="mt-1"
							color="ColorCoolGray600"
						>
							{creditCard.manufacturer} is a Nova Credit partner
						</Typography>
					</Col>
				</Row>
				<Row className="card-details__main-row">
					<Col size={{ md: 10 }} offset={{ md: 1 }}>
						<Row>
							<Col size={{ lg: 3 }} className="mb-4 text-center">
								<img className="card-img" src={imageUrl} alt={creditCard.name} />
								<ApplyButton card={creditCard} currentPage="Card Details" />
								<RatesAndFeesLink
									className="mt-2 text-center"
									href={creditCard.terms_url}
								/>
							</Col>
							<Col size={{ lg: 8 }} className="card-details__primary-content">
								<Heading
									title={creditCard.name}
									subtitle={
										creditCard.manufacturer && `By ${creditCard.manufacturer}`
									}
									disclosure={
										creditCard.source === 'independent'
											? `
									The information for this card has been collected independently by
									Nova Credit and has not been reviewed or provided by the card issuer.
								`
											: ''
									}
								/>
								<CardDetails card={creditCard} />
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</Page>
	);
};

export default CardDetailsPage;
