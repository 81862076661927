import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@novacredit/pandorasbox';

import Icon from 'components/common/Icon';
import { getCmsURL } from 'utils';

import 'components/common/Breadcrumbs.scss';

type Props = {
	crumbs?: {
		isCurrentPage?: boolean;
		title: string;
		url?: string;
	}[];
	homeCrumb?: {
		title: string;
		url: string;
		tag: React.ElementType;
	};
};

/**
 * Creates a breadcrumb navigation UI.
 * @param {array} crumbs Array of crumb nav objects
 * @return {React.reactElement}	Unordered list of links
 */
const Breadcrumbs = ({
	crumbs = [],
	homeCrumb = {
		title: 'Home',
		url: getCmsURL('/personal'),
		tag: 'a',
	},
}: Props) => (
	<ul className="breadcrumbs">
		<li className="breadcrumb-item">
			<Typography
				tag={homeCrumb.tag}
				href={homeCrumb.url}
				to={homeCrumb.url}
				variant="Body-Regular"
				variantMobile="Caption-Regular"
				color="ColorBlue600"
			>
				{homeCrumb.title}
			</Typography>
		</li>
		{crumbs.map(({ isCurrentPage, title, url }) => {
			const className = isCurrentPage ? 'breadcrumb-item active' : 'breadcrumb-item';

			// The link should updated to use the Design System link component
			// as part of https://novacredit.atlassian.net/browse/ENG-14327.
			return (
				<li className={className} key={title}>
					<Icon name="chevron-right" className="breadcrumb-item-chevron" />
					{isCurrentPage ? (
						<Typography
							tag="span"
							variant="Body-Regular"
							variantMobile="Caption-Regular"
							color="ColorCoolGray900"
						>
							{title}
						</Typography>
					) : (
						<Typography
							tag={Link}
							variant="Body-Regular"
							variantMobile="Caption-Regular"
							color="ColorBlue600"
							to={url}
						>
							{title}
						</Typography>
					)}
				</li>
			);
		})}
	</ul>
);

export default Breadcrumbs;
