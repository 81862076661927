import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { Flag, KeyboardArrowRightIcon, Typography } from '@novacredit/pandorasbox';
import { useMount } from '@novacredit/frontend-common/hooks';

import ContentTabsBody from 'components/common/ContentTabs/ContentTabsBody';
import { COUNTRY_FEEDBACK } from 'components/pages/NewcomerGuidePageConfig.js';
import { useStoredCountryContext } from 'contexts/StoredCountryContext';
import { tracker } from 'modules/tracking';

import 'components/common/ContentTabs/ContentTabsContainer.scss';

export type tabType = {
	id: string;
	urlHash: string;
	title: string;
	mobileTitle?: string;
	tasks: any;
};

export const ContentTabButton = ({
	id,
	title,
	mobileTitle,
	isActive,
	clickHandler,
}: {
	id: string;
	title: string;
	mobileTitle?: string;
	isActive: boolean;
	clickHandler: React.MouseEventHandler;
}) => (
	<Typography
		tag="button"
		variant="Body-Semibold"
		type="button"
		id={`content-tab-${id}`}
		className={classnames('content-tab_button', { 'is-active': isActive })}
		name="content-tab"
		role="tab"
		aria-selected={isActive ? 'true' : 'false'}
		aria-controls={`content-tab-body-${id}`}
		data-id={id}
		onClick={clickHandler}
	>
		<p className="content-tab_button-title">{title}</p>
		<p className="content-tab_button-title-sm">{mobileTitle || title}</p>
	</Typography>
);

const ContentTabsContainer = ({ tabList }: { tabList: tabType[] }) => {
	const { storedCountry: currentCountry } = useStoredCountryContext();
	const initCompletedTasks = [];
	let storedTasks = JSON.parse(localStorage.getItem('guideTasks'));
	if (!storedTasks) {
		localStorage.setItem('guideTasks', '{}');
		storedTasks = {};
	}
	tabList.forEach(tab => {
		const isComplete = !tab.tasks.some(task => !storedTasks[task.taskId]);
		if (isComplete) {
			initCompletedTasks.push(tab.id);
		}
	});

	const tabsContainerRef = useRef(null);

	const activeTabFromHash = window.location.hash.replace('#', '');
	const initActiveTab = tabList.some(tab => tab.urlHash === activeTabFromHash)
		? tabList.filter(tab => tab.urlHash === activeTabFromHash)[0].id
		: tabList[0].id;
	const [activeTab, setActiveTab] = useState(initActiveTab);
	const [completedTabs, setCompletedTabs] = useState(initCompletedTasks);
	const tabsLen = tabList.length;
	const trackedTabs = [];

	const [lastCountry, setLastCountry] = useState(currentCountry);
	const [countryChanged, setCountryChanged] = useState(false);

	// Active tab should switch if the user is at, for example, /guide#before and uses navbar to navigate to /guide#arrive
	const history = useHistory();
	useMount(() => {
		const tabIdByUrlHash = {
			'#before': 'BEFORE_MOVE',
			'#arrive': 'WHEN_ARRIVE',
			'#after': 'AFTER_MOVE',
		};
		return history.listen(location => {
			setActiveTab(tabIdByUrlHash[location.hash]);
		});
	});

	// Check to see if the user has already acknowledges the bookmark page suggestion
	const initAcknowledgedBookmarkPage = Boolean(
		JSON.parse(localStorage.getItem('bookmarkNewcomerGuidePage')),
	);
	const [acknowledgedBookmarkPage, setacknowledgedBookmarkPage] = useState(
		initAcknowledgedBookmarkPage,
	);

	// Check to see if at least one task is completed
	const atLeastOneTaskCompleted = Object.values(storedTasks).includes(1);
	const showBookmarkGuidesPage = atLeastOneTaskCompleted && !acknowledgedBookmarkPage;

	const acknowledgeBookmarkTask = () => {
		localStorage.setItem('bookmarkNewcomerGuidePage', 'true');
		setacknowledgedBookmarkPage(true);
	};

	const onTasksChange = (tab: string, remove: boolean) => {
		const tabIndex = completedTabs.indexOf(tab);
		const tabDupe = [...completedTabs];
		if (tabIndex < 0) {
			setCompletedTabs([...completedTabs, tab]);
		} else if (remove) {
			tabDupe.splice(tabIndex, 1);
			setCompletedTabs(tabDupe);
		}
		return completedTabs;
	};

	const onUpdateActiveTab = ({ currentTarget }: React.MouseEvent<HTMLElement, MouseEvent>) => {
		const tabId = currentTarget.dataset.id;
		if (trackedTabs.indexOf(tabId) === -1) {
			tracker.track('nova.consumer_dashboard.GUIDE_TAB_CHANGED', {
				tabName: tabId,
			});
			trackedTabs.push(tabId);
		}
		setActiveTab(tabId);
		// Prevent pushing each tab change to history
		const baseUrl = window.location.href.split('#')[0];
		const urlHash = tabList.filter(tab => tab.id === tabId)[0].urlHash;
		window.location.replace(`${baseUrl}#${urlHash}`);
	};

	const onKeyupTab = (event: KeyboardEvent) => {
		const { key } = event;
		const activeTabArray = tabList.map(tab => tab.id);

		switch (key) {
			case 'ArrowRight':
				setActiveTab(prevTab => {
					const prevTabIndex = activeTabArray.indexOf(prevTab);
					const tabId = tabList[Math.min(prevTabIndex + 1, tabsLen - 1)].id;

					window.location.hash = tabList.filter(tab => tab.id === tabId)[0].urlHash;
					return tabId;
				});
				break;
			case 'ArrowLeft':
				setActiveTab(prevTab => {
					const prevTabIndex = activeTabArray.indexOf(prevTab);
					const tabId = tabList[Math.max(prevTabIndex - 1, 0)].id;

					window.location.hash = tabList.filter(tab => tab.id === tabId)[0].urlHash;
					return tabId;
				});
				break;
			default:
				break;
		}
	};

	useMount(() => {
		tabsContainerRef.current.addEventListener('keyup', onKeyupTab);

		return function cleanup() {
			tabsContainerRef.current?.removeEventListener('keyup', onKeyupTab);
		};
	});

	useEffect(() => {
		if (!currentCountry || currentCountry === lastCountry) {
			return;
		}
		setLastCountry(currentCountry);
		setCountryChanged(true);
	}, [currentCountry, lastCountry]);

	const tabBodies = tabList.map((tab: tabType) => (
		<ContentTabsBody
			id={tab.id}
			title={tab.title}
			tasks={tab.tasks}
			isActive={tab.id === activeTab}
			completedTabs={completedTabs}
			onTasksChange={onTasksChange}
			showBookmarkGuidesPage={showBookmarkGuidesPage}
			acknowledgeBookmarkTask={acknowledgeBookmarkTask}
			setActiveTab={setActiveTab}
			key={`body-${tab.title}`}
		/>
	));
	const tabButtons = tabList.map((tab: tabType) => (
		<ContentTabButton
			id={tab.id}
			title={tab.title}
			mobileTitle={tab.mobileTitle}
			isActive={tab.id === activeTab}
			clickHandler={onUpdateActiveTab}
			key={`tabbutton-${tab.title}`}
		/>
	));

	let countryChangeFeedback = null;
	if (countryChanged) {
		const feedbackText = `${COUNTRY_FEEDBACK[activeTab].toUpperCase()}THE UNITED STATES`;
		countryChangeFeedback = (
			<div className="country-info">
				<Flag countryCode={currentCountry} size="md" />
				<KeyboardArrowRightIcon />
				<Flag countryCode="USA" size="md" />
				<Typography tag="p" variant="Body-Semibold">
					{feedbackText}
				</Typography>
			</div>
		);
	}

	return (
		<div className="content-tabs" ref={tabsContainerRef}>
			<div className="content-tab_buttons" role="tablist">
				{tabButtons}
			</div>
			{countryChangeFeedback}
			<div className="content-tab_bodies">{tabBodies}</div>
		</div>
	);
};

export default ContentTabsContainer;
