// Cookies are categorized as being one of these four types.
export const COOKIE_TYPES = {
	necessary: 'necessary', // this will always be true
	preferences: 'preferences',
	statistics: 'statistics',
	marketing: 'marketing',
} as const;

export const COOKIE_TYPES_DEFAULT = {
	[COOKIE_TYPES.necessary]: true,
	[COOKIE_TYPES.preferences]: false,
	[COOKIE_TYPES.statistics]: false,
	[COOKIE_TYPES.marketing]: false,
};

export const COOKIE_TYPES_ENABLED = {
	[COOKIE_TYPES.necessary]: true,
	[COOKIE_TYPES.preferences]: true,
	[COOKIE_TYPES.statistics]: true,
	[COOKIE_TYPES.marketing]: true,
};

/**
 * This is the format for which cookie types the user has consented, or if
 * a previous consent has been stored and is loaded.
 */
export type ConsentMap = {
	[COOKIE_TYPES.necessary]: boolean;
	[COOKIE_TYPES.preferences]: boolean;
	[COOKIE_TYPES.statistics]: boolean;
	[COOKIE_TYPES.marketing]: boolean;
};

export type CookieType = keyof ConsentMap;

export type OnUpdateConsentCallback = (consents: ConsentMap) => void;

/**
 * This is a mapping between our services/vendors and what types of cookies
 * they use. It must be manually maintained by looking at our public Cookie Policy page.
 */
const SERVICE_MAPPING = {
	FacebookPixel: [COOKIE_TYPES.marketing],
	Fullstory: [COOKIE_TYPES.statistics, COOKIE_TYPES.preferences],
	Intercom: [COOKIE_TYPES.necessary, COOKIE_TYPES.preferences],
	Userleap: [COOKIE_TYPES.statistics],
	AntiFraud: [COOKIE_TYPES.necessary],
};

type ServiceName = keyof typeof SERVICE_MAPPING;

export const consentGrantedForService = (consents: ConsentMap, serviceName: ServiceName) => {
	let consentGranted = true;
	SERVICE_MAPPING[serviceName].forEach((consentType: CookieType) => {
		if (!consents[consentType]) {
			consentGranted = false;
		}
	});

	return consentGranted;
};
