import React, { useContext } from 'react';

import Page from 'components/common/Page';
import PageHeader from 'components/common/PageHeader';
import ContentTabsContainer from 'components/common/ContentTabs/ContentTabsContainer';
import PageTitle from 'components/common/PageTitle';
import StoredCountryContext from 'contexts/StoredCountryContext';

import statueBannerImg from 'assets/images/liberty_night_banner.jpg';
import TAB_CONFIG from 'components/pages/NewcomerGuidePageConfig';
import 'components/pages/NewcomerGuidePage.scss';

const NewcomerGuidePage = () => {
	const { storedCountry } = useContext(StoredCountryContext);

	return (
		<Page className="guide" fullscreen>
			<PageTitle title="Immigration Guide" />
			<PageHeader
				trackingPage="Immigrant Guide Page"
				country={storedCountry}
				className="guide-header"
				breadcrumb="Immigration Guide"
				pageTitle="Immigration Guide"
				bannerImg={statueBannerImg}
				headerCopy="Find all the information you need for your immigration journey to the U.S. We’ve interviewed  immigration experts, lawyers and people who have gone through the process to provide you with a comprehensive guide."
				adDisclosure={false}
			/>
			<div className="guide-body">
				<ContentTabsContainer tabList={TAB_CONFIG} />
			</div>
		</Page>
	);
};

export default NewcomerGuidePage;
