import { APP_PATHS } from 'modules/paths';
import { getCmsURL } from 'utils';

/**
 * The new marketing site will fetch this data from Contentful, but we hardcode it here to avoid a dependency
 * of the consumer dashboard on marketing site resources.
 */

export default {
	tabs: [
		{
			label: 'Enterprise',
			href: getCmsURL(),
			name: 'enterprise',
			menu: [
				{
					title: 'Products',
					columns: [
						{
							columnHeadline: 'Our products',
							links: [
								{
									icon: null,
									href: getCmsURL('/credit-passport'),
									label: 'Credit Passport®',
									newTab: false,
								},
								{
									icon: null,
									href: getCmsURL('/cash-atlas'),
									label: 'Cash Atlas™',
									newTab: false,
								},
								{
									icon: null,
									href: getCmsURL('/income-navigator'),
									label: 'Income Navigator',
									newTab: false,
								},
							],
						},
						{
							columnHeadline: 'Our platform',
							links: [
								{
									icon: null,
									href: getCmsURL('/platform'),
									label: 'Platform',
									newTab: false,
								},
								{
									icon: null,
									href: getCmsURL('/partnerships'),
									label: 'Partnerships',
									newTab: false,
								},
							],
						},
					],
				},
				{
					title: 'Solutions',
					columns: [
						{
							columnHeadline: 'By Use Case',
							links: [
								{
									icon: null,
									href: getCmsURL('/solutions-by-use-case'),
									label: 'All Use Cases',
									newTab: false,
								},
								{
									icon: null,
									href: getCmsURL('/solutions-by-use-case#new-customers'),
									label: 'Reach New Consumers',
									newTab: false,
								},
								{
									icon: null,
									href: getCmsURL('/solutions-by-use-case#orchestrate-data'),
									label: 'Multi-Source Data Orchestration',
									newTab: false,
								},
								{
									icon: null,
									href: getCmsURL('/solutions-by-use-case#portfolio-risk'),
									label: 'Reduce Portfolio Risk',
									newTab: false,
								},
								{
									icon: null,
									href: getCmsURL('/solutions-by-use-case#income-verification'),
									label: 'Automate Income Verification',
									newTab: false,
								},
							],
						},
						{
							columnHeadline: 'By Industry',
							links: [
								{
									icon: null,
									href: getCmsURL('/solutions-by-industry'),
									label: 'All Industries',
									newTab: false,
								},
								{
									icon: {
										url: 'https://images.ctfassets.net/6bj3xgxxnl0k/2pM7CEwWnC45D65pAOPzKb/fbdd84164d02099f4f70e73de52107ff/menu_icon_-_banks.svg',
									},
									href: getCmsURL('/solutions-by-use-case#vertical--banks'),
									label: 'Banks',
									newTab: false,
								},
								{
									icon: {
										url: 'https://images.ctfassets.net/6bj3xgxxnl0k/4eF9Gyju14CbH8lnuJgqK1/3bf68bb0ce47eaeb94596d63f45d9a6d/menu_icon_-_tenent_screening.svg',
									},
									href: getCmsURL('/income-navigator-for-tenant-screening'),
									label: 'Tenant Screening',
									newTab: false,
								},
								{
									icon: {
										url: 'https://images.ctfassets.net/6bj3xgxxnl0k/62xzlIkadgpVJWYcmDnhqj/f6593c56a70a4b1bce821cd28bfdcbe2/menu_icon_-_fintech.svg',
									},
									href: getCmsURL('/solutions-by-use-case#vertical--fintechs'),
									label: 'Fintech',
									newTab: false,
								},
								{
									icon: {
										url: 'https://images.ctfassets.net/6bj3xgxxnl0k/6LeoWCfoNOAnaW2mKFDbYM/118bbdccd9857aa9cf5bb7d517b0ae34/menu_icon_-_credit_unions.svg',
									},
									href: getCmsURL(
										'/solutions-by-use-case#vertical--credit-unions',
									),
									label: 'Credit Unions',
									newTab: false,
								},
								{
									icon: {
										url: 'https://images.ctfassets.net/6bj3xgxxnl0k/4wkE3iPa3GbUgBt3wWzTL1/21b7f79b8ea65d516ab4c49bcdcd4297/menu_icon_-_auto.svg',
									},
									href: getCmsURL('/solutions-by-use-case#vertical--auto'),
									label: 'Auto Lenders',
									newTab: false,
								},
							],
						},
					],
				},
				{
					title: 'Resources',
					columns: [
						{
							columnHeadline: null,
							links: [
								{
									icon: {
										url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
									},
									href: getCmsURL(
										'/corporate-blog/introducing-income-navigator-a-new-leader-in-income-verification-coverage',
									),
									label: 'Introducing ‘Income Navigator,’ a New Leader in Income Verification',
									newTab: false,
								},
								{
									icon: {
										url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
									},
									href: getCmsURL(
										'/corporate-blog/main-takeaways-cash-flow-is-here-top-use-cases-helping-underwriters-today',
									),
									label: 'Webinar On-Demand: Cash Flow is Here: Top use cases helping underwriters today',
									newTab: false,
								},
								{
									icon: {
										url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
									},
									href: getCmsURL(
										'/corporate-blog/nova-credit-appoints-financial-industry-veteran-as-chief-customer-officer',
									),
									label: 'Nova Credit Appoints Financial Industry Veteran as Chief Customer Officer',
									newTab: false,
								},
								{
									icon: {
										url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
									},
									href: getCmsURL(
										'/corporate-blog/nova-credit-raises-usd45m-series-c-financing-to-scale-cash-flow-underwriting',
									),
									label: 'Nova Credit Raises $45M Series C Financing',
									newTab: false,
								},
								{
									icon: {
										url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
									},
									href: getCmsURL(
										'/corporate-blog/nova-credit-expands-into-canada-partnering-with-scotiabank-as-first-canada',
									),
									label: 'Nova Credit Expands to Canada, Partners with Scotiabank',
									newTab: false,
								},
							],
							ctas: [
								{
									icon: {
										url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
									},
									href: getCmsURL('/corporate-blog'),
									label: 'View all resources',
									newTab: false,
								},
							],
						},
					],
				},
			],
			mobileMenu: [
				{
					mobileHeadline: 'Our Products',
					links: [
						{
							icon: null,
							href: getCmsURL('/credit-passport'),
							label: 'Credit Passport®',
							newTab: false,
						},
						{
							icon: null,
							href: getCmsURL('/cash-atlas'),
							label: 'Cash Atlas™',
							newTab: false,
						},
						{
							icon: null,
							href: getCmsURL('/income-navigator'),
							label: 'Income Navigator',
							newTab: false,
						},
					],
					ctas: [],
					includeCtaOnMobile: false,
				},
				{
					mobileHeadline: 'Our Platform',
					links: [
						{
							icon: null,
							href: getCmsURL('/platform'),
							label: 'Platform',
							newTab: false,
						},
						{
							icon: null,
							href: getCmsURL('/partnerships'),
							label: 'Partnerships',
							newTab: false,
						},
					],
					ctas: [],
					includeCtaOnMobile: false,
				},
				{
					mobileHeadline: 'By Use Case',
					links: [
						{
							icon: null,
							href: getCmsURL('/solutions-by-use-case'),
							label: 'All Use Cases',
							newTab: false,
						},
						{
							icon: null,
							href: getCmsURL('/solutions-by-use-case#new-customers'),
							label: 'Reach New Consumers',
							newTab: false,
						},
						{
							icon: null,
							href: getCmsURL('/solutions-by-use-case#orchestrate-data'),
							label: 'Multi-Source Data Orchestration',
							newTab: false,
						},
						{
							icon: null,
							href: getCmsURL('/solutions-by-use-case#portfolio-risk'),
							label: 'Reduce Portfolio Risk',
							newTab: false,
						},
						{
							icon: null,
							href: getCmsURL('/solutions-by-use-case#income-verification'),
							label: 'Automate Income Verification',
							newTab: false,
						},
					],
					includeCtaOnMobile: null,
				},
				{
					mobileHeadline: 'By Industry',
					links: [
						{
							icon: null,
							href: getCmsURL('/solutions-by-industry'),
							label: 'All Industries',
							newTab: false,
						},
						{
							icon: {
								url: 'https://images.ctfassets.net/6bj3xgxxnl0k/2pM7CEwWnC45D65pAOPzKb/fbdd84164d02099f4f70e73de52107ff/menu_icon_-_banks.svg',
							},
							href: getCmsURL('/solutions-by-industry#vertical--banks'),
							label: 'Banks',
							newTab: false,
						},
						{
							icon: {
								url: 'https://images.ctfassets.net/6bj3xgxxnl0k/4eF9Gyju14CbH8lnuJgqK1/3bf68bb0ce47eaeb94596d63f45d9a6d/menu_icon_-_tenent_screening.svg',
							},
							href: getCmsURL('/income-navigator-for-tenant-screening'),
							label: 'Tenant Screening',
							newTab: false,
						},
						{
							icon: {
								url: 'https://images.ctfassets.net/6bj3xgxxnl0k/62xzlIkadgpVJWYcmDnhqj/f6593c56a70a4b1bce821cd28bfdcbe2/menu_icon_-_fintech.svg',
							},
							href: getCmsURL('/solutions-by-industry#vertical--fintechs'),
							label: 'Fintech',
							newTab: false,
						},
						{
							icon: {
								url: 'https://images.ctfassets.net/6bj3xgxxnl0k/6LeoWCfoNOAnaW2mKFDbYM/118bbdccd9857aa9cf5bb7d517b0ae34/menu_icon_-_credit_unions.svg',
							},
							href: getCmsURL('/solutions-by-industry#vertical--credit-unions'),
							label: 'Credit Unions',
							newTab: false,
						},
						{
							icon: {
								url: 'https://images.ctfassets.net/6bj3xgxxnl0k/4wkE3iPa3GbUgBt3wWzTL1/21b7f79b8ea65d516ab4c49bcdcd4297/menu_icon_-_auto.svg',
							},
							href: getCmsURL('/solutions-by-industry#vertical--auto'),
							label: 'Auto Lenders',
							newTab: false,
						},
					],
					includeCtaOnMobile: null,
				},
				{
					mobileHeadline: 'Resources',
					links: [
						{
							icon: {
								url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
							},
							href: getCmsURL(
								'/corporate-blog/introducing-income-navigator-a-new-leader-in-income-verification-coverage',
							),
							label: 'Introducing ‘Income Navigator,’ a New Leader in Income Verification',
							newTab: false,
						},
						{
							icon: {
								url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
							},
							href: getCmsURL(
								'/corporate-blog/main-takeaways-cash-flow-is-here-top-use-cases-helping-underwriters-today',
							),
							label: 'Webinar On-Demand: Cash Flow is Here: Top use cases helping underwriters today',
							newTab: false,
						},
						{
							icon: {
								url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
							},
							href: getCmsURL(
								'/corporate-blog/nova-credit-appoints-financial-industry-veteran-as-chief-customer-officer',
							),
							label: 'Nova Credit Appoints Financial Industry Veteran as Chief Customer Officer',
							newTab: false,
						},
						{
							icon: {
								url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
							},
							href: getCmsURL(
								'/corporate-blog/nova-credit-raises-usd45m-series-c-financing-to-scale-cash-flow-underwriting',
							),
							label: 'Nova Credit Raises $45M Series C Financing',
							newTab: false,
						},
						{
							icon: {
								url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
							},
							href: getCmsURL(
								'/corporate-blog/nova-credit-expands-into-canada-partnering-with-scotiabank-as-first-canada',
							),
							label: 'Nova Credit Expands to Canada, Partners with Scotiabank',
							newTab: false,
						},
					],
					ctas: [
						{
							icon: {
								url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
							},
							href: getCmsURL('/corporate-blog'),
							label: 'View all resources',
							newTab: false,
						},
					],
					includeCtaOnMobile: true,
				},
			],
			cta: {
				label: 'Request a Meeting',
				newTab: false,
				href: getCmsURL('/connect-nova-credit'),
			},
		},
		{
			label: 'Personal',
			href: getCmsURL('/personal'),
			name: 'personal',
			menu: [
				{
					title: 'Marketplace',
					columns: [
						{
							columnHeadline: null,
							links: [
								{
									icon: null,
									href: APP_PATHS.MARKETPLACE,
									label: 'Overview',
									newTab: false,
								},
								{
									icon: {
										url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1F3OsjOWzch9HZEiWDbjkk/735237173f887afb68534b9c18006b3e/menu_icon_-_credit_cards.svg',
									},
									href: APP_PATHS.CARDSHOP,
									label: 'Credit Cards',
									newTab: false,
								},
								{
									icon: {
										url: 'https://images.ctfassets.net/6bj3xgxxnl0k/6CwQDNx4NHz2XpH5VTtHpq/dc8735dc577eefef956d15929d71c7f1/menu_icon_-_mobile_phones.svg',
									},
									href: getCmsURL('/mobile-phone'),
									label: 'Mobile Phone',
									newTab: false,
								},
								{
									icon: {
										url: 'https://images.ctfassets.net/6bj3xgxxnl0k/4wkE3iPa3GbUgBt3wWzTL1/21b7f79b8ea65d516ab4c49bcdcd4297/menu_icon_-_auto.svg',
									},
									href: getCmsURL('/partner-westlake-financial'),
									label: 'Auto Loans',
									newTab: false,
								},
								{
									icon: {
										url: 'https://images.ctfassets.net/6bj3xgxxnl0k/27KN9H9q7reVfLofUJU99v/3184f6a9d6d0448ee22267f264393f95/menu_icon_-_student_loans.svg',
									},
									href: getCmsURL('/student-loan'),
									label: 'Student Loans',
									newTab: false,
								},
							],
							ctas: [
								{
									icon: {
										url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
									},
									href: APP_PATHS.GET_STARTED,
									label: 'Check Your Eligibility for U.S. Products',
									newTab: false,
								},
								{
									icon: {
										url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
									},
									href: APP_PATHS.REFER_A_FRIEND,
									label: 'Refer a Friend',
									newTab: false,
								},
							],
						},
					],
				},
				{
					title: 'Resources',
					columns: [
						{
							columnHeadline: null,
							links: [
								{
									icon: {
										url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
									},
									href: APP_PATHS.GUIDE,
									label: 'Immigration Guide',
									newTab: false,
								},
								{
									icon: {
										url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
									},
									href: getCmsURL(
										'/resources/how-to-build-credit-after-moving-to-the-us',
									),
									label: 'How to Build Credit',
									newTab: false,
								},
								{
									icon: {
										url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
									},
									href: 'https://help.novacredit.com/s/',
									label: 'Help Center',
									newTab: false,
								},
							],
							ctas: [
								{
									icon: {
										url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
									},
									href: getCmsURL('/corporate-blog'),
									label: 'View all Resources',
									newTab: false,
								},
							],
						},
					],
				},
			],
			mobileMenu: [
				{
					mobileHeadline: null,
					links: [
						{
							icon: null,
							href: getCmsURL('/personal'),
							label: 'Overview',
							newTab: false,
						},
						{
							icon: null,
							href: APP_PATHS.MARKETPLACE,
							label: 'Marketplace',
							newTab: false,
						},
						{
							icon: {
								url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1F3OsjOWzch9HZEiWDbjkk/735237173f887afb68534b9c18006b3e/menu_icon_-_credit_cards.svg',
							},
							href: APP_PATHS.CARDSHOP,
							label: 'Credit Cards',
							newTab: false,
						},
						{
							icon: {
								url: 'https://images.ctfassets.net/6bj3xgxxnl0k/6CwQDNx4NHz2XpH5VTtHpq/dc8735dc577eefef956d15929d71c7f1/menu_icon_-_mobile_phones.svg',
							},
							href: getCmsURL('/mobile-phone'),
							label: 'Mobile Phone',
							newTab: false,
						},
						{
							icon: {
								url: 'https://images.ctfassets.net/6bj3xgxxnl0k/4wkE3iPa3GbUgBt3wWzTL1/21b7f79b8ea65d516ab4c49bcdcd4297/menu_icon_-_auto.svg',
							},
							href: getCmsURL('/partner-westlake-financial'),
							label: 'Auto Loans',
							newTab: false,
						},
						{
							icon: {
								url: 'https://images.ctfassets.net/6bj3xgxxnl0k/27KN9H9q7reVfLofUJU99v/3184f6a9d6d0448ee22267f264393f95/menu_icon_-_student_loans.svg',
							},
							href: getCmsURL('/student-loan'),
							label: 'Student Loans',
							newTab: false,
						},
					],
					ctas: [
						{
							icon: {
								url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
							},
							href: APP_PATHS.GET_STARTED,
							label: 'Check Your Eligibility for U.S. Products',
							newTab: false,
						},
						{
							icon: {
								url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
							},
							href: APP_PATHS.REFER_A_FRIEND,
							label: 'Refer a Friend',
							newTab: false,
						},
					],
					includeCtaOnMobile: true,
				},
				{
					mobileHeadline: 'Resources',
					links: [
						{
							icon: {
								url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
							},
							href: APP_PATHS.GUIDE,
							label: 'Immigration Guide',
							newTab: false,
						},
						{
							icon: {
								url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
							},
							href: getCmsURL(
								'/resources/how-to-build-credit-after-moving-to-the-us',
							),
							label: 'How to Build Credit',
							newTab: false,
						},
						{
							icon: {
								url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
							},
							href: 'https://help.novacredit.com/s/',
							label: 'Help Center',
							newTab: false,
						},
					],
					ctas: [
						{
							icon: {
								url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
							},
							href: getCmsURL('/corporate-blog'),
							label: 'View all Resources',
							newTab: false,
						},
					],
					includeCtaOnMobile: true,
				},
			],
			cta: {
				label: 'Get Started',
				newTab: false,
				href: APP_PATHS.GET_STARTED,
			},
		},
	],
	aboutUsDropdownTitle: 'About Us',
	aboutUsDropdownLinks: {
		cta: {
			icon: {
				url: 'https://images.ctfassets.net/6bj3xgxxnl0k/1TCiBU8AS5knBDHJ0s2OvV/0e9075a46c3d626ed6c126988e316f76/menu_icon_-_right_chevron.svg',
			},
			href: 'https://help.novacredit.com/s/contactsupport',
			label: 'Get In Touch',
			newTab: false,
		},
		links: [
			{ label: 'Company', href: getCmsURL('/company'), newTab: false, icon: null },
			{ label: 'Careers', href: getCmsURL('/careers'), newTab: false, icon: null },
			{ label: 'Press', href: getCmsURL('/press'), newTab: false, icon: null },
			{
				label: 'Corporate Blog',
				href: getCmsURL('/corporate-blog'),
				newTab: false,
				icon: null,
			},
		],
		mobileHeadline: null,
		includeCtaOnMobile: true,
	},
};
