// Breakpoints are ported from the CMS.
// New breakpoints to be established by https://novacredit.atlassian.net/browse/ENG-13399.
var breakpoints = {
  // Small devices (landscape phones, 576px and up)
  small: '@media (min-width: 576px)',
  // Medium devices (tablets, 768px and up)
  medium: '@media (min-width: 768px)',
  // Large devices (desktops, 992px and up)
  large: '@media (min-width: 992px)',
  // Extra large devices (large desktops, 1200px and up)
  xl: '@media (min-width: 1200px)'
};

export { breakpoints as b };
