/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';

import './SubmenuLink.scss';

const SubmenuLink = ({ label, href, icon, newTab, isMobileCta, onClick }) => {
	const newTabAttr = newTab
		? {
				target: '_blank',
				rel: 'noreferrer noopener',
		  }
		: {};
	return (
		<li className={classNames('uq-submenu-link', { 'mobile-cta': isMobileCta })}>
			{icon && <img src={icon.url} alt="" className="uq-submenu-link-icon" />}
			<a className="uq-submenu-link-label" href={href} onClick={onClick} {...newTabAttr}>
				{label}
			</a>
		</li>
	);
};

export default SubmenuLink;
