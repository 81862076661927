import type { ConsentMap } from '../consent/common';
import { consentGrantedForService } from '../consent/common';
import type { Env } from '../types';
import { loadUserleap } from '../vendor-scripts/userleap';

const USER_LEAP_ENVS = {
	development: 'HhMqEGSK6UN',
	staging: 'HhMqEGSK6UN', // we don't have a staging env with userleap, so using development
	production: 'dPCcu9x_f-Q',
};

const initialize = (nodeEnv: Env = 'development', consentMap?: ConsentMap): void => {
	if (consentMap && !consentGrantedForService(consentMap, 'Userleap')) {
		// Do not load Userleap if user has not consented to cookies
		return;
	}

	loadUserleap(USER_LEAP_ENVS[nodeEnv]);
};

export default { initialize };
